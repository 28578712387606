<template>
	<div class="psd_wrap">
		<el-dialog :title="$t('modify.password')" :visible.sync="dialogVisible" width="90%" @close='closeDialog' :append-to-body="true" class="login_box" :class="isIphone?'login_mini':''">
			<!-- 表单 -->
      <el-form
        ref="LoginFormRef"
        :model="loginForm"
        label-width="0"
        :rules="LoginFormRules"
        class="login_form"
      >
        <el-form-item prop="password">
          <el-input
            ref="customerInput"
            :placeholder="$t('modify.initial')"
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="comfirpsd">
          <el-input
            :placeholder="$t('modify.confirm')"
            v-model="loginForm.comfirpsd"
            prefix-icon="el-icon-unlock"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPsd">
          <el-input
            :placeholder="$t('modify.confirm')" 
            v-model="loginForm.checkPsd"
            prefix-icon="el-icon-unlock"
            type="password"
          ></el-input>
        </el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="sumitpsd()">{{$t('register.Submit')}}</el-button>
				<!-- <el-button  @click="save()">确 定</el-button> -->
			</span>

		</el-dialog>
	</div>
</template>

<script>
// import { toBase64 } from "../../api/base64.js";
import axios from '../../api/axios'
	export default {
		name: "changePsd",
    props: ["curretUser"],
		data() {
    const comfpsd = (rule, value, callback) => {
      if (value =="") {
        callback(new Error(this.$t('register.passwordss')));
      } else if (value !== this.loginForm.comfirpsd) {
        callback(new Error(this.$t('register.match')));
      } else {
        callback();
      }
    };
    return {
      isIphone:false,
      dialogVisible: false,
      istoEnter: false,
      // 登录的初始化数据：备注默认应该是空
      loginForm: {
        password: "",
        comfirpsd:"",
        checkPsd:"",
      },
      // 正则表达式的相关校验
      LoginFormRules: {
        password: [{ required: true, message: this.$t('modify.initial'), trigger: "blur" }],
        checkPsd: [{ required: true, trigger: 'blur', validator: comfpsd }],
        // comfirpsd: [{ required: true, trigger: "blur" , validator:comfpsd}]
        comfirpsd: [{ required: true, message:  this.$t('modify.newPassword'), trigger: "blur"}]
      },
    };
		},
		// props: ["reGoods"],
		methods: {
      closeDialog(){//点空白处这个事件不执行
				this.dialogVisible = false;
				this.$emit('update:sechShow', false);
			},
			cancel() {
				this.closeDialog();
			},
      sumitpsd(){
        this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
          var that=this;
        var url = '/reach/portal/api/user/modifyPwd'
        axios.post(url, {}, {
          params: {
            oldPassword: this.$md5(this.loginForm.password),
            newPassword: this.$md5(this.loginForm.comfirpsd)
          }
        }).then(response => {
        if (response.data.code === 1) {
           this.$notify({
              title: this.$t('modify.successful'),
              type: "warning",
            });
             setTimeout(function () { 
              that.$router.push({ path: "/loginn" });
            }, 2000);
          } 
          else {
              this.$notify({
                title: this.$t('modify.Initial'),
                type: "warning",
              });
              this.loginForm.password='';
              this.$refs.customerInput.$el.querySelector('input').focus();
          }
        })
        }})
        
      }
		},
		created() {
         this.isIphone=this.$isphone();
		}
	}
</script>
<style lang="less" scoped>
  .login_container{
    // background-color: #2b4b6b;
    // background-image: linear-gradient(to right , #2b4b6b, skyblue);
            // filter: blur(20px);
    height: 10rem;
    padding-top: 9.5rem;
    position: relative;
  }
  .bg{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right , #2b4b6b, skyblue);
    // filter: blur(20Px);
   }
  .login_box{
    width: 10rem;
    height: 51%;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%,-50%)
  }

  .avatar_box{
    width: 80Px;
    height: 80Px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 64%;
      height: 69%;
    //   border-radius: 50%;
      background-color: #eee;
    }
  }

  .login_form{
    /* position: absolute;
    bottom: 5%; */
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 1rem;
  }

  .btns{
    display: flex;
    justify-content:center;
  }
 .login_box /deep/ .el-input__inner {
    height: 0.9rem;
    line-height: 0.4rem;
    width: 98%;
    padding-left: 0.8rem;
}
.login_box /deep/ .el-form-item {
    margin-bottom: 0.35rem;
}
 .login_box /deep/ .el-form-item__error {
    left: 4.5%;
}
 .login_box /deep/ .el-input__prefix {
    left: 0.2rem;
}
 .login_box /deep/ .el-input__icon {
    font-size: 27Px;
    height: 100%;
    width: 0.6rem;
    line-height: 0.58rem;
}
 .login_box /deep/ .el-button {
    width: 1rem;
    height: 0.5rem;
    display: block;
    margin: 0 auto;
    font-size: 17Px;
}
 .login_box /deep/ .el-dialog__title{
    font-size: 20Px;
    font-weight: 600;
}
 .login_box /deep/ .el-dialog{
    margin-top: 0.5rem!important;
 }
 .login_mini{
   height: 33%;
   /deep/ .el-input{
    font-size: 20Px; 
  }
  /deep/ .el-dialog__headerbtn{
    font-size: 24Px;
  }
 }
</style>