<template>
  <div>
      <el-dialog :title="$t('Cart.name')" :visible.sync="dialogVisible" width="40%" @close='closeDialog' :append-to-body="true" class="area_wrap">
        <div class="areaCice_wrap">
            <el-cascader
                :placeholder="$t('Cart.region')"
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange">
            </el-cascader>
            <el-input  :placeholder="$t('Cart.detailed')" v-model="input" clearable></el-input>
            <el-input :placeholder="$t('Cart.phone')"  v-model="phone" clearable></el-input>
            <el-input :placeholder="$t('Cart.recipient')" v-model="addressee" clearable></el-input>
            <span v-show="showEmpty" class="showEmpty">{{$t('Cart.input')}}</span>
        </div>
        <el-button size="mini" type="primary" @click="toSave"  class="cartBnt">{{$t('Cart.Save')}}</el-button>
    </el-dialog>
  </div>
</template>

<script>
  import axios from '../../api/axios'
  import { regionData, CodeToText } from 'element-china-area-data'
  export default {
    name: "areaChioce",
    props: ["user"],
    data () {
      return {
        input:'',
        phone:'',
        addressee:'',
        dialogVisible: false,
        showEmpty: false,
        options: regionData,
        selectedOptions: [],
        adressobj:{},
        sendadview:'',
        getadId:0,//父adressView.vue传来
        getadbnt:''//父adressView.vue传来判断是哪里打开这个组件界面
      }
    },

    methods: {
        closeDialog(){
				this.dialogVisible = false;
				this.$emit('update:sechShow', false);
			},
		cancel() {
				this.closeDialog();
			},
        handleChange (value) {
           /*  console.log(CodeToText[selectedOptions[0]])
            console.log(CodeToText['510500']) */
            // CodeToText['110000'];
        },
        async toSave(){
          if(this.selectedOptions.length != 0&&this.input!=''&&this.phone!=''&&this.addressee!=''){
                    this.adressobj.province =CodeToText[this.selectedOptions[0]];
                    this.adressobj.city =CodeToText[this.selectedOptions[1]];
                    this.adressobj.area =CodeToText[this.selectedOptions[2]];
                    this.adressobj.adDteail =this.input;
                    this.adressobj.addressee = this.addressee;
                    this.adressobj.phone = this.phone;
                    if(this.user&&this.getadbnt=='setad'){
                        this.sendadview=this.adressobj.province+this.adressobj.city+this.adressobj.area+this.adressobj.adDteail;
                        this.sendAdress("user_set");
                    }
                    else if(this.user&&this.getadbnt=='addAd'){
                        this.sendAdress("user_add");
                    }
                    else{
                        var localPay = JSON.parse(window.localStorage.getItem("allbuy")) || {};
                        localPay.adddress=this.adressobj.province+this.adressobj.city+this.adressobj.area+this.adressobj.adDteail
                        window.localStorage.setItem('allbuy', JSON.stringify(localPay));
                        this.sendAdress();
                }
           }
           else{
                 this.showEmpty=true;
           }
        },
        async sendAdress(e){
            var curretUser = window.localStorage.getItem("curretUser") || "";
            var usertoken = window.localStorage.getItem("usertoken") || "";
            var that=this;
            var obj = {
                "city":that.adressobj.city,
                "province":that.adressobj.province,
                "address":that.adressobj.adDteail,
                "isNewRecord":true,
                "phone":that.adressobj.phone,
                "addressee":that.adressobj.addressee,
                "district":that.adressobj.area,
                "username":curretUser,
                "eshoppingtoken": usertoken,
            };
            if(e=='user_set'){
                obj.addressId=that.getadId;
            }
           /*  var parm = this.$qs.stringify(obj);
            let res=await this.$Net.post("/f/eshopping/eshoppingModeAndProduct/saveAddress", parm);
            if(res.data.statuscode==1){
                  that.$parent.getAdress();
                  that.dialogVisible = false;
            } */

             let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/saveAddress",obj);
              if(res.statuscode==0){
                  this.$confirm(this.$t('Cart.faileds'))
              }
              else{
                  this.$confirm(this.$t('Cart.saved'))
                  this.$parent.getAdress()
                  this.cancel()
              }
           /*  if(e!='user_set'&&e!='user_add'){
                that.$parent.send();
            } */
            //
        }
    }
  }
</script>
<style scoped lang="less">
.areaCice_wrap{
    width: 80%;
    margin: auto;
    .el-input{
        margin-top: 0.3rem;
    }
}
.area_wrap /deep/ .el-input__inner {
    height: 0.6rem;
    font-size: 0.3rem;
}
.area_wrap /deep/ .el-input__icon {
    width: 0.35rem;
    line-height:0.6rem;
}

.area_wrap /deep/ .el-dialog__header {
    padding: 0.5rem 0rem 0.3rem;
}
.area_wrap /deep/ .el-dialog__title {
    font-size: 0.3rem;
    font-weight: 600;
}
.area_wrap /deep/ .el-input--suffix .el-input__inner {
    padding-right: 0.5rem;
}

.area_wrap /deep/ .el-cascader-node {
    padding: 0 0.15625rem 0 0.104167rem;
    width: 50%;
    height: 0.4rem;
    line-height: 0.4rem;
    }
.area_wrap .cartBnt{
	// width: 2rem;
    // height: 0.6rem;
    margin: 0 auto;
    display: block;
    margin-top: 0.4rem;
}
.showEmpty{
    font-size: 0.25rem;
    color: red;
    margin-top: 0.2rem;
    display: inline-block;
}
</style>
<style>
    .el-cascader__dropdown {
    top: 27%!important;
}
</style>
