<template>
  <div class="bottom_wrap" :class="isIphone?'phone_wrap':''">
    <div class="bottom_top">
      <div class="bottom_left">
        <el-container>
          <el-header>
            <img :src="logoUrl"/>
          </el-header>
          <el-main>
            <el-row>
              <el-col>{{companyDesc}}</el-col>
              <!--
              <el-col>
                厦门锐骐物联技术股份有限公司是一家物联网智能平台提供商，为全球物联网开发者提供一站式物联网智能化解决方案，致力于连接消费者、
                制造品牌、OEM厂商和零售连锁的智能化需求，建立物联网全面生态，赋能传统行业，形成以AIOT为核心的增值生态平台。
              </el-col>
              -->
            </el-row>
          </el-main>
          <el-footer>
            <el-row>
              <el-col class="gzh">{{$t('buttom.follow')}}>></el-col>
            </el-row>
            <el-row>
              <el-col class="gzh_img">
                <img :src="qrCodeUrl"/>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </div>
      <div class="bottom_right">
        <Menu :dataList="fdata"></Menu>
        <div class="column">
          <Menu :dataList="fdata2" :whichMenu='MenuList[0]'></Menu>
          <Menu :dataList="fdata21" :whichMenu='MenuList[1]'></Menu>
        </div>
        <Menu :dataList="fdata3" :whichMenu='MenuList[2]'></Menu>
        <Menu :dataList="fdata4" :specilMenu=true :whichMenu='MenuList[3]'></Menu>
        <Menu :dataList="fdata5"></Menu>
      </div>
    </div>
    <div class="bottom_copny"><a style="color: #B0B0B0;" href="https://beian.miit.gov.cn/" target="_blank">Copyright © 2024 RIV锐骐科技 PROPERTY Inc. All Rights Reserved. IC 闽ICP备08102588号-1</a></div>
  </div>
</template>
<script>
import Menu from './home_bottom/menu.vue'
import axios from '../api/axios'
export default {
  name: 'bottom',
  components: {
    Menu
  },
  data () {
    return {
      logoUrl: '',
      companyDesc: '',
      qrCodeUrl: '',
      MenuList: ['fdata2', 'fdata21', 'fdata3', '', 'fdata4'],
      isIphone: false,
      fdata: [{
        title: this.$t('buttom.About'),
        names: [
        this.$t('buttom.REACH'),
        this.$t('buttom.news'),
        this.$t('buttom.video'),
        this.$t('buttom.Technical'),
        this.$t('buttom.consultation'),
        this.$t('buttom.Business'),
        this.$t('buttom.Join'),
        this.$t('buttom.Online'),
        this.$t('buttom.Mall'),
        this.$t('buttom.Offline')
        ]
      }],
      fdata2: [{
        title:  this.$t('buttom.Services'),
        names: [
          'LoRaWAN',
          'NBIOT',
          '4G LTE CAT.1',
          '4G LTE',
          '2G',
          'MiniPCIE',
          'USB dongle',
          '网关'
        ]
      }],
      fdata21: [{
        title: '',
        names: [
          'PaaS服务',
          'SaaS服务',
          '数据服务'
        ]
      }],
      fdata3: [{
        title: this.$t('buttom.Solution'),
        names: [
          '水表',
          '电表',
          '气表',
          '热表',
          '烟感',
          '气感',
          'DTU/RTU',
          'T-Box',
          'GPS tracker',
          '对讲机',
          '智能表计',
          '智慧城市',
          '运输及物流',
          '智慧工厂',
          '智慧校园',
          '智慧消防',
          '智慧医疗'
        ]
      }],
      fdata4: [{
        title: '',
        names: [
          '智慧城市',
          '运输及物流',
          '智慧工厂',
          '智慧校园',
          '智慧消防',
          '智慧医疗'
        ]
      }],
      fdata5: [{
        title: this.$t('buttom.Cooperation'),
        names: [
        this.$t('buttom.Partners'),
        this.$t('heard.cases'),
        this.$t('heard.agent'),
        // this.$t('heard.Space'),
        this.$t('buttom.Online'),
        this.$t('buttom.Mall'),
        this.$t('buttom.Offline')
        ]
      }]
    }
  },
  watch: {
    
  },
  //  created() {this.isphone();},
  mounted () {
    // 等待dom渲染完毕再加载
    var that = this
    this.fdata2[0].names = []
    this.fdata21[0].names = []
    this.fdata3[0].names = []
    this.fdata4[0].names = []
    this.commonSySize()
    this.$nextTick(() => {
      var p = navigator.platform
      const mac = p.indexOf('Mac') === 0
      const x11 = p === 'X11' || p.indexOf('Linux') === 0
      // 判断当前系统
      if (p.indexOf('Win') === 0 || mac || x11) {
        // 监听缩放
        window.addEventListener('resize', () => {
          that.commonSySize()
        })
        // 监听页面加载
        window.addEventListener('load', () => {
          that.commonSySize()
        })
      } else {
      }
    })
    this.getProductModuleMenu()
    this.getServiceModuleMenu()
    this.geSolveMenu()
    this.getLogo()
  },
  methods: {
    commonSySize () {
      const idTag = document.body
      if (this.$isphone()) { this.isIphone = true } else {
        if (idTag.offsetWidth <= 859) {
          this.isIphone = true
        } else {
          this.isIphone = false
        }
      }
    },
    getProductModuleMenu () {
      var that = this
      var url = '/reach/portal/api/menu/getProductMenu'
      axios.post(url,{},{
        params:{
          language:localStorage.getItem('locale')}
        }).then(response => {
          // console.log(response)
        if (response.data.code === 1) {
          var menuData = response.data.data
          if (menuData[0].menuList.length > 0) {
            menuData[0].menuList.forEach(element => {
              element.menuList.forEach(element2 => {
                that.fdata2[0].names.push(element2)
                console.log(that.fdata2)
              })
            })
          }
        }
      })
    },
    getServiceModuleMenu () {
      var that = this
      var url = '/reach/portal/api/menu/getServiceMenu'
      axios.post(url,{},{
        params:{
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          var fisRightList = response.data.data
          if (fisRightList.length > 0) {
            fisRightList.forEach(element => {
              that.fdata21[0].names.push(element)
            })
          }
        }
      })
    },
    geSolveMenu () {
      var that = this
      var url = '/reach/portal/api/menu/getSolutionMenu'
      axios.post(url,{},{
        params:{
          language:localStorage.getItem('locale')}
      }).then(response => {
        console.log(response,'1111111111111')
        if (response.data.code === 1) {
          var Iot = response.data.data[0].menuList
          var industryList = response.data.data[1].menuList
          if (Iot.length > 0) {
            Iot.forEach(element => {
              that.fdata3[0].names.push(element)
            })
          }
          if (industryList.length > 0) {
            industryList.forEach(element => {
              that.fdata4[0].names.push(element)
            })
          }
        }
      })
    },
    getLogo () {
      var url = '/reach/portal/api/homepage/getLogo'
      axios.post(url,{},{
        params:{
          language:localStorage.getItem('locale')
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.logoUrl = response.data.data.logoUrl
          this.companyDesc = response.data.data.content
          this.qrCodeUrl = response.data.data.qrCode
        }
      })
    }
  }
}
</script>
<style>
	.bottom_left .el-col-24 {
		color: #646464;
		font-size: 0.25rem;
		margin-top: 0.3rem;
	}
    .bottom_left .el-header{
		display: flex;
        padding-left: 0.1rem;
		height: auto!important;
	}
	.bottom_left .el-row{
		text-align: left;
	}
	.el-main .el-row:nth-child(2) .el-col-24 {
		margin-top: 22px;
	}
</style>
<style scoped lang="less">
	.bottom_wrap {
		.bottom_top {
			display: flex;
			justify-content: space-between;
            width: 100%;
			.bottom_left {
				width: 30%;

				img {
					width: 40%;
                    height: 54%;
				}

				.el-main {
					padding: 0 0 34px 20px;
				}

				.el-footer {
					.gzh {
						color: #333333;
						font-size: 0.25rem;
					}

					.gzh_img {
						text-align: center;
						width: 3.9rem;

						img {
							width: 2.1rem;
							height: 2.1rem;
							margin-top: 0.2rem;
						}
					}
				}
			}

			.bottom_right {
				display: flex;
				width: 60%;
				justify-content: space-around;
				.column{
					display: flex;
					flex-direction: column;
				}
			}
		}

		.bottom_copny {
			    width: 100%;
				text-align: center;
				color: #B0B0B0;
				font-size: 14Px;
				margin: 0.5rem;
				margin-left: 3.6rem;
		}

	}
	.phone_wrap{
		.bottom_top{
			.bottom_left{
				width: 100%;
				img{
					width: 30%;
				}
				.el-col-24 {
					color: #646464;
					font-size: 0.25rem;
				}
				.el-footer {
					padding-top: 0.2rem;
					.gzh_img img{
						margin-top: 0.5rem;
					}
					.gzh{
						font-size: 20Px;
				    }
				}
			}
			.bottom_right{
				display: none;
			}

		}
		.bottom_copny{
			width: 100%;
			text-align: left;
			color: #B0B0B0;
			font-size: 14Px;
			margin: 2rem 0 0.5rem 0;
		}
	}
</style>
