<template>
	<div class="sec_rightbox cursor">
		<div v-if="solveCardCol" @click="toSolvePath(solveCardCol.menuId)">
			<img :src="solveCardCol.icon">
			<span>{{ solveCardCol.menuName }}</span>
		</div>
		<div v-else @click="toPath(cardCol)">
			<img :src="cardCol.imgUrl">
			<span>{{ cardCol.name }}</span>
		</div>
	</div>
</template>

<script>
import globalConst from "../../commethods/globalConst";

export default {
	name: "cardBox",
	data() {
		return {
		}
	},
	props: ["cardCol", "solveCardCol"],
	methods: {
		toPath(item7) {
			console.log(item7.name)
			if (item7.name == '代理商' || item7.name == 'agent') {
				console.log(111111111111)
				this.$router.push({ path: '/agent' })
			}
			if (item7.name == '众创与孵化器' || item7.name == 'Make Space') {
				console.log(22222222)
				this.$router.push({ path: '/incubator' })
			}
			if (item7.name == '如何购买' || item7.name == 'Shopping online') {
				//window.open(this.$store.state.shopUrl+'/eshopping/storePage')
				this.$router.push({ path: '/howBuy' })
			}
			if (item7.name == '视频' || item7.name == 'video') {
				this.$router.push({ path: '/videoView' })
			}
			if (item7.name == '新闻' || item7.name == 'news') {
				this.$router.push({ path: '/news' })
			}
			if (item7.name == '联系我们' || item7.name == 'contact us') {
				this.$router.push({ path: '/contact' })
			}
			if (item7.name == '加入我们' || item7.name == 'Join us') {
				this.$router.push({ path: '/addUs' })
			}
			if (item7.name == '关于锐骐' || item7.name == 'About REACH') {
				this.$router.push({ path: '/reach' })
			}
			if (item7.name == '合作伙伴' || item7.name == 'Partners') {
				this.$router.push({ path: '/cooperation' })
			}
			if (item7.name == '成功案例' || item7.name == 'success cases') {
				this.$router.push({ path: '/cooperation', query: { succs: true } })
			}
			if (item7.name == '水表' || item7.name == '电表' || item7.name == '气表') {
				window.open('http://172.16.10.115:8088')
			}
			if (item7.name == '气感') {
				window.open('http://lora-gas.rivcloud.com')
			}
			if (item7.name == '文档下载' || item7.name == 'Download') {
				this.$router.push({ path: '/doc' })
			}
			if (item7.name == '开发者文档' || item7.name == 'Documentation') {
				var docUrl = globalConst.docUrl + '/#/show/doc/126?page_id=435'
				this.$router.push({ path: '/docCenter', query: { docUrl: docUrl } })
			}
			if (item7.name == '帮助中心' || item7.name == 'Help Center') {
				this.$router.push({ path: '/helpCenter' })
			}
			if (item7.name == '商务咨询' || item7.name == 'Business Consulting') {
				this.$router.push({ path: '/business' })
			}
			if (item7.name == '技术咨询' || item7.name == ' Technical consultation') {
				this.$router.push({ path: '/technology' })
			}

		},
		toSolvePath(solutionTypeId) {
			localStorage.setItem('solutionTypeId', solutionTypeId)
			this.$router.push({ path: '/solve', query: { solutionTypeId } })
		}
	},
	mounted() { }
}
</script>

<style scoped lang="less">
.sec_rightbox {
	display: flex;
	// justify-content: center;
	align-items: center;
	margin: 0.4rem 0 0.6rem 0;

	img {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 0.4rem;
	}

	span {
		line-height: 0.8rem;
		font-size: 14Px;
		color: #3C3C3C;
		font-family: 'Arial Normal', 'Arial', sans-serif;
		font-weight: 400;
		font-style: normal;

		&:hover {
			color: #1db8f3;
		}
	}
}
</style>
