<template>
  <div>
    <div class="product_wrap" :style="[{background:getData.backGroud}]">
      <p class="altitle">{{getData.title}}</p>
      <p class="subtitle">{{getData.content}}</p>
      <el-row type="flex" justify="space-between">
        <el-col v-for="(item,index) in getData.productList" :key="index">
          <el-card shadow="always">
            <a :href="item.productLink" target="_blank">
              <img :src="item.productImage"/>
              <p>{{item.productName}}</p>
            </a>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'produceList',
    props: ['getData'],
    data () {
      return {}
    },
    created () {},
    mounted () {},
    methods: {}
  }
</script>
<style scoped lang="less">
  .product_wrap {
    background-color: #f0f0f0;
    padding-top: 0.3rem;
    .subtitle{
      font-size: 0.25rem;
      margin-top: 0.3rem;
      color: #5a5a5a;
    }
    .el-row{
      overflow: hiddesn;
      padding: 0.5rem 0;
      width: 83%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .el-col {
        width: 16%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.8rem;
        .el-card {
          width: 3rem;
          height: 3rem;;
          border-radius: 0.15rem;
          box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
          .el-card__body {
            padding: 0px;
            color: #a4a4a4;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            a{
              color: #2c3e50;
            }
          }
        }
      }
    }

  }
</style>
