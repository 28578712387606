<template>
 <div class="downLoadWrap">
   <p class="altitle">{{getData.title}}</p>
   <div  class="introWrap">
    <div class="docWrap" v-for="(item,index) in getData.dataFileList" :key="index">
        <div class="top">
            <img :src="item.dataImage"/>
        </div>
        <div class="bottom">
        <p>{{item.dataTitle}}</p>
        <a :href="item.dataFile" target="_blank"><i class="far fa-file-pdf"></i>下载案例</a>
        </div>
    </div>
    <div class="bntWrap">
        <el-button @click.native="consult" v-if="showBnt" class="iconButton"><i class="far fa-comment-dots"></i><span class="txtButton">服务咨询</span></el-button>
        <el-button @click.native="resport">{{getData.button}}</el-button>
    </div>
  </div>
 </div>
</template>
<script>
export default {
  name: 'paas',
  props: ['getData'],
  data () {
    return {
        showBnt:true
    }
  },
  created () {},
  mounted () {
      if(this.$route.path.indexOf('solve')!=-1){
           this.showBnt=false;
      }
    },
  methods: {
    consult () {

    },
    resport () {

    }
  }
}
</script>
<style scoped lang="less">
.downLoadWrap{
    margin-bottom: 2rem;
}
.introWrap{
   width: 85%;
   margin: 0 auto;
//    height: 10.7rem;
   padding-top: 0.5rem;
   display: flex;
   flex-wrap: wrap;
   .docWrap{
        width: 30%;
        margin: 0 0.7rem 0.5rem 0;
        .top{
            width: 100%;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .bottom{
            display: flex;
            justify-content: space-between;
            margin-top: 0.3rem;
            p{
                display: inline-block;
                font-size: 0.3rem;
                font-weight: 600;
            }
            a{
                color: #22b0ee;
                font-size: 0.3rem;
                cursor: pointer;
            }
        }
   }
   .bntWrap{
       width: 100%;
       margin-top: 1rem;
       .el-button{
            background-color: #3ac0f1;
            color: #fff;
            border-radius: 0.17rem;
            width: 2.3rem;
            // height: 0.6rem;
            // position: relative;
            // .svg-inline--fa.fa-w-16{
            //     height: 0.4rem;
            //     width: 4em;
            //     position: absolute;
            //     bottom: 16%;
            //     left: 7%;
            // }
       }
       .iconButton{
            text-align: right;
            // padding-right: 0.2rem;
            margin-right: 2.5rem;

            .txtButton {
                margin-left: 10px;
            }
       }
   }
}

</style>
