<template>
  <div class="content_all">
    <div class="content_wrap">
      <el-tabs type="border-card" v-model="activeName" :stretch=true>
        <el-tab-pane :name="`indexx${i}`" :label="allArry[i][0].buyStatus" v-for="(arr, i) in allArry" :key="i">
          <div v-if="allArry[i][0].orderid == ''" class="noneData"> {{ $t('Purchased.currently') + allArry[i][0].buyStatus
        + $t('Purchased.OrderData') }}</div>
          <buyList ref="buylistt" :getGoods="arr" v-else @fatherMethod="commonHandle" @tochangeTab="changeTab">
          </buyList>
          <div class="editWuliu" @click="openDialog" v-if="allArry[i][0].buyStatus == $t('Purchased.Exchange')">>>{{
        $t('Purchased.logistics') }}</div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :title="$t('Purchased.logistics')" :visible.sync="logisDialog" width="60%">
      <div class="logistics_max">
        <div class="logistics_wrap">
          <el-input :placeholder="$t('Purchased.tracking')" v-model="logisNum"> </el-input>
          <el-input :placeholder="$t('Purchased.company')" v-model="logisName"> </el-input>
        </div>
        <div v-show="showEdit" class="showEdit">{{ $t('Purchased.and') }}</div>
        <el-button type="success" @click="tlogistics()" v-preventReClick="2000">{{ $t('Purchased.Submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../../api/axios'
import buyList from "./buyList.vue";
export default {
  components: {
    buyList,
  },
  data() {
    return {
      allArry: [],
      activeName: 'indexx0',
      logisDialog: false,
      showEdit: false,
      logisNum: '',
      logisName: '',
      curOrderid: '',
    };
  },
  created() {
    this.commonHandle();
  },
  directives: {
    preventReClick: {
      bind(el, binding) {
        el.addEventListener("click", () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, binding.value || 3000);
          }
        });
      },
    },
  },
  methods: {
    openDialog() { this.logisDialog = true; },
    tlogistics() {
      var usertoken = window.localStorage.getItem("usertoken") || "";
      var that = this;
      if (that.logisNum != '' && that.logisName != '') {
        this.showEdit = false;
        axios.post("/f/eshopping/eshoppingModeAndProduct/WlForm", {}, {
          params: {
            orderId: this.curOrderid,
            eshoppingtoken: usertoken,
            WLid: that.logisNum,
            WLcompany: that.logisName
          }
        }).then(res => {
          if (res.data.statuscode == 1) {
            this.$msgboxx(that, this.$t('Purchased.information'));
            this.logisDialog = false;
          }
          else {
            this.$msgboxx(that, this.$t('Purchased.failed'));
          }
          this.$emit('fatherMethod');
        })
      }
      else {
        this.showEdit = true;
      }
    },
    async getBuy(sendObj, buyStatus) {
      var that = this;
      let buyList = []
      let obj = {
        createBy: "",
        createDate: "",
        orderPay: "",
        orderStatus: "",
        orderWay: "",
        orderid: "",
        updateBy: "",
        picture: "",
        productSells: 0,
        productName: "",
        buyStatus: ""
      };
      switch (buyStatus) {
        case 0:
          obj.buyStatus = this.$t('Purchased.notshipped');
          break;
        case 1:
          obj.buyStatus = this.$t('Purchased.Shipped');
          break;
        case 2:
          obj.buyStatus = this.$t('Purchased.Completed');
          break;
        case 3:
          obj.buyStatus = this.$t('Purchased.refund');
          break;
        case 4:
          obj.buyStatus = this.$t('Purchased.Exchange');
          break;
        case 5:
          obj.buyStatus = this.$t('Purchased.Refund');
          break;
        case 6:
          obj.buyStatus = this.$t('Purchased.Refunds');
          break;
        case 7:
          obj.buyStatus = this.$t('Purchased.progress');
          break;
        case 8:
          obj.buyStatus = this.$t('Purchased.completed');
          break;
        case 9:
          obj.buyStatus = this.$t('Purchased.Unpaid');
          break;
        default:
          break;
      }
      let res = await this.$Net.get(
        "/f/eshopping/eshoppingModeAndProduct/orderlist",
        sendObj
      );
      if (res.length > 0) {
        res.forEach((v, i) => {
          let objs = {
            createBy: "",
            createDate: "",
            orderPay: "",
            orderStatus: "",
            orderWay: "",
            orderid: "",
            updateBy: "",
            picture: "",
            productSells: 0,
            productName: "",
            buyStatus: "",
            wuliuCompany: "",
            wuliuId: "",
            // 开票状态
            receiptStatus: "0",
          };
          that.$getSame(objs, obj);
          that.$getSame(objs, res[i]);
          objs.createDate = that.$timestampToTime(objs.createDate);
          objs.orderStatus = objs.buyStatus;
          buyList.push(objs);
        });
      }
      else {
        buyList.push(obj);
      }
      return buyList
    },
    changeTab(orderid) {
      this.activeName = "indexx4";
      this.logisDialog = true;
      this.curOrderid = orderid;
    },
    commonHandle() {
      var that = this;
      that.goods_list = [];
      let batchList = [];
      var usertoken = window.localStorage.getItem("usertoken") || "";
      var curretUser = window.localStorage.getItem("curretUser") || "";
      that.allbuy = JSON.parse(window.localStorage.getItem("allbuy")) || {};
      var sendObj = {
        createby: curretUser,
        eshoppingtoken: usertoken,
      };
      for (let i = 0; i < 10; i++) {
        sendObj.orderStatus = i;
        batchList.push(this.getBuy(sendObj, i));
      }
      Promise.all(batchList).then(res => {
        that.allArry = res
      }).catch(err => {
        console.log('error', err)
      })
    },
  },
};
</script>
<style lang="less" scoped>
.content_all {
  width: 100%;
  height: 100%;
  margin: 2rem 0 1rem 0;

  .content_wrap {
    width: 90%;
    margin: 0 auto;
  }

  .noneData {
    font-size: 16Px;
    line-height: 1rem;
  }

  /deep/ .el-tabs__nav-wrap {
    // margin: 0.3rem 0;
    // padding: 0 0px;
  }

  /deep/ .el-tabs__item {
    font-size: 14Px;
  }

  /deep/ .el-tabs__nav {
    // float: right;
  }
}

/deep/ .el-dialog {
  padding: 2% 0;

  .el-dialog__header {
    border-bottom: 1px solid #dedede;
    padding-bottom: 0.3rem;
    padding-top: 0;
    
    font-weight: 600;

    .el-dialog__title {
      font-size: 0.35rem;
    }
  }
}

.logistics_max {
  .logistics_wrap {
    display: flex;
    margin-bottom: 20px;

    /deep/ .el-input__inner {
      width: 93%;
      height: 0.5rem;
      font-size: 18Px;
    }
  }

  // /deep/ .el-button {
  //   margin-top: 0.4rem;
  //   width: 1.5rem;
  //   height: 0.5rem;
  //   font-size: 18Px;
  // }
}

.editWuliu {
  color: #1fc0f6;
  font-size: 0.27rem;
  font-weight: 600;
  margin: 0 0.2rem 0.2rem 0;
  cursor: pointer;
  text-align: right;
}

.showEdit {
  color: red;
  font-size: 0.25rem;
  margin-top: 0.3rem;
}
</style>
