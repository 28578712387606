import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import storePage from '../views/detail/storePage'
import secstorePage from '../views/detail/secstorePage'
import loginn from '../views/loginn/loginn'
import register from '../views/loginn/register'
import findBack from '../views/loginn/findBack'
import goodsDeil from '../views/goodsDeil/goodsDeil';
import payView from '../views/payView/payView';
import cartView from '../views/cartView/cartView';
import buyTab from '../views/buyList/buyTab';
import homePage from '../views/homePage/homePage';
import userDetail from '../views/userDetail/userDetail';
import wXuserDetail from '../views/userDetail/wXuserDetail';
//首页-产品与服务
import produceSeries from '../views/produce/produceSeries';
import addValue from '../views/produce/addValue';
import Airlan from '../views/produce/Airlan';
import Pass from '../views/produce/Pass';
//解决方案
import solve from '../views/solve/solve';
//关于我们-视频
import videoView from '../views/aboutUs/videoView';
import news from '../views/aboutUs/news';
import contact from '../views/aboutUs/contact';
import addUs from '../views/aboutUs/addUs';
import reach from '../views/aboutUs/reach';
import docCenter from '../views/help/docCenter';
import helpCenter from '../views/help/helpCenter';
import business from '../views/help/business';
import technology from '../views/help/technology';
import developer from '../views/developer/developer';
//下载
import doc from '../views/download/download';
// 合作与生态
import cooperation from '../views/cooperation/cooperation';
import agent from '../views/cooperation/agent';
import applyAgent from '../views/cooperation/AgencyApply'
import incubator from '../views/cooperation/incubator';
import contract from '../views/cooperation/contract';
import howBuy from "../views/buyList/howBuy";
import i18n from '../lang/index'
Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
import Confirm from '@/components/Confirm/Confirm.vue'
const ConfirmBox = Vue.extend(Confirm);

Confirm.install = (content, title, options) => {
  if (typeof title === 'object') {
    options = title;
    title = '';
  } else if (title === undefined) {
    title = '';
  }

  options = Object.assign({
    title: title,
    content: content,
  }, options);

  let instance = new ConfirmBox({
    data: options
  }).$mount();

  document.body.appendChild(instance.$el);

  return instance.confirm();
};

const routes = [
  {
    path: '/',
    name: '/',
    component: homePage,//storePage
    meta: {
      title: 'REACH锐骐物联-锐骐科技-RIV锐智—锐骐物联唯一',
      title2: 'REACH AIoT',

    }
  },
  {
    path: '/storePage',
    name: 'storePage',
    component: storePage,
    meta: {
      title: 'REACH锐骐物联-锐骐科技-RIV锐智—锐骐物联唯一',
      title2: 'REACH AIoT',

    }
  },
  {
    path: '/loginn',
    name: 'loginn',
    component: loginn,
    meta: {
      title:"登录",
      title2:"Login",
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      title: '注册',
      title2:"Register",
    }
  },
  {
    path: '/findBack',
    name: 'findBack',
    component: findBack,
    meta: {
      title: '找回密码',
      title2:"Recover the password",
    }
  },
  {
    path: '/userDetail',
    name: 'userDetail',
    component: userDetail,
    meta: {
      title: '用户中心',
      title2:"User Center",
    }
  },
  {
    path: '/wXuserDetail',
    name: 'wXuserDetail',
    component: wXuserDetail,
    meta: {
      title: '微信用户填写信息',
      title2:"WeChat users fill in the information",
    }
  },
  {
    path: '/produceSeries',
    name: 'produceSeries',
    component: produceSeries,
    meta: {
      title: '产品系列页面',
      title2: 'Collection pages',
    }
  },
  {
    path: '/addValue',
    name: 'addValue',
    component: addValue,
    meta: {
      title: '产品系列页面',
      title2: 'Collection pages',
    }
  },
  {
    path: '/Airlan',
    name: 'Airlan',
    component: Airlan,
    meta: {
      title: '产品系列页面',
      title2: 'Collection pages',
    }
  },
  {
    path: '/Pass',
    name: 'Pass',
    component: Pass,
    meta: {
      title: '产品系列页面',
      title2: 'Collection pages',
    }
  },
  {
    path: '/solve',
    name: 'solve',
    component: solve,
    meta: {
      title: '解决方案',
      title2: 'Solution'
    }
  },
  {
    path: '/videoView',
    name: 'videoView',
    component: videoView,
    meta: {
      title: '视频中心',
      title2: 'Video Center',
    }
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {
      title: '新闻中心',
      title2: 'News Center',
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: {
      title: '联系我们',
      title2: 'Contact us'
    }
  },
  {
    path: '/addUs',
    name: 'addUs',
    component: addUs,
    meta: {
      title: '加入我们',
      title2: 'Join us',
    }
  },
  {
    path: '/reach',
    name: 'reach',
    component: reach,
    meta: {
      title: '关于锐骐',
      title2: 'About REACH',
    }
  },
  {
    path: '/doc',
    name: 'doc',
    component: doc,
    meta: {
      title: '开发者文档下载',
      title2: 'Developer documentation download',
    }
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation,
    meta: {
      title: '合作与生态',
      title2: 'Cooperation and ecology',
    }
  },
  {
    path: '/incubator',
    name: 'incubator',
    component: incubator,
    meta: {
      title: '众创与孵化器',
      title2: 'Crowd creation and incubator',
    }
  },
  {
    path: '/contract',
    name: 'contract',
    component: contract,
    meta: {
      // 修改浏览器标题
      title: '申请认证',
      title2: 'Apply for certification',
    }
  },
  {
    path: '/agent',
    name: 'agent',
    component: agent,
    meta: {
      title: '代理商',
      title2: 'agent',
    }
  },
  {
    path: '/applyAgent',
    name: 'applyAgent',
    component: applyAgent,
    meta: {
      title: '申请代理签约',
      title2: 'Apply for agency contracting',
    }
  },
  {
    path: '/docCenter',
    name: 'docCenter',
    component: docCenter,
    meta: {
      title: '开发者文档中心',
      title2: 'Developer Documentation Center',
    }
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: helpCenter,
    meta: {
      title: '帮助中心',
      title2: 'Help Center',
    }
  },
  {
    path: '/business',
    name: 'business',
    component: business,
    meta: {
      title: '商务咨询',
      title2: 'Business Consulting',
    }
  },
  {
    path: '/technology',
    name: 'technology',
    component: technology,
    meta: {
      title: '技术咨询',
      title2: 'Technical advice',
    }
  },
  {
    path: '/developer',
    name: 'developer',
    component: developer,
    meta: {
      title: '开发者中心',
      title2: 'Developer Center',
    }
  },
  {
    path: '/goodsDeil',
    name: 'goodsDeil',
    component: goodsDeil,
    meta: {
      title: '商品详情',
      title2: 'Product details',
    }
  },
  {
    path: '/howBuy',
    name: 'howBuy',
    component: howBuy,
    meta: {
      title: 'RIV商城',
      title2: 'RIV Mall',
    }
  },
  {
    path: '/payView',
    name: 'payView',
    component: payView,
    meta: {
      title: '支付结果',
      title2: 'Payment results',
    }
  },
  {
    path: '/cartView',
    name: 'cartView',
    component: cartView,
    meta: {
      title: '购物车',
      title2: 'Shopping Cart',
    }
  },
  {
    path: '/buyTab',
    name: 'buyTab',
    component: buyTab,
    meta: {
      title: '已购买商品',
      title2: 'The item has been purchased',
    }
  },
  {
    path: '/secstorePage',
    name: 'secstorePage',
    component: secstorePage,
    meta: {
      title: '后端浏览页'
    }
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: homePage,
    meta: {
      title: 'RIV锐骐物联 - REACH锐骐科技 - 锐骐物联网开源平台唯一官方网站',
      title2: 'REACH AIoT',
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router
