<template>
  <div class="introWrap" :class="showBackgd?'':'hidBg'">
    <p class="altitle">{{getData.title}}</p>
    <div>
      <p class="content">{{getData.content}}</p>
    </div>
    <el-row type="flex" justify="space-around">
      <el-col
        v-for="(item,index) in getData.dataFileList"
        :key="index">
        <a :href="item.link" target="_blank">
          <img :src="item.dataImage" style="width:170px; height:170px" />
          <p>{{item.dataTitle}}</p>
          <span class="enter">{{item.dataDescript}}</span>
        </a>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'paas',
  props: ['getData','showBackgd'],
  data () {
    return {
    }
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style scoped lang="less">
.introWrap{
   background: url('../../../assets/u473.png');
   height: 9rem;
   padding-top: 0.5rem;
  .content{
	width: 49%;
    height: 3rem;
    margin: 0 auto;
    font-size: 0.3rem;
    text-align: left;
    margin-top: 0.5rem;
   }
   .el-row{
        width: 70%;
        margin: 0 auto;
        img{
            width: 43%;
            height: 2rem;
        }
        p{
            font-weight: 700;
            font-style: normal;
            font-size: 20Px;
            margin: 0.25rem 0;
        }
        span{
            font-size: 0.25rem;
            width: 2rem;
            display: inline-block;
        }

   }
}
.hidBg{
  background:#f0f8fa;
  height: 10rem;
}
</style>
