<template>
	<div class="content_all" id="scrollmenu">
		<div class="lunch_wrap">
			<div class="lunch_top" ref="bnNews">
				<!--
        <p>锐骐物联LoRaWAN基于图像识别的<br/>地下表井智能改造解决方案</p>
        <p>疫情之下，热情依旧——锐骐科技隆重<br/>亮相IOTE 2020国际物联网展</p>
        <p>
          智能表计成全球物联网终端最快增量市场！<br/>锐骐科技推全球智能表计解决方案
        </p>
        -->
				<p v-for="(item, index) in newsList" :key="index">{{ item.title }}</p>
			</div>
			<!-- 轮播图  :autoPlay='false'-->
			<div class="lunch_bottom">
				<!-- <Swiper v-if="BannerList.length > 0">
          <Slide v-for="(item,index) in BannerList" :key="index">
            <a :href="item.adLink" target="_blank">
              <img :src="item.adImage" :height="item.height" :width="item.width"/>
            </a>
          </Slide>
        </Swiper>  -->
				<el-carousel :interval="6000" arrow="always" v-if="isHomepage" class="homeBaner">
					<el-carousel-item v-for="(item, index) in BannerList" :key="index">
						<a :href="item.adLink" target="_blank">
							<img :src="item.adImage" :height="item.height" :width="item.width" />
						</a>
					</el-carousel-item>
				</el-carousel>
				<el-carousel :interval="6000" arrow="always" v-else>
					<el-carousel-item v-for="(item, index) in BannerList" :key="index">
						<a :href="item.adLink" target="_blank">
							<img :src="item.adImage" :height="item.height" :width="item.width" />
						</a>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="totop_wrap">
			<!-- <Totop></Totop> -->
		</div>
	</div>
</template>
<script>
// import Totop from '../../components/toTop/toTop.vue'
import { Swiper, Slide } from 'vue-swiper-component'
import axios from '../../api/axios'
export default {
	name: 'banner',
	components: {
		// Totop,
		Swiper,
		Slide
	},
	props: ['type', 'menuId'],
	data() {
		return {
			isHomepage: false,
			currentDate: new Date(),
			tableData: {},
			currentPage: 1, // 当前页码
			total: 10, // 总条数
			pageSize: 2, // 每页的数据条数
			BanerNews: {
				newsBgColor: '#1fbaf3',
				newsHeight: '35px',
				newsNum: '3', // 新闻显示数量
				defaultNum: '3', // 默认显示数量
				fontColor: '#fff'
			},
			newsList: [],
			BannerList: [
				{
					width: '100%',
					height: '390px',
					imgPath: require('../../assets/try.gif'),
					imgLink: 'https://www.baidu.com/'
				},
				{
					width: '100%',
					height: '390px',
					imgPath: require('../../assets/banner3.jpg'),
					imgLink: 'https://www.baidu.com/'
				},
				{
					width: '100%',
					height: '390px',
					imgPath: require('../../assets/banner2.jpg'),
					imgLink: 'https://www.baidu.com/'
				}
			]
		}
	},
	created() { },
	mounted() {
		this.getBanerNews()
		this.getBanner()
		if (this.$route.path.search('homePage') != -1 || this.$route.path == '/') {
			this.isHomepage = true
		}
	},
	methods: {
		getBanerNews() {
			this.$nextTick(function () {
				this.$refs.bnNews.style.backgroundColor = this.BanerNews.newsBgColor
				this.$refs.bnNews.style.height = this.BanerNews.newsHeight
				this.$refs.bnNews.style.color = this.BanerNews.fontColor
			})
			var url = '/reach/portal/api/homepage/getNewsList'
			axios.post(url, {}, {
				params: {
					language: localStorage.getItem("locale")
				}
			}).then(response => {
				if (response.data.code === 1) {
					this.newsList = response.data.data
					// console.log('newList:', this.newsList)
				}
			})
		},
		getBanner() {
			if (this.menuId !== null && this.menuId !== '' && this.menuId !== undefined) {
				var url = '/reach/portal/api/menu/getMenuBanner'
				axios.post(url, {}, {
					params: {
						type: this.type,
						menuId: this.menuId
					}
				}).then(response => {
					if (response.data.code) {
						this.BannerList = response.data.data
					}
				})
			} else {
				url = '/reach/portal/api/homepage/getBannerList'
				axios.post(url, {}, {
					params: {
						type: this.type,
						language: localStorage.getItem("locale")
					}
				}).then(response => {
					if (response.data.code) {
						this.BannerList = response.data.data;
					}
				})
			}
		}
	}
}
</script>
<style scoped>
.heart .el-col-8 {
	width: 18.33333%;
}

.content_all .el-button--primary {
	width: 2.8rem;
	height: 0.63rem;
	line-height: 0.4rem;
	border-radius: 0.15rem;
	background-color: rgba(31, 186, 243, 1);
}

.product_row {
	padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.boderCol:after {
	width: 3.22rem;
	height: 0.1rem;
	background-color: #ffde5d;
	content: "";
	position: absolute;
	left: -0.067708rem;
	top: 3.24rem;
	z-index: 10;
}

.product_row .el-card {
	width: 3rem;
	height: 3rem;
	;
	border-radius: 0.15rem;
	box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
	padding: 0px;
	color: #a4a4a4;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.news_heart p {
	padding: 15px 0 19px 0;
}

.news_heart .el-card {
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

.news_heart .el-card__body {
	padding: 0;
}

.new_indro {
	padding: 0 8px 0 10px;
	text-align: left;
}

.news_heart .new_indro span {
	font-size: 0.25rem;
	color: #827c7c;
}

.news_heart .new_indro .news_title {
	display: block;
	font-weight: 600;
	font-size: 0.28rem;
	color: #3c3b3c;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	margin: 0.1rem;
}

.news_heart img {
	width: 100%;
}

/*  .aboutUs_heart .el-button--primary {
    width: 133px;
    height: 33px;
    line-height: 1px;
    border-radius: 6px;
    margin-top: 26px;
  } */
</style>
<style scoped lang="less">
.el-button {
	border: 0.05rem solid #DCDFE6;
	font-size: 0.26rem;
}

.content_all {
	padding-bottom: 10px;
	position: relative;
}

.content_all .totop_wrap {
	position: fixed;
	bottom: 1rem;
	right: 0.5rem;
	z-index: 20;
}

.altitle {
	font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
	font-weight: 250;
	font-style: normal;
	font-size: 0.4rem;
	margin-top: 0.5rem;
}

.lunch_wrap {
	width: 100%;
	background-color: #1fbaf3;
}

.lunch_top {
	// width: 100%;
	margin: 1rem auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 1.6rem;
	// margin-left:3.7rem;
	padding-left: 30px;
	padding-right: 30px;
}

.lunch_top p {
	max-width: 480px;
	width: 100%;
	margin-top: 1.2rem;
	text-align: center;
	// flex: 1;
	font-size: 0.24rem;
	color: #ffffff;
	// word-break: keep-all;
	white-space: nowrap;
	/* 防止文本换行 */
	overflow: hidden;
	/* 隐藏超出容器的内容 */
	text-overflow: ellipsis;
	/* 当文本溢出时显示省略号 */
}

.lunch_top p:nth-child(2) {
	// flex: 1.4;
	margin-left: 30px;
}

.lunch_top p:nth-child(3) {
	// flex: 1.5;
	text-align: center;
	margin-left: 30px;
}

.lunch_bottom {
	/deep/ .el-carousel {
		.el-carousel__container {
			height: 7.8rem;

			.el-carousel__arrow {
				height: 1rem;
				width: 1rem;
				font-size: 0.8rem;
			}

			img {
				/* width: 100%;
					height: 100%; */
			}
		}

		.el-carousel__button {
			width: 1rem;
			height: 0.1rem;
		}
	}

	.homeBaner {
		/deep/ .el-carousel__container {
			height: 600Px;
		}
	}

	.wh_slide {
		height: auto;
	}

	/deep/ .wh_indicator {
		bottom: 0.2rem;

		.wh_indicator_item {
			width: 1rem;
			height: 0.08rem;
			margin: 0rem 0.1rem;
			background: #fff;
			border-radius: 0;
		}

		.wh_show_bgcolor {
			background: #efcd5c;
		}
	}
}
</style>
