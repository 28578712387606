<template>
	<div class="top_wrap" @click="toTop()">
			<p href="#" class="link"></p>
	</div>
</template>

<script>
	export default {
		name: "Totop",
		data() {
			return {

			}
		},
		methods: {
			toTop() {
				this.$('html,body').animate({"scrollTop":0});
			}
		}
	}
</script>

<style scoped>
	.top_wrap {
		width: 0.7rem;
		height: 0.7rem;
		background-color: rgba(0, 0, 0, 0.498039215686275);
		position: relative;
	}
	.link {
		position: absolute;
		top: 2%;
		left: -75%;
		display: block;
		padding: 1em 3em 1em 1em;
		font-size: 0.35rem;
		font-weight: bold;
		text-decoration: none;
		cursor: pointer;
		/* box-shadow: inset 0 0 0 0.2em #e74c3c; //边框*/
	}

	.link:after,
	.link:before {
		content: "";
		display: block;
		position: absolute;
		-webkit-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
	}

	.link:before {
		top: 50%;
		right: 1em;
		width: 1em;
		height: 1em;
		transform-origin: 50% 50%;
		transform: translate(-0.05em, -0.6em) rotate(315deg);
		box-shadow: inset -0.2em 0.2em 0 0 #bfbfbf;
		border-radius: 0.2em 0.15em 0.2em 0.2em;
	}

	.link:after {
		top: 50%;
		right: 1em;
		width: 1.1em;
		height: 0.3em;
		transform: translate(0em, -0.1em) rotate(270deg);
		background-color: #bfbfbf;
		border-radius: 194.2em 47.15em 2.2em 194.2em;
	}

	.link:hover:before {
		/* -webkit-transform: translate(0.5em, -0.5em) rotate(45deg);
		transform: translate(0.5em, -0.5em) rotate(45deg); */
		box-shadow: inset -0.2em 0.2em 0 0 #ffffff;
	}

	.link:hover:after {
		/* -webkit-transform: translate(0.5em, -0.1em);
		transform: translate(0.5em, -0.1em); */
		background-color: #ffffff;
	}
</style>
