<style lang="scss" scoped>
textarea {
	display: block;
	width: 100%;
	min-height: 100Px;
	padding: 0;
	margin: 20Px 0;
	font-size: 16Px;
}
.keyboard {
	width: 493Px;
	margin: 0;
	padding: 0;
	list-style: none;
	user-select: none;

	li {
		float: left;
		margin: 0 5Px 5Px 0;
		width: 20Px;
		height: 20Px;
		line-height: 20Px;
		text-align: center;
		background: #fff;
		border: 1Px solid #e5e5e5;
		border-radius: 5Px;

		&:hover {
			position: relative;
			border-color: gray;
			cursor: pointer;
		}
		&:active {
			top: 1Px;
			left: 1Px;
		}
	}

	.tab, .delete {
		width: 70Px;
	}
	.capslock {
		width: 80Px;
	}
	.enter {
		width: 77Px;
	}
	.shift {
		width: 102Px;
	}
	.space {
		clear: left;
		width: 481Px;
	}
	.shifted {
		position: relative;
		top: 1Px;
		left: 1Px;
		border-color: #e5e5e5;
		cursor: pointer;
	}
	.capsed {
		position: relative;
		top: 1Px;
		left: 1Px;
		border-color: #e5e5e5;
		cursor: pointer;
	}
}
</style>
<template>
<div>
	<!-- <textarea type="text" v-model="keyboardText"></textarea> -->
	<ul class="keyboard">
		<li v-for="(key,index) in keyList"
		 :key="index" 
		 :class="{delete: key === 'Delete', tab: key === 'Tab', capslock: key === 'Caps', 
		 enter: key === 'Enter', shift: key === 'Shift', space: key === 'Space', 
		 shifted: (key === 'Shift') && hasShifted, capsed: (key === 'Caps') && hasCapsed }" 
		 v-text="key" @click="clickKey(key)">{{key}}</li>
	</ul>
</div>
	
</template>
<script>
	export default {
        name:'loginKey',
		props:['pswd'],
		data() {
			return {
				curKey:'',
				keyboardText:'',
				keyList: [],
				normalKeyList: [],
				shiftedKeyList: [],
				capsedKeyList: [],
				hasShifted: false,
				hasCapsed: false
			}
		},
		created() {
			let normalKeyList = ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'Delete',
						'Tab', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\',
						'Caps', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', "'", 'Enter',
						'Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', 'Shift', 
						'Space'], 

				shiftedKeyList = ['~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', 'Delete',
						'Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '{', '}', '|',
						'Caps', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ':', '"', 'Enter',
						'Shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '<', '>', '?', 'Shift', 
						'Space'],

				capsedKeyList = ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'Delete',
						'Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '[', ']', '\\',
						'Caps', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ';', "'", 'Enter',
						'Shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', '/', 'Shift', 
						'Space'];

			this.keyList = this.normalKeyList = normalKeyList;
			this.shiftedKeyList = shiftedKeyList;
			this.capsedKeyList = capsedKeyList;
		},
		methods: {
			clickKey(key) {

				switch(key) {
					case "Delete":
						let kbt = this.keyboardText;
						this.keyboardText = kbt.length ? kbt.substring(0, kbt.length - 1) : kbt;
						if(psd!=''&&psd!=undefined){
							var psd=this.pswd;
							psd = psd.length ? psd.substring(0, psd.length - 1) : psd;
							this.$emit('update:pswd', psd);
						}
						this.$emit('delKey');
						break;

					case "Tab":
						this.keyboardText += "\t";
						break;

					case "Enter":
						this.keyboardText += "\n";
						break;

					case "Space":
						this.keyboardText += " ";
						break;

					case "Caps":
						this.hasCapsed = !this.hasCapsed;
						this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList;
						break;

					case "Shift":
						this.hasShifted = !this.hasShifted;
						this.keyList = this.hasShifted ? this.shiftedKeyList : this.normalKeyList;
						break;

					default:
						this.curKey=key.toString()
						this.keyboardText += key.toString();
						break;

				}

				this.$emit('sendText',this.keyboardText)

			}
		},
  watch: {
    curKey: function(newValue, oldValue) {
	  var psd=this.pswd+newValue
      this.$emit('update:pswd', psd)
	  this.$emit('update:getCurKey', newValue)
    }
  }
	}
</script>