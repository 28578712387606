<template>
	<div  class="introWrap" :class="cooper?'bg':'nobg'"> 
		<p class="altitle title">{{getData.title}}</p>
		<div> 
			<p class="content">{{getData.content}}</p>
		</div>
	</div>
</template>
<script>
	export default {
        name:'introduce',
		props: ["getData",'cooper'],
		data() {
			return {
			};
		},
		created() {},
		mounted() {},
		methods: {}
	};
</script>
<style scoped lang="less">
.introWrap{
   background: url('../../../assets/u473.png');
   height: 5rem;
   .title{
    width: 30%;
    margin: 0 auto;
	}
  .content{
	width: 49%;
    height: 3rem;
    margin: 0 auto;
    font-size: 0.3rem;
    text-align: left;
    margin-top: 0.5rem;
   }
}
.bg{
	background: url('../../../assets/coo.png');
    background-size: cover;
    padding-top: 1rem;
    height: 7rem;
	.content{
		width: 39%;
		text-align: center;
	}
}
.nobg{
	background:none;
}
</style>
