<template>
  <div class="content_all">
    <div class="topWrap">
          <img :src="bannerImg" class="staticBanner">
          <p class="altitle innerTitle">{{$t('about.Video')}}</p>
    </div>
    <div class="content_inner">
        <div class="inputWrap">
          <el-input v-model="input" :placeholder="$t('about.Search')">
            <i slot="suffix" class="el-icon-search" @click="search"></i>
          </el-input>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane :label="$t('about.all')" name="first">
                <videoo  :videoData='videoAllData'></videoo>
            </el-tab-pane>
            <el-tab-pane :label="item.gatherName" :name="item.id" v-for="(item,index) in tabList" :key="index">
                <videoo  :videoData='videoData'></videoo>
            </el-tab-pane>
        </el-tabs>
        <div>
          <el-pagination
          :prev-text="$t('help.Previous')"
            :next-text="$t('help.Next')"
          layout="prev, pager, next"
          :page-size="6"
          :current-page.sync="currentPage"
          :total="pageTotal">
          </el-pagination>
        </div>
    </div>
  </div>
</template>
<script>
import videoo from '../../components/customPart/video/video.vue'
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  components: {
    videoo
  },
  data () {
    return {
      bannerImg: require('../../assets/vedioBack.png'),
      pageTotal: 0,
      currentPage: 1,
      input: '',
      activeName: 'first',
      tabList: [],
      videoAllData:{},
      videoData: {
        total: 12,
        videoList: []
      }
    }
  },
  watch: {
    activeName: function (val) { // 获取点了哪个菜单的id
      this.currentPage=1;
      if(val!='first'){
        this.getVideoListByGather()
      }
      else{
        this.pageTotal=this.videoAllData.total;
      } 
    },
    currentPage: function (val) {
        if(this.activeName!='first'){
        this.getVideoListByGather()
      }else{
        this.getAllVideoList()
      }
    },
  },
  created () {},
  mounted () {
    this.getAllVideoList()
    this.getVideoGatherList()
    this.getBannerImg()
  },
  methods: {
    handleBvid (list) {
      list.forEach((item, index) => {
        item.bvid = 'https://player.bilibili.com/player.html?bvid=' + item.bvid + '&page=1'
      })
    },
    getVideoGatherList () {
      var url = '/reach/portal/api/about/getVideoGatherList'
      axios.post(url,{},{
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.tabList = response.data.data
        }
      })
    },
    // 获取全部视频
    getAllVideoList () {
      var url = '/reach/portal/api/about/getVideoList'
      axios.get(url, {
        params: {
          pageIndex: this.currentPage,
          pageSize: 6,
          keyWord: this.input,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.videoAllData = response.data.data;
          this.pageTotal = this.videoAllData.total;
          this.handleBvid(this.videoAllData.videoList);
        }
      })
    },
    // 按分类获取视频
    getVideoListByGather () {
      var url = '/reach/portal/api/about/getVideoList'
      axios.get(url, {
        params: {
          pageIndex: this.currentPage,
          pageSize: 6,
          gatherId: this.activeName,
          keyWord: this.input,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.videoData = response.data.data;
          this.handleBvid(this.videoData.videoList);
          this.pageTotal=this.videoData.total;
        }
      })
    },
    search () {
      if (this.activeName === 'first') {
        this.getAllVideoList()
      } else {
        this.getVideoListByGather()
      }
    },
    getBannerImg () {
      var bannerType = 'video'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
            if(response.data.data.length>0){
              this.bannerImg = response.data.data[0].adImage
            }
        }
      })
    }
  }
}
</script>
<style  scoped>
.heart .el-col-8 {
  width: 18.33333%;
}

.content_all .el-button--primary {
  width: 2.8rem;
  height: 0.63rem;
  line-height: 0.4rem;
  border-radius: 0.15rem;
  background-color: rgba(31, 186, 243, 1);
}

.product_row {
  padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boderCol:after {
  width: 3.22rem;
  height: 0.1rem;
  background-color: #ffde5d;
  content: "";
  position: absolute;
  left: -0.067708rem;
  top: 3.24rem;
  z-index: 10;
}

.product_row .el-card {
  width: 3rem;
  height: 3rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
  padding: 0px;
  color: #a4a4a4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.news_heart p {
  padding: 15px 0 19px 0;
}

.news_heart .el-card {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.news_heart .el-card__body {
  padding: 0;
}

.new_indro {
  padding: 0 8px 0 10px;
  text-align: left;
}

.news_heart .new_indro span {
  font-size: 0.25rem;
  color: #827c7c;
}

.news_heart .new_indro .news_title {
  display: block;
  font-weight: 600;
  font-size: 0.28rem;
  color: #3c3b3c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.1rem;
}

.news_heart img {
  width: 100%;
}
</style>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    position: relative;
    margin-top: 1.5rem;
    height: 240Px;
    // background: url(../../assets/vedioBack.png) no-repeat;
    background-size: cover;
    .innerTitle{
      position: absolute;
      top:34%;
      left:50%;
    }
  }
  .content_inner{
      width: 70%;
      margin: 0 auto;
      /deep/ .el-tabs{
        .el-tabs__nav-wrap{
          height: 0.8rem;
          background-color: #f2f2f2;
          line-height: 0.8rem;
          padding-left: 0.5rem;
        }
        .el-tabs__active-bar{
            height: 0.07rem;
        }
        .el-tabs__item{
           font-size: 0.3rem;
        }
      }
        /deep/ .el-pagination
        {
          button{
            border: 0.03rem #b9bfc1 solid;
            padding: 0.1rem;
            color: #39a9d9;
            display: inline-block;
            font-size: 0.3rem;
            min-width: 0.6rem;
            height: 0.7rem;
          }
          .btn-prev{
            border-radius: 0.1rem 0 0 0.1rem;
          }
          .btn-next{
            border-left: none;
            border-radius: 0 0.1rem 0.1rem 0;
          }
          .el-pager li{
              padding: 0 0.25rem;
              border: 0.03rem #b9bfc1 solid;
              border-left: none;
              font-size: 0.3rem;
              height: 0.7rem;
              line-height: 0.7rem;
          }
          .el-pager li.active{
              color: #fff;
              background-color: #32a6d8;
          }
        }
      .inputWrap{
        height: 1rem;
        padding-top: 0.2rem;
        /deep/ .el-input{
           .el-input__inner{
            height: 0.6rem;
            width: 21%;
            float: right;
          }
          .el-input__suffix{
            font-size: 0.3rem;
            right: 0.2rem;
            top: 0.1rem;
          }
        }
      }
  }
}
</style>
