<template>
  <div>
    <div class="blutl_wrap" :class="scaleShow?'':'blutl_mini'">
      <div class="bgimg"></div>
      <div class="text_wrap">
        <span>{{rebl.modeName}}</span>
        <span>{{rebl.createBy}}</span>
        <span>{{rebl.updateBy}}</span>
      </div>
    </div>
    <div :class="scaleShow?'cotent_wrap':'cotent_mini'">
      <v-touch
        ref="advSwiper"
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        tag="div"
        class="storeCol_wrap"
      >
        <storeCol
          :scaleShow="scaleShow"
          :reGoods="item"
          :valll.sync="item.value"
          v-for="(item,index) in sendGoods"
          :key="index"
		  :bluerow=true
        ></storeCol>
      </v-touch>
    </div>
  </div>
</template>

<script>
import storeCol from "../../components/storeCol/storeCol.vue";
export default {
  name: "secstoreBlurow",
  components: {
    storeCol,
  },
  data() {
    return {
      sendGoods: [],
	  swipNum: 0,
	  allcot: 0,
      swipcotMnin: 0,
    };
  },
  props: ["rebl", "scaleShow"],
  created() {
    this.getbluData();
  },
  methods: {
    async getbluData() {
      var that = this;
      let res = await this.$Net.get("/f/eshopping/eshoppingTestOut/ProductList", { modeid: that.rebl.id });
	  that.allcot = res.length;
      that.swipcotMnin = res.length - 1;
      res.forEach((v, i) => {
        let obj = {
          id: "",
          productName: "",
          modeName: "",
          value: 4.5,
          productPrice: "",
          productPicture: "",
          productOldPrice: "",
          modeId: "",
        };
        this.$getSame(obj, res[i]);
        that.sendGoods.push(obj);
      });
    },
    onSwipeLeft() {
      if (this.swipcotMnin > 0) {
        this.swipNum += 33;
        --this.swipcotMnin;
      } else {
        var that = this;
        this.$msgboxx(that, "提示", "商品列表已显示完整");
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
    onSwipeRight() {
      this.swipNum -= 33;
      if (this.swipcotMnin<(this.allcot-1)) {
        this.swipcotMnin +=1;
      }
	  this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
  },
};
</script>

<style scoped lang="less">
	.blutl_wrap {
		background-color: rgba(31, 186, 243, 1);
		height: 3.15rem;
		position: relative;
		margin-top: 0.12rem;

		.bgimg {
			opacity: 0.32;
			width: 100%;
			height: 100%;
			background: url(../../assets/store/blue.png);
			background-size: cover;
			-moz-background-size: cover;
			/* 老版本的 Firefox */
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		.text_wrap {
			position: relative;
			z-index: 5;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			span {
				font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
				font-weight: 700;
				font-style: normal;
				font-size: 0.38rem;
				letter-spacing: 0.1rem;
				color: #FFFFFF;
			}

			span:nth-child(2) {
				font-size: 0.29rem;
				font-family: 'Arial Normal', 'Arial', sans-serif;
				font-weight: 400;
				font-style: normal;
				padding: 0.06rem 0;
			}

			span:nth-child(3) {
				font-size: 0.27rem;
				font-family: 'Arial Normal', 'Arial', sans-serif;
				font-weight: 400;
				font-style: normal;
			}
		}
	}
	.blutl_mini{
			height: 2.19rem!important;
			margin-top: 0.19rem!important;
			.text_wrap {
				span {
					font-size: 30Px!important;
				}
				span:nth-child(2) {
					font-size: 23Px!important;
				}
				span:nth-child(3) {
					font-size: 19Px!important;
				}
			}

	}
	.cotent_wrap {
		margin-top: 0.1rem;
		background-color: #ffffff;
		.storeCol_wrap{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			storeCol {
				width: 20%;
			}
		}
	}
	.cotent_mini{
		margin-top: 0.05rem;
        background-color: #ffffff;
        overflow: hidden;
		.storeCol_wrap{
			width: 300%;
			display: flex;
			transition: all 0.5s ease-in-out;
            justify-content: space-around;
			/* storeCol {
				width: 20%;
			} */
		}
	}
</style>
