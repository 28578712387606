<template>
  <div class="content_all">
    <div class="topWrap">
        <p class="altitle innerTitle">{{$t('help.Technical')}}</p>
        <img :src="bannerImg" class="staticBanner">
    </div>
    <div class="content_inner">
       <div  v-for="(item,index) in compoentList" :key="index" class="firentWrap">
            <serve style="padding:1rem 0 0 0;"  :showBackgd=false :sendData='item' :styleName='item.styleName'></serve>
            <a  class="contract"><el-button size="mini" type="primary" @click="toLogin">{{$t('help.Log')}}>></el-button></a>
        </div>
    </div>
    <div class="iconWrap">
        <el-row type="flex">
            <i class="fab fa-qq"></i>
            <span>
                <i>{{contactService.qqName}}</i><i>{{contactService.qqService}}</i>
            </span>
        </el-row>
        <el-row>
            <i class="icon"><img src="../../assets/u246.svg" alt=""></i>
            <span>
                <i>{{contactService.techName}}</i><i>{{contactService.techService}}</i>
            </span>
        </el-row>
    </div>
  </div>
</template>
<script>
import serve from '../../components/customPart/serve.vue'
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  components: {
    serve
  },
  data () {
    return {
      contactService: {
        qqName: '',
        qqService: '',
        techName: '',
        techService: ''
      },
      bannerImg: require('../../assets/tech.png'),
      // 合作伙伴
      compoentList: [
        {
          styleName: 'introduce',
          title: this.$t('help.smart'),
          content:this.$t('help.Ruiqi'),
        }
      ]
    }
  },
  created () {},
  mounted () {
    this.getBannerImg()
    this.getTechService()
  },
  methods: {
    toLogin () {
      this.$router.push({path: '/loginn'})
    },
    getBannerImg () {
      var bannerType = 'technology'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          if(response.data.data.length>0){
            this.bannerImg = response.data.data[0].adImage
          }
        }
      })
    },
    getTechService () {
      var url = '/reach/portal/api/about/getCustomService'
      axios.get(url, {
        params: {
          serviceType: 'tech'
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.contactService = response.data.data
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    position: relative;
    margin-top: 2rem;
    // padding-top: 1.5rem;
    height: 240Px;
    // background: url(../../assets/tech.png) no-repeat;
    background-size: cover;
    .innerTitle{
      position: absolute;
      top:50%;
      left:50%;
    }
  }
  .content_inner{
      width: 100%;
      margin: 0 auto;
      .firentWrap{
         position: relative;
        .el-button{
            // background-color: #3ac0f1;
            // color: #fff;
            // border-radius: 0.17rem;
            // width: 2.3rem;
            // height: 0.6rem;
            // font-size: 0.25rem;
        }
        .contract{
           position: absolute;
            top: 86%;
            left: 50%;
            transform:translate(-50%,-50%);
        }
        .RIV{
            position: absolute;
            bottom: 2%;
            left: 45%;
            transform:translate(0,-50%);
        }
      }
      .cooImg{
        .imgWrap{
          display: flex;
          flex-wrap: wrap;
          width: 70%;
          margin: 0 auto;
          justify-content: space-around;
          margin-top: 0.5rem;
          img{
            width: 15%;
          }
        }
      }
      .el-tabs{
          /deep/ .el-tabs__content{
                .el-tab-pane{
                  .coopCotenWrap{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: 0.7rem;
                    .docWrap{
                            width: 30%;
                            margin: 0 0.5rem 0.5rem 0;
                            .top{
                                width: 100%;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .bottom{
                                margin-top: 0.3rem;
                                p{
                                    display: inline-block;
                                    font-size: 0.3rem;
                                    font-weight: 600;
                                }
                                a{
                                    color: #22b0ee;
                                    font-size: 0.3rem;
                                    cursor: pointer;
                                }
                            }
                    }
                  }
            }
          }
      }
      /deep/ .el-tabs{
        .el-tabs__header{
          margin:0;
        }
        .el-tabs__nav-wrap{
          height: 0.8rem;
          line-height: 0.8rem;
          padding-left: 0.5rem;
          .el-tabs__nav-scroll{
            display: flex;
            justify-content: center;
          }
        }
        .el-tabs__active-bar{
            height: 0.07rem;
        }
        .el-tabs__item{
          padding: 0 0.4rem;
           font-size: 0.3rem;
        }
      }
        /deep/ .el-pagination
        {
          button{
            border: 0.03rem #b9bfc1 solid;
            padding: 0.1rem;
            color: #39a9d9;
            display: inline-block;
            font-size: 0.3rem;
            min-width: 0.6rem;
            height: 0.7rem;
          }
          .btn-prev{
            border-radius: 0.1rem 0 0 0.1rem;
          }
          .btn-next{
            border-left: none;
            border-radius: 0 0.1rem 0.1rem 0;
          }
          .el-pager li{
              padding: 0 0.25rem;
              border: 0.03rem #b9bfc1 solid;
              border-left: none;
              font-size: 0.3rem;
              height: 0.7rem;
              line-height: 0.7rem;
          }
          .el-pager li.active{
              color: #fff;
              background-color: #32a6d8;
          }
        }
  }
  .iconWrap{
      background-color: #e5edf0;
      height: 3.5rem;
      padding-top: 0.2rem;
      .el-row{
            margin-top: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          .svg-inline--fa.fa-w-14{
              font-size: 0.6rem;
          }
          .icon{
              img{
                  width: 0.7rem;
                  height: auto;
              }
          }
          span{
              display: flex;
              flex-direction: column;
              text-align: left;
              margin-left: 0.2rem;
              justify-content: center;
              i{
                  font-style: normal;
                  font-size: 0.3rem;
              }
          }
      }
  }
}
</style>
