<template>
  <div class="bnr_wrap" :class="scaleShow?'':'bnrRWrap_mini'">
    <div class="bnrRtitle">
      <span>{{$t('about.information')}}</span>
      <span>LAUNCH NOW</span>
    </div>
    <el-row type="flex" justify="space-around" align="middle" class="bnr_center">
      <el-col :span="1" class="arrow_left">
        <i class="left" @click="clickArrow(-1)"></i>
      </el-col>
      <el-col :span="2" class="bnr_img" v-loading="loading" >
        <!-- <img src="../../assets/store/ban2.png" /> -->
        <img
          :src="smallImg[currentImg].productPicture"
          @click="todetail(smallImg[currentImg].id,smallImg[currentImg].modeId)"
        />
      </el-col>
      <el-col :span="1" class="arrow_right">
        <i class="right" @click="clickArrow(1)"></i>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="goods_name">{{smallImg[currentImg].productName}}</el-row>
    <el-row type="flex" justify="space-between" class="bnr_botm">
      <el-col style="text-decoration: line-through;">¥{{smallImg[currentImg].productOldPrice}}</el-col>
      <el-col>¥ {{smallImg[currentImg].productPrice}}</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "banerRight",
  props: ["scaleShow"],
  data() {
    return {
      smallImg: [
        {
          productPicture:require("/src/assets/store/prod1.png"),
          productPrice:"0",
          productOldPrice:"0"
        },
      ],
      currentImg: 0,
      cotTag: "", //定时器
      cotTimeout: "", //定时器
      loading:false,
    };
  },
  created() {
     this.getRightimg();
  },
  mounted() {
    var that = this;
    that.cotTag = setInterval(function () {
      that.currentImg =
      that.currentImg < that.smallImg.length - 1 ? ++that.currentImg : 0;
    }, 4000);
  },
  methods: {
    clickArrow(e) {
      var that = this;
      // console.log(e);
      clearInterval(this.cotTag);
      clearInterval(this.cotTimeout);
      if(this.currentImg <= 0 && e === -1) this.currentImg = this.smallImg.length;
      if(this.currentImg >= this.smallImg.length-1 && e === 1) this.currentImg = -1;
      this.currentImg += e;
      //console.log("page：" + this.currentImg);
      that.cotTimeout=setTimeout(function () {
        that.cotTag = setInterval(function () {
          that.currentImg =
            that.currentImg < that.smallImg.length - 1 ? ++that.currentImg : 0;
          // console.log("gasa:" + that.currentImg);
        }, 5000);
      }, 4000);
    },
    todetail(produId, modeId) {
      localStorage.setItem('productId', modeId)
      localStorage.setItem('id', produId)
      this.$router.push({
        name: "goodsDeil",
        query: {
          id: produId,
          modeId
        },
      });
    },
    async getRightimg() {
      this.loading=true;
      var that = this;
      let res = await this.$Net.get(
        "/f/eshopping/eshoppingModeAndProduct/ProductList",{ language: localStorage.getItem("locale")}
      );
      if(res) this.loading=false;
      that.smallImg.splice(0, 1);
      res.forEach((v, i) => {
        var obj = {
          id: "",
          productPicture: "",
          productName: "",
          productOldPrice: "",
          productPrice: "",
          modeId: "",
        };
       this.$getSame(obj, res[i]);
        if (v.recommandStatus == "1") {
          that.smallImg.push(obj);
        }
      });
    },
  },
};
</script>

<style scoped>
.bnr_wrap {
  text-align: center;
}

.bnr_wrap .bnrRtitle{
   display: flex;
   width: 100%;
   flex-direction: column;
}
.bnr_wrap .bnrRtitle span {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 3px;
}

.bnr_wrap .bnrRtitle span:nth-child(2) {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.24rem;
  color: #ff8900;
  letter-spacing: 0.01rem;
}

/* .bnr_center {
		display: flex;
		align-items: center;
		padding: 0 20px 0 20px;
	}

	.bnr_center img {
		flex: 2;
height: 148px;
	} */
.bnr_center {
  margin-left: 7px;
}

.bnr_img {
  width: 51.5%;
  height: 3.1rem;
  overflow: hidden;
  margin-top: 17px;
  cursor: pointer;
}

.bnr_center img {
    width: 100%;
    height: 100%;
}

i {
  /* flex: 5; */
  border: solid #dfdfdf;
  border-width: 0 0.09rem 0.09rem 0;
  display: inline-block;
  padding: 0.14rem;
}

.arrow_right {
  margin-right: 0.22rem;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  cursor: pointer;
}

.goods_name {
  color: #333333;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16Px;
  letter-spacing: 1px;
}

.bnr_botm {
  padding:0.4rem;
  padding-bottom: 0;
}

.bnr_botm .el-col-24 {
  text-align: left;
  font-size: 0.25rem;
  color: #9b9b9b;
  font-family: "Arial Normal", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bnr_botm .el-col-24:nth-child(2) {
  text-align: right;
  font-size: 0.38rem;
  color: #ff8900;
  font-family: "Arial Normal", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
}
</style>
<style lang="less" scoped>
  .bnrRWrap_mini{
    // p{font-size: 0.48rem!important;}
    .bnrRtitle{
    span{
      letter-spacing: 0.09rem;
      font-size: 0.48rem!important;
      &:nth-child(2){font-size: 0.27rem!important;letter-spacing: 0.04rem;}
    }
    }
    .bnr_center{
      margin-bottom: 0.25rem!important;
      .bnr_img{height: 3.08rem!important;}
    }
    .arrow_left i{padding: 0.2rem!important;border-width: 0 0.08rem 0.08rem 0!important;}
    .arrow_right i{padding: 0.2rem!important;border-width: 0 0.08rem 0.08rem 0!important;}
    .goods_name{font-size: 20Px!important;}
    .bnr_botm {
      padding: 0.45rem 0.57rem 0 0.62rem!important;
      .el-col-24{
       font-size: 24Px!important;
       &:nth-child(2){font-size: 28Px!important;}
      }
    }
  }
</style>
