<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="58%" @close='closeDialog' :append-to-body="true" class="container">
      <div class="inner">
        <div class="tit">{{$t('agreement.agreement')}}</div>
        <div class="tit2">{{$t('agreement.Effective')}}</div>
        <div class="tit2">{{$t('agreement.Updated')}}</div>
        <p>{{$t('agreement.Preface')}}</p>
        <p>{{$t('agreement.services')}}</p>
        <p>{{$t('agreement.confidentiality')}}</p>
        <div>{{$t('agreement.confidentiality2')}}</div>
        <div>{{$t('agreement.confidentiality3')}}</div>
        <div>{{$t('agreement.Unless')}}</div>
        <div>{{$t('agreement.According')}}</div>
        <div>{{$t('agreement.According2')}}</div>
        <div>{{$t('agreement.According3')}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "serct",
  components: {
  },
  data() {
    return {
      dialogVisible: true
    }
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
      this.$emit('update:sechShow', false);
    },
  }
}
</script>
<style lang="less" scoped>
.container {
  .inner {
    width: 70%;
    margin: 0.5rem auto;

    .tit {
      text-align: center;
      font-weight: 600;
      font-size: 0.4rem;
      margin: 0.5rem 0 0.4rem 0;
    }
    .tit2 {
      text-align: right;
      font-weight: 600;
      font-size: 0.4rem;
      margin: 0.5rem 0 0.4rem 0;
    }

    p {
      font-weight: 600;
      font-size: 0.4rem;
      margin: 0.5rem 0 0.4rem 0;
    }
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    font-size: 0.5rem;
  }
  div {
      font-size: 0.4rem;
      margin: 0.5rem 0 0.4rem 0;
  }
}</style>
