<template>
  <div class="baner_wrap" :class="scaleShow?'':'banerWrap_mini'">
    <div class="left_img" :class="scaleShow?'':'store_mini'">
      <a :href="larginImg[0].indexHref" target="_blank"></a>
      <img :src="larginImg[0].indexPicture" />
      <!-- <img src="../../assets/store/ban1.jpg" /> -->
    </div>
    <div class="right_wrap">
      <banerRight :scaleShow="scaleShow"></banerRight>
    </div>
  </div>
</template>

<script>
import banerRight from "./banerRight.vue";
import { getBannerleft } from "../../api/index.js";
export default {
  name: "Storebaner",
  components: {
    banerRight,
  },
  props: ["scaleShow"],
  data() {
    return {
      larginImg: [
        {
          indexHref: "",
        },
      ],
    };
  },
  created() {
    this.getLeft();

    /* var that = this;
			this.$axios({
				method: 'get',
				url: '/f/eshopping/eshoppingModeAndProduct/indexpicture'
			}).then(res => {
				that.larginImg.splice(0, 1);
				res.data.forEach((v, i) => {
					var obj = {
						indexPicture: '',
						indexHref: '',
					};
					const listAssign = (arra, arrb) => Object.keys(arra).forEach(key => {
						arra[key] = arrb[key] || arra[key]
					});
					listAssign(obj, res.data[i]);
					that.larginImg.push(obj);
				})
			}).catch(err => {
				this.$notify({
					title: '错误',
					message: err,
					type: 'warning'
				})
				return false
			}); */
  },
  methods: {
    async getLeft() {

      const res = await getBannerleft();
      this.larginImg.splice(0, 1);
      res.forEach((v, i) => {
        var obj = {
          indexPicture: "",
          indexHref: "",
        };
        this.$getSame(obj, res[i]);
        obj.indexHref=obj.indexHref;
        this.larginImg.push(obj);
      });
    },
  },
};
</script>

<style scoped lang="less">
.banerWrap_mini {
  padding-top: 0.5rem;
  height: 7.43rem !important;
  .right_wrap {
    padding: 0.83rem 0 0 0.01rem!important;
  }
}
.baner_wrap {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  height: 6.6rem;
  .store_mini {
    flex: 3 !important;
  }
  .left_img {
    flex: 3.9;
    position: relative;
    height: 100%;
    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right_wrap {
    flex: 2;
    padding:1rem 0 0 0.01rem;
  }
}
@media screen and (min-width:1500px) and (max-width:1930px){
  .baner_wrap {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    height: 9.6rem;
  }
}
</style>
