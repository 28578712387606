<template>
  <div class="row_alwrap">
    <div class="arrow_wrap" v-if="sendGoods.length>0&&scaleShow" :class="isIpad?'arrow_ipad':''">
      <div class="arrow_line" @click="changePage(-1)">
        <div class="arrow arrow_left"></div>
        <i class="i_left"></i>
      </div>
      <div class="arrow_line" @click="changePage(1)">
        <div class="arrow arrow_right"></div>
        <i class="i_right"></i>
      </div>
    </div>
    <div :class="isEven? 'row_onetitle':'row_setitle'">
      <span :class="scaleShow?'':'rowtitle_mini'">{{recmoz.modeName}}</span>
      <span>{{recmoz.createBy}}</span>
    </div>
    <div class="row_content">
      <v-touch
        ref="rowSwiper"
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        tag="div"
        class="RowSwiper_wrap"
      >
        <storeCol
          :reGoods="item"
          :scaleShow="scaleShow"
          v-for="(item,index) in sendGoods"
          :key="index"
        ></storeCol>
      </v-touch>
    </div>
  </div>
</template>

<script>
import storeCol from "../../components/storeCol/storeCol.vue";
export default {
  components: {
    storeCol,
  },
  name: "secstoreRow",
  props: ["recmoz", "isEven", "scaleShow","isIpad"],
  data() {
    return {
      sendGoods: [],
      swipNum: 0,
      allcot: 0,
      swipcotMnin: 0,
      swipcot: 0,
    };
  },
  created() {
    this.getrowData();
  },
  methods: {
    async getrowData() {
      var that = this;
      let res = await this.$Net.get(
        "/f/eshopping/eshoppingTestOut/ProductList",
        { modeid: that.recmoz.id }
      );
      that.allcot = res.length;
      that.swipcotMnin = res.length - 1;
	  that.swipcot = this.allcot-5;
	  if(!that.scaleShow){
    this.$nextTick(() => {
      that.$refs.rowSwiper.$el.style.width = that.allcot * 100 + "%";
	})
	}
	else{
	  this.$nextTick(() => {
      that.$refs.rowSwiper.$el.style.width = that.allcot * 20 + "%";
	 })
	}
      res.forEach((v, i) => {
        let obj = {
          id: "",
          productName: "",
          modeName: "",
          value: 4.5,
          productPrice: "",
          productPicture: "",
          productOldPrice: "",
          modeId: "",
        };
        this.$getSame(obj, res[i]);
        that.sendGoods.push(obj);
      });
    },
    changePage(e) {	
	  if (e > 0) {
        if (this.swipcot > 0) {
          this.swipNum += this.allcot * 2;
          --this.swipcot;
        } else {
          var that = this;
          this.$msgboxx(that, "提示", "商品列表已显示完整");
        }
      } else {
        this.swipNum -= this.allcot * 2;
        this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
        if (this.swipNum == 0) {
          //this.swipcot = Math.ceil(this.allcot/5)-1;这是算有多少页
          this.swipcot = this.allcot-5;
        }
      }
      this.$refs.rowSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
	},
    onSwipeLeft() {
      if (this.swipcotMnin > 0) {
        if (this.allcot % 2 == 0) {
          this.swipNum += (this.allcot * 100) / Math.pow(this.allcot,2);
        } else {
          this.swipNum += (this.allcot * 100) / (Math.pow(this.allcot,2) + 1);
        }
        --this.swipcotMnin;
      } else {
        var that = this;
        this.$msgboxx(that, "提示", "商品列表已显示完整");
      }
      this.$refs.rowSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
    onSwipeRight() {
      if (this.allcot % 2 == 0) {
        this.swipNum -= (this.allcot * 100) / (Math.pow(this.allcot,2));
      } else {
        this.swipNum -= (this.allcot * 100) / (Math.pow(this.allcot,2) + 1);
      }
      if (this.swipcotMnin < this.allcot - 1) {
        this.swipcotMnin += 1;
      }
      this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      this.$refs.rowSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
  },
};
</script>

<style scoped lang="less">
	.row_alwrap {
		position: relative;
		margin-top: 19px;
    .arrow_ipad{
      width: 126.9% !important;
      left: -13.4% !important;
    }
		.arrow_wrap {
			position: absolute;
		  top: 37%;
      left: -9.4%;
      width: 118.9%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.arrow_line {
				position: relative;

				.arrow {
					width: 0;
					height: 0;
					border-width: 0.9rem;
					border-style: solid;
					border-color: transparent #ff8168 transparent transparent;
					cursor: pointer
				}

				/* 	.arrow:hover+i {
					border-width: 0 4px 4px 0;
					border: solid #5e5e5e;
					display: inline-block;
					padding: 10px;
				} */

				.arrow_left {
					transform: rotate(0deg);
				}

				.arrow_right {
					transform: rotate(180deg);
				}

				i {
					/* flex: 5; */
					border: solid #ffffff;
					border-width: 0 0.1rem 0.1rem 0;
          display: inline-block;
          padding: 0.2rem;
					cursor: pointer
				}

				i:hover {
					border: solid #5e5e5e;
					border-width: 0 0.1rem 0.1rem 0;
				}

				.i_right {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					position: absolute;
					left: 0;
					top: 37%;
				}

				.i_left {
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					position: absolute;
					right: 0;
					top: 37%;
				}
			}
		}

		.row_content {
			background-color: #ffffff;
			overflow: hidden;
			.RowSwiper_wrap{
				display: flex;
				justify-content: space-around;
				width: 100%;
				transition: all 0.5s ease-in-out;
			}
		}
		.row_onetitle {
			border-bottom: 0.07rem solid #02a7f0;
			text-align: left;
			padding: 0.2rem 0 0.05rem 0;
		}

		.row_setitle {
			border-bottom: 0.07rem solid #ffc501;
      text-align: left;
      padding: 0.2rem 0 0.05rem 0;
		}

		.row_onetitle,
		.row_setitle {
			span {
				font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
				font-weight: 700;
				font-style: normal;
				font-size: 23Px;
				letter-spacing: 5px;
				color: #3E3E3E;
			}

			span:nth-child(2) {
				font-family: 'Roboto Light', 'Roboto Regular', 'Roboto', sans-serif;
				font-weight: 300;
				font-style: normal;
				font-size: 19Px;
				color: #AAAAAA;
				letter-spacing: 0px;
				margin-left: 4px;
			}
			.rowtitle_mini{
				margin:0 0.2rem;
			}
		}
		}
</style>
