<template>
  <div class="row_alwrap" >
    <div class="arrow_wrap" v-show="scaleShow&&sendGoods.length>0" :class="isIpad?'arrow_ipad':''">
      <div class="arrow_line arleft_wrap" @click="changePage(-1)">
        <div class="arrow arrow_left"></div>
        <i class="i_left"></i>
      </div>
      <div class="arrow_line arright_wrap" @click="changePage(1)">
        <div class="arrow arrow_right"></div>
        <i class="i_right"></i>
      </div>
    </div>
    <div :class="isEven? 'row_onetitle':'row_setitle'">
      <span :class="scaleShow?'':'rowtitle_mini'">{{recmoz.modeName}}</span>
      <span>{{recmoz.createBy}}</span>
    </div>
    <div class="row_content">
      <v-touch
        ref="rowSwiper"
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        tag="div"
        class="RowSwiper_wrap"
      >
        <storeCol
          :reGoods="item"
          :scaleShow="scaleShow"
          v-for="(item,index) in sendGoods"
          :key="index"
        ></storeCol>
      </v-touch>
    </div>
  </div>
</template>

<script>
import storeCol from "../../components/storeCol/storeCol.vue";
export default {
  components: {
    storeCol,
  },
  name: "storeRow",
  props: ["recmoz", "isEven","anmindex"],
  data() {
    return {
      isIpad:false,
      sendGoods: [],
      swipNum: 0,
      allcot: 0,
      swipcotMnin: 0,
      movee:0,//和swipNum区别是这个只有赋一次值，平移的长度
	    semove:0,//对于单数张产品图的第一张需要特殊处理 
      swipcot: 0,
      scaleShow:false,
      nextpage:false,
      nextIndex:0,
      rightcot:0,
      spRighttag:false,
      spLeftmini:false,
      spRightmini:0
    };
  },
  //必须放在mounted中，因为放在created中未获取dom节点 
  mounted() {
    //等待dom渲染完毕再加载
    var that=this;
    this.$nextTick(() => {
      that.getrowData();
      that.commonSySize();
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      } else {
      }
    });
  },
  /* created() {
    this.commonSySize();
  }, */
  methods: {
    //公共使用控制页面大小收缩
    commonSySize() {
      let idTag = document.body;
       if(this.$isphone()){this.scaleShow = false;}
      else{
        if (idTag.offsetWidth <= 1020) {
          this.scaleShow = false;
        } else {
          this.scaleShow = true;
        }
      }
      this.judgeWidth();
    },
     judgeWidth(){
      var that=this;
      if(that.$refs.rowSwiper){
        if(!this.scaleShow){
          this.$nextTick(()=>{
              that.$refs.rowSwiper.$el.style.width = that.allcot * 100 + "%";
          })   
          }
          else{
            this.$nextTick(()=>{
              that.$refs.rowSwiper.$el.style.width = that.allcot * 20 + "%";    
          })
        } 
      }
        },
    async getrowData() {
      var that = this;
      let res = await this.$Net.get(
        "/f/eshopping/eshoppingModeAndProduct/ProductList",
        { modeid: that.recmoz.id, language: localStorage.getItem("locale") }
      );
      that.allcot = res.length;
      that.swipcotMnin = res.length - 1;
      that.swipcot = this.allcot-5;
      that.judgeWidth();
      res.forEach((v, i) => {
        let obj = {
          id: "",
          productName: "",
          modeName: "",
          value: 4.5,
          productPrice: "",
          productPicture: "",
          productOldPrice: "",
          modeId: "",
        };
        this.$getSame(obj, res[i]);
        that.sendGoods.push(obj);
      });
    },
    changePage(e) {	
      if(this.allcot>1){
      // this.$refs.rowSwiper.$el.style.transition= "none"; 
	  if (e > 0) {
        if (this.swipcot > 0&&!this.spRighttag) {
          // this.$refs.rowSwiper.$el.style.transition= '0.5s';
          --this.swipcot;
          if (this.nextpage){
            this.sendGoods.push(this.sendGoods[0]);
            this.sendGoods.splice(0,1);
          }
          else{
            this.swipNum -= (this.allcot * 2-3.5);
          }
        } 
        else {
          /* var that = this;
          this.$msgboxx(that, "提示", "商品列表已显示完整"); */
          this.sendGoods.push(this.sendGoods[0]);
          this.sendGoods.splice(0,1);
          this.nextpage=true;//开始回到第一个商品的标志

           var styleSheets = document.styleSheets[0];
        var styleindex = styleSheets.length;
        if(styleSheets.insertRule){  
        styleSheets.insertRule(` @keyframes  pcLeftTo${this.anmindex}{
            0%{
                transform:translateX(${(this.swipNum+5)}%);
               
            } 
            50%{
                transform:translateX(${(this.swipNum+2)}%);
                
            }
            100%{
                transform:translateX(${this.swipNum}%);
                
            }
        }`, styleindex);
        }
        var that = this;
        this.$refs.rowSwiper.$el.style.animation =`pcLeftTo${this.anmindex} 1s ease 0s backwards running`;
        // that.$refs.rowSwiper.$el.classList.add("miniLeft");
    　　setTimeout(function(){
        that.$refs.rowSwiper.$el.style.animation ='none';
        //that.$refs.rowSwiper.$el.classList.remove("miniLeft");
    　　},250);
          // this.$refs.rowSwiper.$el.style.transition= '0s'; 
          // this.swipNum=0;
          // this.swipcot = this.allcot-5;
        }
      } else {
        if (!this.nextpage&&(this.swipNum!=0)){
            this.swipNum += (this.allcot * 2-3.5);
            // this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
            if (this.swipNum == 0) {
              //this.swipcot = Math.ceil(this.allcot/5)-1;这是算可以移动多少个 
              this.swipcot = this.allcot-5;
            }
        }
        else{ 
          ++this.rightcot;  
          if(this.rightcot>=(this.allcot-5)||this.swipNum==0){
            this.spRighttag=true;
            this.sendGoods.unshift(this.sendGoods[this.sendGoods.length-1]);
            this.sendGoods.splice(this.sendGoods.length-1,1);
            this.swipNum=0;
            var styleSheets = document.styleSheets[0];
            var styleindex = styleSheets.length;
            if(styleSheets.insertRule){  
            styleSheets.insertRule(` @keyframes  pcRightTo${this.anmindex}{
                0%{
                    transform:translateX(${(this.swipNum-5)}%);
                  
                } 
                50%{
                    transform:translateX(${(this.swipNum-2)}%);
                    
                }
                100%{
                    transform:translateX(${this.swipNum}%);
                    
                }
            }`, styleindex);
            }
            var that = this;
            this.$refs.rowSwiper.$el.style.animation =`pcRightTo${this.anmindex} 1s ease 0s backwards running`;
            // that.$refs.rowSwiper.$el.classList.add("miniLeft");
        　　setTimeout(function(){
            that.$refs.rowSwiper.$el.style.animation ='none';
            //that.$refs.rowSwiper.$el.classList.remove("miniLeft");
        　　},250);
          }
          else{
            this.swipNum+=(this.allcot * 2-3.5);
          }
        }
        ++this.swipcot;
      }
      this.$refs.rowSwiper.$el.style.transform =
        "translateX(" + this.swipNum + "%)";
    }
	},
    onSwipeLeft() {
      if(this.scaleShow==false&&this.allcot>1){
         if (this.swipcotMnin > 0) {
           if (this.allcot % 2 == 0) {
             if(this.swipcotMnin==(this.allcot-1)){this.movee=(this.allcot * 100) / Math.pow(this.allcot,2);}
                this.swipNum += (this.allcot * 100) / Math.pow(this.allcot,2);
            } 
            else {
                let val=parseInt((this.allcot * 100) / Math.pow(this.allcot,2));
                let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
                if(yal==0){
                  if(this.swipcotMnin==(this.allcot-1)){this.movee=val}
                          this.swipNum +=val;
                }
                else{
                  if(this.swipcotMnin==(this.allcot-1)){this.semove=(val+0.5);this.swipNum +=(val+0.5);}
                  else{
                    if(this.swipcotMnin%2!=0){this.swipNum +=(val);this.movee=val;}
                    else{this.swipNum +=(val+0.5);this.movee=val+0.5;}
                  }
                }
                    //只能对于3个：this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3);
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3*(3-this.swipcotMnin));
                    /* console.log('右边移动：'+3*(3-this.swipcotMnin));
                    console.log('当前值：'+this.swipcotMnin); */
                    
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 3*(3-swipcotMnin))+2);
            }
        --this.swipcotMnin;
      } 
      else {
        /* var that = this;
        this.$msgboxx(that, "提示", "商品列表已显示完整"); */
        //this.swipNum=0;
        var styleSheets = document.styleSheets[0];
        /* if(this.spLeftmini){
          styleSheets.deleteRule(0);
        } */
        var styleindex = styleSheets.length;
        if(styleSheets.insertRule){  
        styleSheets.insertRule(` @keyframes  minLeftTo${this.anmindex}{
            0%{
                transform:translateX(${-(this.swipNum-8)}%);
               
            } 
            50%{
                transform:translateX(${-(this.swipNum-3)}%);
                
            }
            100%{
                transform:translateX(${-this.swipNum}%);
                
            }
        }`, styleindex);
        }else{
          styleSheets.addRule(` @keyframes minLeftTo${this.anmindex}{
            0%{
                transform:translateX(${-(this.swipNum-8)}%);
            } 
            50%{
                transform:translateX(${-(this.swipNum-3)}%);
            }
            100%{
                transform:translateX(${-this.swipNum}%);
            }
        }`, 0);
        }
        var that = this;
        this.$refs.rowSwiper.$el.style.animation =`minLeftTo${this.anmindex} .35s linear 0s backwards running`;
        // that.$refs.rowSwiper.$el.classList.add("miniLeft");
    　　setTimeout(function(){
        that.$refs.rowSwiper.$el.style.animation ='none';
        //that.$refs.rowSwiper.$el.classList.remove("miniLeft");
    　　},350);
        this.sendGoods.push(this.sendGoods[0]);
        this.sendGoods.splice(0,1);
        this.spLeftmini=true;
        // this.swipcotMnin = this.allcot-5;
        }
        this.$refs.rowSwiper.$el.style.transform ="translateX(-" + this.swipNum + "%)";
        }
    },
    onSwipeRight() {
       if(this.spLeftmini||this.swipNum==0){
          ++this.spRightmini;
          var styleSheets = document.styleSheets[0];  
       /*  if(this.spLeftmini){
          styleSheets.deleteRule(0);
        } */
        if(styleSheets.insertRule){  
        styleSheets.insertRule(` @keyframes  miniRight${this.anmindex}{
            0%{
             
                transform:translateX(${-(this.swipNum+10)}%);
            } 
            
            100%{
            
                transform:translateX(${-this.swipNum}%);
            }
        }`, 0);
        }else{
          styleSheets.addRule(` @keyframes  miniRight${this.anmindex}{
            0%{
             
                transform:translateX(${-(this.swipNum+10)}%);
            } 
            
            100%{
            
                transform:translateX(${-this.swipNum}%);
            }
        }`, 0);
        }

       var that = this;
// 　　　　that.$refs.rowSwiper.$el.classList.add("miniRight");
       this.$refs.rowSwiper.$el.style.animation =`miniRight${this.anmindex} .4s linear 0s backwards running`;
        
　　setTimeout(function(){
// 　　　　that.$refs.rowSwiper.$el.classList.remove("miniRight");
       that.$refs.rowSwiper.$el.style.animation ='none';
　　},800);
  this.sendGoods.unshift(this.sendGoods[this.sendGoods.length-1]);
            this.sendGoods.splice(this.sendGoods.length-1,1);
            if(this.spRightmini<=this.allcot-1){
             this.$refs.rowSwiper.$el.style.transform =
        "translateX(" + -this.swipNum + "%)";
        // this.swipNum=this.swipNum-0.5;
        
        }
         return
        }
      if (this.allcot % 2 == 0) {
          this.swipNum -= (this.allcot * 100) / Math.pow(this.allcot,2);
      }
      else{
          let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
          if(this.swipcotMnin==(this.allcot-2)&&yal!=0){
            this.swipNum -=this.semove;
            
          }
          else{
              if(this.swipcotMnin%2==0){this.swipNum -= this.movee;this.movee+=0.5;}
              else{this.swipNum -= this.movee;this.movee-=0.5;}        
          }
      }
      /* if (this.swipcotMnin<(this.allcot-1)) {
			this.swipcotMnin +=1;
      } */
     
      // this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      
      this.$refs.rowSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
  },
};
</script>

<style scoped lang="less">
 @keyframes  minLeftTo{

    100%{
        transform:translateX(-87.5%);
    }
}
 @keyframes  minRightTo{
   /*  0%{
        transform:translateX(-95%);
    } 
     50%{
        transform:translateX(-90%);
    }  */
    100%{
        transform:translateX(-87.5%);
    }
}
	.row_alwrap {
		position: relative;
		margin-top: 19px;
    .arrow_ipad{
      width: 126.9% !important;
      left: -13.4% !important;
    }
		.arrow_wrap {
      width: 100%;
			position: absolute;
		  top: 37%;
      left: 0%;
			display: flex;
			justify-content: space-between;
			align-items: center;
      z-index: 3;

			.arrow_line {
				.arrow {
					width: 0;
					height: 0;
					border-width: 0.9rem;
					border-style: solid;
					border-color: transparent #ff8168 transparent transparent;
					cursor: pointer
				}

				/* 	.arrow:hover+i {
					border-width: 0 4px 4px 0;
					border: solid #5e5e5e;
					display: inline-block;
					padding: 10px;
				} */

				.arrow_left {
					transform: rotate(0deg);
				}

				.arrow_right {
					transform: rotate(180deg);
				}

				i {
					/* flex: 5; */
					border: solid #ffffff;
					border-width: 0 0.1rem 0.1rem 0;
          display: inline-block;
          padding: 0.2rem;
					cursor: pointer
				}

				i:hover {
					border: solid #5e5e5e;
					border-width: 0 0.1rem 0.1rem 0;
				}

				.i_right {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					position: absolute;
					left: 0;
					top: 37%;
				}

				.i_left {
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					position: absolute;
					right: 0;
					top: 37%;
				}
			}
		}

		.row_content {
      position: relative;
			background-color: #ffffff;
			overflow: hidden;
      z-index: 6;
			.RowSwiper_wrap{
				display: flex;
				justify-content: space-around;
				width: 100%;
				// transition: all .5s ease-in-out;
				transition: all .5s linear;
        
        // animation-play-state: paused;
			}
		}
    // 移动端左右移动开始
    .miniLeft{
       animation: minLeftTo .7s ease-in-out 0s backwards running;
    }
  
.miniRight{
       animation: minRightTo .4s linear 0s backwards running;
    }
// 移动端左右移动结束
		.row_onetitle {
			border-bottom: 0.07rem solid #02a7f0;
			text-align: left;
			padding: 0.2rem 0 0.05rem 0;
		}

		.row_setitle {
			border-bottom: 0.07rem solid #ffc501;
      text-align: left;
      padding: 0.2rem 0 0.05rem 0;
		}

		.row_onetitle,
		.row_setitle {
			span {
				font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
				font-weight: 700;
				font-style: normal;
				font-size: 23Px;
				letter-spacing: 5px;
				color: #3E3E3E;
			}

			span:nth-child(2) {
				font-family: 'Roboto Light', 'Roboto Regular', 'Roboto', sans-serif;
				font-weight: 300;
				font-style: normal;
				font-size: 19Px;
				color: #AAAAAA;
				letter-spacing: 0px;
				margin-left: 0.16rem;
			}
			.rowtitle_mini{
				margin:0 0.2rem;
			}
		}
    .arleft_wrap{
      position: absolute;
      top: 50%;
      left: -1.7rem;
    }
    .arright_wrap{
      position: absolute;
      top: 50%;
      right: -1.7rem;
    }
		}
</style>
