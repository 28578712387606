<template>
  <div class="content_all">
    <banner :type="BannerType"></banner>
    <div class="content_inner">
       <div  v-for="(item,index1) in compoentList" :key="index1" class="firentWrap">
            <serve   :showBackgd=false :sendData='item' :styleName='item.styleName'></serve>
            <div class="bgImgWrap">
              <img src="../../assets/fuliucheng.png">
            </div>
        </div>
        <div class="xia" v-for="(item,index2) in incubatorList" :key="item.id">
          <div class="xiaWrap">
            <p>{{item.tiltle}}</p>
            <p>{{item.content}}</p>
            <div class="detail">
              <p>{{$t('help.address')}}: {{item.addr}}</p>
              <p>{{$t('cooperate.Station')}}: {{item.stationNum}}</p>
              <p>{{$t('cooperate.type')}}: {{item.siteType}}</p>
              <p>{{item.siteDescript}}</p>
            </div>
            <div class="imgWrap">
              <img :src="item2.img" v-for="(item2,index3) in item.imgList" :key="index3">
            </div>
            <a  class="contract"><el-button @click="toContract(item.id)">{{$t('cooperate.register')}} >></el-button></a>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import banner from '../../components/homePages/banner.vue'
import serve from '../../components/customPart/serve.vue'
import axios from '../../api/axios'

export default {
  name: 'produceSeries',
  components: {
    banner,
    serve
  },
  data () {
    return {
      BannerType: 'incubator',
      compoentList: [
        {
          styleName: 'introduce',
          title: this.$t('cooperate.REACH6'),
          content: this.$t('cooperate.REACH7')
        }
      ],
      incubatorList: [
        {
          id: 'asfasf',
          tiltle: '厦门RIV锐智专业物联网众创空间',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          addr: '地址：  中国.厦门思明区软件园二期望海路51号5楼',
          stationNum: '工位： 100 个',
          siteType: '场地类型：开放空间、独立办公室（4人位/8人位/12人位/24人位）',
          siteDescript: '市级孵化器 | 核心地段 | 物联网开发实验室',
          createBy: 'test',
          createDate: '2021-11-09T09:05:40.000+00:00',
          updateBy: 'test',
          updateDate: '2021-11-09T09:05:44.000+00:00',
          remarks: null,
          status: '0',
          imgList: [
            {
              id: '1111',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            },
            {
              id: '11112',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            },
            {
              id: '11113',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            },
            {
              id: '11114',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            }
          ]
        },
        {
          id: 'asfasf2',
          tiltle: '厦门RIV锐智专业物联网众创空间',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          addr: '地址：  中国.厦门思明区软件园二期望海路51号5楼',
          stationNum: '工位： 100 个',
          siteType: '场地类型：开放空间、独立办公室（4人位/8人位/12人位/24人位）',
          siteDescript: '市级孵化器 | 核心地段 | 物联网开发实验室',
          createBy: 'test',
          createDate: '2021-11-09T09:05:40.000+00:00',
          updateBy: 'test',
          updateDate: '2021-11-09T09:05:44.000+00:00',
          remarks: null,
          status: '0',
          imgList: [
            {
              id: '1111',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            },
            {
              id: '11112',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            },
            {
              id: '11113',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            },
            {
              id: '11114',
              incubatorId: 'asfasf',
              img: require('../../assets/riv.png'),
              createBy: 'test',
              createDate: '2021-11-09T09:24:10.000+00:00',
              updateBy: 'test',
              updateDate: '2021-11-09T09:24:16.000+00:00',
              remarks: null
            }
          ]
        }]
    }
  },
  created () {},
  mounted () {
    this.getIncubatorList()
  },
  methods: {
    
       checkToken (idd) {
          var url = '/reach/portal/api/user/getUserInfo'
          //区别刷新时候结束，不用往下执行
          axios.get(url).then(response => {
              if(response.data.code==2){
                  this.$my_confirm(this.$t('Cart.expired'), "提示", {
                        type: "warn",
                        cancelBtnText: this.$t('login.sure'),
                        otherBtnText: "",
                        yesBtnText: ""
                    })
                    .then(action => {
                        if (action == "yes") {
                            this.$router.push({ path: "/loginn" });
                        } else if (action == "other") {}
                    })
                    .catch(() => {});  
                    Cookies.set('token', null)
                    window.localStorage.setItem('curretUser', '')
                    window.localStorage.setItem('usertoken', '')
                    this.reload();
              }
              else{
                 this.$router.push({ name: 'contract', params: { incubatorId: idd } })
              }
          })
    },
    toContract (id) {
       this.checkToken(id)
    },
    getIncubatorList () {
      var url = '/reach/portal/api/cooperate/getIncubatorList'
      axios.post(url,{},{
        params:{ language: localStorage.getItem("locale")}
      }).then(response => {
        if (response.data.code === 1) {
          this.incubatorList = response.data.data
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.content_all {
  padding-bottom: 2rem;
  .content_inner{
      width: 100%;
      margin: 0 auto;
      .firentWrap{
         position: relative;
         height: 7rem;
         margin-bottom: 1.5rem;
         .bgImgWrap{
           position: absolute;
           top: 72%;
           left: 50%;
           transform:translate(-50%,-50%);
           width: 86%;
           margin: 0 auto;
           img{
             width: 100%;
           }
         }
      }
      .xia{
        position: relative;
        background-color: #f2f2f2;
        height: 14rem;
        .xiaWrap{
          width: 80%;
          margin: 0 auto;
          padding: 0.5rem 0;
          p{
            text-align: left;
            font-size: 0.3rem;
            margin-top: 0.5rem;
            &:nth-child(1){
              font-size: 0.6rem;
            }
          }
          .detail{
            margin: 0.8rem 0;
            p{
              font-size: 0.25rem;
              color:#848484;
              margin-top: 0.2rem;
            }
          }
          .imgWrap{
            display: flex;
            justify-content: space-between;
            img{
              width: 22%;
            }
          }
        }
        .contract{
            float: right;
            margin: 1rem 0;
            .el-button{
              background-color: #3ac0f1;
              color: #fff;
              border-radius: 0.17rem;
              width: 2.3rem;
              // height: 0.6rem;
            }
        }
      }
  }
}
</style>
