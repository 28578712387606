export const getSame = (arra, arrb) => Object.keys(arra).forEach(key => {
  arra[key] = arrb[key] || arra[key]
})
export const timestampToTime = (timestamp) => {
  var date = new Date(timestamp)
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() + ' '
  var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours()) : date.getHours()) + ':'
  var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':'
  var s = date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds()) : date.getSeconds()
  return Y + M + D + h + m + s
}

export const msgboxx = (that, title, cotent) => {
  const h2 = that.$createElement // 创建元素
  that.$msgbox({
    title: title,
    message: h2(
      'p',
      {
        style:
                'display: flex;justify-content: center;align-items: center;'
      },
      [
        // 通过 class名添加图标
        h2(
          'i',
          {
            class: 'el-icon-circle-check',
            style: 'font-size: 47px;margin:0 40px 10px 0;color: #F5A400;'
          },
          ''
        ),
        h2(
          'p',
          { style: 'font-size: 37px;margin-bottom:10px;' },
          cotent
        )
      ]
    ),
    showCancelButton: false, // 不显示取消按钮
    showConfirmButton: false // 不显示确定按钮
  })
    .then((action) => {
      // 点击确定后执行的方法
    })
    .catch(() => {
      // 点击取消后执行的方法
    })
}

export const isphone = () => {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
