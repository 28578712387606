<template>
	<div class="buycont_all">
		<div class="content_wrap">
			<el-main>
				<div class="main_title">{{ getGoods[0].buyStatus }}{{ $t('Purchased.list') }}</div>
				<el-dialog :title="$t('Purchased.View')" :visible.sync="dialogVisible" width="60%">
					<div class="messageWrap">
						<p class="message" v-for="(item, index) in wuliuList" :key="index">
							<span class="timee">{{ item.AcceptTime }}</span>
							<span>{{ item.AcceptStation }}</span>
						</p>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button @click="cancel()" type="primary">{{ $t('Purchased.Confirm') }}</el-button>
					</span>
				</el-dialog>
				<!--				<div class="idNum">>订单编号：{{ getGoods[0].orderid }}</div>-->
				<el-table :data="getGoods" border style="width: 100%">
					<el-table-column type="index"> </el-table-column>
					<el-table-column prop="orderid" :label="$t('Purchased.Order')">
					</el-table-column>
					<el-table-column prop="productName" :label="$t('Purchased.name')">
					</el-table-column>
					<el-table-column prop="picture" :label="$t('Purchased.image')">
						<template slot-scope="scope">
							<viewer>
								<img :src="scope.row.picture" class="img_row" />
							</viewer>
						</template>
					</el-table-column>
					<el-table-column width="80" prop="orderPay" :label="$t('Purchased.price')">
					</el-table-column>
					<el-table-column width="80" prop="productSells" :label="$t('Purchased.quantity')">
					</el-table-column>
					<el-table-column prop="createDate" :label="$t('Purchased.time')">
					</el-table-column>
					<el-table-column width="220" prop="createDate" :label="$t('Purchased.Operation')"
						v-if="(getGoods[0].buyStatus == $t('Purchased.notshipped')) || (getGoods[0].buyStatus == $t('Purchased.Shipped')) || (getGoods[0].buyStatus == $t('Purchased.Completed')) || (getGoods[0].buyStatus == $t('Purchased.Unpaid'))">
						<template slot-scope="scope">
							<div class="bnt_wrap">
								<el-button size="mini"
									v-if="(getGoods[0].buyStatus == $t('Purchased.notshipped')) || (getGoods[0].buyStatus == $t('Purchased.Shipped')) || (getGoods[0].buyStatus == $t('Purchased.Completed'))"
									type="danger" @click="tkuan(scope)" v-preventReClick="2000">{{
					$t('Purchased.refunded') }}</el-button>
								<el-button size="mini"
									v-if="(getGoods[0].buyStatus == $t('Purchased.Shipped')) || (getGoods[0].buyStatus == $t('Purchased.Completed'))"
									type="primary" @click="thuo(scope)" v-preventReClick="2000">{{
					$t('Purchased.exchanges') }}</el-button>
								<el-button size="mini" v-if="(getGoods[0].buyStatus == $t('Purchased.Shipped'))"
									type="success" @click="toLogistics(scope)">{{ $t('Purchased.View') }}</el-button>
								<el-button size="mini" v-if="(getGoods[0].buyStatus == $t('Purchased.Unpaid'))"
									type="danger" @click="deleteOrder(scope)" v-preventReClick="2000">{{
					$t('Purchased.Delete') }}</el-button>
								<!-- 申请开票-已完成订单可申请开票 -->
								<el-button size="mini"
									v-if="(getGoods[0].buyStatus == $t('Purchased.Completed') && scope.row.receiptStatus === '0')"
									@click="invoicing(scope, 'add')" v-preventReClick="2000">{{
					$t('Purchased.invoicing') }}</el-button>
								<!-- 已提交开票信息显示修改发票 receiptStatus=1 开票中 -->
								<el-button size="mini"
									v-if="(getGoods[0].buyStatus == $t('Purchased.Completed') && scope.row.receiptStatus === '1')"
									@click="invoicing(scope, 'edit')" v-preventReClick="2000">{{
					$t('Purchased.editInvoicing') }}</el-button>
								<!-- 已开票 receiptStatus=2 -->
								<el-button size="mini"
									v-if="(getGoods[0].buyStatus == $t('Purchased.Completed') && scope.row.receiptStatus === '2')"
									@click="downInvoicing(scope)" v-preventReClick="2000">{{
					$t('Purchased.downInvoicing') }}</el-button>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="orderStatus" label="订单状态">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.num" @change="issub(scope.$index)" size="mini" :min="0">
							</el-input-number>
						</template>
					</el-table-column> -->
					<!-- <el-table-column label="操作" width="131">
						<template slot-scope="scope">
							<el-button @click="update(scope)" type="text" size="small">编辑</el-button>
							<el-button type="text" size="small" @click="del(scope.$index)">删除</el-button>
						</template>
					</el-table-column> -->
				</el-table>
				<div class="bottom">
					<!-- <div class="bnt_wrap">
						<el-button v-if="(getGoods[0].buyStatus=='未发货')||(getGoods[0].buyStatus=='已发货')||(getGoods[0].buyStatus=='已完成')" type="danger" @click="tkuan()" v-preventReClick="2000">申请退款</el-button>
						<el-button v-if="(getGoods[0].buyStatus=='已发货')||(getGoods[0].buyStatus=='已完成')" type="primary" @click="thuo()" v-preventReClick="2000">退换货</el-button>
					</div> -->
					<div class="bottom_right">
						<!--						<span class="allCout">合计:&nbsp;&nbsp;&nbsp;&nbsp;<span>￥{{ getGoods[0].orderPay }}</span></span>-->
						<!-- <el-button type="danger" @click="send()" v-preventReClick="2000" size="mini">提交订单</el-button> -->
					</div>
				</div>

			</el-main>
		</div>

		<!-- 申请、修改开票弹窗 -->
		<el-dialog :title="invoicingOrEdit == true ? $t('Purchased.invoicing') : $t('Purchased.editInvoicing')"
			:visible.sync="invoicingDialog" width="40%">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px">
				<el-form-item :label="$t('Purchased.invoicingType')" prop="receiptType">
					<el-radio-group v-model="form.receiptType" @change="handleInvoiceTypeChange">
						<el-radio label="0">{{ $t('Purchased.regularInvoice') }}</el-radio>
						<el-radio label="1">{{ $t('Purchased.specialInvoice') }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('Purchased.invoiceTitle')" prop="receiptHeader">
					<el-input v-model="form.receiptHeader"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Purchased.unitTaxNumber')" prop="taxIdNumber">
					<el-input v-model="form.taxIdNumber"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Purchased.registeredAddress')" prop="registerAddr">
					<el-input v-model="form.registerAddr"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Purchased.registeredPhone')" prop="registerPhone">
					<el-input v-model="form.registerPhone"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Purchased.bankOfDeposit')" prop="bank">
					<el-input v-model="form.bank"></el-input>
				</el-form-item>
				<el-form-item :label="$t('Purchased.bankAccount')" prop="bankAccount">
					<el-input v-model="form.bankAccount"></el-input>
				</el-form-item>
				<el-form-item>
					<div class="invoicing-btn">
						<el-button @click="onCancel">{{ $t('Purchased.cancel') }}</el-button>
						<el-button type="primary" @click="onSubmit">{{ $t('Purchased.confirm')}}</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import axios from '../../api/axios'
import Net from '../../api/net.js';
import newAxios from '../../api/newAxios';

export default {
	name: "buyList",
	props: ["getGoods"],
	data() {
		return {
			wuliuList: [],
			usertoken: '',
			allbuy: {
				orderStatus: '',
				createDate: '',
				orderWay: ''
			},
			activeIndex: "1",
			activeIndex2: "4",
			bkContent: "",
			dialogVisible: false,
			active: "zero_num",
			goods_name: "",
			goods_num: "",
			goods_price: "",
			goods_decri: "",
			goods_index: -1, // 当前本次操作的商品信息[-1表示新增,大于等于0表示编辑]
			numId: 0,
			goods_list: [
				// {
				// 	name: "电脑入门",
				// 	img: require("../../assets/store/u1053.png"),
				// 	num: 27,
				// 	price: 20,
				// 	decri: "知识性商品介绍是为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考",
				// },
			],
			// 开票
			invoicingDialog: false,
			// 选择开票的订单id
			selOrderId: '',
			// 开票 true || 修改 false
			invoicingOrEdit: true,
			// 开票表单数据
			form: {},
			// 表单校验规则
			rules: {
				receiptType: [{ required: true, message: this.$t('Purchased.selInvtype'), trigger: 'change' }],
				receiptHeader: [{ required: true, message: this.$t('Purchased.enterInvTitle'), trigger: 'change' }],
				taxIdNumber: [{ required: true, message: this.$t('Purchased.enterUnitTax'), trigger: 'change' }],
				registerAddr: [{ required: false, message: this.$t('Purchased.enterRegAdd'), trigger: 'change' }],
				registerPhone: [{ required: false, message: this.$t('Purchased.enterRegPhone'), trigger: 'change' }],
				bank: [{ required: false, message: this.$t('Purchased.enterBankDeposit'), trigger: 'change' }],
				bankAccount: [{ required: false, message: this.$t('Purchased.enterBankAccount'), trigger: 'change' }],
			},
		};
	},
	directives: {
		preventReClick: {
			bind(el, binding) {
				el.addEventListener("click", () => {
					if (!el.disabled) {
						el.disabled = true;
						setTimeout(() => {
							el.disabled = false;
						}, binding.value || 3000);
					}
				});
			},
		},
	},
	created() {
		this.usertoken = window.localStorage.getItem("usertoken") || "";
		/* this.goods_list = [];
			this.allbuy = JSON.parse(window.localStorage.getItem('allbuy')) || {};
			this.goods_list = this.allbuy.cartlist;
			this.getOrderlist(this.allbuy.numId);
		this.numId = this.RondomPass(18); */

		//console.log(this.rebl.title) 可以
		/* 	var that = this;
			that.$axios({
				method: 'get',
				url: '/f/eshopping/eshoppingModeAndProduct/orderlist',
				params: {
					createBy: "system",
					orderStatus: "2",
					orderid: "1618920204109020116156"
				}
				// url: '/f/eshopping/eshoppingModeAndProduct/ModeList',
			}).then(res => {
				console.log(res)
			}).catch(err => {
				this.$notify({
					title: '错误',
					message: err,
					type: 'warning'
				})
				return false
			}); */

	},
	methods: {
		// 更改表单校验规则
		handleInvoiceTypeChange() {
			// 清除表单校验状态
			this.$refs.form.clearValidate();
			if (this.form.receiptType === '0') {
				this.rules.receiptHeader = [{ required: true, message: this.$t('Purchased.enterInvTitle'), trigger: 'change' }];
				this.rules.taxIdNumber = [{ required: true, message: this.$t('Purchased.enterUnitTax'), trigger: 'change' }];
				this.rules.registerAddr = [{ required: false, message: this.$t('Purchased.enterRegAdd'), trigger: 'change' }];
				this.rules.registerPhone = [{ required: false, message: this.$t('Purchased.enterRegPhone'), trigger: 'change' }];
				this.rules.bank = [{ required: false, message: this.$t('Purchased.enterBankDeposit'), trigger: 'change' }];
				this.rules.bankAccount = [{ required: false, message: this.$t('Purchased.enterBankAccount'), trigger: 'change' }];
			} else if (this.form.receiptType === '1') {
				this.rules.receiptHeader = [{ required: true, message: this.$t('Purchased.enterInvTitle'), trigger: 'change' }];
				this.rules.taxIdNumber = [{ required: true, message: this.$t('Purchased.enterUnitTax'), trigger: 'change' }];
				this.rules.registerAddr = [{ required: true, message: this.$t('Purchased.enterRegAdd'), trigger: 'change' }];
				this.rules.registerPhone = [{ required: true, message: this.$t('Purchased.enterRegPhone'), trigger: 'change' }];
				this.rules.bank = [{ required: true, message: this.$t('Purchased.enterBankDeposit'), trigger: 'change' }];
				this.rules.bankAccount = [{ required: true, message: this.$t('Purchased.enterBankAccount'), trigger: 'change' }];
			}
		},
		onCancel() {
			this.invoicingDialog = false;
		},
		// 提交开票数据
		onSubmit() {
			this.$refs.form.validate(async valid => {
				// 订单id
				this.form.orderId = this.selOrderId;
				// "receiptType": "0",  //0 普通发票，1专用发票
				if (valid) {
					// 表单校验通过，执行提交操作
					// 申请开票
					if (this.invoicingOrEdit == true) {
						await newAxios.post('/f/eshopping/customReceipt/createReceipt', this.form)
							.then((res) => {
								if (res.data.code === 1) {
									this.$message.success(res.data.message);
									this.invoicingDialog = false;
									this.$emit('fatherMethod');
								}
							}).catch((err) => {
								this.$message.error(err.message)
							});
					}
					// 修改发票
					else {
						await newAxios.post('/f/eshopping/customReceipt/editReceipt', this.form)
							.then((res) => {
								if (res.data.code === 1) {
									this.$message.success(res.data.message);
									this.invoicingDialog = false;
								}
							}).catch((err) => {
								this.$message.error(err.message)
							});
					}

				}
			});
		},
		// 申请开票
		invoicing(item, type) {
			// console.log('invoicing:', item.row.orderid)
			this.form = {
				receiptType: '0'
			};
			this.selOrderId = item.row.orderid;
			this.invoicingDialog = true;
			if (type === 'edit') {
				// 标记是编辑还是新增
				this.invoicingOrEdit = false;
				this.getInvoicing(item.row.orderid)
					.then((res) => {
						this.form = res.data;
						this.handleInvoiceTypeChange();
					}).catch((err) => {
						console.error('申请开票,', err);
					});
			} else {
				this.invoicingOrEdit = true;
			}
		},
		// 获取订单发票
		getInvoicing(orderid) {
			return new Promise((resolve, reject) => {
				newAxios.get('/f/eshopping/customReceipt/getReceipt?orderId=' + orderid)
					.then((res) => {
						if (res.data.code === 1) {
							resolve(res.data); // 成功获取数据后返回
						} else {

						}
					}).catch((err) => {
						reject(err); // 处理错误情况
					});
			});
		},
		// 下载发票
		downInvoicing(item) {
			this.getInvoicing(item.row.orderid)
				.then((res) => {
					// 在这里使用发票地址
					const baseUrl = window.location.origin;
					const downUrl = baseUrl + res.data.receiptUrl;
					console.log(downUrl);
					// 在新标签页中打开发票链接
					window.open(downUrl);
				})
				.catch((err) => {
					// 处理错误
					console.error('下载发票：', err);
				});
		},
		// 删除订单
		deleteOrder(e) {
			var that = this;
			this.$my_confirm(this.$t('Purchased.operation'), this.$t('login.prompt'), {
				type: "warn",
				cancelBtnText: this.$t('login.sure'),
				otherBtnText: "",
				yesBtnText: ""
			})
				.then(action => {
					if (action == "yes") {

						axios.post("/f/eshopping/eshoppingModeAndProduct/deleteOrder", {}, {
							params: {
								orderId: e.row.orderid,
								eshoppingtoken: that.usertoken,
							}
						}).then(res => {
							that.$emit('fatherMethod');
						})
					} else if (action == "other") { }
				})
				.catch(() => {
					// console.log("点击了取消按钮cancel");
				});
		},
		tkuan(e) {
			var that = this;
			this.$my_confirm(this.$t('Purchased.products'), this.$t('login.prompt'), {
				type: "warn",
				cancelBtnText: this.$t('login.sure'),
				otherBtnText: "",
				yesBtnText: ""
			})
				.then(action => {
					if (action == "yes") {

						axios.post("/f/eshopping/eshoppingModeAndProduct/returnPay", {}, {
							params: {
								orderId: e.row.orderid,
								eshoppingtoken: that.usertoken,
							}
						}).then(res => {
							that.$emit('fatherMethod');
						})
					} else if (action == "other") { }
				})
				.catch(() => { });
		},
		thuo(e) {
			var that = this;
			this.$my_confirm(this.$t('Purchased.goods'), this.$t('login.prompt'), {
				type: "warn",
				cancelBtnText: this.$t('login.sure'),
				otherBtnText: "",
				yesBtnText: ""
			})
				.then(action => {
					if (action == "yes") {
						that.$emit('tochangeTab', e.row.orderid);
					}
					else if (action == "other") { }
				})
				.catch(() => {
				});

			/* axios.post("/f/eshopping/eshoppingModeAndProduct/changePay", {}, {
					params: {
						orderId: e.row.orderid,
						eshoppingtoken: that.usertoken,
					}
					}).then(res => {
						that.$emit('fatherMethod');
						that.$emit('tochangeTab');
					})  */
		},
		tochangeOrderStatus(e) {
			var that = this;
			this.$my_confirm(this.$t('Purchased.pay'), this.$t('login.prompt'), {
				type: "warn",
				cancelBtnText: this.$t('login.sure'),
				otherBtnText: "",
				yesBtnText: ""
			})
				.then(action => {
					if (action == "yes") {

						axios.post("/f/eshopping/eshoppingModeAndProduct/saveorder", {
							orderid: e.row.orderid,
							orderStatus: "9"
						}, {
							params: {
								eshoppingtoken: that.usertoken
							}
						}).then(res => {
							that.$emit('fatherMethod');
						})
					} else if (action == "other") { }
				})
				.catch(() => {
					console.log("点击了取消按钮cancel");
				});
		},
		toLogistics(e) {
			var that = this;
			axios.post("/f/eshopping/eshoppingModeAndProduct/wuliuformget", {}, {
				params: {
					LogisticsOrderNo: e.row.wuliuId,
					eshoppingtoken: that.usertoken
				}
			}).then(res => {
				that.wuliuList = [];
				that.wuliuList = res.data;
				that.dialogVisible = true;
			})
		},

		send() {
			/* var url =
			  "/eshopping"; */
			/* var data = {
			  WIDout_trade_no: 202151113594430764,
			  WIDtotal_amount: 200,
			  WIDsubject: "1",
			  WIDbody: "2",
			};  */

			// console.log("this.Axios");
			var subject = "";
			this.goods_list.forEach((v) => {
				subject = subject !== "" ? subject + ";" + v.name : subject + v.name;
			});
			console.log(subject);

			/* this.$axios(url, params).then((res) => {
			  //请求成功
			  console.log(res);
			}); */
			// var urll="http://192.168.16.161:8989/f/eshopping/eshoppingKey/pay?WIDout_trade_no=202151113594430764&WIDtotal_amount=200&WIDsubject=TES&WIDbody=12S";
			/*  this.$axios({
			  method: "post",
			  url: url,
			  params,
			}).then((result) => {
			  console.log(result);
			}); */
		},
		handleClose(done) {
			this.$confirm(this.$t('Purchased.closure'))
				.then(() => {
					done();
				})
				.catch(() => { });
		},
		issub(index) {
			if (this.goods_list[index].num === 0) {
				this.del(index);
			}
		},
		cancel() {
			this.dialogVisible = false;
		},
		del(index) {
			// 删除数据
			this.goods_list.splice(index, 1);
		},
		update(index) {
			// 显示当前编辑的商品信息
			this.dialogVisible = true;
			console.log(index);
			this.goods_index = index.$index;
			this.goods_name = index.row.name;
			this.goods_num = index.row.num;
			this.goods_price = index.row.price;
			this.goods_decri = index.row.decri;
			// 当用户点击保存时，修改对应数据
		},
		RondomPass(number) {
			var arr = new Array();
			var arr1 = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");

			for (var i = 0; i < number; i++) {
				var n = Math.floor(Math.random() * 10);
				arr[i] = arr1[n];
				//document.write(arr1[n]);
			}

			var val = arr.join("");
			//document.write(val);
			return val;
		},
		async getOrder() {
			var that = this;
			/* let {
				numId
			} = JSON.parse(window.localStorage.getItem('allPay')) || ''; */
			that.goods_list = [];
			that.allbuy = JSON.parse(window.localStorage.getItem('allbuy')) || {};
			that.goods_list = that.allbuy.cartlist;
			that.getOrderlist(that.allbuy.numId);
			/* 	let obj=that.getOrderlist(that.allbuy.numId);
				console.log(obj); */
			// console.log(res);
			/* buyObj.cartlist.forEach((v, i) => {
				console.log(i);
				let res = that.getOrderlist(v.numId);
				console.log(res)
				/* res.forEach((vv, ii) => {
					var obj = {
						createBy: '',
						createDate: '',
						orderPay: '',
						orderStatus: '',
						orderWay: '',
						orderid: '',
						updateBy: ''
					};
					getSame(obj, res[ii]);
					obj.orderStatus = obj.orderStatus == "0" ? '未发货' : obj.orderStatus == "1" ? '已发货' : '已完成';
					obj.createDate = that.timestampToTime(obj.createDate);
					obj.productName='';
					that.goods_list.push(obj);
			})

			// console.log(res);
			// that.goods_list.splice(0, 1);

				//这里的i如果判断getSame的obj没有对应值获取，即i不等于goods_list的index，传goods_list.length-1
				// that.getproName(v.id,i);
			}) */

		},
		async getproName(idd, index) {
			var that = this;
			that.goods_list = [];
			let res2 = await Net.get('/f/eshopping/eshoppingModeAndProduct/ProductList', {
				productid: '1398161108522135552', language: localStorage.getItem("locale")
			});
			// console.log(res2)
			res2.forEach((vv) => {
				// if(idd==vv.id){
				if ('1398161108522135552' == vv.id) {
					// that.goods_list[index].productName=vv.productName;
					// that.goods_list[index].productPicture=vv.productPicture;
					that.goods_list[index].productPicture = vv.productPicture;

					that.$set(that.goods_list[index], "productName", vv.productName);
				}
			})
			// this.$forceUpdate();
			/* 	var that=this;
				that.goods_list.forEach((v, i) => {if(v.id==idd){
					let res2 = await Net.get('/f/eshopping/eshoppingModeAndProduct/ProductList', {
						productid: idd
					});
					res2.forEach((vv, ii) => {
										  if(idd==vv.id){
										  v.productName=vv.productName;
										  v.productPicture=vv.productPicture;
											}
										  })
				}}); */

		},
		async getOrderlist(id) {
			var that = this;
			that.goods_list = [];
			var curretUser = window.localStorage.getItem('curretUser') || '';
			var sendObj = {
				createby: curretUser,
				orderStatus: "0",
				orderid: id,
				eshoppingtoken: that.usertoken
			};
			//   var parm=this.$qs.stringify(sendObj);
			let res = await this.$Net.get(
				"/f/eshopping/eshoppingModeAndProduct/orderlist",
				sendObj
			);
			console.log('获取订单：');
			console.log(res);

			res.forEach((v, i) => {
				let obj = {
					createBy: '',
					createDate: '',
					orderPay: '',
					orderStatus: '',
					orderWay: '',
					orderid: '',
					updateBy: '',
					picture: '',
					productSells: 0,
					productName: ''
				};
				that.$getSame(obj, res[i]);
				obj.orderStatus = this.$t('Purchased.notshipped');
				obj.createDate = that.$timestampToTime(obj.createDate);
				that.goods_list.push(obj);
			});
			/* 	不需要，一个id对应一个商品状态（不用返回数组，返回对象即可）
			res.forEach((vv, ii) => {
									let obj={};
									obj.orderStatus=vv.orderStatus;
									obj.createDate=vv.createDate;
									  }) */
			// return res;

			/*
			this.allbuy.orderStatus = res[0].orderStatus;
			this.allbuy.createDate = res[0].createDate;
			this.allbuy.orderWay = res[0].orderWay;
			this.allbuy.orderStatus= this.allbuy.orderStatus== "0" ? '未发货' : this.allbuy.orderStatus== "1" ? '已发货' : '已完成';
			this.allbuy.createDate = this.timestampToTime(this.allbuy.createDate);
			// this.$set(this.allbuy, "orderStatus", this.allbuy.orderStatus);
			this.allbuy = Object.assign({}, this.allbuy) */
			// var that = this;


			/* this.goods_list.forEach((vv) => {
				// console.log(ii)
				vv.orderStatus = res[0].orderStatus == "0" ? '未发货' : res[0].orderStatus == "1" ? '已发货' :
					'已完成';
				vv.createDate = timestampToTime(res[0].createDate);
				vv.orderWay = res[0].orderWay;
			}) */


			/* let obj={};s
			obj.orderStatus=res[0].orderStatus;
			obj.createDate=res[0].createDate;
			return obj; */
		},

	},
	computed: {
		/* total() {
			let ret = 0;
			this.goods_list.forEach(function(value) {
				// console.log(value,index);
				let sum_price = parseFloat(value.price) * parseFloat(value.num);
				ret = ret + sum_price;
			});
			return ret;
		} */
	}
};
</script>

<style scoped>
.invoicing-btn {
	margin-right: 80px;
}

.buycont_all {
	width: 100%;
	height: 100%;
	/* margin: 0.5rem 0; */
}

.el-header {
	background-color: rgb(84, 92, 100);
}

.buycont_all .content_wrap {
	width: 100%;
	margin: 0 auto;


}

.el-header {
	padding: 0;
}

.el-main {
	/* padding: 20px 0 20px 0; */
}

.el-main .main_title {
	font-size: 23Px;
	font-weight: 600;
	text-align: center;
	/* margin: 20px; */
	margin-bottom: 0.68rem;
}

.idNum {
	text-align: left;
	margin-bottom: 10px;
	font-size: initial;
	font-weight: normal;
}

.img_row {
	height: 50px;
	margin: 0 auto;
	display: inherit;
}

.el-table /deep/ .decrit .cell {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.el-table /deep/ th.is-leaf {
	border-bottom: 1px solid #ebeef5;
	text-align: center;
}

/* .el-table--enable-row-hover /deep/ .el-table__body tr:hover>td { */
.el-table--enable-row-hover /deep/ .el-table__body tr>td {
	background-color: #f5f7fa;
	text-align: center;
}

.bottom {
	margin: 0.7rem 0 1rem 0;
	display: flex;
	justify-content: flex-end;
}

.allCout {
	display: inline-block;
	margin-right: 11px;
	font-family: "Gill Sans", sans-serif;
	height: 32px;
	line-height: 32px;
	font-size: 14Px;
}

.allCout>span {
	color: #b13841;
	font-size: 17Px;
	font-weight: 600;
	margin-left: 0.1rem;
}
</style>
<style lang="less" scoped>
.content_wrap /deep/ .el-table {
	font-size: 14Px;
}

.content_wrap /deep/ .el-table th {
	// padding: 0.5rem 0;
}

.content_wrap /deep/ .el-input--mini {
	.el-input__inner {
		height: 0.5rem;
		line-height: 5rem;
	}
}

.content_wrap /deep/ .el-table .cell {
	line-height: 0.4rem;
}

.content_wrap /deep/ .img_row {
	height: 1.6rem;
}

.content_wrap .cartBnt {
	width: 2rem;
	height: 0.6rem;
}

.content_wrap /deep/ .el-dialog {
	padding: 2% 0;

	.el-dialog__header {
		border-bottom: 1px solid #dedede;
		// padding-bottom: 0.3rem;
		padding: 0;
		font-weight: 600;

		.el-dialog__title {
			font-size: 0.35rem;
		}
	}

	.message {
		font-size: 0.27rem;
		text-align: left;
		border-bottom: 1px solid #dedede;
		padding: 0.2rem 0 0.1rem 0;

		&:nth-last-child(1) {
			color: #1c1cff;
			font-weight: 600;
		}

		.timee {
			margin-bottom: 0.15rem;
			display: block;
		}
	}

	.el-dialog__footer {
		text-align: center;
		margin-top: 0.25rem;

		// .el-button {
		// 	height: 0.5rem;
		// 	width: 1.3rem;
		// 	margin-right: 0.2rem;

		// 	span {
		// 		font-size: 0.3rem;
		// 	}
		// }
	}
}

.bnt_wrap {
	// text-align: left;

	.el-button {
		margin-top: 10px;
		// margin-left: 10px;
	}

	// margin-right: 0.2rem;

	// /deep/ .el-button {
	// 	width: 1.5rem;
	// 	height: 0.55rem;
	// 	line-height: 0.2rem;
	// 	font-size: 18Px;
	// 	margin-bottom: 0.2rem;
	// }
}
</style>
