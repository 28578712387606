<template>
    <div>
		<div class="search_wrap">
			<p>{{!isDeve?  $t("heard.System") :$t("heard.keywords") }}</p>
			<p v-if="!isDeve">{{$t('heard.Requirements')}}</p>
			<el-input placeholder="" v-model="input" clearable @change="seaechFor()">
			</el-input>
			<div class="search_bnt" @click="sendsearch()"><img src="../../assets/store/searlogo.png" /><span>{{$t('heard.search')}}</span></div>
		</div>
		<serchView ref="searchbox" v-if="serboxShow&&!isDeve" :sechShow.sync="serboxShow"></serchView>
		<deveSerch ref="deveSearchbox" v-if="serboxShow&&isDeve" :sechShow.sync="serboxShow"></deveSerch>
	</div>
</template>

<script>
	import serchView from "../../components/serchView/serchView.vue";
	import deveSerch from "../../components/serchView/deveSerch.vue";
	export default {
		name: "storeSearch",
		components: {
			serchView,
			deveSerch
		},
		props: ['isDeve'],
		data() {
			return {
				input: '',
				endinput: '',
				serboxShow: false
			}
		},
		methods: {
			seaechFor() {
				this.endinput = this.input;
			},
			sendsearch() {
				var that = this;
				// console.log(this.endinput);
				this.serboxShow = true;
				if(this.isDeve){
					this.$nextTick(() => {
					  that.$refs.deveSearchbox.getSearch(that.endinput);
				    })
				}
				else{
					this.$nextTick(() => {
					  that.$refs.searchbox.getSearch(that.endinput);
				    })
				}
				// this.$refs.searchbox.getSearch(this.endinput);//获取不到
				
				/* that.smallImg.splice(0, 1);
				res.forEach((v, i) => {
					var obj = {
						id: '',
						productPicture: '',
						productName: '',
						productOldPrice: '',
						productPrice: '',
						modeId: ''
					};
					getSame(obj, res[i]);
					if (v.recommandStatus == "1") {
						that.smallImg.push(obj);
					}
				}) */
			}
		}
		//props: ['dataList'],
	}
</script>
<style>
	.search_wrap .el-input {
		width: 70%;
		margin-top: 0.22rem;
	}

	.search_wrap .el-input__inner {
		padding-right: 30px;
		/* width: 847px;
		height: 37px; */
		border-radius: 0;
		height: 0.7rem;
	}
</style>
<style scoped lang="less">
	.search_wrap {
		background-color: #19c27b;
		height: 4.85rem;
		text-align: center;
		padding-top: 0.5rem;
		margin-top: 0.17rem;

		p {
			font-family: '微软雅黑 Light', '微软雅黑 Regular', '微软雅黑', sans-serif;
			font-weight: 250;
			font-style: normal;
			font-size: 0.42rem;
			color: #FFFFFF;
			margin-bottom: 0.2rem;
		}

		p:nth-child(2) {
			font-family: '微软雅黑 Light', '微软雅黑 Regular', '微软雅黑', sans-serif;
			font-weight: 250;
			font-style: normal;
			font-size: 0.27rem;
			color: #FFFFFF;
		}
        /deep/ .el-input{
            font-size: 24Px; 
        }
		.search_bnt {
			cursor: pointer;
			margin: 0 auto;
			margin-top: 0.7rem;
			width: 3rem;
			height: 0.8rem;
			border: 0.05rem solid white;
			font-size: 0.37rem;
			color: #FFFFFF;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			display: flex;
			justify-content: space-around;
			align-items: center;
            img{
				width: 0.5rem;
			}
			span {
				margin-right: 0.7rem;
			}
		}
	}
</style>
