<template>
  <div class="content_all">
    <banner :type="BannerType" :menuId="menuId" :key="menuId"></banner>
    <serve :sendData='item' :styleName='item.styleName' v-for="(item,index) in compoentList" :key="index"></serve>
  </div>
</template>
<script>
import banner from '../../components/homePages/banner.vue'
import serve from '../../components/customPart/serve.vue'
import axios from '../../api/axios'
export default {
  name: 'addValue',
  components: {
    banner,
    serve
  },
  data () {
    return {
      BannerType: 'service',
      menuId: '',
      compoentList: [
        {
          styleName: 'fourColWord',
          title: '锐骐数据服务介绍',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          wordCol: [
            {title: 'T1-BOX', content: '锐骐物联为全球物联网\n开发者打造物联网智能'},
            {title: 'T2-BOX', content: '锐骐物联为全球物联网\n开发者打造物联网智能'},
            {title: 'T3-BOX', content: '锐骐物联为全球物联网\n开发者打造物联网智能'},
            {title: 'T4-BOX', content: '锐骐物联为全球物联网\n开发者打造物联网智能'},
            {title: 'T5-BOX', content: '锐骐物联为全球物联网\n开发者打造物联网智能'}
          ]
        },
        {
          styleName: 'LbntImg',
          button: '#1fbaf3',
          buttonLink: 'https://www.baidu.com/',
          backGroud: '',
          maxImg: require('../../assets/saas.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          produceData: ''
        },
        {
          styleName: 'download',
          title: '选择您感兴趣的数据服务',
          button: '定制报告  >>',
          dataFileList: [
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            }
          ]

        }
      ]
    }
  },
  created () {
    this.menuId = this.$route.query.serviceTypeId
  },
  mounted () {
    this.getDataSerModule()
  },
   watch: {
     $route: {
          handler(val,oldVal) {
            this.getDataSerModule()
            this.menuId = this.$route.query.serviceTypeId
          },
        deep: true,
    }
  },
  methods: {
    getDataSerModule () {
      var serviceTypeId = this.$route.query.serviceTypeId
      var url = '/reach/portal/api/module/getDataSerList'
      axios.post(url, {}, {
        params: {
          serviceTypeId: localStorage.getItem('serviceTypeId'),
          language: localStorage.getItem('locale')
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.compoentList = response.data.data
        }
      })
    }
  }
}
</script>
<style  scoped>
.heart .el-col-8 {
  width: 18.33333%;
}

.content_all .el-button--primary {
  width: 2.8rem;
  height: 0.63rem;
  line-height: 0.4rem;
  border-radius: 0.15rem;
  background-color: rgba(31, 186, 243, 1);
}

.product_row {
  padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boderCol:after {
  width: 3.22rem;
  height: 0.1rem;
  background-color: #ffde5d;
  content: "";
  position: absolute;
  left: -0.067708rem;
  top: 3.24rem;
  z-index: 10;
}

.product_row .el-card {
  width: 3rem;
  height: 3rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
  padding: 0px;
  color: #a4a4a4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.news_heart p {
  padding: 15px 0 19px 0;
}

.news_heart .el-card {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.news_heart .el-card__body {
  padding: 0;
}

.new_indro {
  padding: 0 8px 0 10px;
  text-align: left;
}

.news_heart .new_indro span {
  font-size: 0.25rem;
  color: #827c7c;
}

.news_heart .new_indro .news_title {
  display: block;
  font-weight: 600;
  font-size: 0.28rem;
  color: #3c3b3c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.1rem;
}

.news_heart img {
  width: 100%;
}

/*  .aboutUs_heart .el-button--primary {
    width: 133px;
    height: 33px;
    line-height: 1px;
    border-radius: 6px;
    margin-top: 26px;
  } */
</style>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 10px;
  position: relative;
}

.lunch_wrap {
  width: 100%;
  background-color: #1fbaf3;
  margin-top: 1.5rem;
}

.lunch_top {
  width: 16rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.6rem;
}

.lunch_top p {
  text-align: left;
  flex: 1;
  font-size: 0.24rem;
  color: #ffffff;
}

.lunch_top p:nth-child(2) {
  flex: 1.4;
}

.lunch_top p:nth-child(2) {
  flex: 1.5;
  text-align: center;
}
.lunch_bottom {
  .wh_slide {
    height: auto;
  }
  /deep/ .wh_indicator {
    bottom: 0.2rem;
    .wh_indicator_item {
      width: 1rem;
      height: 0.08rem;
      margin: 0rem 0.1rem;
      background: #fff;
      border-radius: 0;
    }
    .wh_show_bgcolor {
      background: #efcd5c;
    }
  }
}
</style>
