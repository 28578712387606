<template>
  <div class="docWrap">
    <div class="docTop">
       <img :src="bannerImg" class="staticBanner">
        <div class="docTopIner">
            <p class="altitle">{{$t('help.center')}}</p>
            <p>{{$t('help.keywords')}}</p>
            <div class="serchWrap">
                <el-input v-model="input"></el-input>
                <el-button icon="el-icon-search">{{$t('develop.search')}}</el-button>
            </div>
        </div>
    </div>
    <div class="docBottom">
          <iframe width="100%" height="100%" :src=url  frameborder="0" align="center"> </iframe>
    </div>
  </div>
</template>
<script>
import globalConst from '../../commethods/globalConst'
import axios from '../../api/axios'

export default {
  name: 'videoo',
  props: ['videoData'],
  data () {
    return {
      url: '',
      input: '',
      bannerImg: require('../../assets/helpBg.png')
    }
  },
  created () {
    this.url = globalConst.docUrl + '/#/show/doc/127?page_id=446'
  },
  mounted () {
    this.getBannerImg()
  },
  methods: {
    getBannerImg () {
      var bannerType = 'helpCenter'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          this.bannerImg = response.data.data[0].adImage
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.docWrap{
    .docTop{
        // background: url('../../assets/helpBg.png') no-repeat;
        height: 7.5rem;
        position: relative;
        background-position:-2% 26%;
        .docTopIner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 52%;
            p{
                color: #fff;
                &:nth-child(2){
                    font-size: 0.3rem;
                    margin: 0.26rem;
                }
            }
           .serchWrap{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            /deep/ .el-input{
                margin-right: 0.3rem;
                .el-input__inner{
                    // height: 0.6rem;
                }
            }
            .el-button{
                background-color: #19c27b;
                color: #fff;
                border-color: #19c27b;
                font-size: 0.3rem;
                font-weight: 600;
                width: 2.3rem;
                // height: 0.6rem;
                .el-icon-search{
                    margin-right: 0.1rem;
                    font-weight: 600;
                }
            }
           }
        }
    }
   .docBottom{
     height: 20rem;
     margin-bottom: 0.5rem;
   }
}
</style>
