<template>
  <div class="login_container" v-loading="loading">
    <div class="bg"></div>
    <!-- <div class="bg_left"></div> -->
    <div class="login_box">
      <!-- 头像 -->
      <p class="title">{{ $t('heard.authentication') }}</p>
      <!-- 申请人档案表单 -->
      <el-form :model="teamForm" label-width="0" class="login_form" :rules="rules" ref="LoginFormRef">
        <!-- <p class="seTitle">申请人档案</p> -->
        <el-form-item prop="contactPerson">
          <span>{{ $t('heard.name') }}：</span><el-input :placeholder="$t('heard.Required')"
            v-model="teamForm.contactPerson"></el-input>
        </el-form-item>
        <el-form-item prop="phoneNum">
          <span>{{ $t('heard.mobile') }}：</span><el-input :placeholder="$t('heard.Required')"
            v-model="teamForm.phoneNum"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <span>E-mail：</span><el-input :placeholder="$t('heard.Required')" v-model="teamForm.email"></el-input>
        </el-form-item>
        <el-form-item prop="addr">
          <span>{{ $t('heard.Address') }}：</span><el-input :placeholder="$t('heard.notRequired')"
            v-model="teamForm.addr"></el-input>
        </el-form-item>
        <!-- 营业执照 -->
        <!-- <el-form-item prop="imgList">
          <span>{{ $t('heard.License') }}:</span>
          <el-upload class="avatar-uploader" :http-request="Upload" action="" list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item> -->
        <!-- 公司名-->
        <!-- <el-form-item prop="profile">
            <span>个人简介：</span>
          <el-input
            placeholder="请输入个人简介"
            v-model="teamForm.profile"
            type="textarea"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <!-- 团队资料 -->
      <!-- <el-form
        :model="teamForm"
        label-width="0"
        class="login_form"
        :rules="rules"
        ref="LoginFormRef2"
      > -->
      <!-- <p class="seTitle">团队资料</p>
        <el-form-item prop="teamName">
          <span>团队名称：</span><el-input
            placeholder="请输入团队名称"
            v-model="teamForm.teamName"
          ></el-input>
        </el-form-item> -->
      <!-- <el-form-item>
          <span>网址：</span><el-input
            placeholder="请输入网址（选填）"
            v-model="teamForm.teamWebsite"
          ></el-input>
        </el-form-item>
        <el-form-item>
           <span>专业领域：</span>
           <el-radio-group v-model="teamForm.teamProfession">
                <el-radio :label="1">互联网+</el-radio>
                <el-radio :label="2">智能硬件</el-radio>
                <el-radio :label="3">其他</el-radio>
            </el-radio-group>
        </el-form-item>
        -->
      <!-- <el-form-item>
            <span>经营方式：</span>
           <el-radio-group v-model="teamForm.manageMode">
                <el-radio :label="1">个人</el-radio>
                <el-radio :label="2">公司</el-radio>
            </el-radio-group>
        </el-form-item> -->

      <!-- </el-form> -->
      <!-- 项目详情 -->
      <el-form :model="teamForm" label-width="0" class="login_form" :rules="rules">
        <!-- <el-form-item prop="proBackgroud">
            <span>项目背景：</span>
          <el-input
            placeholder="请输入项目背景"
            v-model="teamForm.proBackgroud"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="proContent">
            <span>内容说明：</span>
          <el-input
            placeholder="请输入内容说明"
            v-model="teamForm.proContent"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="operationPlan">
            <span>运营方案：</span>
          <el-input
            placeholder="请输入运营方案"
            v-model="teamForm.operationPlan"
            type="textarea"
          ></el-input>
        </el-form-item> -->

        <el-form-item>
          <span>{{ $t('heard.remark') }}:</span>
          <el-input class="textarea-view" :placeholder="$t('heard.notRequired')" v-model="teamForm.postscript"
            type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btns">
            <el-button type="primary" @click="submitRegister">{{ $t('register.Submit') }}</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from '../../api/axios'
import qs from 'qs'
export default {
  data() {
    return {
      loading: false,
      teamForm: {
        // incubatorId: '',
        // teamProfession: 1,
        // manageMode: 1,
        contactPerson: '',
        // education: '',
        // major: '',
        phoneNum: '',
        email: '',
        addr: '',
        // profile: '',
        // teamName: '',
        // teamWebsite: '',
        // proBackgroud: '',
        // proContent: '',
        // operationPlan: '',
        postscript: '',
        imgList: [],
      },
      rules: {
        contactPerson: [
          { required: true, message: this.$t('heard.name2'), trigger: 'blur' }
        ],
        // education: [
        //    { required: true, message: '请输入学历', trigger: 'blur'}
        // ],
        // major: [
        //    { required: true, message: '请输入专业', trigger: 'blur'}
        // ],
        phoneNum: [
          { required: true, message: this.$t('heard.mobile2'), trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('heard.email'), trigger: 'blur' }
        ],
        imgList: [
          { required: true, message: this.$t('heard.License2'), trigger: 'blur' }
        ],
        // profile: [
        //    { required: true, message: '请输入个人简介', trigger: 'blur'}
        // ],
        // teamName: [
        //    { required: true, message: '请输入团队名称', trigger: 'blur'}
        // ],
        // proBackgroud: [
        //    { required: true, message: '请输入项目背景', trigger: 'blur'}
        // ],
        // proContent: [
        //    { required: true, message: '请输入内容说明', trigger: 'blur'}
        // ],
        // operationPlan: [
        //    { required: true, message: '请输入运营方案', trigger: 'blur'}
        // ]
      }
    }
  },
  mounted() {
    // this.getIncubatorId();
    this.checkToken(1);
  },
  methods: {
    checkToken(parm) {
      var url = '/reach/portal/api/user/getUserInfo'
      //区别刷新时候结束，不用往下执行
      axios.get(url).then(response => {
        if (response.data.code == 2) {
          this.$my_confirm(this.$t('Cart.expired'), "提示", {
            type: "warn",
            cancelBtnText: this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => {
              if (action == "yes") {
                this.$router.push({ path: "/loginn" });
              } else if (action == "other") { }
            })
            .catch(() => { });
          Cookies.set('token', null)
          window.localStorage.setItem('curretUser', '')
          window.localStorage.setItem('usertoken', '')
          this.reload();
        }
        else {
          if (parm == 2) {
            this.submitRegister()
          }
        }
      })
    },
    // getIncubatorId () {
    //   this.teamForm.incubatorId = this.$route.params.incubatorId
    //   console.log(this.teamForm.incubatorId)
    // },
    Upload(e) {
      const params = new FormData()
      params.append('fileList', e.file)
      axios.post('/reach/portal/api/file/uploadImage', params).then(res => {
        if (res.data.code === 1) {
          this.teamForm.imgList.push(res.data.data[0])
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    beforeSubmit() {
      this.checkToken(2);
    },
    submitRegister() {
      var that = this;
      var value1 = false;
      // var value2=false;
      // var value3=false;
      this.$refs.LoginFormRef.validate(async (valid) => {
        console.log(valid)
        if (valid) {
          value1 = true;
          this.loading = true;
        }
      })
      //  this.$refs.LoginFormRef2.validate(async (valid) => {
      //    console.log(valid)
      // if (valid) {

      //    value2=true
      // }})
      // this.$refs.LoginFormRef3.validate(async (valid) => {
      // if (valid) {
      //   value3=true
      // }})
      if (value1) {
        axios.post(
          '/reach/portal/api/cooperate/applyOnlineSign',
          this.teamForm
        )
          .then(res => {
            this.loading = false;
            console.log(res)
            if (res.data.code == 1) {
              console.log('code == 1')
              that.$my_confirm(this.$t('heard.application'), '提示', {
                type: 'warn',
                cancelBtnText: this.$t('login.sure'),
                otherBtnText: '',
                yesBtnText: ''
              })
                .then(action => {
                  if (action == "yes") {
                    this.$router.push({ name: 'homePage' })
                  }
                  else if (action == "other") { }
                })
                .catch(() => { });
            } else if (res.data.code === 2) {
              that.$message.warning(this.$t('heard.logged'))
            }
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.textarea-view {
  width: 370px;
}

.redFont {
  color: #FF4E00;
  position: absolute;
  right: 11%;
  top: 112%;
}

.hadTopTitle {
  color: #333333;
  font-size: 14Px;
  position: absolute;
  right: 35%;
  top: -93%;
}

.login_container {
  height: 25rem;
  position: relative;
  margin: auto;

  a {
    text-decoration: underline;
    color: #0E00FF;
  }
}

.bg {
  background: url(../../assets/city.jpg) no-repeat center;
  background-size: cover;
  height: 100%;
}

/* .bg_left{
     background:url(../../assets/u3058.png) no-repeat;
     background-size: cover;
     height: 20%;
     position: absolute;
     top: 31%;
     left: 13%;
     width: 30%;
   } */
.login_box {
  width: 10rem;
  height: auto;
  background-color: #fff;
  border-radius: 10Px;
  position: absolute;
  // 盒子居中显示
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.title {
  /*  position: absolute;
    left: 12%;
    top: 2.5%; */
  font-size: 23Px;
  font-weight: 600;
  margin: 0.3rem 0;
}

.login_form {
  /* position: absolute;
    bottom: -3%;
    width: 100%; */
  padding: 7Px;
  box-sizing: border-box;

  .seTitle {
    text-align: left;
    font-size: 0.3rem;
    margin-bottom: 0.1rem;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 0.5rem;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  /deep/ .el-form-item__content {
    display: flex;
    align-items: center;

    span {
      font-size: 0.3rem;
      width: 22%;
    }

    .el-input {
      font-size: 20Px;
      width: 70%;

      .el-input__inner {
        width: 100%;
        height: 0.57rem;
        padding-left: 0.1rem;
        border-radius: 4Px;
        font-size: 16Px;
      }
    }

    .el-textarea {
      font-size: 16Px;
    }
  }

  .marlf {
    /deep/ .el-input__inner {
      padding-left: 1.27rem;
    }
  }

  /deep/ .el-radio {
    .el-radio__label {
      padding-left: 0.3rem;
    }

    .el-radio__inner {
      width: 0.4rem;
      height: 0.4rem;
      border: 0.07rem solid #DCDFE6;
      margin-right: 0.1rem;

      &:hover {
        border-color: #409EFF;
      }
    }

    .is-checked {
      .el-radio__inner {
        border: 0.2rem solid #409EFF;

        &::after {
          width: 0.3rem;
          height: 0.3rem;
        }
      }
    }
  }
}

.keyWrap {
  position: absolute;
  top: 55%;
  right: 0%;
  background-color: #3270a5;
  padding: 10Px 0 0 12Px;
}

.psdKey {
  top: 44%;
}

.agreement {
  /deep/ .el-form-item__content {
    margin-left: 0px;
    text-align: left;
    padding-left: 10%;

    .el-checkbox__inner {
      border: 0.05rem solid #DCDFE6;
      border-radius: 0.03rem;
      width: 0.25rem;
      height: 0.25rem;

      &::after {
        border: 0.04rem solid #FFF;
        border-left: 0;
        border-top: 0;
        height: 0.18rem;
        left: 0.03rem;
        top: -0.09rem;
        width: 0.1rem;
      }
    }
  }
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  /deep/ .el-form-item__content {
    width: 80%;

    .el-button {
      // width: 100%;
      // height: 0.6rem;
      // border-radius: 6Px;
      // font-size: 17Px;
      // letter-spacing: 14px;
      // background-color: #1fbaf3;
    }
  }
}

.login_box /deep/ .el-form-item {
  margin-bottom: 0.35rem;
}

.login_box /deep/ .el-form-item__error {
  left: 22%;
  line-height: 1.5;
  padding-top: 0.05rem;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  // width: 1rem;
  // height: 0.5rem;
  // font-size: 19Px;
}
</style>
