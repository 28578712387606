import { render, staticRenderFns } from "./storeCol.vue?vue&type=template&id=23135e6d&scoped=true&"
import script from "./storeCol.vue?vue&type=script&lang=js&"
export * from "./storeCol.vue?vue&type=script&lang=js&"
import style0 from "./storeCol.vue?vue&type=style&index=0&lang=css&"
import style1 from "./storeCol.vue?vue&type=style&index=1&id=23135e6d&scoped=true&lang=css&"
import style2 from "./storeCol.vue?vue&type=style&index=2&id=23135e6d&lang=less&scoped=true&"
import style3 from "./storeCol.vue?vue&type=style&index=3&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23135e6d",
  null
  
)

export default component.exports