<template>
  <div>
	<secheadTop></secheadTop>
	<div class="store_wrap">
		<div class="store_inner">
		<Storebaner :scaleShow="scaleShow"></Storebaner>
		<storeList :scaleShow="scaleShow"></storeList>
		<secstoreAdv :scaleShow="scaleShow"></secstoreAdv>
		<div v-for="(v,i) in sendmoz" :key="i">
			<secstoreRow :recmoz="v" :isEven="i%2==0?true:false" :scaleShow="scaleShow" :isIpad="false"></secstoreRow>
		</div>
		<div v-for="(v2,i2) in sendbl" :key="'info2-'+i2">
			<secstoreBlurow :rebl="v2" :scaleShow="scaleShow"></secstoreBlurow>
		</div>
		<storeSearch></storeSearch>
		</div>
	</div>
   </div>
</template>

<script>
import secheadTop from "../../components/secheadTop.vue";
import Storebaner from "../../components/storeBaner/storeBaner.vue";
	import secstoreRow from "../../components/sec_storeRow/sec_storeRow.vue";
	import secstoreBlurow from "../../components/store_secblueRow/store_secblueRow.vue";
	import storeSearch from "../../components/storeSearch/storeSearch.vue";
	import storeList from "../../components/storeList/storeList.vue";
	import secstoreAdv from "../../components/sec_storeAdv/sec_storeAdv.vue";
	export default {
		components: {
			secheadTop,
			Storebaner,
			secstoreRow,
			secstoreBlurow,
			storeSearch,
			storeList,
			secstoreAdv
		},
  data() {
    return {
      sendmoz: [],
      sendbl: [],
      scaleShow: true,
      ipadpro:false
    };
  },
  //必须放在mounted中，因为放在created中未获取dom节点
  mounted() {
    //等待dom渲染完毕再加载
    var that=this;
    this.$nextTick(() => {
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      } else {
      }
    });
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'overflow-x:scroll;')
    },
beforeDestroy() {
    document.body.removeAttribute('style')
    },
  methods: {
    //公共使用控制页面大小收缩 
    commonSySize() {
      let idTag = document.body;
      if (idTag.offsetWidth <= 1280) {
        // this.scaleShow = false;
      } else {
        // this.scaleShow = true;
      }
      if (idTag.offsetWidth >= 809) {
      }
      if(new RegExp("ipad","i").test(window.navigator.userAgent)){//获取代理头信息
        this.ipadpro=true;
      }
      else{
        this.ipadpro=false;
      }
    },
     async getModeList(){
       var that=this;
       let res = await this.$Net.get("/f/eshopping/eshoppingTestOut/ModeList");
          res.forEach((v, i) => {
          var obj = {
            modeType: "",
            id: "",
            createBy: "",
            modeName: "",
            updateBy: "",
          };
          that.$getSame(obj, res[i]);
          if (v.modeType == "0") {
            that.sendmoz.push(obj);
          } else if (v.modeType == "1") {
            that.sendbl.push(obj);
          }
          else{}
        });
    }
  },
  created() {
    this.getModeList();
  }
};
</script>

<style scoped lang="less">
html, body{
  overflow-x:scroll!important;
}
.storeWrap_mini{
  margin-top: 2rem!important;
}
.store_ipad{
  margin-top: 0rem!important;
}
.store_wrap {
  width: 100%;
  background-color: #ebebeb;
//   margin-top: 1rem;
  .store_inner {
    width: 71%;
    margin: 0 auto;
  }
  .store_mini {
    width: 100%;
  }
}
</style>
