<template>
  <div class="headAll">
    <div class="head_wrap">
      <div class="top_left" @click="toHome()">
        <!-- <img src="../assets/logo333.png" /> -->
        <img src="../assets/logo10.jpg" />
        <div class="leftBottom"></div>
        <!-- <i class="logo_icon"></i> -->
      </div>
      <div class="top_right">
        <!-- <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#fff" text-color="#000" active-text-color="#ffd04b" :router="true">-->
        <el-menu
          :default-active="$route.name"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          text-color="#3C3C3C"
          active-text-color="#1fbaf3"
          :router="true"
        >
          <el-menu-item
            index="payView"
            :route="{path:'/payView'}"
            @mouseenter.native="mouseover(0)"
            @mouseleave.native="mouseout()"
            :class="scaleItem?'scaleItemm':''"
          >产品与服务</el-menu-item>
          <el-menu-item
            index="/updView"
            @mouseenter.native="mouseover(1)"
            @mouseleave.native="mouseout()"
            :class="scaleItem?'scaleItemm':''"
          >解决方案</el-menu-item>
          <el-menu-item index="/updView" class="dev_icon" @mouseenter.native="mouseover(7)">开发者中心</el-menu-item>
          <el-menu-item
            index="/updView"
            class="Intel_icon"
            @mouseenter.native="mouseover(2)"
            @mouseleave.native="mouseout()"
          >智能开发平台</el-menu-item>
          <el-menu-item
            index="cartView"
            :route="{path:'/cartView'}"
            @mouseenter.native="mouseover(3)"
            @mouseleave.native="mouseout()"
            :class="scaleItem?'scaleItemm':''"
          >RIV商城</el-menu-item>
          <el-menu-item
            index="/updView"
            @mouseenter.native="mouseover(4)"
            @mouseleave.native="mouseout()"
            :class="scaleItem?'scaleItemm':''"
          >帮助支持</el-menu-item>
          <el-menu-item
            index="/updView"
            @mouseenter.native="mouseover(5)"
            @mouseleave.native="mouseout()"
            :class="scaleItem?'scaleItemm':''"
          >合作与生态</el-menu-item>
          <el-menu-item
            index="/updView"
            @mouseenter.native="mouseover(6)"
            @mouseleave.native="mouseout()"
          >关于我们</el-menu-item>
        </el-menu>
        <el-menu
          :default-active="$route.path"
          class="el-menu-right"
          mode="horizontal"
          @select="handleSelect"
          background-color="#fff"
          text-color="#000"
          active-text-color="#ffd04b"
          :router="true"
        >
          <!-- <span style="position: absolute;right: 92px;top: 43px;z-index: 200;">简体中文</span> -->
          <el-menu-item
            class="itemNobtm langText"
            style="position: relative;"
          >
            简体中文
            <div
              v-show="showlang"
              class="langau_wrap langau_pc"
              @mouseenter="langauBox()"
              @mouseleave="outlangauBox()"
            >
              <span>简体中文</span>
              <span>English</span>
            </div>
          </el-menu-item>
          <el-submenu class="el-thesubmenu" index="b" @click.native="openlang">
            <template slot="title" v-if="false"></template>
            <el-menu-item index="2-1" class="secitem" v-if="false">简体中文</el-menu-item>
            <el-menu-item index="2-2" class="secitem" v-if="false">English</el-menu-item>
          </el-submenu>

          <el-menu-item  class="itemNobtm" index="userDetail">
            <i class="mine_icon"  @mouseenter="userBox()"></i>
             <div
              v-show="showUserbox"
              class="langau_wrap langau_pc"
               @mouseleave="outUserBox()"
            >
              <span @click="toChangepwd">修改密码</span>
              <span @click="exit">退出</span>
            </div>
          </el-menu-item>
          <el-menu-item index="/sendDd" class="itemNobtm">
            <i class="serch_icon"></i>
          </el-menu-item>
          <el-menu-item class="itemNobtm" v-if="false" @click.native="openMinmemu">
            <i class="toggle"></i>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <transition name="show" tag="span">
      <div
        class="topBox"
        v-show="showMenubox"
        @mouseenter="mouseoverBox()"
        @mouseleave="mouseoutBox()"
      >
        <!-- <topMenubox :curindex="sendindex" ref="menubox"></topMenubox> -->
        <topMenubox ref="menubox"></topMenubox>
      </div>
    </transition>
    <transition name="show" tag="span">
      <ul v-if="miniMenu" class="minmenuWrap" :class="ipadpro?'minmenu_ipad':''">
        <li>
          <a>产品与服务</a>
        </li>
        <li>
          <a>解决方案</a>
        </li>
        <li>
          <a class="min_devicon">开发者中心</a>
        </li>
        <li>
          <a class="intel_devicon">智能开发平台</a>
        </li>
        <li>
          <a>RIV商城</a>
        </li>
        <li>
          <a>帮助支持</a>
        </li>
        <li>
          <a>合作与生态</a>
        </li>
        <li>
          <a>关于我们</a>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import topMenubox from "./topMenubox/topMenubox.vue";
import axios from '../api/axios'
import Cookies from "js-cookie";
export default {
  name: "secheadTop",
  components: {
    topMenubox,
  },
  data() {
    return {
      //showchangepsd:false,判断一个量即可
      activeIndex: "1",
      activeIndex2: "4",
      showMenubox: false,
      boxfocus: false,
      showlang: false,
      showUserbox: false,
      scaleItem: false,
      scaleRightt: false,
      scaleShow: true,
      miniMenu: false,
      ipadpro:false
    };
  },
  mounted() {
    //等待dom渲染完毕再加载
    this.$nextTick(() => {
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          this.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          this.commonSySize();
        });
      } else {
      }
    });
  },
  methods: {
    //公共使用控制页面大小收缩
    commonSySize() {
      let idTag = document.body;
      // console.log('aafuuuuuuu:'+idTag.offsetWidth);

      if (idTag.offsetWidth >= 1440) {
        this.scaleItem = false;
      }
      if (idTag.offsetWidth < 1440 && idTag.offsetWidth > 1110) {
        this.scaleItem = true;
        this.scaleRightt = true;
        // console.log('yes:');
      }
      if (idTag.offsetWidth <= 1280) {
        this.scaleRightt = false;
        this.scaleShow = false;
      } else {
        this.scaleShow = true;
        this.miniMenu = false;
        // console.log("max"+idTag.offsetWidth);
      }
      if (idTag.offsetWidth >= 809) {
        // this.kcSecondDhShow=true;
        // this.perSonCenterShow=true;
      }
      if(new RegExp("ipad","i").test(window.navigator.userAgent)){//获取代理头信息
        this.scaleRightt = false;
        this.scaleShow = false;
        this.ipadpro=true;
      }
    },
    openlang() {
      this.showlang = !this.showlang;
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    toHome() {
      this.$router.push({
        name: "storePage",
      });
    },
    langauBox() {
      this.showlang = true;
    },
    outlangauBox() {
      this.showlang = false;
    },
    userBox() {
      var usertoken=window.localStorage.getItem('usertoken')||'';
      if(usertoken==''){this.showUserbox =false}
      else{
        this.showUserbox = !this.showUserbox;
      }
    },
    outUserBox() {
      this.showUserbox = false;
    },
    mouseoverBox() {
      this.showMenubox = true;
      this.boxfocus = true;
    },
    mouseoutBox() {
      this.showMenubox = false;
    },
    mouseover(e) {
      // console.log("fa11:"+e);
      // this.sendindex=e;
      // this.$refs.menubox.curindexx = e;
      // this.$refs.menubox.boxList[e]=true;
      // this.$refs.menubox.boxList.forEach((v)=>{v=false});
      this.showMenubox = false;
      var that = this;
      if (e == 7) {
        that.showMenubox = false;
      } else {
        setTimeout(function () {
          that.showMenubox = true;
          that.$refs.menubox.boxList.forEach(function (item, index) {
            that.$refs.menubox.boxList[index] = false;
            // item=false;
            // console.log("fa:" + item+index + that.$refs.menubox.boxList[index]);
          });
          // that.$refs.menubox.boxList[e]=true;
          that.$set(that.$refs.menubox.boxList, e, true);
        }, 500);
      }
    },
    mouseout() {
      // var that=this;
      this.showMenubox = true;
      // this.showMenubox = this.boxfocus == true ? true : false;
      // that.showMenubox=false;
      /* 	this.$refs.menubox.boxList.forEach(function(item, index) {
						// that.$refs.menubox.boxList[index]= false;
						// item=false;
						that.$set(that.$refs.menubox.boxList,index,false);
						// console.log("fa:" + item+index + that.$refs.menubox.boxList[index]);
					}); */
    },
    openMinmemu() {
      this.miniMenu = !this.miniMenu;
    },
    toChangepwd(){
      this.$router.push({
        name: `userDetail`,
        params: {
          changepwd: '1'
        }
      })
    },
    exit(){
      var url = '/reach/portal/api/user/logout'
      axios.post(url)
      // 退出登录清空token
      Cookies.set('token', null)
      window.localStorage.setItem('curretUser', null)
      window.localStorage.setItem('usertoken', null)
      this.$router.push({ path: "/loginn" });
    },
  },
};
</script>
<style>
.el-menu-right .el-submenu__title {
  font-size: 13px !important;
  color: #939393 !important;
  padding: 0;
  margin-left: 0.09rem;
}

.el-menu-right .el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 104px !important;
  position: absolute;
  top: -6px;
  /* display: none; */
}

.top_right .el-menu-item {
  height: 100% !important;
}

.top_right .el-menu-item:after {
  content: " ";
  position: absolute;
  z-index: 2;
  bottom: -0.05rem;
  left: 0;
  display: block;
  width: 165px;
  height: 0px;
}

.top_right .el-menu-item:hover {
  color: #1fbaf3 !important;
}

.top_right .itemNobtm:hover {
  background-color: rgb(255 255 255 / 0%) !important;
}

.top_right .itemNobtm:hover:after {
  height: 0px !important;
}

.top_right .el-menu-item:hover:after {
  height: 0.09rem;
  animation: ad_width 0.5s linear forwards;
  background: #ffde5d;
}

.top_right .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  background-color: rgb(255 255 255 / 0%) !important;
}

.top_right .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-icon-arrow-down:before {
  color: #1fbaf3;
}

@keyframes ad_width {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
.scaleLeft {
  width: 1.5rem !important;
}

.scaleRight {
  padding-left: 1.7rem !important;
}

.scaleShoww {
  justify-content: flex-end !important;
  padding-left: 1rem!important;
}

.scaleItemm {
  padding: 0 0.5rem !important;
  height: 100%;
}

.el-menu-right {
  position: relative;
  padding-right: 0rem;
}

.el-thesubmenu {
  position: relative;
}

.el-menu-right .langau_wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 6px;
  z-index: 200;
}
.el-menu-right .langau_pc {
  top: 0.8rem;
  span {
    width: 1.2rem;
  }
}
.el-menu-right .langau_mini {
  top: 0.8rem;
  span {
    width: auto;
  }
}
.el-menu-right .langText_mini span {
  font-size: 26Px !important;
}
.el-menu-right .langau_wrap span {
  height: 0.37rem;
  @include font_size($font_samll);
  background-color: rgb(255, 255, 255);
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-menu-right .langau_mini span {
  padding: 0.5rem 0 0.5rem 0;
}
.el-menu-right .langau_wrap span:first-child {
  border-bottom: 1px solid #d7d7d7;
}
.el-menu-right .langau_wrap span:hover {
  background-color: #d7d7d7;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #939393;
}

.el-menu--horizontal .el-menu--popup-bottom-start .el-menu-item {
  font-size: 12px;
  text-align: center;
}

.el-menu-right > .el-submenu {
  height: 100%;
  display: flex;
  align-items: center;
}

.headAll {
  width: 100%;
  position: relative;
}

.head_wrap {
  width: 24rem;
  display: flex;
  margin: 0 auto;
  position: relative;
}
.head_mini {
  width: 10rem;
  padding: 0.4rem 0.15rem;
  display: flex;
  margin: 0 auto;
  position: relative;
  .top_left {
    img {
      width: 200Px;
    }
  }
}
.head_wrap .top_left {
  width: 2.1rem;
  height: 1.2rem;
  position: relative;
  padding-left: 0.6rem;
  display: flex;
  align-items: center;
}
.head_wrap .leftBottom{
  width: 4.5rem;
  position: absolute;
  bottom: 0;
  left: -18%;
}
.head_wrap .leftBottom:after {
  content: " ";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: -18%;
  display: block;
  /* width: 165px;//没用 */
  height: 1px;
}

.head_wrap .top_left:hover .leftBottom:after {
  height: 0.09rem;
  animation: ad_width 0.5s linear forwards;
  background: #ffde5d;
}

@keyframes ad_width {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.head_wrap .logo_icon {
  position: relative;
}

.head_wrap .logo_icon:before {
  width: 100px;
  height: 100px;
  background-image: url(../assets/logo.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: 14px;
  top: 13px;
  background-size: contain;
}

.head_wrap .top_left img {
  width: 100%;
  cursor: pointer;
}

.top_right {
  padding-left: 0.2rem;
  display: flex;
  flex: 6;
  justify-content: space-between;
}

.el-header {
  background-color: #fff;
}

.el-menu--horizontal /deep/ .el-menu-item {
  height: 100%;
  border-bottom-color: rgb(255, 255, 255) !important;
  @include font_size(13Px);
  display: flex;
  align-items: center;
}

.el-menu-item {
  padding: 0 0.35rem;
}

.el-menu-right .el-menu-item {
  padding: 0 0.046875rem;
  padding-right: 0.38rem;
}

.el-menu.el-menu--horizonta {
  background-color: rgb(84, 92, 100);
}

.el-menu--horizontal {
  border-bottom: none !important;
}

.dev_icon {
  position: relative;
  padding-left: 0.8rem;
}

.dev_icon:before {
  width: 0.4rem;
    height: 0.4rem;
  background-image: url(../assets/dev.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: 13%;
    top: 28%;
  background-size: contain;
}

.Intel_icon {
  position: relative;
  padding-left: 0.8rem;
}

.Intel_icon:before {
  width: 0.4rem;
  height: 0.4rem;
  background-image: url(../assets/inli_icon.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: 8.5%;
  top: 26%;
  background-size: contain;
}

.mine_icon {
  display: inline-block;
  width: 0.425rem;
  height: 0.4rem;
  background-image: url(../assets/login.png);
  background-size: 100% 100%;
}

.serch_icon {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-image: url(../assets/serch.png);
  /* background-position: center center;
background-repeat:no-repeat; */
  background-size: 100% 100%;
}

.serch_icon:hover {
  background-image: url(../assets/serch2.png);
}

.toggle {
  display: block;
  width: 22px;
  border-top: 1px solid #000000;
  position: relative;
  color: #000000;
}

.toggle:before {
  content: "";
  width: 22px;
  border-top: 1px solid #000000;
  position: absolute;
  top: -8px;
  left: 0;
  color: #000000;
}

.toggle:after {
  content: "";
  width: 22px;
  border-top: 1px solid #000000;
  position: absolute;
  bottom: -7px;
  left: 0;
  color: #000000;
}

.toggle:hover {
  display: block;
  border-color: #1fbaf3;
  position: relative;
  color: #1fbaf3;
}

.toggle:hover:before {
  content: "";
  border-color: #1fbaf3;
  color: #1fbaf3;
}

.toggle:hover:after {
  content: "";
  border-color: #1fbaf3;
  color: #1fbaf3;
}

.topBox {
  width: 100%;
  position: absolute;
  top: 1.2rem;
  left: 0;
  z-index: 1000;
}

.topBox:before {
  content: " ";
  position: absolute;
  z-index: 2;
  top: -1px;
  left: 0;
  display: block;
  width: 165px;
  height: 1px;
  /* transform: translate(-50%); */
}

.topBox:hover:before {
  height: 2px;
  animation: boxad_width 0.5s linear forwards;
  background: #dbdbdb;
}

.head_wrap:hover + .topBox:before {
  height: 7px;
  animation: boxad_width 3s linear forwards;
  background: #dbdbdb;
}

@keyframes boxad_width {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* vue动画 */
@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.show-enter-active {
  animation: show 0.9s;
}

.show-leave-active {
  animation: hide 0.5s;
}

.show-enter,
.show-leave-to {
  opacity: 0;
}
.minmenu_ipad{
      .min_devicon:before{
        width: 0.5rem;
        height: 0.55rem;
        left: -55%;
        top: -47%;
      }
      .intel_devicon:before{
        left: -36%;
        top: -45%;
        width: 0.5rem;
        height: 0.5rem;
      }
}
.minmenuWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 97%;
  left: 0;
}

.minmenuWrap li {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid #cdcdcd;
  padding: 10px 0 10px 0;
}

.minmenuWrap li a {
  line-height: 0.48rem;
  @include font_size($font_medium);
}

.min_devicon,
.intel_devicon {
  position: relative;
}

.min_devicon:before {
  background-image: url(../assets/dev.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 1.53rem;
  height: 0.47rem;
  left: -75%;
  top: -9%;
  background-size: contain;
}

.intel_devicon:before {
  background-image:url(../assets/inli_icon.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: -53%;
  top: -25%;
  width: 1.53rem;
  height: 0.47rem;
  background-size: contain;
}

.el-thesubmenu /deep/ .el-submenu__icon-arrow {
  margin-left: 0rem !important;
  margin-top: -0.03rem !important;
  margin-right: 0.4rem;
  @include font_size($font_samll);
}
</style>
<style scoped lang="scss">
.head_ipadpro{
  width: 100%;
   padding: 0.1rem 0.15rem;
   display: flex;
    margin: 0 auto;
    position: relative;
    .scaleLeft{
      width: 1.5rem !important;
      img{
            width: 80%;
      }
    }
    .el-menu-right .langau_mini{
          top: 0.5rem;
          span{
            padding: 0.3rem 0.1rem 0.3rem 0.1rem;
          }
    }
}
.langText {
  padding-right: 0 !important;
  @include font_size(9Px);
  color: #939393 !important;
}
.langText_mini {
  padding-right: 0 !important;
  color: #939393 !important;
  font-size: 27Px !important;
}

.langText:hover {
  color: #939393 !important;
}

@media only screen and (max-width: 1110px) {
  /*   .el-thesubmenu:after {
      content: "简体中文";
      position: absolute;
      right: 0.32rem;
      top: 0.16rem;
      z-index: 200;
      display: block;
      color: #939393;
      @include font_size($font_medium_s);
    } */

  .mine_icon {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-image: url(../assets/login.png);
    background-size: 100% 100%;
  }

  .serch_icon {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-image: url(../assets/serch.png);
    background-size: 100% 100%;
  }

  .toggle {
    display: block;
    width: 0.5rem;
    border-top: 0.006452rem solid #050505;
    position: relative;
    color: #1fbaf3;
    /* top: 17px; */
  }

  .toggle:before {
    content: "";
    width: 0.5rem;
    border-top: 0.006452rem solid #050505;
    position: absolute;
    top: -0.201613rem;
    left: 0;
    color: #1fbaf3;
  }

  .toggle:after {
    content: "";
    width: 0.5rem;
    border-top: 0.006452rem solid #050505;
    position: absolute;
    bottom: -0.201613rem;
    left: 0;
    color: #1fbaf3;
  }
}

@media only screen and (max-width: 734px) {
  .el-thesubmenu {
    /* padding-left: 2.5rem; */
  }

  .el-thesubmenu:after {
    right: 1.4rem;
    top: 0.1rem;
  }
  .el-menu-right .langau_wrap {
    top: 0.75rem;
    left: 0.45rem;
  }
}
</style>
