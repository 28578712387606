<template>
	<div class="serview_wrap">
		<el-dialog :title="serwhat" :visible.sync="dialogVisible" width="90%" @close='closeDialog' :append-to-body="true"
			class="serview_wrap">
			<el-table :data="goods_list" border style="width: 100%;cursor: pointer;" @row-click="openDetails">
				<el-table-column type="index"> </el-table-column>
				<el-table-column prop="productName" :label="$t('Purchased.name')">
				</el-table-column>
				<el-table-column prop="signImages" :label="$t('Purchased.type')">
					<template slot-scope="scope">
						<img style="width: 100%;" :src="scope.row.signImages" class="img_row" />
					</template>
				</el-table-column>
				<el-table-column prop="modeName" :label="$t('Purchased.image')">
				</el-table-column>
				<el-table-column prop="productOldPrice" :label="$t('Purchased.original')">
				</el-table-column>
				<el-table-column prop="productPrice" :label="$t('Purchased.now')">
				</el-table-column>
				<el-table-column prop="productSells" :label="$t('Purchased.sold')">
				</el-table-column>
				<el-table-column prop="productSku" :label="$t('Purchased.Stock')">
				</el-table-column>
				<el-table-column prop="createDate" :label="$t('Purchased.Shelf')">
				</el-table-column>
				<!--  	<el-table-column prop="num" label="商品数量">
					<template slot-scope="scope">
						<el-input-number v-model="scope.row.num" @change="issub(scope.$index)" size="mini" :min="0">
						</el-input-number>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="decri" label="商品描述" width="340" class-name="decrit" :resizable="true">
				</el-table-column> -->
				<!-- 	<el-table-column label="操作" width="131">
					<template slot-scope="scope">
						<el-button @click="update(scope)" type="text" size="small">编辑</el-button>
						<el-button type="text" size="small" @click="del(scope.$index)">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<!-- 	<viewer :images="signImages">
					<img v-for="(src,index) in signImages" :src="src" :key="index" class="img_row" v-show="index==1"/>
				</viewer> -->
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="cancel()">{{ $t('modify.close') }}</el-button>
				<!-- <el-button  @click="save()">确 定</el-button> -->
			</span>
		</el-dialog>
	</div>
</template>

<script>
import Net from '../../api/net.js';
import {
	getSame,
	timestampToTime
} from '../../commethods/commethods.js';
export default {
	name: "serchView",
	data() {
		return {
			serwhat: '',
			dialogVisible: true,
			goods_list: []
		}
	},
	props: ["reGoods", "isstList"],
	methods: {
		openDetails(row) {
			localStorage.setItem('productId',row.modeId)
			localStorage.setItem('id', row.id)
			this.$router.push({ name: 'goodsDeil', query: { id: row.id, modeId: row.modeId } })
		},
		closeDialog() {
			this.dialogVisible = false;
			this.$emit('update:sechShow', false);
		},
		cancel() {
			this.closeDialog();
		},
		async getSearch(e) {
			var that = this;
			// that.serwhat=e+'搜索结果';
			that.serwhat = e;
			let signImages = [
				require("../../assets/store/u1053.png"),
				require("../../assets/store/prod1.png"),
				require("../../assets/store/kefu.png")
			];
			let res = await Net.get('/f/eshopping/eshoppingModeAndProduct/ProductFindList', {
				productName: e,
				language: localStorage.getItem("locale")
			});
			that.goods_list = [];
			res.forEach((v, i) => {
				// console.log("a:"+v.productSells+":a");//getSame不能获取productSells的值给obj
				var obj = {
					modeName: '',
					productName: '',
					productOldPrice: '',
					productPrice: '',
					productSells: '',
					productSku: '',
					modeId: '',
					id: ''
				};
				getSame(obj, res[i]);
				// signImages[0] = v.productPicture;
				obj.productSells = v.productSells;
				obj.createDate = timestampToTime(v.createDate);
				obj.signImages = v.productPicture;
				that.goods_list.push(obj);
			})
		},
		async getlistSearch(modeid, modeName) {
			// console.log('当前搜索:'+modeName);
			var that = this;
			that.serwhat = modeName;

			let res = await this.$Net.get(
				"/f/eshopping/eshoppingModeAndProduct/ProductList",
				{ modeid: modeid, language: localStorage.getItem("locale") }
			);
			let signImages = [
				require("../../assets/store/u1053.png"),
				require("../../assets/store/prod1.png"),
				require("../../assets/store/kefu.png")
			];
			res.forEach((v, i) => {
				let obj = {
					modeName: '',
					productName: '',
					productOldPrice: '',
					productPrice: '',
					productSells: '',
					productSku: '',
					modeId: '',
					id: ''
				};
				this.$getSame(obj, res[i]);
				signImages[0] = v.productPicture;
				obj.productSells = v.productSells;
				obj.createDate = timestampToTime(v.createDate);
				obj.signImages = v.productPicture;
				that.goods_list.push(obj);
			});
		}
	},
	created() {
		/* if(this.isstList==true){
			console.log('stor');
			
		} */
	}
}
</script>

<style>
.el-dialog__header {
	text-align: center;
}

.serview_wrap .el-dialog__footer {
	text-align: center;
}

.serview_wrap .el-table--enable-row-transition .el-table__body td {
	text-align: center;
}

.serview_wrap .el-table th>.cell {
	text-align: center;
}

.serview_wrap .el-table__body-wrapper::-webkit-scrollbar {
	/*width: 0;宽度为0隐藏*/
	width: 0px;
}
</style>
<style lang="less" scoped>
.serview_wrap {
	/deep/ .el-dialog__header {
		padding-top: 0.3rem;

		.el-dialog__title {
			font-size: 26Px;
			font-weight: 600;
		}
	}

	/deep/ .el-dialog__headerbtn {
		font-size: 24Px;
	}

	/deep/ .el-table th>.cell {
		font-size: 22Px;
		line-height: 0.5rem;
	}

	/deep/ .el-table {
		font-size: 20Px;

		.cell {
			line-height: 0.5rem;
		}
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button {
			font-size: 21Px;
		}
	}

	/deep/ .el-table__empty-text {
		line-height: 0.7rem;
	}
}</style>
