<template>
  <div>
    <div class="blutl_wrap" :class="scaleShow?'':'blutl_mini'">
      <div class="bgimg"></div>
      <div class="text_wrap">
        <span>{{rebl.modeName}}</span>
        <span>{{rebl.createBy}}</span>
        <span>{{rebl.updateBy}}</span>
      </div>
    </div>
    <div :class="scaleShow?'cotent_wrap':'cotent_mini'">
      <v-touch
        ref="advSwiper"
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        tag="div"
        class="storeCol_wrap"
      >
        <storeCol
          :scaleShow="scaleShow"
          :reGoods="item"
          :valll.sync="item.value"
          v-for="(item,index) in sendGoods"
          :key="index"
		      :bluerow=true
        ></storeCol>
      </v-touch>
    </div>
  </div>
</template>

<script>
import storeCol from "../../components/storeCol/storeCol.vue";
export default {
  name: "storeBlurow",
  components: {
    storeCol,
  },
  data() {
    return {
	  scaleShow:false,
    sendGoods: [],
	  swipNum: 0,
	  allcot: 0,
    swipcotMnin: 0,
	  movee:0,//和swipNum区别是这个只有赋一次值，平移的长度
	  semove:0//对于单数张产品图的第一张需要特殊处理
    };
  },
  props: ["rebl","anmindex"],
    //必须放在mounted中，因为放在created中未获取dom节点
  mounted() {
    //等待dom渲染完毕再加载
    var that=this;
    this.$nextTick(() => {
      that.getbluData();
       that.commonSySize();
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      } else {
      }
    });
  },
 /*   created() {
    
  }, */
  /*  mounted() {
	  this.judgeWidth()
  }, */
  methods: {
	commonSySize() {
      let idTag = document.body;
      // console.log("blue:"+idTag.offsetWidth);//iphone下是1242=》 this.scaleShow = true;
      if(this.$isphone()){this.scaleShow = false;}
      else{
      if (idTag.offsetWidth <= 1020) {
        this.scaleShow = false;
      } else {
        this.scaleShow = true;
      }
      }
      this.judgeWidth();
    },
	judgeWidth(){
		if(!this.scaleShow){
			this.$refs.advSwiper.$el.style.width = this.allcot * 100 + "%";
			// this.$refs.advSwiper.$el.style.width = "700%";
		}
		else{
			this.$refs.advSwiper.$el.style.width = "100%";
		}
	},  
    async getbluData() {
      var that = this;
      let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/ProductList", { modeid: that.rebl.id, language: localStorage.getItem("locale") });
      that.allcot = res.length;
      that.swipcotMnin = res.length - 1;
      that.judgeWidth();
      res.forEach((v, i) => {
        let obj = {
          id: "",
          productName: "",
          modeName: "",
          value: 4.5,
          productPrice: "",
          productPicture: "",
          productOldPrice: "",
          modeId: "",
        };
        that.$getSame(obj, res[i]);
        that.sendGoods.push(obj);
      });
    },
   onSwipeLeft() {
      if(this.scaleShow==false&&this.allcot>1){
         if (this.swipcotMnin > 0) {
           if (this.allcot % 2 == 0) {
             if(this.swipcotMnin==(this.allcot-1)){this.movee=(this.allcot * 100) / Math.pow(this.allcot,2);}
                this.swipNum += (this.allcot * 100) / Math.pow(this.allcot,2);
            } 
            else {
                let val=parseInt((this.allcot * 100) / Math.pow(this.allcot,2));
                let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
                if(yal==0){
                  if(this.swipcotMnin==(this.allcot-1)){this.movee=val}
                          this.swipNum +=val;
                }
                else{
                  if(this.swipcotMnin==(this.allcot-1)){this.semove=(val+0.5);this.swipNum +=(val+0.5);}
                  else{
                    if(this.swipcotMnin%2!=0){this.swipNum +=(val);this.movee=val;}
                    else{this.swipNum +=(val+0.5);this.movee=val+0.5;}
                  }
                }
                    //只能对于3个：this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3);
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3*(3-this.swipcotMnin));
                    /* console.log('右边移动：'+3*(3-this.swipcotMnin));
                    console.log('当前值：'+this.swipcotMnin); */
                    
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 3*(3-swipcotMnin))+2);
            }
        --this.swipcotMnin;
      } 
      else {
        /* var that = this;
        this.$msgboxx(that, "提示", "商品列表已显示完整"); */
        //this.swipNum=0;
        var styleSheets = document.styleSheets[0];
        /* if(this.spLeftmini){
          styleSheets.deleteRule(0);
        } */
        var styleindex = styleSheets.length;
        if(styleSheets.insertRule){  
        styleSheets.insertRule(` @keyframes  minLeftTo${this.anmindex}{
            0%{
                transform:translateX(${-(this.swipNum-8)}%);
               
            } 
            50%{
                transform:translateX(${-(this.swipNum-3)}%);
                
            }
            100%{
                transform:translateX(${-this.swipNum}%);
                
            }
        }`, styleindex);
        }else{
          styleSheets.addRule(` @keyframes minLeftTo${this.anmindex}{
            0%{
                transform:translateX(${-(this.swipNum-8)}%);
            } 
            50%{
                transform:translateX(${-(this.swipNum-3)}%);
            }
            100%{
                transform:translateX(${-this.swipNum}%);
            }
        }`, 0);
        }
        var that = this;
        this.$refs.advSwiper.$el.style.animation =`minLeftTo${this.anmindex} .35s linear 0s backwards running`;
        // that.$refs.advSwiper.$el.classList.add("miniLeft");
    　　setTimeout(function(){
        that.$refs.advSwiper.$el.style.animation ='none';
        //that.$refs.advSwiper.$el.classList.remove("miniLeft");
    　　},350);
        this.sendGoods.push(this.sendGoods[0]);
        this.sendGoods.splice(0,1);
        this.spLeftmini=true;
        // this.swipcotMnin = this.allcot-5;
        }
        this.$refs.advSwiper.$el.style.transform ="translateX(-" + this.swipNum + "%)";
        }
    },
    onSwipeRight() {
       if(this.spLeftmini||this.swipNum==0){
          ++this.spRightmini;
          var styleSheets = document.styleSheets[0];
       /*  if(this.spLeftmini){
          styleSheets.deleteRule(0);
        } */
        if(styleSheets.insertRule){  
        styleSheets.insertRule(` @keyframes  minibluRight${this.anmindex}{
            0%{
             
                transform:translateX(${-(this.swipNum+7)}%);
            } 
            
            100%{
            
                transform:translateX(${-this.swipNum}%);
            }
        }`, 0);
        }else{
          styleSheets.addRule(` @keyframes  minibluRight${this.anmindex}{
            0%{
             
                transform:translateX(${-(this.swipNum+7)}%);
            } 
            
            100%{
            
                transform:translateX(${-this.swipNum}%);
            }
        }`, 0);
        }

       var that = this;
// 　　　　that.$refs.advSwiper.$el.classList.add("miniRight");
       this.$refs.advSwiper.$el.style.animation =`minibluRight${this.anmindex} .4s linear 0s backwards running`;
        
　　setTimeout(function(){
// 　　　　that.$refs.advSwiper.$el.classList.remove("miniRight");
       that.$refs.advSwiper.$el.style.animation ='none';
　　},800);
  this.sendGoods.unshift(this.sendGoods[this.sendGoods.length-1]);
            this.sendGoods.splice(this.sendGoods.length-1,1);
            if(this.spRightmini<=this.allcot-1){
             this.$refs.advSwiper.$el.style.transform =
        "translateX(" + -this.swipNum + "%)";
        // this.swipNum=this.swipNum-0.5;
        
        }
         return
        }
      if (this.allcot % 2 == 0) {
          this.swipNum -= (this.allcot * 100) / Math.pow(this.allcot,2);
      }
      else{
          let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
          if(this.swipcotMnin==(this.allcot-2)&&yal!=0){
            this.swipNum -=this.semove;
            
          }
          else{
              if(this.swipcotMnin%2==0){this.swipNum -= this.movee;this.movee+=0.5;}
              else{this.swipNum -= this.movee;this.movee-=0.5;}        
          }
      }
      /* if (this.swipcotMnin<(this.allcot-1)) {
			this.swipcotMnin +=1;
      } */
     
      // this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
  },
};
</script>

<style scoped lang="less">
	.blutl_wrap {
		background-color: rgba(31, 186, 243, 1);
		height: 3.15rem;
		position: relative;
		margin-top: 0.12rem;

		.bgimg {
			opacity: 0.32;
			width: 100%;
			height: 100%;
			background: url(../../assets/store/blue.png);
			background-size: cover;
			-moz-background-size: cover;
			/* 老版本的 Firefox */
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		.text_wrap {
			position: relative;
			z-index: 5;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			span {
				font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
				font-weight: 700;
				font-style: normal;
				font-size: 0.38rem;
				letter-spacing: 0.1rem;
				color: #FFFFFF;
			}

			span:nth-child(2) {
				font-size: 0.29rem;
				font-family: 'Arial Normal', 'Arial', sans-serif;
				font-weight: 400;
				font-style: normal;
				padding: 0.06rem 0;
			}

			span:nth-child(3) {
				font-size: 0.27rem;
				font-family: 'Arial Normal', 'Arial', sans-serif;
				font-weight: 400;
				font-style: normal;
			}
		}
	}
	.blutl_mini{
			height: 2.19rem!important;
			margin-top: 0.19rem!important;
			.text_wrap {
				span {
					font-size: 30Px!important;
				}
				span:nth-child(2) {
					font-size: 23Px!important;
				}
				span:nth-child(3) {
					font-size: 19Px!important;
				}
			}

	}
	.cotent_wrap {
		margin-top: 0.1rem;
		background-color: #ffffff;
		.storeCol_wrap{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			storeCol {
				width: 20%;
			}
		}
	}
	.cotent_mini{
		margin-top: 0.05rem;
        background-color: #ffffff;
        overflow: hidden;
		.storeCol_wrap{
			width: 300%;
			display: flex;
			transition: all 0.5s ease-in-out;
      justify-content: space-around;
			/* storeCol {
				width: 20%;
			} */
		}
	}
</style>
