const eh = {
    heard: {
        Products: 'Products and Services',
        Solution: 'Solution',
        Developer: 'Developer Center',
        Intelligent: 'Smart R&D Platform',
        buy: 'RIV Mall',
        support: 'Help',
        Cooperation: 'Cooperation',
        About: 'About us',
        Login: 'Login',
        register: "Register",
        information: 'Modify information',
        Purchased: "Purchased items",
        shopping: "shopping cart",
        quit: "quit",
        log: "Please log in first",
        solve: "To solve the challenges of high development costs, long cycles, and high difficulty for customers, we will complete the end-to-cloud reference design of IoT products in a one-stop manner, helping customers achieve zero foundation development.",
        Learn: "Learn more",
        AIoT: "AIoT Hardware Solution",
        Solutions: "Industry Solutions",
        methods: "REACH AIoT provides you with multiple purchasing methods",
        Website: "Website Usage",
        Support: "Developer Support",
        consulting: "consulting service",
        cooperation: "cooperation",
        ecology: "ecology",
        About: "About us",
        Center: 'News Center',
        contact: "contact us",
        Partners: "Partners",
        agent: "agent",
        Space: 'Make Space',
        cases: "success cases",
        Help: "Help Center",
        Documentation: "Documentation",
        Business: "Business Consulting",
        Download: "Download",
        Technical: "Technical consultation",
        System: "REACH Global Supply System",
        keywords: "Enter keywords to search for documents",
        Requirements: "REACH AIoT's powerful global ecological chain helps you find the AIoT products you need. Please enter your Requirements elicitation for more information and support",
        online: "Shopping online or consultation with our sales team.",
        Mall: "Shopping online",
        AirLand: "AirLand end-to-cloud one-stop service",
        control: "Airland is an intelligent platform for the AIoT, providing a one-stop intelligent solution that integrates product, cloud, and control ends.",
        news: "news",
        video: "video",
        Join: "Join us",
        search: "search",
        Start:"Start now",
        report    :"View report cases",
        authentication: "Real-name authentication",
        name:"name",
        mobile :"mobile phone",
        Address  :"Address",
        License  :"License",
        remark  :"remark",
        Required  :"Required field",
        notRequired  :"Optional",
        name2  :"Please enter your name",
        mobile2  :"Please enter your Mobile phone number",
        email  :"Please enter your E-mail",
        License2  :"Please enter your License",
        logged   :"You are not logged in",
        application   :"The application was submitted successfully"
    },
    home: {
        REACH: "REACH's 'Tianzhou AirLand' helps you quickly embark on your journey to the AIoT",
        Provide: "Provide you with a one-stop solution that integrates AIoT product, cloud, and control ends",
        Learn: "Learn more",
        success: "success cases",
        stories: "View success stories",
        Industry: "Industry dynamics",
        solution: "One stop intelligent solution",
        About: "About REACH",
        Focusing: "Focusing on the field of AIoT, possessing mature AIoT products and over 15 years of rich research and development experience",
        Empowerment: "REACH Helps Your Industry Realize AIoT Empowerment",
        Smart: "Smart Energy",
        Vehicle: "Vehicle-to-everything",
        City: "Smart City",
        protection: "Smart fire protection",
        AIoT: "Industrial AIoT"
    },
    login: {
        login: "Login",
        logins: "login",
        username: "username",
        password: "password",
        automatic: "automatic login",
        Forgot: "Forgot password",
        Quick: "Quick login",
        or: "or",
        free: "free registration",
        passwords: "Please enter your password",
        usernames: "Please enter username",
        Incorrect: "Incorrect username or password",
        failed: "Login failed, please check the format",
        successful: "User login successful",
        phone: "Retrieve password through phone",
        Return: "Return to login page",
        email: "This email is not registered!",
        email2: "Please enter your email address",
        Retrieve: "Retrieve password through email",
        sure: "sure",
        prompt: "Prompt",
        empty2: "The email cannot be empty",
        emailFormat: "Please enter the correct email format",
        sent: "The verification code has been sent",
        exist: "The registered mobile phone number does not exist",
        confirm: "Please confirm that the password matches",
        changed: "The password was changed successfully"
    },
    register: {
        number: "Please enter your phone number",
        registered: "This number is already registered!",
        userName: "The user name has been registered!",
        code: "Please enter the verification code",
        Obtain: "Obtain verification code",
        seconds: "seconds to reacquire",
        enter: "Please enter the username",
        already: "This username is already registered!",
        Check: "Check if the user is registered",
        can: "This username can be registered!",
        // password:"请输入手机号",
        phone: "Please enter your password",
        reEnter: "Please re-enter the password",
        company: "Please enter your company name (optional)",
        name: "Please enter your name (optional)",
        email: "Please enter your email address (optional)",
        agreement: "Read and agree to ",
        agreement2: "the Non-disclosure agreement",
        notagreement: "Agreement not checked",
        Submit: "Submit",
        empty: "Phone number cannot be empty",
        numerical: "Please enter a numerical value",
        Incorrect: "Incorrect phone number format",
        passwords: "Please enter your password",
        passwordss: "Please enter the password again",
        match: "The two passwords entered do not match",
        nickname: "Please re-enter your account nickname",
        Chinese: "Please re-enter a non Chinese account nickname",
        // verification:"请输入验证码",
        account: "Please enter the account name",
        successful: "Register was successful",
        error: "Verification code error",
    },
    modify: {
        nickname: "Please enter the nickname",
        company: "Please enter the company name",
        email: "Please enter your email address",
        phone: "Please enter your phone number",
        shipping: "Please enter the shipping address",
        password: "Change password",
        return: "return",
        information: 'Confirm changing information? ',
        initial: "Please enter the initial password",
        confirm: "Please confirm changing the password",
        numerical: "Please enter a numerical value",
        two: "The two passwords entered do not match",
        successful: "Password modification successful, will return to login interface",
        Initial: "Initial password error, please re-enter",
        List: "Shipping Address List",
        currently: "There is currently no shipping address, please add",
        Add: "Add",
        close: "close",
        payment: "payment",
        shipping: "Please enter the shipping address",
        sure: "Are you sure to delete the shipping address?",
        deleted: "Successfully deleted",
        fill: "Please fill in the shipping address first",
        bind: "Modify the bind phone number",
        bound: "This number is already bound to another account!",
        newPassword: "Please enter a new password",
        modification: "The modification was successful",
        addressee: "addressee",
        Receiving: "Receiving number",
        address: "Shipping address",
        registration: "WeChat login user registration",
        informations: "Submit information",
        submission: "Confirm the submission information",
        homepage: "Submission successful, return to home page",
        returnHome:'return to homepage',
    },
    Purchased: {
        invoicing: 'Invoicing',
        editInvoicing: "Revise invoice",
        downInvoicing: "Download invoice",
        invoicingType: "Invoicing Type",
        regularInvoice: "Regular Invoice",
        specialInvoice: "Special Invoice",
        invoiceTitle: "Invoice Title",
        unitTaxNumber: "Unit Tax Number",
        registeredAddress: "Registered Address",
        registeredPhone: "Registered Phone",
        bankOfDeposit: "Bank of deposit",
        bankAccount: "Bank Account",
        cancel: "cancel",
        confirm: "confirm",

        selInvtype: "Please select the invoice type",
        enterInvTitle: "Please enter the invoice title",
        enterUnitTax: "Please enter the unit tax number",
        enterRegAdd: "Please enter the registered address",
        enterRegPhone: "Please enter the registered phone",
        enterBankDeposit: "Please enter the bank of deposit",
        enterBankAccount: "Please enter the bank account",

        sales :"Cumulative sales",
        currently: "There is currently no ",
        OrderData:"Order Data",
        logistics: "Fill in return logistics",
        tracking: "Please enter the logistics tracking number",
        company: "Please enter the name of the logistics company",
        and: "Please enter the logistics tracking number and logistics company name",
        Submit: "Submit Logistics",
        information: 'Prompt "," Logistics information submitted successfully "',
        failed: "Prompt, 'Logistics information submission failed'",
        notshipped: "Not shipped",
        Shipped: "Shipped",
        Completed: "Completed",
        refund: "Request a refund",
        Exchange: "Request Exchange",
        Refund: "Refund in progress (not shipped)",
        Refunds: "Refund in progress (shipped)",
        progress: "Exchange in progress",
        completed: "After sales processing completed",
        Unpaid: "Unpaid orders",
        list: "list",
        View: "View logistics",
        Confirm: "Confirm",
        Order: "Order number",
        name: "Product name",
        image: "Product image",
        price: "Product price",
        quantity: "Product quantity",
        time: "Buying time",
        Operation: "Order Operation",
        refunded: "Request a refund",
        exchanges: "Returns and exchanges",
        View: "View logistics",
        Delete: "Delete Order",
        products: 'This operation will delete all the products in the order. Please be cautious when operating',
        goods: 'Are you sure you want to return or exchange the goods? ',
        pay: 'This operation will pay for all products under the order. Please carefully review before payment',
        REACH: 'REACH AIoT',
        operation  :"This operation will delete all items of the order, please proceed carefully",
        closure    :"Confirm the closure?",
        type:"Product type",
        original  :"Cost of goods",
        now  :"Current commodity price",
        sold :"Have been sold",
        Stock :"Stock on hand",
        Shelf  :"Shelf time",
    },
    Cart: {
        name: "Added shipping address",
        Buy: "Buy Now",
        Cart: "My Shopping Cart",
        Product: "Product name",
        quantity: "Product quantity",
        pricing: "item pricing ",
        Description: "Product Description",
        number: "Order number",
        inventory: "Insufficient inventory",
        review: "Under review",
        Unlisted: "Unlisted",
        Listing: "Listing",
        Module: "Module Type",
        operation: "operation",
        edit: "edit",
        Remove: "Remove",
        Add: "Add products",
        total: "total",
        place: "place order",
        remove: 'Please remove non listed products first',
        before: 'Please remove items with inventory of 0 before submitting the order',
        empty: 'The shopping cart is empty and cannot be submitted. "" Prompt "',
        // sure :'Are you sure you want to remove? "," Prompt "',
        removes: 'Are you sure you want to remove? "," Prompt "',
        Successfully: 'Successfully removed"," prompt "',
        expired: 'Login expired, please log in first',
        completed: 'Congratulations, your payment has been completed!',
        list: 'View the list of purchased products',
        address: 'Please enter your shipping address',
        region: 'Please select a region',
        detailed: 'Please enter detailed shipping address',
        phone: 'Please enter your phone number',
        recipient: 'Please enter the recipient',
        input: 'Each input cannot be empty, please complete the input',
        Save: 'Save Address',
        faileds: 'Save failed',
        saved: 'Successfully saved',
        method: 'Please select payment method',
        WeChat: 'Please scan WeChat Pay',
        Alipay: 'Select Alipay to pay',
        WeChat2:"WeChat",
        Alipay2:"Alipay",
        Cancel:"Cancel",
        ItemStatus  :"Item status"
    },
    agreement: {
        agreement:"REACH AIOT Website Non-disclosure agreement",
        Effective :"Effective Date: July 19, 2023",
        Updated :"Updated on: July 19, 2023",
        Preface:"Preface",
        services :"Thank you for using the products and/or services provided by Xiamen REACH.",
        confidentiality :'AIoT Technology Co., Ltd. (hereinafter or referred to as "REACH") Before completing the registration procedure or using the website services in any way, you must carefully read and thoroughly understand the Non-disclosure agreement of this website (hereinafter or referred to as "NDA"), and choose to accept or not accept this NDA after confirming your full understanding; Once you complete the "Agree Terms and Register", you have read and agreed to be bound by this NDA.',
        confidentiality2 :"REACH promises to take confidentiality measures for the information submitted or known to you when registering your account or using REACH services, and not disclose your information to third parties;",
        confidentiality3 :"You promise to take confidentiality measures for any materials and information you obtain from REACH when using REACH services, and not disclose the above materials and information to third parties without REACH's permission.",
        Unless:"Unless:",
        According  :"1. According to these service terms or other service agreements, contracts, online terms, etc. between you and REACH, it can be provided;",
        According2  :"2. According to the provisions of laws and regulations or the requirements of administrative, judicial and other authorities, it should be provided;",
        According3  :"3. Without violating the responsibilities stipulated in this service clause, the information has been made public or can be obtained from the public domain."
    },
    buttom: {
        About:"About us",
        REACH:"About REACH",
        news:"news",
        video:"video",
        Technical:"Business Consulting",
        Business:"Business cooperation",
        consultation:"Technical consultation",
        Join:"Join us",
        Online:"Online shopping mall",
        // Mall:"WeChat Mall",
        Mall:"WeChat Mall",
        Offline:"Offline purchase",
        Services:"Products and Services",
        Solution:"AIoT System Solution",
        Partners:"Partners",
        Cooperation:"Cooperation and Ecology",
        agent:"agent",
        follow:"Please follow our Wechat official account for more information",
    },
    develop: {
        develop:"System engineer, hardware developer or software developer",
        support:"You can find suitable technical support services",
        Entering:"Entering the Document Center",
        Document:"Developer Document Center",
        keywords:"Please search for product development issues according to the catalog or enter keywords",
        search:"search",
    },
    help: {
        center:"Welcome to the help center",
        keywords:"Website usage issues, please search by directory or enter keywords",
        consultation:"Business cooperation consultation",
        address:"address",
        telephone:"telephone",
        facsimile:"facsimile",
        cooperation:"Business cooperation consultation",
        WeChat:"WeChat Contact",
        download:"Developer documentation download",
        Select:"Select the module model and view the corresponding document",
        check:"check",
        Document:"Document Name",
        Update:"Update time",
        Previous:"Previous",
        Next:"Next",
        Technical:"Technical consultation",
        Log:"Log in now",
        smart:"Log in to the smart R&D platform and enjoy exclusive technical services for signing contracts",
        Ruiqi    :'REACH IoT has built an IoT intelligent platform for global IoT developers, connecting the intelligent needs of consumers, manufacturing brands, OEMs and retail chains, solving problems such as high development costs, long cycles and difficulties for customers, and realizing the development of "networking module + cloud + computer/mobile phone control terminal" in one stop, so that zero-based enterprises can quickly enter the intelligent field.',
        devices  :"All devices"
    },
    cooperate: {
        Partners:"Partners",
        Registration:"Registration",
        success:"success cases",
        address:"Agent registration",
        registration :"List of agents",
        region:"region",
        agent:"agent",
        Contact:"Contact information",
        Station:"Station",
        type:"site type",
        register:"register",
        REACH  :"Become a global partner of REACH and enjoy exclusive services",
        REACH2  :"Become a REACH agent to achieve a win-win situation",
        REACH3  :"REACH IoT builds an IoT intelligent platform for global IoT developers to connect consumers and manufacturing brands",
        REACH4  :"Global open agency cooperation",
        REACH5  :"Become REACH Global HeRuiqi IoT to build an IoT intelligent platform for global IoT developers, connect the intelligent needs of consumers, manufacturing brands, OEMs and retail chains, solve high development costs, long cycle and difficult partners for customers, and enjoy exclusive services",
        REACH6  :"REACH crowd-creation and incubator to comprehensively build an Internet of Things ecosystem",
        REACH7  :"REACH IoT builds an IoT intelligent platform for global IoT developers, connecting the intelligent needs of consumers, manufacturing brands, OEMs and retail chains, and solving the high development cost, long cycle and difficulty for customers",
    },
    about: {
        Center :"News Center",
        history:"Our history",
        Offices:"Branches and Offices",
        Description:"Job Description" ,
        email:"Resume delivery email",
        Video :"Video Center",
        Search:"Search",
        all:"all",
        full :"full screen",
        information  :"New product information",
        Quantity :"Quantity to purchase",
        Inventory :"Inventory remaining",
        Add :"Add to cart",
        Product :"Product details",
        Products :"Product introduction",
        name :"Product name",
        cart :"Stock is 0 and cannot be added to cart",
        again   :"Please log in again",
        logged   :"You are not logged in yet, please log in first"
    },
    other: {
        unknownError: "unknown error",
        formError: "The entered information is incorrect, please check",
    }
}
export default eh
