<template>
  <div class="content_all">
    <div class="topWrap">
        <img :src=bannerImg class="staticBanner">
        <p class="altitle">{{$t('help.consultation')}}</p>
    </div>
    <div class="bottomWrap">
        <el-card class="box-card" v-for="(item, index) in businessConsultList" :key="index" >
                <p>{{$t('Purchased.REACH')}}</p>
                <p>{{item.title}}</p>
                <p class="adress"><span>{{$t('help.address')}}：</span><span>{{item.addr}}</span></p>
                <p class="phone">{{$t('help.telephone')}}：{{item.phone}}</p>
                <p class="phone">{{$t('help.facsimile')}}：{{item.fax}}</p>
                <img :src="item.image">
                <p class="text">{{$t('buttom.Business')}}</p>
                <p class="text">{{$t('help.WeChat')}}</p>
                <p class="text">{{item.contacts}}</p>
        </el-card>
     </div>
  </div>
</template>
<script>
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  data () {
    return {
      bannerImg: require('../../assets/busines.png'),
      businessConsultList: []
    }
  },
  created () {},
  mounted () {
    this.getBusinessConsultList()
    this.getBannerImg()
  },
  methods: {
    getBusinessConsultList () {
      var url = '/reach/portal/api/support/getBusinessConsult'
      axios.post(url, {}, {
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.businessConsultList = response.data.data
          console.log(  this.businessConsultList)
        }
      })
    },
    getBannerImg () {
      var bannerType = 'business'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          this.bannerImg = response.data.data[0].adImage
        }
      })
    }

  }
}
</script>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    margin-top: 1.5rem;
    // padding-top: 1.5rem;
    height: 240Px;
    // background: url(../../assets/busines.png) no-repeat;
    background-size: cover;
  }
  .bottomWrap{
      width: 77%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      .box-card{
            width: 220Px;
            min-height: 8.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem;
            &:hover{
                background-color: #1db8f3;
            }
          p{
              font-weight: 400;
              font-size: 0.5rem;
              color: #333333;
              &:nth-child(1){
                  font-weight: 600;
              }
          }
          .text{
              font-size: 0.25rem;
          }
          .phone{
              font-size: 0.25rem;
              text-align: left;
              padding-left: 0.2rem;
              margin-top: 0.2rem;
          }
          .adress{
              font-size: 0.25rem;
              display: flex;
              align-items: flex-start;
              width: 90%;
              margin: auto;
              margin-top: 0.5rem;
              // height: 1rem;
              // overflow: auto;
              span{
                text-align: left;
                margin-left: 0;
                &:nth-child(1){
                    width: 33%;
                    white-space: nowrap;
                }
              }
          }
          img{
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.4rem 0;
          }
          .svg-inline--fa.fa-w-14{
              font-size: 0.5rem;
          }
          .icon{
              img{
                  width: 0.4rem;
                  height: auto;
              }
          }
          span{
              display: flex;
              flex-direction: column;
              text-align: left;
              margin-left: 0.2rem;
              justify-content: center;
              i{
                  font-style: normal;
                  font-size: 0.25rem;
              }
          }
       }
  }
}
</style>
