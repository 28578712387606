<template>
  <div class="content_all">
    <div class="topWrap">
        <img :src="bannerImg" class="staticBanner">
        <p class="altitle innerTitle">{{$t('heard.Join')}}</p>
    </div>
    <div class="content_inner">
      <el-row type="flex" v-for="(item, index) in joinUsList" :key="index">
          <el-col class="leftWrap">
            <p>{{item.title}}</p>
            <p class="content">{{item.content}}</p>
            <div class="positionWrap">
              <p>{{item.position1}}</p>
              <p>{{$t('about.Offices')}}:</p>
              <p class="descibe">{{item.pos1Desc}}</p>
              <p>{{item.position2}}</p>
              <p>{{$t('about.Offices')}}:</p>
              <p class="descibe">{{item.pos2Desc}}</p>
              <p>{{item.position3}}</p>
              <p>{{$t('about.Offices')}}:</p>
              <p class="descibe">{{item.pos3Desc}}</p>
            </div>
          </el-col>
          <el-col class="rightWrap">
            <img :src="item.image">
          </el-col>
      </el-row>
      <el-row type="flex" class="bottomWrap">
          <el-col><i class="far fa-envelope"></i></el-col>
          <el-col><p class="huanH">{{$t('about.email')}}</p><p class="mtop">recruitment@reachxm.com</p></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  data () {
    return {
      bannerImg: require('../../assets/vedioBack.png'),
      activeIndex: '1',
      joinUsList: []
    }
  },
  created () {},
  mounted () {
    this.getJoinUsList()
    this.getBannerImg()
  },
  methods: {
     handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
    getJoinUsList () {
      var url = '/reach/portal/api/about/getJoinUs'
      axios.post(url, {}, {
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.joinUsList = response.data.data
        }
      })
    },
    getBannerImg () {
      var bannerType = 'joinUs'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          this.bannerImg = response.data.data[0].adImage
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
 .topWrap{
    margin-top: 1.5rem;
    height: 240Px;
    // background: url(../../assets/vedioBack.png) no-repeat;
    background-size: cover;
    position: relative;
    .innerTitle{
      position: absolute;
      top:34%;
      left:50%;
    }
  }
  .content_inner{
      margin-bottom: 1rem;
      .el-row{
        width: 72%;
        margin: 0 auto;
        margin-top: 1.7rem;
        .leftWrap{
          margin-right: 1rem;
          p{
            text-align: left;
            &:nth-child(1){
              font-size: 0.6rem;
            }
            &:nth-child(2){
              font-size: 0.3rem;
              margin: 0.5rem 0;
            }
          }
          .positionWrap{
            p{
              font-size: 0.35rem;
              margin: 0.27rem 0;
            }
          }
        }
        .rightWrap{
          width: 93%;
          img{
            width: 100%;
          }
        }
      }
      .bottomWrap{
        margin-bottom: 2rem;
        .el-col{
          width: 5%;
          &:nth-child(2){
            width: 10%;
          }
          .svg-inline--fa.fa-w-16{
            font-size: 0.4rem;
          }
          p{
            text-align: left;
            font-size: 0.3rem;
          }
          .mtop{
            margin-top: 0.3rem;
          }
        }
      }
  }
  .descibe{
    line-height: 0.6rem;
  }
</style>
