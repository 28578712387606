<template>
  <div class="login_container">
      <div class="bg"></div>
      <div class="bg_left"></div>
    <div class="login_box" v-show="inputShow">
      <!-- 头像 -->
        <p class="title">{{$t('login.login')}}</p>
        <div class="QRCode"><img src="../../assets/code2.png" alt="" @click="changeLogin"></div>

      <!-- 表单 -->
      <el-form ref="LoginFormRef" :model="loginForm" label-width="0" :rules="LoginFormRules" class="login_form">
        <el-form-item prop="username">
      <!-- 用户名-->
          <span>{{$t('login.username')}}：</span> <el-input v-model="loginForm.username" style="margin-left:6px"></el-input>
        </el-form-item>
      <!-- 密码-->
        <el-form-item prop="password" class="pwd_wrap">
          <span>{{$t('login.password')}}： </span>
          <el-input v-model="loginForm.password"  ref="psd" type="password" @keyup.enter.native="sumitLogin" style="margin-left: 12px;"></el-input>
          <span  class="keyboardSpan" @click="openKeyboard" :class="showKeybord?'active':''">
            <!-- <i class="far fa-keyboard"></i> -->
            <img src="../../assets/keyboad.png" alt="">
          </span>
        </el-form-item>
         <div class="check_wrap">
             <el-checkbox v-model="autoLogin">{{$t('login.automatic')}}</el-checkbox>
             <span><a href="#" @click="toFindPsd">{{$t('login.Forgot')}}</a></span>
         </div>
        <el-form-item class="btns">
          <el-button class="btns-btn" type="primary" @click="sumitLogin">{{$t('login.logins')}}</el-button>
         <!--  <el-button type="info" @click="resetLoginForm">重置</el-button>
          <el-button type="success" @click="toRegister">注册</el-button> -->
        </el-form-item>
        <el-form-item class="quick">
         <a href="#">{{$t('login.Quick')}}</a><i>{{$t('login.or')}}</i><a href="#" @click="toRegister">{{$t('login.free')}}</a>
      </el-form-item>
      <el-form-item class="weChartWrap">
         <img src="../../assets/u3080.svg" alt="Wechart" @click="changeLogin">
      </el-form-item>
      </el-form>
      <div class="keyWrap" v-if="showKeybord">
        <loginKey :pswd.sync="loginForm.password"></loginKey>
      </div>
    </div>
    <div class="loginM" v-show="!inputShow">
      <span @click="changeLogin"><i class="far fa-arrow-alt-circle-left" ></i></span>
      <!--
      <img src="../../assets/loginM.png">
      -->
      <wxlogin
        :appid="'wxbe1210d653828edf'"
        :scope="'snsapi_login'"
        :redirect_uri="'http://www.reachxm.com'"
      >
      </wxlogin>
    </div>
  </div>
</template>

<script>
import loginKey from '../../components/loginKey/loginKey.vue'
import Cookies from 'js-cookie'
import axios from '../../api/axios'
import wxlogin from 'vue-wxlogin'

export default {
  components: {
    loginKey,
    wxlogin
  },
  data () {
    var passWord = (rule, value, callback) => {
      if (value == '' && this.$refs.psd != document.activeElement) {
        return callback(new Error(this.$t('login.passwords')))
      } else {
        callback()
      }
    }
    return {
      inputShow: true,
      autoLogin: false,
      showKeybord: false,
      wxCode: '',
      // 登录的初始化数据：备注默认应该是空
      loginForm: {
        username: '',
        password: ''
      },
      // 正则表达式的相关校验
      LoginFormRules: {
        username: [
          { required: true, message: this.$t('login.usernames'), trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'change', validator: passWord }
        ]
      }
    }
  },
  watch: {
    autoLogin: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.autoLoginn()
        }
      },
      deep: true
    }
  },
  created () {
    // console.log('params:'+this.$route.params.lgin);
    // console.log('query:'+this.$route.query.isRegis);
    Cookies.set('token', null)
    window.localStorage.setItem('curretUser', null)
    window.localStorage.setItem('usertoken', null)
    window.localStorage.removeItem('curretUser')
    window.localStorage.removeItem('usertoken')
    window.localStorage.removeItem('wxCode')
  },
  mounted () {
    var loginJson = JSON.parse(window.localStorage.getItem('loginJson')) || {}
    if (JSON.stringify(loginJson) != '{}') {
      this.autoLogin = true
      // this.loginForm.username = this.$base64ToString(loginJson.username)
      // this.loginForm.password = this.$base64ToString(loginJson.password)
      this.goLogin(loginJson)
    }
  },
  methods: {
    getWechatQrCode () {
      // var obj = new WxLogin({
      //   self_redirect: true,
      //   id: 'wechat-qrcode1',
      //   appid: 'wxb38507ba1da34854',
      //   scope: 'snsapi_login',
      //   redirect_uri: 'http://u35u062214.51vip.biz/reach/portal/api/user/login',
      //   state: '',
      //   style: '',
      //   href: ''
      // })
    },
    changeLogin () {
      this.inputShow = !this.inputShow
      // console.log(this.inputShow)
    },
    toFindPsd () {
      this.$router.push({ path: '/findBack' })
    },
    autoLoginn () {
      this.$refs.LoginFormRef.validate(async (valid) => {})
    },
    openKeyboard () {
      this.showKeybord = !this.showKeybord
      if (this.showKeybord) {
        this.$refs.psd.focus()
      }
    },
    // 清空表单的校验
    resetLoginForm () {
      this.$refs.LoginFormRef.resetFields()
    },
    sumitLogin () {
      var obj = {
        // username: this.$toBase64(this.loginForm.username),
        // password: this.$toBase64(this.loginForm.password)
        username: this.loginForm.username,
        password: this.loginForm.password
      }
      var that = this
      this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
          that.goLogin(obj)
        } else {
          this.$my_confirm(this.$t('login.failed'), {
             type: "warn",
             cancelBtnText: this.$t('login.sure'),
             otherBtnText: "",
             yesBtnText: ""
          })
        }
      })
    },
    async goLogin (obj) {
      var url = '/reach/portal/api/user/login'
      // var parm = this.$qs.stringify(obj)
      var username = obj.username
      var password = this.$md5(obj.password)
      axios.post(url, {}, {
        params: {
          username: username,
          password: password
        }
      }).then(response => {
        if (response.data.code === 1) {
          console.log()
          Cookies.set('token', response.data.data.token)
          this.checkToken(response.data.data.token)
        } else {
          this.$my_confirm( this.$t('login.Incorrect'), {
             type: "warn",
             cancelBtnText: this.$t('login.sure'),
             otherBtnText: "",
             yesBtnText: ""
          })
        }
      })
    },
    // 验证token
    async checkToken (token) {
      var that = this
      axios.post('/f/eshopping/eshoppinglogin/tokencheck', {}, {
        params: {
          eshoppingtoken: token
        }
      }).then(res => {
        console.log('code:' + res.data.statuscode)
        switch (res.data.statuscode) {
          case 0:
            window.localStorage.setItem('curretUser', res.data.username)
            window.localStorage.setItem('usertoken', token)
            that.$message.success( this.$t('login.successful'))
            that.$router.push({ path: "/homePage" });
            // that.$router.back(-1)
            break
          case 1:
            that.$msgboxx(that, '提示', '登陆已失效1')
            setTimeout(function () {
              that.$router.push({ path: '/loginn' })
            }, 2000)
            break
          case 2:
            that.$msgboxx(that, '提示', '登陆已失效2')
            setTimeout(function () {
              that.$router.push({ path: '/loginn' })
            }, 2000)
            break
          case 3:
            that.$msgboxx(that, '提示', '登陆已失效3')
            break
          default:
            break
        }
      })
    },
    toRegister () {
      this.$router.push({ path: '/register' })
    }
  }
}
</script>

<style lang="less" scoped>
  .login_container{
    // background-color: #2b4b6b;
    // background-image: linear-gradient(to right , #2b4b6b, skyblue);
            // filter: blur(20px);
    height: 10rem;
    position: relative;
  }
  .bg{
    background:url(../../assets/u3056.png) no-repeat center;
    background-size: cover;
    height: 100%;
  /*   position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right , #2b4b6b, skyblue); */
    // filter: blur(20Px);
   }
   .bg_left{
     background:url(../../assets/u3058.png) no-repeat center;
     background-size:contain;
     height: 20%;
     position: absolute;
     top: 31%;
     left: 13%;
     width: 30%;
   }
  .login_box{
    width: 8.7rem;
    height: 8rem;
    background-color: #fff;
    border-radius: 0.015625rem;
    position: absolute;
    right: -9%;
    top: 59%;
    transform: translate(-50%, -50%);
  }
  .loginM{
    width: 8.7rem;
    height: 8rem;
    position: absolute;
    right: -13%;
    top: 59%;
    transform: translate(-50%, -50%);
    .svg-inline--fa.fa-w-16{
      cursor: pointer;
      position: absolute;
      top: 3%;
      right: 3%;
      font-size: 0.5rem;
      color: #009fee;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .title{
    position: absolute;
    left: 39%;
    top: 6%;
    font-size: 23Px;
    font-weight: 600;
  }
  .QRCode{
    width: 0;
    height: 0;
    border-top: 152Px solid #ededed;
    border-left: 154Px solid transparent;
    position: absolute;
    top: 0%;
    right: 0%;
    cursor: pointer;
    img{
      position: absolute;
      top: -152Px;
      right: 5Px;
      transform: scale(0.9);
    }
  }
  .login_form{
    position: absolute;
    // bottom: -4%;
    height: 60%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 70px;
  }
  .pwd_wrap{
    .keyboardSpan{
      width: 25Px;
      height: 50Px;
      position: absolute;
      top: -6%;
      right: 14%;
      cursor: pointer;
      .svg-inline--fa.fa-w-18{
        //color: #4d87b9;//蓝色
          color: #4a4a4ac7;
          cursor: pointer;
          width: 100%;
          height: 21Px;
          // margin-top: 3px;
      }
    }
    .active{
       .svg-inline--fa.fa-w-18{
        color: #4d87b9;
       }
    }
  }
  .keyWrap{
    position: absolute;
    top: 48%;
    right: 0%;
    background-color: #3270a5;
    padding:10Px 0 0 12Px;
    z-index: 99;
  }
.check_wrap {
    display: flex;
    justify-content: space-between;
    width: 60%;
    padding-left: 99Px;
    margin-bottom: 20Px;
    a{
      font-size: 0.23rem;
      color: #333;
      &:hover{
        color: #1fbaf3;
      }
    }
    i{
      margin: 0 7Px;
    }
    /deep/ .el-checkbox{
      .el-checkbox__input{
        span{
          height: 15Px;
          line-height: 19Px;
          width: 0.3rem;

        }
        &::after{
          border-left: 0;
          border-top: 0;
          height: 0.2rem;
          left: 0.07rem;
          position: absolute;
          top: 0rem;
          width: 0.1rem;
        }
        .el-checkbox__inner{
          &::after{
              border: 0.05rem solid #fafafa;
              border-left: 2.5rem;
              border-top: 12.5rem;
              height: 0.2rem;
              left: 0.05rem;
              position: absolute;
              top: -0.05rem;
              width: 0.1rem;
          }
        }
      }
        .el-checkbox__label{
            font-size: 0.23rem;
            line-height: 0;
        }
    }
}
.btns{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .btns-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 8px;
    }

  /deep/ .el-form-item__content{
     width: 68%;
     .el-button{
        width: 87%;
        height: 0.7rem;
        font-size: 19Px;
        background-color: #1fbaf3;
        border-radius: 8Px;
     }
  }
}
.quick{
  margin-bottom: 10px;
  /deep/ .el-form-item__content{
    text-align: center!important;
    padding-right: 50px;
    a{
        color: #333;
        font-size: 0.23rem;
        &:hover{
          color: #1fbaf3;
        }
    }
    i{
      margin: 0 7Px;
      font-style:normal;
      font-size: 0.23rem;
    }
  }
}
.weChartWrap{
  /deep/ .el-form-item__content{
      margin: 0 auto;
      margin-left: auto!important;
      background-color: #7dd03f;
      width: 0.9rem;
      border-radius: 28Px;
      padding-left: 9Px!important;
      height: 1rem;
      line-height: 0.81rem;
      cursor: pointer;
  }
}
.login_box /deep/  .el-input{
  width: 66%;
  font-size: 20Px;
}
.login_box /deep/ .el-input__inner {
    height: 35Px;
    line-height: 35Px;
    width: 100%;
    background-color: #e9f6ff;
    border-radius: 5Px;
    border-color: #d0cfcf;
}
.login_box /deep/ .el-form-item__content {
  padding-left: 50Px;
  text-align: left;
  span{
    height: 100%;
    font-size: 0.3rem;
    width: 8%;
    border-radius: 5Px;
    border-color: #d0cfcf;
  }
}
.login_box /deep/ .el-form-item {
    // margin-bottom: 0.5rem;
}
 .login_box /deep/ .el-form-item__error {
    // line-height: 2;
    left: 30%;
    font-size: 0.2rem;
}
 .login_box /deep/ .el-input__prefix {
    left: 0.2rem;
}
 .login_box /deep/ .el-input__icon {
    font-size: 27Px;
    height: 100%;
    width: 0.6rem;
    line-height: 0.58rem;
}
</style>
