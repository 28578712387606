<template>
  <div class="content_all">
    <introduce v-if="styleName=='introduce'" :getData='sendData' :cooper='cooper'></introduce>
    <LbntImg v-if="styleName=='LbntImg'" :getData='sendData'></LbntImg>
    <RbntImg v-if="styleName=='RbntImg'" :getData='sendData'></RbntImg>
    <Lassemble v-if="styleName=='Lassemble'" :getData='sendData'></Lassemble>
    <Rassemble v-if="styleName=='Rassemble'" :getData='sendData'></Rassemble>
    <produceList v-if="styleName=='produceList'" :getData='sendData'></produceList>
    <paas v-if="styleName=='paas'" :getData='sendData' :showBackgd='showBackgd'></paas>
    <bottomImg v-if="styleName=='bottomImg'" :getData='sendData'></bottomImg>
    <fourColWord v-if="styleName=='fourColWord'" :getData='sendData'></fourColWord>
    <download v-if="styleName=='download'" :getData='sendData'></download>
    <Mapping v-if="styleName=='Mapping'" :getData='sendData'></Mapping>
    <airEmpty v-if="styleName=='airEmpty'" :getData='sendData'></airEmpty>
    <videoo v-if="styleName=='videoo'" :getData='sendData'></videoo>
  </div>
</template>
<script>
import LbntImg from "./serve/LbntImg.vue";
import RbntImg from "./serve/RbntImg.vue";
import Lassemble from "./serve/Lassemble.vue";
import Rassemble from "./serve/Rassemble.vue";
import produceList from "./serve/produceList.vue";
import introduce from "./serve/introduce.vue";
import paas from "./serve/paas.vue";
import bottomImg from "./serve/bottomImg.vue";
import fourColWord from "./serve/fourColWord.vue";
import download from "./serve/download.vue";
import Mapping from "./serve/Mapping.vue";
import airEmpty from "./serve/airEmpty.vue";
import videoo from "./video/video.vue";
export default {
  name:'serve',
  props:['styleName','sendData','showBackgd','cooper'],
  components: {
      LbntImg,
      Lassemble,
      Rassemble,
      produceList,
      introduce,
      paas,
      RbntImg,
      bottomImg,
      fourColWord,
      download,
      Mapping,
      airEmpty,
      videoo
		},
  data() {
    return {};
  },
  created() {},
  mounted() {
    //this.getData();
  },
  methods: {
    getData() {},
  },
};
</script>
<style  scoped>
.heart .el-col-8 {
  width: 18.33333%;
}

.content_all .el-button--primary {
  width: 2.8rem;
  height: 0.63rem;
  line-height: 0.4rem;
  border-radius: 0.15rem;
  background-color: rgba(31, 186, 243, 1);
}

.product_row {
  padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boderCol:after {
  width: 3.22rem;
  height: 0.1rem;
  background-color: #ffde5d;
  content: "";
  position: absolute;
  left: -0.067708rem;
  top: 3.24rem;
  z-index: 10;
}

.product_row .el-card {
  width: 3rem;
  height: 3rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
  padding: 0px;
  color: #a4a4a4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.news_heart p {
  padding: 15px 0 19px 0;
}

.news_heart .el-card {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.news_heart .el-card__body {
  padding: 0;
}

.new_indro {
  padding: 0 8px 0 10px;
  text-align: left;
}

.news_heart .new_indro span {
  font-size: 0.25rem;
  color: #827c7c;
}

.news_heart .new_indro .news_title {
  display: block;
  font-weight: 600;
  font-size: 0.28rem;
  color: #3c3b3c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.1rem;
}

.news_heart img {
  width: 100%;
}

/*  .aboutUs_heart .el-button--primary {
    width: 133px;
    height: 33px;
    line-height: 1px;
    border-radius: 6px;
    margin-top: 26px;
  } */
</style>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 10px;
  position: relative;
}
</style>
