<template>
  <div class="psd_wrap">
    <el-dialog :title="$t('modify.bind')" :visible.sync="dialogVisible" width="90%" @close='closeDialog' :append-to-body="true"
               class="login_box" :class="isIphone?'login_mini':''">
      <!-- 手机号-->
      <el-form
        ref="LoginFormRef"
        :model="loginForm"
        label-width="0"
        :rules="LoginFormRules"
        class="login_form"
      >
        <el-form-item prop="phone" class="pwd_wrap">
          <el-input :placeholder="$t('modify.phone')" v-model="loginForm.phone" prefix-icon="el-icon-phone"></el-input>
        </el-form-item>
        <span v-if="hadSave">
            <span class="redFont">{{$t('modify.bound')}}</span>
          </span>
        <el-form-item prop="verification">
          <el-input :placeholder="$t('register.code')" v-model="loginForm.verification"></el-input>
          <el-button class="verifBnt" @click="getverifBnt" v-if="isVerif">{{$t('register.Obtain')}}</el-button>
          <el-button class="verifBnt verifBnt2" disabled v-else>{{seconds}}{{$t('register.seconds')}}</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPhone()">{{$t('register.Submit')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../../api/axios'

export default {
  name: 'modifyPhone',
  data () {
    
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

      if (!value) {
        return callback(new Error(this.$t('register.empty')))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error(this.$t('register.numerical')))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error(this.$t('register.Incorrect')))
          }
        }
      }, 100)
    }
    return {
      isIphone: false,
      err: false,
      dialogVisible: false,
      istoEnter: false,
      isVerif: true,
      hadSave: false,
      seconds: 60,
      // 登录的初始化数据：备注默认应该是空
      loginForm: {
        password: '',
        comfirpsd: ''
      },
      // 正则表达式的相关校验
      LoginFormRules: {
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
        verification: [{ required: true, trigger: 'blur', message: this.$t('register.code') }]
      }
    }
  },
  methods: {
    closeDialog () { // 点空白处这个事件不执行
      this.dialogVisible = false
      this.$emit('update:sechShow', false)
    },
    cancel () {
      this.closeDialog()
    },
    async isPhoneRegister () {
      var phoneNumber = this.loginForm.phone
      if(phoneNumber!=''){
      var url = '/reach/portal/api/user/isPhoneRegister'
      var result = await axios.get(url, {
        params: {
          phone: phoneNumber
        }
      })
      return result
    }
    else{
      this.err=true
    }
    },
    getverifData () {
      var url = '/reach/portal/api/user/getVerifyCode'
      var phoneNumber = this.loginForm.phone
      axios.post(url, {}, {
        params: {
          phone: phoneNumber
        }
      }).then(response => {
        if (response.data.code === 1) {

        }
      })
    },
    checkVerifyCode () {
      var url = '/reach/portal/api/user/checkVerifyCode'
      var checkResult = axios.post(url, {}, {
        params: {
          phone: this.loginForm.phone,
          verifyCode: this.loginForm.verification
        }
      })
      return checkResult
    },
    async getverifBnt () {
      // 请求先看是否存在这个手机号 this.loginForm.phone，再获取
      var result = await this.isPhoneRegister()
      if (result.data === true) {
        this.hadSave = true
        return
      } else {
        this.hadSave = false
      }
      this.getverifData()
      this.isVerif = false
      var that = this
      var myVar = setInterval(function () {
        --that.seconds
        if (that.seconds === 0) {
          that.isVerif = true
          that.seconds = 60
          clearInterval(myVar)
        }
      }, 1000)
    },
    async submitPhone () {
      this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
          var checkResult = await this.checkVerifyCode()
      if (checkResult.data.code === 0) {
        this.$my_confirm(this.$t('register.error'), this.$t('login.prompt'), {
          type: 'warn',
          cancelBtnText: this.$t('login.sure'),
          otherBtnText: '',
          yesBtnText: ''
        })
        return
      } 
      var url = '/reach/portal/api/user/Modify'
      var obj = {
        phone: this.loginForm.phone
      }
      axios.post(url, obj).then(response => {
        if (response.data.code === 1) {
          this.$message.success(this.$t('modify.modification'))
        } else {
          this.$message.error(response.data.message)
        }
      })
        }})
    }
  },
  created () {
    this.isIphone = this.$isphone()
  }
}
</script>
<style lang="less" scoped>
  .login_container {
    // background-color: #2b4b6b;
    // background-image: linear-gradient(to right , #2b4b6b, skyblue);
    // filter: blur(20px);
    height: 10rem;
    padding-top: 9.5rem;
    position: relative;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #2b4b6b, skyblue);
    // filter: blur(20Px);
  }

  .login_box {
    width: 10rem;
    height: 51%;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%)
  }

  .avatar_box {
    width: 80Px;
    height: 80Px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 64%;
      height: 69%;
      //   border-radius: 50%;
      background-color: #eee;
    }
  }

  .login_form {
    /* position: absolute;
    bottom: 5%; */
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 1rem;
  }

  .btns {
    display: flex;
    justify-content: center;
  }

  .login_box{
    max-height: 11.2rem;
    width: 10rem;
    height: fit-content;
  }

  .login_box /deep/ .el-input__inner {
    height: 0.9rem;
    line-height: 0.4rem;
    width: 98%;
    padding-left: 0.8rem;
  }

  .login_box /deep/ .el-form-item {
    margin-bottom: 0.35rem;
  }

  .login_box /deep/ .el-form-item__error {
    left: 4.5%;
  }

  .login_box /deep/ .el-input__prefix {
    left: 0.2rem;
  }

  .login_box /deep/ .el-input__icon {
    font-size: 27Px;
    height: 100%;
    width: 0.6rem;
    line-height: 0.58rem;
  }

  .login_box /deep/ .el-button {
    // width: 1rem;
    // height: 0.5rem;
    display: block;
    margin: 0 auto;
    font-size: 17Px;
  }

  .login_box /deep/ .el-dialog__title {
    font-size: 20Px;
    font-weight: 600;
  }

  .login_box /deep/ .el-dialog {
    margin-top: 0.5rem !important;
  }

  .login_mini {
    height: 33%;

    /deep/ .el-input {
      font-size: 20Px;
    }

    /deep/ .el-dialog__headerbtn {
      font-size: 24Px;
    }
  }
  
    .verifBnt{
        width: 1.9rem !important;
        height: 0.88rem !important;
        font-size: 15Px!important;
        position: absolute;
        right: 2.4%;
        top: 0%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(219, 219, 219, 1) 100%);
        box-sizing: border-box;
        border-width: 1Px;
        border-style: solid;
        border-color: rgba(212, 212, 212, 1);
        border-radius: 5Px;
        border-top-left-radius: 0Px;
        border-bottom-left-radius: 0Px;
        box-shadow: none;
        color: #848484;
        text-align: center;
        &:hover{
          border-radius: 5Px;
          background: #53a8ff;//不能用background-color无效
          color: #fff;
        }
    }
    .verifBnt2{
      width: 2.5rem !important;
    }
</style>
