<template>
    <!-- 组件参考网址：https://www.cnblogs.com/conglvse/p/9667647.html -->
    <transition name="fade">
        <!--框-->
        <div class="hx-confirm-wrap" v-if="visible" @click="handleAction('close')">
            <!--模态框-->
            <div class="hx-confirm-modal">
                <!--内容-->
                <!-- 说明：@click.stop="stopAction() @click.prevent="stopAction()阻止冒泡事件  防止点击模态框空白内容时关闭模态框 -->
                <div class="hx-confirm-content" @click.stop="stopAction()">
                    <!-- 头部 -->
                    <div class="hx-confirm-header">
                        {{title}}
                        <button class="close" @click="handleAction('close')">
                            <span>&times;</span>
                        </button>
                    </div>
                    <!-- 身体 -->
                    <div class="hx-confirm-body">
                        <!-- 图片 -->
                        <svg
                            v-if="type=='error'"
                            class="far fa-times-circle hx-icon-font-error"
                            aria-hidden="true"
                        >
                            <use xlink:href="#icon-error" />
                        </svg>
                        <!-- 图片 -->
                        <svg
                            v-if="type=='success'"
                            class="far fa-check-circle hx-icon-font-success"
                            aria-hidden="true"
                        >
                            <use xlink:href="#icon-success" />
                        </svg>
                        <!-- 图片 -->
                        <svg
                            v-if="type=='warn'"
                            class="far fa-engine-warning hx-icon-font-warn"
                            aria-hidden="true"
                        >
                            <use xlink:href="#icon-warn" />
                        </svg>
                        {{content}}
                    </div>
                    <!-- 底部 -->
                    <div class="hx-confirm-foot">
                        <!-- 通过v-if来判断当变量值为空时按钮不存在 -->
                        <button
                            v-if="cancelBtnText"
                            class="hx-custombtn-cancel"
                            @click="handleAction('yes')"
                        >{{cancelBtnText}}</button>
                        <button
                            v-if="otherBtnText"
                            class="hx-custombtn-del"
                            @click="handleAction('other')"
                        >{{otherBtnText}}</button>
                        <button
                            v-if="yesBtnText"
                            class="hx-custombtn-confirm"
                            @click="handleAction('cancel')"
                        >{{yesBtnText}}</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "MyConfirm",
    data() {
        return {
            visible: false,
            title: "", //标题
            content: "", //提示文字
            yesBtnText: "确定", //确定按钮
            cancelBtnText: "取消", //取消按钮
            otherBtnText: "", //其他按钮  (如果只需要两个按钮  此部分可不写)
            type: "", //提示类型success warn error(图标) 可忽略
            promiseStatus: null
        };
    },
    watch: {},
    methods: {
        confirm() {
            let _this = this;
            this.visible = true; //显示模态框
            return new Promise(function(resolve, reject) {
                _this.promiseStatus = { resolve, reject };
            });
        },
        //点击按钮触发函数
        handleAction(action) {
            this.visible = false; //关闭模态框
            if (action != "cancel") {
                //点击确认按钮
                this.promiseStatus && this.promiseStatus.resolve(action);
                
            } else {
                //点击取消按钮
                this.promiseStatus && this.promiseStatus.reject();
            }
        },
        //阻止冒泡事件的空函数
        stopAction() {
            console.log("阻止事件冒泡");
        }
    }
};
</script>
<style scope>
button {
    border: none;
    background: none;
}
.hx-confirm-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.3);
}
.hx-confirm-modal {
    height: 100%;
    position: relative;
    padding: 20px;
    margin: auto;
}
@media (min-width: 768px) {
    .hx-confirm-modal {
        width: 500px;
    }
    .hx-confirm-content {
        width: 10rem;
    }
}
@media (max-width: 768px) {
    .hx-confirm-modal {
        width: 80%;
    }
    .hx-confirm-content {
        width: 95%;
    }
}
.hx-confirm-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translate(-50%, 0);
    margin: auto;
    height: 5rem;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.2rem;
    -webkit-animation: zoom 0.6s;
    animation: zoom 0.6s;
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.6);
    -moz-user-select: none; /*火狐*/ /*选中文字时避免出现蓝色背景*/
    -webkit-user-select: none; /*webkit浏览器*/ /*选中文字时避免出现蓝色背景*/
    -ms-user-select: none; /*IE10*/ /*选中文字时避免出现蓝色背景*/
    -khtml-user-select: none; /*早期浏览器*/ /*选中文字时避免出现蓝色背景*/
    user-select: none; /*选中文字时避免出现蓝色背景*/
}
.hx-confirm-header {
    color: #090909;
    font-size: 0.35rem;
    padding: 0.25rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.1rem;
}
.close {
    float: right;
    font-size: 0.5rem;
    line-height: 0.5;
    font-weight: bold;
    color: #9b9b9b;
}

.hx-confirm-body {
    padding: 0 0.6rem;
    font-size: 0.4rem;
}
.hx-confirm-foot {
    text-align: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.hx-confirm-foot button{
    width: 2rem;
    border-radius: 0.12rem;
    padding: 0.04rem 0;
    color: #fff;
    cursor: pointer;
}
/* 确认按钮 */
.hx-custombtn-cancel {
    outline: none;
    padding: 6px 12px;
    border-radius: 4px;
    background: #1fbaf3;
    border: 1px solid #1fbaf3;
}
/* 删除按钮 */
.hx-custombtn-del{
    outline: none;
    padding: 6px 12px;
    border-radius: 4px;
    background: #f70000;
    border: 1px solid #f70000;
}
/* 返回按钮 */
.hx-custombtn-confirm {
    outline: none;
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #8a8a8a;
    background: #8a8a8a;
}
.icon-font {
    width: 16px;
    height: 16px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
/* 成功标识 */
.hx-icon-font-success {
    fill: #2baf2b !important;
    color: #2baf2b;
}
/* 警告标识 */
.hx-icon-font-warn {
    fill: #e59c0b !important;
    color: #e59c0b;
}
/* 失败标识 */
.hx-icon-font-error {
    fill: #ec1616!important;
    color: #ec1616;
}
</style>
