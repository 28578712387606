<template>
	<div class="serview_wrap">
		<el-dialog :title="serwhat+'的搜索结果'" :visible.sync="dialogVisible" width="90%" @close='closeDialog' :append-to-body="true" class="serview_wrap">
			<el-table :data="goods_list" border style="width: 100%;cursor: pointer;" @row-click="openDetails">
				<el-table-column type="index"> </el-table-column>
				<el-table-column prop="itemName" label="文件名" >
				</el-table-column>
				<el-table-column prop="addtime" label="创建时间">
				</el-table-column>
				<el-table-column prop="lastUpdateTime" label="修改时间">
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="cancel()">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Net from '../../api/net.js';
	import {
		getSame,
		timestampToTime
	} from '../../commethods/commethods.js';
    import globalConst from '../../commethods/globalConst'
	export default {
		name: "deveSerch",
		data() {
			return {
				serwhat:'',
				dialogVisible: true,
				goods_list: []
			}
		},
		props: ["reGoods","isstList"],
		methods: {
			toDoc (index) {
				var docUrl = globalConst.docUrl+ '/#/show/doc/' + index;
				this.$router.push({path:'/docCenter', query: {docUrl: docUrl}})
			},
			openDetails(row){
				var docUrl = globalConst.docUrl+ '/#/show/doc/' + row.itemId;
				this.$router.push({path:'/docCenter', query: {docUrl: docUrl}})
			},
			closeDialog(){
			    this.dialogVisible = false;
			    this.$emit('update:sechShow', false);
			},
			cancel() {
				this.closeDialog();
			},
			async getSearch(e) {
				var that = this;
				that.serwhat=e;
				//console.log(timestampToTime('2021-08-06T07:23:59.000+00:00'));
				
				 let res = await Net.get('/reach/portal/api/doc/searchDoc', {
					keyWord: e
				});
				that.goods_list=res.data;
				that.goods_list.forEach((v, i) => {
					v.addtime = timestampToTime(v.addtime);
					v.lastUpdateTime = timestampToTime(v.lastUpdateTime);
				}) 
			}
		},
		created() {
			/* if(this.isstList==true){
				console.log('stor');
				
			} */
		}
	}
</script>

<style>
	.el-dialog__header {
		text-align: center;
	}

	.serview_wrap .el-dialog__footer {
		text-align: center;
	}

	.serview_wrap .el-table--enable-row-transition .el-table__body td {
		text-align: center;
	}

	.serview_wrap .el-table th>.cell {
		text-align: center;
	}

	.serview_wrap .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0px;
	}
</style>
<style lang="less" scoped>
.serview_wrap{
	/deep/ .el-dialog__header{
            padding-top: 0.3rem;
            .el-dialog__title{
            font-size: 26Px;
            font-weight: 600;
            }
        }
        /deep/ .el-dialog__headerbtn{
            font-size: 24Px;
        }
		/deep/ .el-table th>.cell{
			font-size: 22Px;
			line-height: 0.5rem;
		}
		/deep/ .el-table{
            font-size: 20Px;
			.cell{
				line-height: 0.5rem;
			}
		}
		/deep/ .el-dialog__footer{
			text-align: center;
			.el-button{
					font-size: 21Px;
			}
		}
		/deep/ .el-table__empty-text{
		    line-height: 0.7rem;
		}
}
</style>
