 <template>
  <div class="content_all">
    <div class="topWrap">
      <img :src="bannerImg">
    </div>
    <div class="content_inner">
        <div  class="firentWrap">
         <p class="altitle nomarTop">{{$t('develop.develop') }}</p>
         <p class="altitle">{{$t('develop.support') }}</p>
          <el-button @click="toDoc(0)">{{$t('develop.Entering') }}>></el-button>
        </div>
        <div class="cardWrap">
          <el-card v-for="(item1,index1) in deveData" :key="index1">
            <el-row type="flex">
              <div class="leftWrap">
                <span class="leftTitle">{{item1.projectName}}</span>
                <img src="../../assets/dev1.png" alt="">
              </div>
              <div v-for="(item2,index2) in item1.folderList" :key="index2" class="rightWrap">
                <p class="secondTitle">{{item2.folderName}}</p>
                <div class="thridWrap">
                  <p @click="toDoc(item3.itemId)" v-for="(item3,index3) in item2.itemList" :key="index3" class="cursor">{{item3.itemName}}</p>
                </div>
              </div>
            </el-row>
            <!--
            <el-row class="RowBottom" @click.native="toMore"><span>查看更多文档>></span></el-row>
            -->
          </el-card>
        </div>
    </div>
    <storeSearch :isDeve=true></storeSearch>
  </div>
</template>
<script>
import storeSearch from '../../components/storeSearch/storeSearch.vue'
import axios from '../../api/axios'
import globalConst from '../../commethods/globalConst'
export default {
  name: 'produceSeries',
  components: {
    storeSearch
  },
  data () {
    return {
      bannerImg:'',
      deveData: [
        {
          projectName: 'pro1',
          projectId: 1,
          folderList: [
            {
              itemList: [
                {
                  itemId: 5,
                  itemName: 'test4'
                },
                {
                  itemId: 33,
                  itemName: 'teststst'
                },
                {
                  itemId: 47,
                  itemName: 'asfsfas'
                },
                {
                  itemId: 48,
                  itemName: 'teststst--copy'
                },
                {
                  itemId: 49,
                  itemName: 'test123s--copy'
                },
                {
                  itemId: 51,
                  itemName: 'newPdf'
                }
              ],
              folderName: 'folder111',
              folderId: 1
            },
            {
              itemList: [],
              folderName: 'folder2',
              folderId: 2
            },
            {
              itemList: [],
              folderName: 'folder3',
              folderId: 3
            },
            {
              itemList: [],
              folderName: 'folder222d',
              folderId: 4
            },
            {
              itemList: [],
              folderName: 'folder221',
              folderId: 5
            }
          ]
        },
        {
          projectName: 'pro2',
          projectId: 2,
          folderList: [
            {
              itemList: [],
              folderName: 'folder1',
              folderId: 7
            }
          ]
        },
        {
          projectName: 'pro3',
          projectId: 3,
          folderList: [
            {
              itemList: [],
              folderName: 'test',
              folderId: 6
            }
          ]
        },
        {
          projectName: 'testpro',
          projectId: 4,
          folderList: []
        },
        {
          projectName: 'test2',
          projectId: 5,
          folderList: []
        },
        {
          projectName: 'test3',
          projectId: 6,
          folderList: []
        },
        {
          projectName: 'test23',
          projectId: 7,
          folderList: []
        },
        {
          projectName: 'test8',
          projectId: 8,
          folderList: []
        },
        {
          projectName: 'test9',
          projectId: 9,
          folderList: []
        },
        {
          projectName: 'wmw233pro',
          projectId: 13,
          folderList: [
            {
              itemList: [],
              folderName: 'test',
              folderId: 8
            }
          ]
        },
        {
          projectName: 'test10',
          projectId: 17,
          folderList: []
        }
      ]
    }
  },
  created () {},
  mounted () {
    this.getDocIndex()
    this.getBannerImg()
  },
  methods: {
    getDocIndex () {
      var privateUrl = '/reach/portal/api/doc/privateDocIndex'
      var publicUrl = '/reach/portal/api/doc/publicDocIndex'
      axios.get(privateUrl).then(response => {
        if (response.data.code === 1) {
          this.deveData = response.data.data
        } else {
          axios.get(publicUrl).then(response1 => {
            this.deveData = response1.data.data
          })
        }
      })
    },
    toDoc (index) {
      if(index!==0){
        var docUrl = globalConst.docUrl+ '/#/show/doc/' + index;
        this.$router.push({path:'/docCenter', query: {docUrl: docUrl}})
      } else{
        docUrl = globalConst.docUrl+ '/#/show/doc/126?page_id=435'
        this.$router.push({path:'/docCenter', query: {docUrl: docUrl}})
      }
    },
    toMore () {
      var docUrl = globalConst.docUrl
      window.open(docUrl + '/#/open/docIndex')
    },
    getBannerImg () {
      var bannerType = 'develope'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          if(response.data.data.length>0){
           this.bannerImg = response.data.data[0].adImage
          }
        }
      })
    }

  }
}
</script>
<style scoped lang="less">
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    margin-top: 1.5rem;
    // padding-top: 1.5rem;
    height: 5.2rem;
    // background: url(../../assets/dever.jpg) no-repeat;
    background-size: cover;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content_inner{
      width: 100%;
      margin: 0 auto;
      position:relative;
      background: url(../../assets/dev2.png) no-repeat;
      padding-top: 2rem;
      margin-bottom: 1rem;
      .firentWrap{
         position: relative;
         .nomarTop{
           margin-top: 0;
         }
        .el-button{
            background-color: #35c68f;
            color: #fff;
            border-radius: 0.17rem;
            width: auto;
            // height: 0.6rem;
            font-size: 0.25rem;
            margin: 0.5rem 0;
            text-align: center;
            &:hover{
              background-color: #cacaca;
            }
        }
      }
      .cardWrap{
        .el-card{
          width: 70%;
          margin: 0 auto;
          padding: 0.5rem 0;
          border-radius: 0 0.5rem 0.5rem;
          margin-top: 0.5rem;
          .leftWrap{
            width: 13%;
            margin-left: 0.4rem;
            img{
              width: 100%;
              margin-left: 0.5rem;
            }
            .leftTitle{
                display: inline-block;
                width: 3.55rem;
                word-wrap: break-word;
                font-size: 0.39rem;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
          }
          .rightWrap{
              margin-left: 2.3rem;
          }
          p{
            font-size: 0.39rem;
            text-align: left;
          }
          .secondTitle{
            width: 3.5rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space:nowrap;
            text-align: left;
          }
          .thridWrap{
            p{
              text-align: left;
              font-size: 0.26rem;
              margin-top: 0.3rem;
              width: 3.9rem;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              &:hover{
                color: #fff;
              }
            }
          }
          .RowBottom{
              text-align: right;
              margin: 4% 10.5% 0 0;
              font-size: 0.27rem;
              color: #aeaeae;
              span{
                cursor: pointer;
              }
          }
          &:nth-child(2n+1):hover{
            background-color: #19c27b;
          }
          &:nth-child(2n):hover{
            background-color: #ff705d;
          }
        }
      }
  }
}
</style>
