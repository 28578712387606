<template>
	<!--为echarts准备一个具备大小的容器dom-->
	<div class="main_wrap">
		<div id="main" class="main" style="width: 900px;height: 200px;"></div>
	</div>
</template>
<script>
	// import echarts from 'echarts'
	import * as echarts from 'echarts'
	export default {
		name: "zheXian",
		data() {
			return {
				charts: '',
				YData: ["0", "0.9","0.02", "1.2","0.2"]
			}
		},
		methods: {
			drawLine(id) {
				this.charts = echarts.init(document.getElementById(id));
				this.charts.setOption({
					tooltip: {
						//trigger: 'axis',//显示hover，区别	series[0].itemStyle.normal.label.formatter是折点上的文字
						trigger: ''
					},
					/* 	//不用就隐藏，否则F12控制台有黄色的提示
					legend: {
							data: ['近七日收益']
						}, */
					grid: {
						left: '10%',
						//right: '4%',
						bottom: '25%',
						containLabel: true  
					},

					toolbox: {
						feature: {
							//saveAsImage: {}//保存为图片的右上角logo以及功能
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data:  ["2008","2012", "2015", "2019", "2020","2021"],
						//opinionData: ["0", "2", "4", "4", "5"],
						show: false

					},
					yAxis: {
						type: 'value',
						show: false,
						splitLine: { show: false }
					},
					series: [{
						//	symbol: 'none',  //取消折点圆圈，但是label也会消失
						//smooth: true,//非曲线，平滑线
						symbolSize: 0.1,//折点圆圈大小，因为把折点圆圈设置了透明色
						markPoint: {
							symbolSize: 30,
							itemStyle: { color: '#009dd9' },//折点气球
							data: [
								{xAxis: "2008", yAxis: "0"},
								{xAxis: "2012", yAxis: "0.9"},
								{xAxis: "2015", yAxis: "0.02"},
								{xAxis: "2019", yAxis: "1.2"},
								{xAxis: "2020", yAxis: "0.2"},
								{xAxis: "2021", yAxis: "1.3"}
							]
						},
						itemStyle: {
							normal: {
								label: {
									padding: [5, 15, 5, 15],
									show: true,
									formatter: (dataa)=>{
										var arr=[];
										if (dataa.value !== "0.3" && dataa.value !== "0.6" && dataa.value !== "0.4" && dataa.value !== "0.8" && dataa.value !== "0.7") {
											if (dataa.value == "0") {
												arr.push('{b|' + dataa.name + '}', '\n', '{c|' + "公司在厦门成立" + '}');
											}
											else if (dataa.value == "0.9") {
												arr.push('{b|' + dataa.name + '}', '\n', '{c|' + "国家火炬计划重点高新技术企业" + '}');
											}
											else if (dataa.value == "0.02") {
												arr.push('{b|' + dataa.name + '}', '\n', '{c|' + "南宁分公司成立" + '}');
											}
											else if (dataa.value == "1.2") {
												arr.push('{b|' + dataa.name + '}', '\n', '{c|' + "物联网孵化器认证" + '}');
											}
											else if (dataa.value == "0.2") {
												arr.push('{b|' + dataa.name + '}', '\n', '{c|' + "福建重点科技研发机构" + '}');
											}
											else {
												arr.push('{b|' + dataa.name + '}', '\n', '{c|' + dataa.value + '}');
											}
										}
										return arr.join('');
										/* for (var i = 0; i < option.series[0].data.length; i++) {
											if (i !== 2) {
												return "{b|{b}}\n{c|{c}}";
											}
										} */
									},
									position: 'bottom',
									offset: [1, 6],
									rich: {
										b: {
											align: 'center',
											color: '#414141',
											fontSize: "23px"
										},
										c: {
											align: 'center',
											color: '#80837f',
											fontSize: "14px"
											//padding: [0, 5, 0, 10]
										}
									},
									textStyle: {
										color: '#000',
										fontSize: "18px",
									},
								},
								color: '#fc5532', //改变折线点的颜色
								lineStyle: { color: '#009dd9', width: 0.7 }, //改变折线颜色,0.1是最细
							},
						},
						// name: '近七日收益',
						type: 'line',
						stack: '总量',
						data: this.YData
					}]
				})
				this.charts.on('click', function (params) {
				if (params.componentType === 'series') {
					// 点击到了 markPoint 上
					if (params.dataIndex === 0) {
						// 点击到了 index 为 5 的 series 的 markPoint 上。
						console.log('第一个');
						//window.location.href = "https://www.baidu.com/";
						window.open('https://www.baidu.com/');
					}
				}
			})
		    },
			getPoint(){
				this.$parent.addPointOffset();
			}
		},
		//调用
		mounted() {
			this.$nextTick(function () {
				this.drawLine('main');
			})
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.main_wrap{
		position: relative;
		.el-button{
			background-color: #5c99c4;
			color: #fff;
			padding: 0.15rem 0.3rem;
			border-radius: 0.1rem;
			position: absolute;
			top: -20%;
			right: -6%;
		}
	}
</style>