<template>
  <div class="content_all">
    <div class="topWrap">
        <img :src="bannerImg" class="staticBanner">
        <p class="altitle innerTitle">{{ $t('cooperate.address') }}</p>
    </div>
    <div class="content_inner">
       <div  v-for="(item,index) in compoentList" :key="index" class="firentWrap">
            <serve :cooper="index==0?true:false"   :showBackgd=false :sendData='item' :styleName='item.styleName' :class="index==1?'secHeight':''"></serve>
            <a v-if="index==0" class="contract" ><el-button @click="toSign">{{ $t('cooperate.Registration') }}>></el-button></a>
            <div v-if="index==1" class="tableWrap">
                <p class="altitle">{{ $t('cooperate.registration') }}</p>
                <el-table
                    :data="tableData"
                    style="width: 100%"
                    :header-cell-style="{background:'#dfe7e9',color:'#000'}">
                    <el-table-column
                      prop="city"
                      :label="$t('cooperate.region')"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="applyPerson"
                      :label="$t('cooperate.agent')"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="phoneNumber"
                      :label="$t('cooperate.Contact')">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import serve from '../../components/customPart/serve.vue'
import axios from "../../api/axios";
export default {
  name: 'produceSeries',
  components: {
    serve
  },
  data () {
    return {
      bannerImg: require('../../assets/tech.png'),
      compoentList: [
        {
          styleName: 'introduce',
          title:   this.$t('cooperate.REACH2'),
          content:  this.$t('cooperate.REACH3'),
        },
        {
          styleName: 'introduce',
          title:  this.$t('cooperate.REACH4'),
          content: this.$t('cooperate.REACH5'),
        }
      ],
      tableData: [{
            area: '成都',
            agent: 'xxx',
            phone: '45215478'
          }, {
            area: '重庆',
            agent: 'xxx',
            phone: '21254789'
          }]
    }
  },
  created () {},
  mounted () {
    this.getBannerImg()
    this.getAgentList()
  },
  methods: {
    
       checkToken () {
          var url = '/reach/portal/api/user/getUserInfo'
          //区别刷新时候结束，不用往下执行
          axios.get(url).then(response => {
              if(response.data.code==2){
                that.$my_confirm(this.$t('heard.application'), '提示', {
              type: 'warn',
              cancelBtnText: this.$t('login.sure'),
              otherBtnText: '',
              yesBtnText: ''
            })
                    .then(action => {
                        if (action == "yes") {
                            this.$router.push({ path: "/loginn" });
                        } else if (action == "other") {}
                    })
                    .catch(() => {});  
                    Cookies.set('token', null)
                    window.localStorage.setItem('curretUser', '')
                    window.localStorage.setItem('usertoken', '')
                    this.reload();
              }
              else{
                this.$router.push({path: '/applyAgent'})
              }
          })
    },
    toSign () { 
      this.checkToken()
    },
    getBannerImg () {
      var bannerType = 'agent'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          if(response.data.data.length>0){
           this.bannerImg = response.data.data[0].adImage
          }
        }
      })
    },
    getAgentList () {
      var url = '/reach/portal/api/cooperate/getAgentList'
      axios.get(url).then(response => {
        if (response.data.code === 1) {
          this.tableData = response.data.data
        } else {
          this.$my_confirm("获取代理商失败", "提示", {
                        type: "warn",
                        cancelBtnText: "确定",
                        otherBtnText: "",
                        yesBtnText: ""
                    })
                    .then(action => {
                        if (action == "yes") {} 
                        else if (action == "other") {}
                    })
                    .catch(() => {}); 
        }
      })
    }

  }
}
</script>
<style scoped lang="less">
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    position: relative;
    margin-top: 1.5rem;
    // padding-top: 1.5rem;
    height: 240Px;
    // background: url(../../assets/tech.png) no-repeat;
    background-size: cover;
    .innerTitle{
      position: absolute;
      top:34%;
      left:47%;
    }
  }
  .content_inner{
      width: 100%;
      margin: 0 auto;
      .firentWrap{
         position: relative;
         .secHeight{
           height: 1rem;
         }
        .el-button{
            background-color: #3ac0f1;
            color: #fff;
            border-radius: 0.17rem;
            width: 2.3rem;
            // height: 0.6rem;
            font-size: 0.25rem;
        }
        .contract{
            position: absolute;
            top: 74%;
            left: 50%;
            transform:translate(-50%,-50%);
        }
        .tableWrap{
          width: 80%;
          margin: 0 auto;
          /deep/ .el-table{
            margin-top: 0.5rem;
            .cell{
              line-height: 1rem;
              text-align: center;
              font-size: 0.3rem;
            }
            .el-table__cell{
                color:#B0B0B0;
            }
          }
        }
      }
  }
}
</style>
