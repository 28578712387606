<template>
	<div class="menu_wrap" :class="specilMenu?'menu_wrap_spl':''">
		<div class="menu_col flexcolom" v-for="(item, i) in dataList" :key="i">
			<p>{{item.title}}</p>
			<template v-if="whichMenu==undefined">
				<div class="menu flexcolom" v-for="(item2, index) in item.names" :key="index">
					<a  @click="toPath(item2)" class="cursor"><span>{{item2}}</span></a>
				</div>
			</template>
			<template v-else>
				<div class="menu flexcolom" v-for="(item2, index) in item.names" :key="index">
					<a v-if="whichMenu==MenuList[0]" @click="toProduce(item2.menuId)" class="cursor"><span>{{item2.menuName}}</span></a>
					<a v-if="whichMenu==MenuList[1]" @click="toService(item2.menuId)" class="cursor"><span>{{item2.menuName}}</span></a>
					<a v-if="whichMenu==MenuList[2]" @click="toSolvePath(item2.menuId)" class="cursor"><span>{{item2.menuName}}</span></a>
					<a v-if="whichMenu==MenuList[3]" @click="toSolvePath(item2.menuId)" class="cursor"><span>{{item2.menuName}}</span></a>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Menu",
		props: ['dataList','specilMenu','whichMenu'],
		data() {
			return {
				MenuList:['fdata2','fdata21','fdata3','fdata4'],
			}
		},
		mounted() {
          console.log(this.dataList)
          console.log(this.whichMenu)
		},
		methods: {
			
			toPath(item7){
				console.log(item7,'1111111111111')
				if(item7=='网上商城'){
					//window.open(this.$store.state.shopUrl+'/eshopping/storePage')
					this.$router.push({path:'/storePage'})
				}
			    if(item7 == '视频'){
					this.$router.push({path:'/videoView'})
				}
				if(item7 == '新闻'){
					this.$router.push({path:'/news'})
				}
				if(item7 == '联系我们'){
					this.$router.push({path:'/contact'})
				}
				if(item7 == '加入我们'){
					this.$router.push({path:'/addUs'})
				}
				if(item7 == '关于锐骐'){
					this.$router.push({path:'/reach'})
				}
				if(item7 == '合作伙伴'){
					this.$router.push({path:'/cooperation'})
				}
				if(item7 == '成功案例'){
					this.$router.push({path:'/cooperation', query: {succs: true}})
				}
				if(item7 == '水表'||item7 =='电表'||item7 == '气表'){
					window.open('http://172.16.10.115:8088')
				}
				if(item7 == '气感'){
					window.open('http://lora-gas.rivcloud.com')
				}
				if(item7 == '文档下载'){
					this.$router.push({path:'/doc'})
				}
				if(item7 == '开发者文档'){
					this.$router.push({path:'/docCenter'})
				}
				if(item7 == '帮助中心'){
					this.$router.push({path:'/helpCenter'})
				}
				if(item7 == '商务咨询'){
					this.$router.push({path:'/business'})
				}
				if(item7 == '技术咨询'){
					this.$router.push({path:'/technology'})
				}
				if(item7 == '代理商'){
					this.$router.push({path:'/agent'})
				}
				if(item7 == '众创与孵化器'){
					this.$router.push({path:'/incubator'})
				}
			},
			toProduce (routerId) {
			this.$router.push({ path: '/produceSeries', query: { routerId } })
			},
			toService (serviceTypeId) {
			this.$router.push({ path: '/addValue', query: { serviceTypeId } })
			},
			toSolvePath (solutionTypeId) {
			this.$router.push({ path: '/solve', query: { solutionTypeId } })
			}
		}
	}
</script>

<style lang="less">
	.menu_wrap {
		display: flex;
		margin-right: 20px;
		text-align: left;

		.flexcolom {
			display: flex;
			flex-direction: column;
			margin: 0.05rem 0;
		}

		p {
			font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
			font-weight: 700;
			font-style: normal;
			font-size: 14Px;
			color: #3C3C3C;
			margin: 0;
			margin-bottom: 4px;
		}

		.menu a {
			margin-top: 5px;
		}

		.menu span {
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			font-size: 0.25rem;
			color: #3C3C3C;
			display: inline-block;
		}
	}
	.menu_wrap_spl{
		margin-top: 0.28rem;
		margin-left: -0.6rem;
		text-align: left;
	}
</style>
