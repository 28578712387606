<template>
	<div  class="introWrap" :style="[{background:getData.backGroud}]">
		<p class="altitle">{{getData.title}}</p>
    <p class="subtitle">{{getData.content}}</p>
    <el-button v-if="getData.button!=null">{{getData.button}}</el-button>
    <div class="imgWrap"><img :src="getData.maxImg" alt=""></div>
	</div>
</template>
<script>
export default {
  name: 'introduce',
  props: ['getData'],
  data () {
    return {
    }
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style scoped lang="less">
.introWrap{
	margin-top: 0.3rem;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
	p{
		margin-bottom: 0.3rem;
	}
  .imgWrap{
    width: 33%;
    float: none;
    margin: 0 0.4rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
    .el-button{
        background-color: #3ac0f1;
        color: #fff;
        border-radius: 0.17rem;
        width: 2.3rem;
        height: 0.6rem;
        margin-bottom: 0.3rem;
	}
}

</style>
