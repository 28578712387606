<template>
  <div class="introWrap">
    <p class="altitle title">{{getData.title}}</p> 
    <div>
      <p class="content">{{getData.content}}</p>
    </div>
    <el-row type="flex" justify="space-between">
      <el-col v-for="(item,index) in getData.productList" :key="index">
        <el-card shadow="always">
          <a :href="item.productLink" target="_blank">
            <img :src="item.productImage"/>
            <p>{{item.productName}}</p>
          </a>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'bottomImg',
  props: ['getData'],
  data () {
    return {
    }
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style scoped lang="less">
.introWrap{
   padding-top: 0.6rem;
   height: 9rem;
   background: #f0f8fa;
   .title{
    width: 28%;
    margin: 0 auto;
	}
  .content{
	width: 49%;
    height: 2.4rem;
    margin: 0 auto;
    font-size: 0.3rem;
    text-align: left;
    margin-top: 0.5rem;
   }
    .el-row{
        overflow: hiddesn;
        padding: 0.5rem 0;
        width: 76%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        .el-col {
            width: 16%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.8rem;
            .el-card {
                width: 3rem;
                height: 3rem;;
                border-radius: 0.15rem;
                box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
                .el-card__body {
                    padding: 0px;
                    color: #a4a4a4;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    a{
                        color: #2c3e50;
                    }
                    img{
                        width: 90%;
                    }
                }
            }
        }
    }

}

</style>
