<template>
  <div class="row_alwrap" :class="scaleShow?'':'alwrap_mini'">
    <div class="arrow_wrap" v-if="scaleShow" :class="isIpad?'arrow_ipad':''">
      <div class="arrow_line">
        <div class="arrow arrow_left" @click="changeAdv(-1)"></div>
        <i class="i_left" @click="changeAdv(-1)"></i>
      </div>
      <div class="arrow_line">
        <div class="arrow arrow_right" @click="changeAdv(1)"></div>
        <i class="i_right" @click="changeAdv(1)"></i>
      </div>
    </div>
    <div class="row_content">
      <v-touch
        ref="advSwiper"
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        tag="div"
        :class="scaleShow?'adv_swiper':'adv_mini'"
      >
        <a :href="item.adHref" v-for="(item,index) in smallImg" :key="index">
          <img :src="item.adPicture" />
        </a>
      </v-touch>
    </div>
  </div>
</template>

<script>
export default {
  name: "storeAdv",
  props: ["isIpad"],
  data() {
    return {
      scaleShow:false,
      smallImg: [],
      swipNum: 0,
      allcot: 0,
      swipcot: 0,
      swipcotMnin: 0,
      movee:0,//和swipNum区别是这个只有赋一次值，平移的长度
	    semove:0//对于单数张产品图的第一张需要特殊处理
    };
  },
/*   created() {
    this.getAdv();
  }, */
  mounted() {
    //等待dom渲染完毕再加载
    var that=this;
    this.$nextTick(() => {
      that.getAdv();
      that.commonSySize();
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      } else {
      }
    });
  },
  methods: {
    commonSySize() {
      let idTag = document.body;
       if(this.$isphone()){this.scaleShow = false;}
      else{
      if (idTag.offsetWidth <= 1020) {
        this.scaleShow = false;
      } else {
        this.scaleShow = true;
      }
      }
      this.judgeWidth();
    },
    judgeWidth(){
      if(!this.scaleShow){
        this.$refs.advSwiper.$el.style.width = this.allcot * 100 + "%";
      }
      else{ 
        if(this.allcot==3){this.$refs.advSwiper.$el.style.width = "100%";}
        else{
          this.$refs.advSwiper.$el.style.width = ((this.allcot-3) *33+100) + "%";
        }
      }
	  },
    changeAdv(e) {
      if (e > 0) {
        if (this.swipcot > 0) {
          this.swipNum += 25;
          --this.swipcot;
        } else {
          var that = this;
          this.$msgboxx(that, "提示", "广告已显示完整");
        }
      } else {
        this.swipNum -= 25;
        this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
        if (this.swipNum == 0) {
          this.swipcot = this.allcot - 3;
        }
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
    onSwipeLeft() {
      if(this.scaleShow==false){
         if (this.swipcotMnin > 0) {
           if (this.allcot % 2 == 0) {
             if(this.swipcotMnin==(this.allcot-1)){this.movee=(this.allcot * 100) / Math.pow(this.allcot,2);}
                this.swipNum += (this.allcot * 100) / Math.pow(this.allcot,2);
            } 
            else {
                let val=parseInt((this.allcot * 100) / Math.pow(this.allcot,2));
                let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
                if(yal==0){
                  if(this.swipcotMnin==(this.allcot-1)){this.movee=val}
                          this.swipNum +=val;
                }
                else{
                  if(this.swipcotMnin==(this.allcot-1)){this.semove=(val+0.5);this.swipNum +=(val+0.5);}
                  else{
                    if(this.swipcotMnin%2!=0){this.swipNum +=(val);this.movee=val;}
                    else{this.swipNum +=(val+0.5);this.movee=val+0.5;}
                    }
                  
                }
                    //只能对于3个：this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3);
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3*(3-this.swipcotMnin));
                    /* console.log('右边移动：'+3*(3-this.swipcotMnin));
                    console.log('当前值：'+this.swipcotMnin); */
                    
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 3*(3-swipcotMnin))+2);
            }
        --this.swipcotMnin;
      } else {
        var that = this;
        this.$msgboxx(that, "提示", "广告已显示完整");
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
     }
    },
    onSwipeRight() {
      let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
		  if(this.swipcotMnin==(this.allcot-2)&&yal!=0){
			  this.swipNum -=this.semove;
		  }
      else{
           if(this.swipcotMnin%2==0){this.swipNum -= this.movee;this.movee+=0.5;}
           else{this.swipNum -= this.movee;this.movee-=0.5;}
      }
      if (this.swipcotMnin<(this.allcot-1)) {
			this.swipcotMnin +=1;
      }
      this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      if (this.swipcotMnin == 0&&this.swipcotMnin<=this.allcot - 1) {
        this.swipcotMnin +=1;
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
    async getAdv() {
      var that = this;
      let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/adlist",{language: localStorage.getItem("locale")});
      that.smallImg.splice(0, 1);
      that.allcot = res.length;
      that.judgeWidth();
      that.swipcot = res.length - 3;
      that.swipcotMnin = res.length - 1;
      res.forEach((v, i) => {
        var obj = {
          adPicture: "",
          adHref: "",
          id: "",
        };
        this.$getSame(obj, res[i]);
        obj.adHref='http://'+obj.adHref;
        that.smallImg.push(obj);
      });
    },
  },
};
</script>

<style scoped lang="less">
.alwrap_mini {
  height: 7rem !important;
}
.row_alwrap {
  position: relative;
  /* height: 4.9rem;
  margin: 0.036458rem 0 0.109375rem 0; */
  .arrow_ipad{
      width: 126.5% !important;
      left: -13.2% !important;
    }
  .arrow_wrap {
    position: absolute;
    top: 37%;
    left: -9.4%;
    width: 118.7%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow_line {
      position: relative;

      .arrow {
        width: 0;
        height: 0;
        border-width: 0.9rem;
        border-style: solid;
        border-color: transparent #20baf3 transparent transparent;
        cursor: pointer;
      }

      .arrow_left {
        transform: rotate(0deg);
      }

      .arrow_right {
        transform: rotate(180deg);
      }

      i {
        /* flex: 5; */
        border: solid #ffffff;
        border-width: 0 0.1rem 0.1rem 0;
        display: inline-block;
        padding: 0.2rem;
        cursor: pointer;
      }

      i:hover {
        border: solid #5e5e5e;
        border-width: 0 0.1rem 0.1rem 0;
      }

      .i_right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: 37%;
      }

      .i_left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        position: absolute;
        right: -3.5%;
        top: 34%;
      }
    }
  }

  .row_content {
    overflow: hidden;
    .adv_swiper {
      display: flex;
      justify-content: space-around;
      width: 134%;
      transition: all 0.5s ease-in-out;
      a {
        width: 33.3%;
        margin-left: 0.1rem;
        img {
          width: 100%;
          height: 5.8rem;
          margin-left: 4px;
        }
        &:nth-child(1) {
          margin-left: 0px;
        }
      }
    }
    .adv_mini {
      display: flex;
      // justify-content: space-around;
      width: 400%;
      transition: all 0.5s ease-in-out;
      a {
        width: 100%;
        img {
          width: 50%;
          height: 100%;
        }
      }
    }
  }
}
</style>
