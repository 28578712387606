<template>
	<div class="search_wrap">
		<el-dialog :title="$t('heard.search')" :append-to-body="true" :visible.sync="dialogVisible" width="90%" @close='closeDialog' class="topser_warp">
			<storeSearch></storeSearch>
		</el-dialog>
	</div>
</template>

<script>
    import storeSearch from "../../components/storeSearch/storeSearch.vue";
	export default {
		name: "topSearch",
         components: {
    storeSearch
  },
		data() {
			return {
				dialogVisible: true,
				goods_list: []
			}
		},
		methods: {
			closeDialog(){
				this.dialogVisible = false;
				// console.log("safsa");
				this.$emit('update:sechShow', false);
			},
			cancel() {
				this.closeDialog();
			},
		},
		created() {
		}
	}
</script>

<style>
	.el-dialog__header {
		text-align: center;
	}

	.search_wrap .el-dialog__footer {
		text-align: center;
	}
</style>
<style scoped lang="less">
	.topser_warp {
        /deep/ .el-dialog__header{
            padding-top: 0.3rem;
            .el-dialog__title{
            font-size: 26Px;
            font-weight: 600;
            }
        }
        /deep/ .el-dialog__headerbtn{
            font-size: 24Px;
        }
}
</style>
