<template>
  <div class="LRWrap">
    <div class="LRinner" :style="[{background:getData.backGroud}]" :class="reach?'bg':''"> 
      <div class="imgWrap"><img :src="getData.maxImg" alt=""></div>
      <div class="explainWrap">
        <p class="altitle">{{getData.title}}</p>
        <p class="content">{{getData.content}}</p>
        <div class="imgList" v-if="showList">
          <el-row class="product_row" type="flex" justify="space-between">
            <el-col v-for="(item,index) in getData.productList" :key="index">
              <el-card shadow="always">
                <a :href="item.productLink" target="_blank">
                  <img :src="item.productImage"/>
                  <p>{{item.productName}}</p>
                </a>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Rassemble',
  props: ['getData'],
  data () {
    return {
      showList: true,
      reach: false,
    }
  },
  created () {},
  mounted () {
    if (this.$route.path.indexOf('solve')) {
      this.showList = false
    }
    if (this.$route.path.indexOf('reach')) {
      this.reach = true
    }
  },
  methods: {}
}
</script>
<style scoped lang="less">
.LRWrap{
	.LRinner{
		width: 82%;
		height: 9rem;
		margin: 0 auto;
		padding-top: 1rem;
		.imgWrap{
			width: 33%;
			float: right;
			margin: 0 0.4rem;
			margin-top: 0.6rem;
			img{
				width: 100%;
			}
		}
		.explainWrap{
			    width: 62%;
				float: left;
				.content{
					margin: 0.5rem 0;
					font-size: 0.3rem;
					text-align: left;
				}
				.imgList{
					.el-row{
						overflow: hiddesn;
						padding: 0.5rem 0;
						width: 89%;
						margin: 0 auto;
						display: flex;
						flex-wrap: wrap;
						.el-col {
							width: 16%;
							cursor: pointer;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-bottom: 0.8rem;
							.el-card {
								width: 3rem;
								height: 3rem;;
								border-radius: 0.15rem;
								box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
								.el-card__body {
									padding: 0px;
									color: #a4a4a4;
									font-size: 14px;
									display: flex;
									justify-content: center;
									align-items: center;
									flex-direction: column;
									width: 100%;
									height: 100%;
									a{
										color: #2c3e50;
									}
								}
							}
						}
					}
				}

		}
  }
  .bg{
	  height: 7rem;
	  background-size:cover!important;
  }
}
</style>
