<template>
  <div class="row_alwrap" :class="scaleShow?'':'alwrap_mini'">
    <div class="arrow_wrap" v-if="smallImg.length>0&&scaleShow" :class="isIpad?'arrow_ipad':''">
      <div class="arrow_line arleft_wrap">
        <div class="arrow arrow_left" @click="changeAdv(-1)"></div>
        <i class="i_left" @click="changeAdv(-1)"></i>
      </div>
      <div class="arrow_line arright_wrap">
        <div class="arrow arrow_right" @click="changeAdv(1)"></div>
        <i class="i_right" @click="changeAdv(1)"></i>
      </div>
    </div>
    <div class="row_content">
      <v-touch
      v-if="scaleShow"
        ref="advSwiper"
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        tag="div"
        :class="scaleShow?'adv_swiper':'adv_mini'"
      >
        <div  class="img_adv" v-for="(item,index) in smallImg" :key="index">
          <img :src="item.adPicture" />
          <a :href="item.adHref" target="_blank" :class="scaleShow?'a_max':'ascacl'"></a>
        </div>
      </v-touch>
      <swper :smallImg="smallImg" v-else></swper>
    </div>
  </div>
</template>

<script>
import swper from "../../components/swiper/swiper.vue";
export default {
  name: "storeAdv",
  props: ["isIpad"],
  components: {
    swper,
  },
  data() {
    return {
      scaleShow:false,
      smallImg: [],
      swipNum: 0,
      allcot: 0,
      swipcot: 0,
      swipcotMnin: 0,
      movee:0,//和swipNum区别是这个只有赋一次值，平移的长度
	    semove:0,//对于单数张产品图的第一张需要特殊处理
      nextpage:false,
      spRighttag:false,
    };
  },
/*   created() {
    this.getAdv();
  }, */
  mounted() {
    //等待dom渲染完毕再加载
    var that=this;
    this.$nextTick(() => {
      that.getAdv();
      that.commonSySize();
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      }
    });
  },
  methods: {
    commonSySize() {
      let idTag = document.body;
       if(this.$isphone()){this.scaleShow = false;}
      else{
      if (idTag.offsetWidth <= 1020) {
        this.scaleShow = false;
      } else {
        this.scaleShow = true;
      }
      }
      this.judgeWidth();
    },
    judgeWidth(){
      var that=this;
      if(!this.scaleShow){
        this.$nextTick(()=>{
          if (that.$refs.advSwiper.$el) {
             that.$refs.advSwiper.$el.style.width = that.allcot * 100 + "%";
          }
        })
      }
      else{
        if(this.allcot==3){
           this.$nextTick(()=>{
             if (that.$refs.advSwiper.$el) {
              that.$refs.advSwiper.$el.style.width = "100%";
             }
           }) 
        }
        else{
          this.$nextTick(()=>{
            if (that.$refs.advSwiper.$el) {
              that.$refs.advSwiper.$el.style.width = ((that.allcot-3) *33+100) + "%";
            }
          })
        }
      }
	  },
    changeAdv(e) {
      if(this.allcot>1){
      if (e > 0) {
      if (this.swipcot > 0&&!this.spRighttag) {
          // this.$refs.advSwiper.$el.style.transition= '0.5s';
          --this.swipcot;
          if (this.nextpage){
            this.smallImg.push(this.smallImg[0]);
            this.smallImg.splice(0,1);
          }
          else{
            this.swipNum -= (this.allcot * 2-3.5);
          }
        }
        else {
          /* var that = this;
          this.$msgboxx(that, "提示", "商品列表已显示完整"); */
          this.smallImg.push(this.smallImg[0]);
          this.smallImg.splice(0,1);
          this.nextpage=true;//开始回到第一个商品的标志
           var styleSheets = document.styleSheets[0];
        var styleindex = styleSheets.length;
        if(styleSheets.insertRule){
        styleSheets.insertRule(` @keyframes  pcAdvLeftTo${this.anmindex}{
            0%{
                transform:translateX(${(this.swipNum+5)}%);

            }
            50%{
                transform:translateX(${(this.swipNum+2)}%);

            }
            100%{
                transform:translateX(${this.swipNum}%);

            }
        }`, styleindex);
        }
        var that = this;
        this.$refs.advSwiper.$el.style.animation =`pcAdvLeftTo${this.anmindex} 1.5s ease 0s backwards running`;
        // that.$refs.advSwiper.$el.classList.add("miniLeft");
    　　setTimeout(function(){
        that.$refs.advSwiper.$el.style.animation ='none';
        //that.$refs.advSwiper.$el.classList.remove("miniLeft");
    　　},350);
          // this.$refs.advSwiper.$el.style.transition= '0s';
          // this.swipNum=0;
          // this.swipcot = this.allcot-5;
        }
      } else {
        if (!this.nextpage&&(this.swipNum!=0)){
            this.swipNum += (this.allcot * 2-3.5);
            // this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
            if (this.swipNum == 0) {
              //this.swipcot = Math.ceil(this.allcot/5)-1;这是算可以移动多少个
              this.swipcot = this.allcot-5;
            }
        }
        else{
          ++this.rightcot;
          if(this.rightcot>=(this.allcot-5)||this.swipNum==0){
            this.spRighttag=true;
            this.smallImg.unshift(this.smallImg[this.smallImg.length-1]);
            this.smallImg.splice(this.smallImg.length-1,1);
            this.swipNum=0;
            var styleSheets = document.styleSheets[0];
            var styleindex = styleSheets.length;
            if(styleSheets.insertRule){
            styleSheets.insertRule(` @keyframes  pcRightTo${this.anmindex}{
                0%{
                    transform:translateX(${(this.swipNum-5)}%);

                }
                50%{
                    transform:translateX(${(this.swipNum-2)}%);

                }
                100%{
                    transform:translateX(${this.swipNum}%);

                }
            }`, styleindex);
            }
            var that = this;
            this.$refs.advSwiper.$el.style.animation =`pcRightTo${this.anmindex} 1s ease 0s backwards running`;
            // that.$refs.advSwiper.$el.classList.add("miniLeft");
        　　setTimeout(function(){
            that.$refs.advSwiper.$el.style.animation ='none';
            //that.$refs.advSwiper.$el.classList.remove("miniLeft");
        　　},250);
          }
          else{
            this.swipNum+=(this.allcot * 2-3.5);
          }
        }
        ++this.swipcot;
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
      }
    },
    onSwipeLeft() {
      if(this.scaleShow==false){
         if (this.swipcotMnin > 0) {
           if (this.allcot % 2 == 0) {
             if(this.swipcotMnin==(this.allcot-1)){this.movee=(this.allcot * 100) / Math.pow(this.allcot,2);}
                this.swipNum += (this.allcot * 100) / Math.pow(this.allcot,2);
            }
            else {
                let val=parseInt((this.allcot * 100) / Math.pow(this.allcot,2));
                let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
                if(yal==0){
                  if(this.swipcotMnin==(this.allcot-1)){this.movee=val}
                          this.swipNum +=val;
                }
                else{
                  if(this.swipcotMnin==(this.allcot-1)){this.semove=(val+0.5);this.swipNum +=(val+0.5);}
                  else{
                    if(this.swipcotMnin%2!=0){this.swipNum +=(val);this.movee=val;}
                    else{this.swipNum +=(val+0.5);this.movee=val+0.5;}
                    }

                }
                    //只能对于3个：this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3);
                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 1)+3*(3-this.swipcotMnin));
                    /* console.log('右边移动：'+3*(3-this.swipcotMnin));
                    console.log('当前值：'+this.swipcotMnin); */

                    // this.swipNum += ((this.allcot * 100) / (Math.pow(this.allcot,2) + 3*(3-swipcotMnin))+2);
            }
        --this.swipcotMnin;
      } else {
        var that = this;
        this.$msgboxx(that, "提示", "广告已显示完整");
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
     }
    },
    onSwipeRight() {
      let yal=(this.allcot * 100) % Math.pow(this.allcot,2);
		  if(this.swipcotMnin==(this.allcot-2)&&yal!=0){
			  this.swipNum -=this.semove;
		  }
      else{
           if(this.swipcotMnin%2==0){this.swipNum -= this.movee;this.movee+=0.5;}
           else{this.swipNum -= this.movee;this.movee-=0.5;}
      }
      if (this.swipcotMnin<(this.allcot-1)) {
			this.swipcotMnin +=1;
      }
      this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      if (this.swipcotMnin == 0&&this.swipcotMnin<=this.allcot - 1) {
        this.swipcotMnin +=1;
      }
      this.$refs.advSwiper.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
    async getAdv() {
      var that = this;
      let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/adlist",{language: localStorage.getItem("locale")});
      that.smallImg.splice(0, 1);
      that.allcot = res.length;
      that.judgeWidth();
      that.swipcot = res.length - 3;
      that.swipcotMnin = res.length - 1;
      res.forEach((v, i) => {
        var obj = {
          adPicture: "",
          adHref: "",
          id: "",
        };
        this.$getSame(obj, res[i]);
        obj.adHref=obj.adHref;
        that.smallImg.push(obj);
      });
    },
  },
};
</script>

<style scoped lang="less">
.alwrap_mini {
  height: 7rem !important;
}
.row_alwrap {
  position: relative;
  /* height: 4.9rem;
  margin: 0.036458rem 0 0.109375rem 0; */
  .arrow_ipad{
      width: 126.5% !important;
      left: -13.2% !important;
    }
  .arrow_wrap {
    position: absolute;
    top: 37%;
    left: 0%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    .arrow_line {
      position: relative;

      .arrow {
        width: 0;
        height: 0;
        border-width: 0.9rem;
        border-style: solid;
        border-color: transparent #20baf3 transparent transparent;
        cursor: pointer;
      }

      .arrow_left {
        transform: rotate(0deg);
      }

      .arrow_right {
        transform: rotate(180deg);
      }

      i {
        /* flex: 5; */
        border: solid #ffffff;
        border-width: 0 0.1rem 0.1rem 0;
        display: inline-block;
        padding: 0.2rem;
        cursor: pointer;
      }

      i:hover {
        border: solid #5e5e5e;
        border-width: 0 0.1rem 0.1rem 0;
      }

      .i_right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: 37%;
      }

      .i_left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        position: absolute;
        right: -3.5%;
        top: 34%;
      }
    }
  }
.ascacl{
          width: 50%;
          height: 100%;
          position: absolute;
          left: 25%;
          top: 0;
        }
  .row_content {
    overflow: hidden;
    position: relative;
    z-index: 6;
    height: 100%;
    .adv_swiper {
      display: flex;
      justify-content: space-around;
      width: 134%;
      transition: all 0.5s ease-in-out;
      .img_adv {
        width: 33.3%;
        margin-left: 0.1rem;
        position: relative;
        .a_max{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
        img {
          width: 100%;
          height: 5.8rem;
          margin-left: 4px;
        }
        &:nth-child(1) {
          margin-left: 0px;
        }
      }
    }
    .adv_mini {
      display: flex;
      // justify-content: space-around;
      // width: 400%;
      transition: all 0.5s ease-in-out;
      height: 100%;
      .img_adv {
        width: 100%;
        height: 100%;
        img {
          width: 50%;
          height: 100%;
        }
      }
    }
  }
  .arleft_wrap{
      position: absolute;
      top: 50%;
      left: -1.7rem;
    }
    .arright_wrap{
      position: absolute;
      top: 50%;
      right: -1.7rem;
    }
}
</style>
