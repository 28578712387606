<template>
  <div class="content_all">
    <div class="topWrap">
      <img :src="bannerImg" class="staticBanner">
    </div>
    <div class="content_all">
      <!-- <banner :type="BannerType"></banner> -->
      <serve :sendData='item' :styleName='item.styleName' v-for="(item,index) in compoentList" :key="index"></serve>
    </div>
    <!--
    <div class="serveWrap" v-for="(item,index) in compoentList" :key="index" :class="index==1?'serveHeight':''">
      <serve :cooper=false :sendData='item' :styleName='item.styleName' style="padding-bottom:0rem;"></serve>
       <div class="RassText" v-if="index==0">
         <p>完整的产品线</p>
         <p>成熟的产品 丰富的开发经验</p>
         <p>专业的服务</p>
       </div>
       <div class="imgWrap" v-if="index==1">
         <img src="../../assets/u5.svg">
         <img src="../../assets/u5.svg">
       </div>
    </div>
    <div class="prize">
      <p class="altitle">荣誉奖项</p>
    </div>
    -->
    <div class="lichengWrap">
      <p class="altitle">{{$t('about.history')}}</p>
      <div class="licheng">
        <img src="../../assets/licheng.png">
      </div>
    </div>
  </div>
</template>
<script>
import serve from "../../components/customPart/serve.vue";
import axios from "../../api/axios";
export default {
  name: 'produceSeries',
  components: {
    serve
  },
  data () {
    return {
      bannerImg: require('../../assets/bner.png'),
      compoentList: [
        { styleName: "Rassemble",
          backGroud:"url("+require("../../assets/u473.png")+")",
          maxImg:require("../../assets/u5.svg"),
          title:"十五年用心积累，提供最可靠的服务",
          content:"锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。",
          productList:[
            {productImage:require("../../assets/1pro.png"),productName:"T1-BOX",productLink:"https://www.baidu.com/"},
            {productImage:require("../../assets/2pro.png"),productName:"T2-BOX",productLink:"https://www.baidu.com/"},
            {productImage:require("../../assets/3pro.png"),productName:"T3-BOX",productLink:"https://www.baidu.com/"}
          ]
        },
        { styleName: "introduce",
          title:"产品系列特点介绍",
          content:"锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域",
        }
      ],
    }
  },
  created () {},
  mounted () {
    this.getAboutReach()
    this.getBannerImg()
  },
  methods: {
    getAboutReach () {
      var url = '/reach/portal/api/about/abouReach'
      axios.post(url, {}, {
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.compoentList = response.data.data
        }
      })
    },
    getBannerImg () {
      var bannerType = 'aboutReach'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          this.bannerImg = response.data.data[0].adImage
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    margin-top: 1.5rem;
    position: relative;
    height: 240Px;
    // background: url(../../assets/bner.png) no-repeat;
    background-size: cover;
    .innerTitle{
      position: absolute;
      top:34%;
      left:50%;
    }
  }
  .serveWrap{
    position: relative;
    .RassText{
      position: absolute;
      bottom: 23%;
      left: 9%;
      font-size: 0.3rem;
      p{
        margin-bottom: 0.16rem;
      }
    }
    .imgWrap{
      position: absolute;
      bottom: 24%;
      left: 28%;
      display: flex;
      width: 40%;
      height: 40%;
      img{
        width: 50%;
        margin-right: 1rem;
      }
    }
  }
  .serveHeight{
    height: 9rem;
  }
  .prize{
    height: 7rem;
  }
  .lichengWrap{
      .licheng{
        padding: 2% 0 2% 13%;
    }
  }
}
</style>
