<template>
  <div class="content_all">
    <banner :type="BannerType" :menuId="menuId" :key="menuId"></banner>
    <!-- <pre>
      <a :href="item.hreff" :class="index==curIndex?'active':''"
       v-for="(item,index) in tabList" :key="index" @click="change(index)">   {{item.name}}  <span v-show="index!==(tabList.length-1)">|</span></a>
    </pre> -->
    <serve :sendData='item' :styleName='item.styleName' v-for="(item,index) in compoentList" :key="index"></serve>
  </div>
</template>
<script>
import banner from '../../components/homePages/banner.vue'
import serve from '../../components/customPart/serve.vue'
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  components: {
    banner,
    serve
  },
  data () {
    return {
      curIndex:0,
      BannerType: 'solution',
      menuId: '',
      tabList:[
        {
        name:'方案介绍',
        hreff:'#Lassemble'
        },
        {
        name:'适用产品',
        hreff:'#bottomImg'
        },
        {
        name:'案例',
        hreff:'#produceList'
        },
        {
        name:'服务',
        hreff:'#download'
        }
        ],
      compoentList: [
        {
          styleName: 'Lassemble',
          backGroud: '',
          maxImg: require('../../assets/ceshi2.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          productList: [
            { productImage: require('../../assets/3pro.png'), productName: 'T1-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/2pro.png'), productName: 'T2-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/1pro.png'), productName: 'T3-BOX', productLink: 'https://www.baidu.com/' }
          ]
        },
        {
          styleName: 'Rassemble',
          backGroud: '',
          maxImg: require('../../assets/ceshi2.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          productList: [
            { productImage: require('../../assets/1pro.png'), productName: 'T1-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/2pro.png'), productName: 'T2-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/3pro.png'), productName: 'T3-BOX', productLink: 'https://www.baidu.com/' }
          ]
        },
        {
          styleName: 'Lassemble',
          backGroud: '',
          maxImg: require('../../assets/ceshi2.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          productList: [
            { productImage: require('../../assets/3pro.png'), productName: 'T1-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/2pro.png'), productName: 'T2-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/1pro.png'), productName: 'T3-BOX', productLink: 'https://www.baidu.com/' }
          ]
        },
        {
          styleName: 'Rassemble',
          backGroud: '',
          maxImg: require('../../assets/ceshi2.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          productList: [
            { productImage: require('../../assets/1pro.png'), productName: 'T1-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/2pro.png'), productName: 'T2-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/3pro.png'), productName: 'T3-BOX', productLink: 'https://www.baidu.com/' }
          ]
        },
        {
          styleName: 'Lassemble',
          backGroud: '',
          maxImg: require('../../assets/ceshi2.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          productList: [
            { productImage: require('../../assets/3pro.png'), productName: 'T1-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/2pro.png'), productName: 'T2-BOX', productLink: 'https://www.baidu.com/' },
            { productImage: require('../../assets/1pro.png'), productName: 'T3-BOX', productLink: 'https://www.baidu.com/' }
          ]
        },
        {
          styleName: 'bottomImg',
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          imgList: [
            {
              productLink: 'https://www.baidu.com/s?wd=1&ie=UTF-8',
              productImage: require('../../assets/u382.png'),
              productName: 'CN-M50'
            },
            {
              productLink: 'https://www.baidu.com/s?wd=1&ie=UTF-8',
              productImage: require('../../assets/u382.png'),
              productName: 'CN-M50'
            },
            {
              productLink: 'https://www.baidu.com/s?wd=1&ie=UTF-8',
              productImage: require('../../assets/u382.png'),
              productName: 'CN-M50'
            },
            {
              productLink: 'https://www.baidu.com/s?wd=1&ie=UTF-8',
              productImage: require('../../assets/u382.png'),
              productName: 'CN-M50'
            },
            {
              productLink: 'https://www.baidu.com/s?wd=1&ie=UTF-8',
              productImage: require('../../assets/u382.png'),
              productName: 'CN-M50'
            }
          ]
        },
        {  styleName: "produceList",
				   backGroud:"",
				   title:"锐骐助力您的行业实现AIoT赋能",
				   subTitle:"锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌",
				   productList:[
					   {productImage:require("../../assets/3pro.png"),productName:"T1-BOX",productLink:"https://www.baidu.com/"},
					   {productImage:require("../../assets/2pro.png"),productName:"T2-BOX",productLink:"https://www.baidu.com/"},
					   {productImage:require("../../assets/1pro.png"),productName:"T3-BOX",productLink:"https://www.baidu.com/"},
					   {productImage:require("../../assets/2pro.png"),productName:"T4-BOX",productLink:"https://www.baidu.com/"},
					   {productImage:require("../../assets/1pro.png"),productName:"T5-BOX",productLink:"https://www.baidu.com/"},
					   {productImage:require("../../assets/1pro.png"),productName:"T6-BOX",productLink:"https://www.baidu.com/"},
					   {productImage:require("../../assets/4pro.png"),productName:"T7-BOX",productLink:"https://www.baidu.com/"},
				   ],
        },
        {
          styleName: 'download',
          button: '了解更多  >>',
          title: '选择您感兴趣的数据服务',
          dataFileList: [
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            },
            {
              dataImage: require('../../assets/u252.svg'),
              dataTitle: '智能表计报告',
              downLink: 'https://www.baidu.com/'
            }
          ]

        },
        {
          styleName: 'LbntImg',
          button: '#1fbaf3',
          buttonLink: 'https://www.baidu.com/',
          backGroud: '',
          maxImg: require('../../assets/saas.png'),
          title: '智能表计应用说明',
          content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域。',
          produceData: ''
        }
      ]
    }
  },
  watch: {
     $route: {
          handler(val,oldVal) {
            this.getSolutionList()
            this.menuId = this.$route.query.solutionTypeId
          },
        deep: true
    }
  },
  created () {
    this.menuId = this.$route.query.solutionTypeId
  },
  mounted () {
    this.getSolutionList()
  },
  methods: {
    getSolutionList () {
      var solutionTypeId = this.$route.query.solutionTypeId
      var url = '/reach/portal/api/module/getSolutionList'
      axios.post(url, {}, {
        params: {
          solutionTypeId: localStorage.getItem('solutionTypeId'),
          language: localStorage.getItem('locale')
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.compoentList = response.data.data
        }
      })
    },
    change(index){
        this.curIndex=index
    }
  }
}
</script>
<style  scoped>
.heart .el-col-8 {
  width: 18.33333%;
}

.content_all .el-button--primary {
  width: 2.8rem;
  height: 0.63rem;
  line-height: 0.4rem;
  border-radius: 0.15rem;
  background-color: rgba(31, 186, 243, 1);
}

.product_row {
  padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boderCol:after {
  width: 3.22rem;
  height: 0.1rem;
  background-color: #ffde5d;
  content: "";
  position: absolute;
  left: -0.067708rem;
  top: 3.24rem;
  z-index: 10;
}

.product_row .el-card {
  width: 3rem;
  height: 3rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
  padding: 0px;
  color: #a4a4a4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.news_heart p {
  padding: 15px 0 19px 0;
}

.news_heart .el-card {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.news_heart .el-card__body {
  padding: 0;
}

.new_indro {
  padding: 0 8px 0 10px;
  text-align: left;
}

.news_heart .new_indro span {
  font-size: 0.25rem;
  color: #827c7c;
}

.news_heart .new_indro .news_title {
  display: block;
  font-weight: 600;
  font-size: 0.28rem;
  color: #3c3b3c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.1rem;
}

.news_heart img {
  width: 100%;
}
</style>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 10px;
  // position: relative;
  pre{
    a{
      color: #abb3b6;
      font-size: 0.3rem;
      cursor: pointer;
    }
    .active{
      color: #45bdf1;
      font-weight: 600;
      border-bottom: 1px #4fbff0 solid;
    }
  }
}

.lunch_wrap {
  width: 100%;
  background-color: #1fbaf3;
  margin-top: 1.5rem;
}

.lunch_top {
  width: 16rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.6rem;
}

.lunch_top p {
  text-align: left;
  flex: 1;
  font-size: 0.24rem;
  color: #ffffff;
}

.lunch_top p:nth-child(2) {
  flex: 1.4;
}

.lunch_top p:nth-child(2) {
  flex: 1.5;
  text-align: center;
}
.lunch_bottom {
  .wh_slide {
    height: auto;
  }
  /deep/ .wh_indicator {
    bottom: 0.2rem;
    .wh_indicator_item {
      width: 1rem;
      height: 0.08rem;
      margin: 0rem 0.1rem;
      background: #fff;
      border-radius: 0;
    }
    .wh_show_bgcolor {
      background: #efcd5c;
    }
  }
}
</style>
