<template>
  <div class="LRWrap">
    <div class="LRinner" :style="[{background:getData.backGroud}]">
      <div class="imgWrap"><img :src="getData.maxImg" alt=""></div>
      <div class="explainWrap">
        <p class="altitle titl">{{getData.title}}</p>
        <p class="content">{{getData.content}}</p>
        <div class="btnWrap">
          <el-button :style="[{background:getData.button}]"><a :href="getData.buttonLink" target="_blank">{{getData.button}}</a>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LbntImg',
  props: ['getData'],
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style scoped lang="less">
.LRWrap{
	.LRinner{
		width: 82%;
		height: 6rem;
		margin: 0 auto;
		padding-top: 1rem;
		.imgWrap{
			width: 33%;
			float: left;
			margin: 0 0.4rem;
			img{
				width: 100%;
			}
		}
		.explainWrap{
			    width: 62%;
				float: left;
				.titl{
					text-align: left;
					margin-top: 0;
				}
				.content{
					line-height: 0.6rem;
					margin: 0.4rem 0 1.2rem 0;
					font-size: 0.3rem;
					text-align: left;
				}
				.btnWrap{
					text-align: right;
					.el-button{
						background: #3ac0f1;
						line-height: 0.6rem;
						padding: 0 0.3rem;
						font-size: 0.28rem;
						border-radius: 0.15rem;
						a{
							color: #fff;
						}
				    }
				}
				.imgList{
					.el-row{
						overflow: hiddesn;
						padding: 0.5rem 0;
						width: 89%;
						margin: 0 auto;
						display: flex;
						flex-wrap: wrap;
						.el-col {
							width: 16%;
							cursor: pointer;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-bottom: 0.8rem;
							.el-card {
								width: 3rem;
								height: 3rem;;
								border-radius: 0.15rem;
								box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
								.el-card__body {
									padding: 0px;
									color: #a4a4a4;
									font-size: 14px;
									display: flex;
									justify-content: center;
									align-items: center;
									flex-direction: column;
									width: 100%;
									height: 100%;
									a{
										color: #2c3e50;
									}
								}
							}
						}
					}
				}

		}
  }
}
</style>
