<template>
  <div class="store_wrap" :class="!scaleShow?'storeWrap_mini':ipadpro?'store_ipad':''">
    <div class="store_inner">
      <Storebaner :scaleShow="scaleShow"></Storebaner>
      <storeList :scaleShow="scaleShow" :isIpad="ipadpro" :stList="sendStlist"></storeList>
      <storeAdv :scaleShow="scaleShow" :isIpad="ipadpro"></storeAdv>
      <div v-for="(v,i) in sendmoz" :key="i">
        <storeRow :recmoz="v" :isEven="i%2==0?true:false"  :isIpad="ipadpro" :anmindex="i"></storeRow>
      </div>
      <div v-for="(v2,i2) in sendbl" :key="'info2-'+i2">
        <storeBlurow :rebl="v2" :scaleShow="scaleShow" :anmindex="i2"></storeBlurow>
      </div>
      <storeSearch></storeSearch>
    </div>
  </div>
</template>

<script>
import Storebaner from "../../components/storeBaner/storeBaner.vue";
import storeRow from "../../components/storeRow/storeRow.vue";
import storeBlurow from "../../components/store_bluRow/store_bluRow.vue";
import storeSearch from "../../components/storeSearch/storeSearch.vue";
import storeAdv from "../../components/storeAdv/storeAdv.vue";
import storeList from "../../components/storeList/storeList.vue";

export default {
  components: {
    Storebaner,
    storeRow,
    storeBlurow,
    storeSearch,
    storeAdv,
    storeList,
  },
  data() {
    return {
      sendmoz: [],
      sendbl: [],
      sendStlist: [],
      scaleShow: true,
      ipadpro:false
    };
  },
  //必须放在mounted中，因为放在created中未获取dom节点 
  mounted() {
    //等待dom渲染完毕再加载
    var that=this;
    this.$nextTick(() => {
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        that.commonSySize();
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      } else {

      }
    });
  },
   // 监听,当路由发生变化的时候执行
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.commonSySize();
      },
      // 深度观察监听
      deep: true,
      immediate: true, 
    }
  },
  methods: {
    //公共使用控制页面大小收缩
    commonSySize() {
      let idTag = document.body;
      if (idTag.offsetWidth <= 1280) {
        this.scaleShow = false;
      } else {
        this.scaleShow = true;
      }
      if (idTag.offsetWidth >= 809) {
      }
      if(new RegExp("ipad","i").test(window.navigator.userAgent)){//获取代理头信息
        this.ipadpro=true;
      }
      else{
        this.ipadpro=false;
      }
    },
     async checkToken(){
       var that=this;
       var usertoken=window.localStorage.getItem('usertoken')||'';
       if(usertoken==''){
        /*  this.$confirm('尚未登录，请问是否前往登录', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        that.$router.push({ path: "/loginn" });
      })
      .catch(() => {}) */
       }
       else{
       var obj = {
        eshoppingtoken: usertoken
      };
      var parm=this.$qs.stringify(obj);  
       let res = await this.$Net.post(
            "/f/eshopping/eshoppinglogin/tokencheck",
            parm
          );
          switch (res.data.statuscode) {
            case 0:
              window.localStorage.setItem('curretUser', res.data.username);
              break;
            case 1:
              that.$msgboxx(that, "提示", "登陆已失效");
              setTimeout(function()  {
                that.$router.push({ path: "/loginn" });
              }, 2000);
              break;
            case 2:
              that.$msgboxx(that, "提示", "登陆已失效");
              setTimeout(function()  {
                that.$router.push({ path: "/loginn" });
              }, 2000);
              break;
            case 3:
              that.$msgboxx(that, "提示", "登陆已失效");
              break;
            default:
              break;
          }
          }
     },
     async getModeList(){
       var that=this;
       let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/ModeList",{ language: localStorage.getItem("locale")});
          res.forEach((v, i) => {
          var obj = {
            modeType: "",
            id: "",
            createBy: "",
            modeName: "",
            updateBy: "",
            modeid: "",
          };
          that.$getSame(obj, res[i]);
          if (v.modeType == "0") {
            that.sendmoz.push(obj);
          } 
          else if (v.modeType == "1") {
            that.sendbl.push(obj);
          }
          else {
             let objj = {
            modeName: obj.modeName,
						modeid:obj.id,
        };
            that.sendStlist.push(objj);
          }
        });
    }
  },
  created() {
    this.getModeList();
    // this.checkToken();
  }
};
</script>

<style scoped lang="less">
.storeWrap_mini{
  margin-top: 1rem!important;
  .store_inner {
    width: 100%!important;
  }
}
.store_ipad{
  margin-top: 0rem!important;
}
.store_wrap {
  width: 100%;
  background-color: #ebebeb;
  margin-top: 1rem;
  .store_inner {
    width: 71%;
    margin: 0 auto;
  }
  
}
</style>
