<template>
  <div class="adrss_wrap">
    <el-dialog v-loading="loading" :title="!wechart ? $t('Cart.method') : $t('Cart.WeChat')"
      :visible.sync="dialogVisible" width="70%" @close='closeDialog' :append-to-body="false" class="login_box">
      <div class="imgWrap" v-if="!wechart">
        <!-- 2024年5月7日 隐藏支付宝支付 -->
        <!-- <img src="../../assets/zhifub.png" @click="choseZhi"> -->
        <img src="../../assets/wechatLogo.png" @click="choseWec">
      </div>
      <div id="qrCode" ref="qrCodeDiv" :class="!wechart ? 'specialQrCode' : ''"></div>
      <span class="dialog-footer">
        <!-- 2024年5月7日 隐藏支付宝支付 -->
        <!-- <el-button type="primary" @click="choseZhi()" v-show="wechart">{{ $t('Cart.Alipay') }}</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../../api/axios'
import QRCode from 'qrcodejs2';
import Cookies from 'js-cookie'
export default {
  name: "choosePay",
  inject: ["reload"],
  data() {
    const comfadress = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('modify.shipping')));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      payStatus: "",
      code_url: "",
      whichChose: "",
      isEmpty: false,
      tradeState: "",
      mchId: "",
      wechart: false,
      whichCheck: 1,
      currentAd: -1,//选中的地址id
      currentAdname: '',//选中的地址全称
      edit: true,
      dialogVisible: false,
      adressList: [],
      adressObj: {},
      elFormmm: { adressList: [] }
    };
  },
  props: ["total", "numId"],
  methods: {
    closeDialog() {
      this.reload();
      this.dialogVisible = false;
      this.wechart = false;
      this.$emit('update:sechShow', false);
    },
    cancel() {
      this.closeDialog();
    },
    // 检查支付状态
    async checkStatus() {
      for (let i = 0; i < 300; i++) {
        await axios.post("/f/eshopping/eshoppingModeAndProduct/wxcheck?out_trade_no=" + this.numId)
          .then(res => {
            this.payStatus = res.data;
            if (res.data == "1") {
              this.$router.push({ path: "/payView" });
            }
          }).catch((err) => {
            console.log('',err);
          });
        if (this.payStatus == "1") {
          break;
        }
        this.sleep(1000);
      }
    },
    sleep(n) {
      var start = new Date().getTime();//定义起始时间的毫秒数
      while (true) {
        var time = new Date().getTime();//每次执行循环取得一次当前时间的毫秒数
        if (time - start > n) {//如果当前时间的毫秒数减去起始时间的毫秒数大于给定的毫秒数，即结束循环
          break;
        }
      }
    },
    async getQrCode() {
      this.$nextTick(function () {
        let ti = this.total * 100;
        var obj = {
          out_trade_no: this.numId,
          total_fee: parseInt(ti),
          body: "锐骐物联支付",
          product_id: this.numId
        }
        axios.post("/f/eshopping/eshoppingModeAndProduct/WXPAY", obj)
          .then(res => {
            this.code_url = res.data.code_url;
            this.mchId = res.data.mch_id;
            this.creatQrCode();
            this.checkStatus();
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            console.log(err);
          });
      })
    },
    creatQrCode() {
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.code_url,
        width: 150,
        height: 150,
        colorDark: "#333333",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L
      })
    },
    async choseZhi() {
      await this.$parent.send();
      this.$parent.saveOrder(this.$t('Cart.Alipay2'));
    },
    async choseWec() {
      this.loading = true;
      await this.$parent.sendwx();
      this.$parent.saveOrder(this.$t('Cart.WeChat2'));
      this.checkToken(1)
    },
    checkToken(parm) {
      var url = '/reach/portal/api/user/getUserInfo'
      axios.get(url).then(response => {
        if (response.data.code == 2) {
          this.loading = false;
          this.dialogVisible = false;
          this.$my_confirm(this.$t('Cart.expired'), this.$t('login.prompt'), {
            type: "warn",
            cancelBtnText: this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => {
              if (action == "yes") {
                this.$router.push({ path: "/loginn" });
              } else if (action == "other") { }
            })
            .catch(() => { });
          Cookies.set('token', null)
          window.localStorage.setItem('curretUser', '')
          window.localStorage.setItem('usertoken', '')
          this.reload();
        }
        else {
          if (parm == 1) {
            this.getQrCode();
            this.wechart = true;
          } else {
            this.loading = false;
          }

        }
      })
    },
  },
  created() {
    this.checkToken()
  },
}
</script>
<style lang="less" scoped>
#qrCode {
  margin: 0.2rem 0;

  /deep/ img {
    margin: 0 auto;
  }
}

.specialQrCode {
  /deep/ img {
    display: none !important;
  }
}

.login_container {
  // background-color: #2b4b6b;
  // background-image: linear-gradient(to right , #2b4b6b, skyblue);
  // filter: blur(20px);
  height: 10rem;
  padding-top: 9.5rem;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #2b4b6b, skyblue);
  // filter: blur(20Px);
}

.login_box {
  max-height: 11.2rem;
  width: 10rem;
  height: fit-content;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%)
}

.avatar_box {
  width: 80Px;
  height: 80Px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 64%;
    height: 69%;
    //   border-radius: 50%;
    background-color: #eee;
  }
}

.login_form {
  /* position: absolute;
    bottom: 5%; */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  // padding-top: 1rem;
}

.btns {
  display: flex;
  justify-content: center;
}

.login_box /deep/ .el-form-item__content {
  display: flex;
}

.login_box /deep/ .el-checkbox__inner {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.12rem;
}

.login_box /deep/ .el-checkbox__inner::after {
  border: 0.09rem solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 0.5rem;
  left: 0.33rem;
  top: 0.005208rem;
  width: 0.2rem;
}

.login_box /deep/ .el-input__inner {
  height: 0.9rem;
  line-height: 0.4rem;
  width: 98%;
  padding-left: 0.8rem;
}

.login_box /deep/ .el-form-item {
  margin-bottom: 0.35rem;
}

.login_box /deep/ .el-form-item__error {
  left: 4.5%;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  // width: 1rem;
  // height: 0.5rem;
  // display: block;
  // margin: 0 auto
}

.login_box /deep/ .el-dialog__title {
  font-size: 20Px;
  font-weight: 600;
}

.login_box /deep/ .el-dialog {
  margin-top: 0.5rem !important;
}

.adress_row {
  position: relative;

  .adress_edit {
    position: absolute;
    right: 3%;
    top: 33%;
    font-size: 17Px;
    cursor: pointer;
    display: none;

    i {
      margin-right: 0.15rem;

      &:hover {
        color: #1fbaf3;
      }
    }
  }

  &:hover {
    .adress_edit {
      display: block;
    }
  }
}

.toload {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 17Px;
  line-height: 2rem;
}

.el-radio-group {
  margin-bottom: 0.3rem;

  /deep/ .el-radio {
    width: 8.3rem;
    margin-bottom: 0.25rem;

    .el-radio__input {
      margin-bottom: 0.5rem;

      .el-radio__inner {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.1rem;

        &::after {
          width: 0rem;
          height: 0rem;
        }
      }
    }

    .el-radio__label {
      font-size: 0.6rem;

      .el-textarea {
        .el-textarea__inner {
          min-height: 1.5rem !important;
          color: #5c5a5a;
          font-size: 0.25rem;
        }
      }
    }
  }
}

.imgWrap {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-around;

  img {
    width: 2rem;
    height: 2.2rem;
    cursor: pointer;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;

  .el-button {
    // width: 1.7rem;
    // height: 0.6rem;
  }
}
</style>
