<template>
  <div>
    <v-touch
      ref="swiprer"
      v-on:swipeleft="onSwipeLeft"
      v-on:swiperight="onSwipeRight"
      tag="ul"
      :class="scaleShow?'stroList':'stroList_mini'"
    >
      <li
        v-for="(item,index) in listNamee"
        :key="index"
        :class="index==2||index==5?'hot':''"
        @click="toSerch(item.modeid,item.modeName)"
      >{{item.modeName}}</li>
    </v-touch>
    <serchView ref="searchbox" v-if="serboxShow" :sechShow.sync="serboxShow" :isstList=true></serchView>
  </div>
</template>
<script>
import serchView from "../serchView/serchView.vue";
export default {
  name: "storeList",
  props: ["isIpad","stList"],
  components: {
			serchView
		},
  data() {
    return {
      listNamee: [
        "CAT.1 智能模组",
        "LoRaWAN 模组",
        "NB-IOT 模组",
        "智能水表",
        "智能电表",
        "车载T-BOX",
        "智能燃气表",
        "烟雾传感器",
        "LoRa网关",
        "车载后视镜",
      ],
      swipNum: 0,
      scaleShow:false,
      serboxShow: false
    };
  },
  created() {
    this.listNamee=[];
    this.listNamee=this.stList;
  },
  mounted() {
    var that=this;
    that.commonSySize();
    this.$nextTick(() => {
      var p = navigator.platform;
      let mac = p.indexOf("Mac") === 0;
      let x11 = p === "X11" || p.indexOf("Linux") === 0;
      //判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        //监听缩放
        window.addEventListener("resize", () => {
          that.commonSySize();
        });
        //监听页面加载
        window.addEventListener("load", () => {
          that.commonSySize();
        });
      } else {
      }
    });
  },
  methods: {
    //公共使用控制页面大小收缩
    commonSySize() {
      let idTag = document.body;
       if(this.$isphone()){this.scaleShow = false;}
      else{
        if (idTag.offsetWidth <= 1020) {
          this.scaleShow = false;
        } else {
          this.scaleShow = true;
        }
      }
    },
    onSwipeLeft() {
      if (this.swipNum == 70) {
        var that=this;
        this.$msgboxx(that,"提示","菜单已显示完整");
      } else {
        this.swipNum += 10;
        this.$refs.swiprer.$el.style.transform =
          "translateX(-" + this.swipNum + "%)";
      }
    },
    onSwipeRight() {
      this.swipNum -= 10;
      // console.log("right"+"translateX(-" + this.swipNum + "%)");
      this.swipNum = this.swipNum < 0 ? 0 : this.swipNum;
      this.$refs.swiprer.$el.style.transform =
        "translateX(-" + this.swipNum + "%)";
    },
    toSerch(modeid,modeName){
        this.serboxShow = true;
				var that = this;
				this.$nextTick(() => {
					that.$refs.searchbox.getlistSearch(modeid,modeName);
				})
    }
  },
  watch: {
    isIpad: {
      handler(newName, oldName) {
        switch(newName){
            case true:
              // console.log('stroList_ipad');
              this.$refs.swiprer.$el.classList.add('stroList_ipad')
              break
            case false:
              this.$refs.swiprer.$el.classList.remove('stroList_ipad')
              break
            default:
              break
        }
      }
    }
  }
}
</script>
<style lang="less">
  .el-message-box{
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 9.2rem!important;
  p{
    font-size:27Px;
  }
  .el-message-box__title {
    text-align: center;
    font-size: 27Px;
    letter-spacing: 16Px;
}
.el-message-box__headerbtn .el-message-box__close{
  font-size: 29Px!important;
}
  .el-message-box__message{
      margin: 0;
      height: 3rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
  }
}
</style>
<style scoped lang="less">
.stroList {
  background-color: #ffffff;
  height: 1rem;
  margin: 0.15rem 0;
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;

  li {
    flex:1;
    cursor: pointer;
    font-size: 13Px;
    color: #373737;
    font-family: "Arial Normal", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    // display: inline-block;
    /* width: 1.1rem; */
    height: 37%;
    border-right: 0.01rem solid #373737;
    text-align: center;
    margin: auto;
    padding-right: 1.5%;
    position: relative;
    transform: scale(0.98);
  }

  li:last-child {
    border-right: none;
  }
  .hot:after {
    content: "HOT!";
    position: absolute;
    border: 6px solid transparent;
    top: -0.28rem;
    right: -0.14rem;
    font-family: "Arial Black", "Arial Normal", "Arial", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 12Px;
    color: #fd0000;
    transform: scale(0.75);
  }
}
.stroList_ipad{
  height:0.5rem;
    .hot:after {
      top: -0.16rem!important;
      right: -0.06rem!important;
    }
}
.stroList_mini {
  background-color: #ffffff;
  height: 1.45rem;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  width: 335%; // 超过百分百才能扩大每个li的宽
  transition: all 0.5s ease-in-out;
  li {
    font-size: 0.4rem;
    color: #373737;
    font-family: "Arial Normal", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    width: 33.3%;
    // height: 22%;
    border-right: 0.01rem solid #373737;
    text-align: center;
    margin: auto;
    // padding: 0 0.06rem 0 0.06rem;
    position: relative;
  }
  .hot:after {
    content: "HOT!";
    position: absolute;
    border: 0.06rem solid transparent;
    top: -63%;
    right: 0.9rem;
    font-family: "Arial Black", "Arial Normal", "Arial", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 0.2rem;
    color: #fd0000;
  }
}

</style>
