<template>
  <div class="content_all">
    <div class="topWrap">
          <img :src="bannerImg" class="staticBanner">
          <p class="altitle innerTitle">{{$t('about.Center')}}</p>
    </div>
    <div class="content_inner">
        <div class="inputWrap">
          <el-input v-model="input" :placeholder="$t('about.Search')">
            <i slot="suffix" class="el-icon-search" @click="search"></i>
          </el-input>
        </div>
        <div class="newsRow">
            <el-row justify="space-space" type="flex" v-for="(item,index) in newsData.newsList" :key="index">
              <a :href="item.newsLink" target="_blank">
                <el-col><span class="title">{{item.title}}</span><span class="time">{{item.createDate}}</span></el-col>
              </a>
            </el-row>
        </div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :prev-text="$t('help.Previous')"
            :next-text="$t('help.Next')"
            layout="prev, pager, next"
            :page-size="6"
            :total="newsData.total">
          </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  data () {
    return {
      bannerImg: require('../../assets/vedioBack.png'),
      currentPage: 1,
      input: '',
      newsData: {
        total: 30,
        newsList: [
          {
            title: '今天',
            createDate: '2021-11-01'
          },
          {
            title: '明天',
            createDate: '2021-11-02'
          },
          {
            title: '明天2',
            createDate: '2021-11-02'
          },
          {
            title: '明天3',
            createDate: '2021-11-02'
          },
          {
            title: '明天4',
            createDate: '2021-11-02'
          }
        ]
      }
    }
  },
  created () {},
  mounted () {
    this.getNewsList()
    this.getBannerImg()
  },
  methods: {
    search () {
      this.getNewsList()
    },
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getNewsList()
    },
    getNewsList () {
      var url = '/reach/portal/api/about/getNewsList'
      axios.get(url, {
        params: {
          pageIndex: this.currentPage,
          pageSize: 6,
          keyWord: this.input,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          var newsList = response.data.data.newsList
          newsList.forEach(item => {
            item.createDate = this.$timestampToTime(item.createDate)
          })
          this.newsData.newsList = newsList
          this.newsData.total = response.data.data.total
        }
      })
    },
    getBannerImg () {
      var bannerType = 'news'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          if(response.data.data.length>0){
            this.bannerImg = response.data.data[0].adImage
          }
        }
      })
    }
  }
}
</script>
<style  scoped>
.heart .el-col-8 {
  width: 18.33333%;
}

.content_all .el-button--primary {
  width: 2.8rem;
  height: 0.63rem;
  line-height: 0.4rem;
  border-radius: 0.15rem;
  background-color: rgba(31, 186, 243, 1);
}

.product_row {
  padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boderCol:after {
  width: 3.22rem;
  height: 0.1rem;
  background-color: #ffde5d;
  content: "";
  position: absolute;
  left: -0.067708rem;
  top: 3.24rem;
  z-index: 10;
}

.product_row .el-card {
  width: 3rem;
  height: 3rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
  padding: 0px;
  color: #a4a4a4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.news_heart p {
  padding: 15px 0 19px 0;
}

.news_heart .el-card {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.news_heart .el-card__body {
  padding: 0;
}

.new_indro {
  padding: 0 8px 0 10px;
  text-align: left;
}

.news_heart .new_indro span {
  font-size: 0.25rem;
  color: #827c7c;
}

.news_heart .new_indro .news_title {
  display: block;
  font-weight: 600;
  font-size: 0.28rem;
  color: #3c3b3c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.1rem;
}

.news_heart img {
  width: 100%;
}
</style>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    margin-top: 1.5rem;
    position: relative;
    height: 240Px;
    // background: url(../../assets/vedioBack.png) no-repeat;
    background-size: cover;
    .innerTitle{
      position: absolute;
      top:34%;
      left:50%;
    }
  }
  .content_inner{
      width: 70%;
      margin: 0 auto;
      .newsRow{
            border: 1px #cfd7da solid;
            border-left: none;
            border-right: none;
        /deep/ .el-row{
            font-size: 0.3rem;
            background-color: #f0f8fa;
            height: 0.8rem;
            line-height: 0.8rem;

            a{
              color: #333;
              width: 98%;

            }
            .el-col{
                 display: flex;
            justify-content: space-around;
            .title{
              width: 65%;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .time{
              width: 20%;
            }
               // text-align: left;
              //  padding-left: 2rem;
                &:nth-child(2n){
               //     text-align: right;
                //    padding-right: 2rem;
                }
            }
            &:nth-child(2n){
                background-color: #cee1e7;
            }
         }
        }
        /deep/ .el-pagination
        {
          padding-top: 1rem;
          button{
            border: 0.03rem #b9bfc1 solid;
            padding: 0.1rem;
            color: #39a9d9;
            display: inline-block;
            font-size: 0.3rem;
            min-width: 0.6rem;
            height: 0.7rem;
          }
          .btn-prev{
            border-radius: 0.1rem 0 0 0.1rem;
          }
          .btn-next{
            border-left: none;
            border-radius: 0 0.1rem 0.1rem 0;
          }
          .el-pager li{
              padding: 0 0.25rem;
              border: 0.03rem #b9bfc1 solid;
              border-left: none;
              font-size: 0.3rem;
              height: 0.7rem;
              line-height: 0.7rem;
          }
          .el-pager li.active{
              color: #fff;
              background-color: #32a6d8;
          }
        }
      .inputWrap{
        height: 1rem;
        padding-top: 0.2rem;
        /deep/ .el-input{
           .el-input__inner{
            height: 0.6rem;
            width: 21%;
            float: right;
          }
          .el-input__suffix{
            font-size: 0.3rem;
            right: 0.2rem;
            top: 0.1rem;
          }
        }
      }
  }
}
</style>
