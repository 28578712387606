<template>
	<div ref="rowColl" class="col_wrap" @click="toDeial()" :class="scaleShow?(bluerow?'blueCol_wrap':''):'colWrap_mini'">
		<div class="col_content">
			<el-row class="img_wrap"><img :src="reGoods.productPicture" /></el-row>
			<div class="col_text">
				<el-row>{{reGoods.productName==''?'未定义商品名':reGoods.productName}}</el-row>
				<el-row>{{reGoods.modeName}}</el-row>
				<el-row>
					<el-rate allow-half v-model="reGoods.value" @change="evtStar"></el-rate>
				</el-row>
				<el-row class="oldcot" v-if="reGoods.productOldPrice>reGoods.productPrice">￥{{reGoods.productOldPrice==undefined?'未知原价':reGoods.productOldPrice}}</el-row>
				<el-row class="newcot">￥{{reGoods.productPrice==undefined?'未知现价':reGoods.productPrice}}</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "storeCol",
		data() {
			return {
				value1: 4.5,
				colData: {
					title: 'NB-IOT智能模组',
					type: 'CNM50',
					value: 4.5,
					oldcot: '¥ 99.00',
					newcot: '¥ 66.00'
				}
			}
		},
		props: ["reGoods",'scaleShow','bluerow','isOne'],
		methods: {
			evtStar(e) {
				//console.log(e)
				// this.$emit('update:value', e);
				this.$emit('update:valll', e);
			},
			toDeial(){
				// console.log(this.reGoods.id)
				this.$router.push({name:'goodsDeil',query: {id:this.reGoods.id,modeId:this.reGoods.modeId}})
			},
			oneCol(){
         var that = this;
			 this.$nextTick(()=>{
                that.$refs.rowColl.classList.add("trans");
			})

			}
		},
		mounted() {
			
		}

	}
</script>

<style>
	.col_text .el-row {
		font-family: 'Arial Normal', 'Arial', sans-serif;
		font-weight: 400;
		font-style: normal;
		font-size: 14Px;
		color: #333333;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.col_text .el-rate {
		transform: scale(0.9);
	}
</style>
<style scoped>
	.col_wrap {
		width: 100%;
		height: 3.9rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem 0;
		cursor: pointer;
	}
    .col_wrap .el-rate {
		height: 0.5rem;
		line-height: 2;
    }

	.col_content {
		width: 100%;
	}

	.img_wrap {
		width: 100%;
		height: 2.3rem;
		text-align: center;
	}

	.img_wrap img {
		width: 55%;
		height: 100%;
	}

	.col_text {
		text-align: center;
	}

	.oldcot {
		text-decoration: line-through;
		font-size: 13px;
		color: #9B9B9B;
		font-family: 'Arial Normal', 'Arial', sans-serif;
		font-weight: 400;
		font-style: normal;
		transform: scale(0.95);
	}

	.newcot {
		font-family: 'Arial Normal', 'Arial', sans-serif;
		font-weight: 400;
		font-style: normal;
		font-size: 18Px;
		color: #FF8900;
		margin-top: 5px;
	}
	.blueCol_wrap {
	    width: 20%!important;
}
</style>
<style lang="less" scoped>
	.colWrap_mini{
		// width: 100%!important;
		width: 9.7rem!important;
		height: 8.7rem!important;
		.col_content{
			.img_wrap{
				height: 6rem!important;
				img{
					width: 38%!important;
				}
			}
			.col_text{
				.el-row{
					font-size: 25Px!important;
				}
				.el-rate{
					height: 0.7rem;
					.el-rate__icon {
						font-size: 0.5rem;
						}
				}
				.newcot{
					font-size: 30Px!important;
					margin-top: 0.05rem!important;
				}
			}
		}
	}
</style>
<style lang="less">
    .col_wrap  .el-rate__icon{
		font-size: 0.3rem;
    }
	.colWrap_mini{
				.el-rate{
					.el-rate__icon {
						font-size: 0.5rem!important;
						}
				}
	}
	.trans{
        animation-name:mycolor;
        /*animation-name规定@keyframes 动画名称*/
        animation-duration:1s;
        animation-timing-function:ease;
    }
           @keyframes mycolor
          {
               0%   { transform:translateX(-150px)}
               50%  { transform:translateX(-30px)}
              100% { transform:translateX(0)}
}	
</style>