<template>
  <div class="login_container">
    <div class="bg"></div>
    <div class="login_box">
      <div class="topBotmLine"></div>
      <el-tabs v-model="activeName">
        <el-tab-pane :label='$t("login.phone")' name="first">
          <el-form ref="LoginFormRef" :model="loginForm" label-width="0" :rules="LoginFormRules" class="login_form">
            <!-- 手机号-->
            <el-form-item prop="phone">
              <el-select size='mini' v-model="areaNum" autocomplete="off" class="sleTbtype marLeft"
                :popper-append-to-body=false>
                <el-option v-for="item in areaNumList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
              <el-input :placeholder="$t('register.number')" v-model="loginForm.phone" class="marlf"></el-input>
              <!--   <span v-if="unregister">
                  <span class="redFont">此号码未注册！</span>
              </span> -->
            </el-form-item>
            <el-form-item prop="verification">
              <el-input :placeholder="$t('register.code')" v-model="loginForm.verification"></el-input>
              <el-button class="verifBnt" @click="getverifBnt" v-if="isVerif">{{$t('register.Obtain')}}</el-button>
              <el-button class="verifBnt verifBnt2" disabled v-else>{{ seconds }}{{$t('register.seconds')}}</el-button>
            </el-form-item>
            <!-- 密码-->
            <el-form-item prop="password">
              <el-input :placeholder="$t('register.phone')" v-model="loginForm.password" type="password" ref="psd"></el-input>
              <span class="keyboardSpan" @click="openKeyboard(1)" :class="Keybord1 ? 'active' : ''">
                <!-- <i class="far fa-keyboard"></i> -->
                <img src="../../assets/keyboad.png" alt="">
              </span>
            </el-form-item>
            <!-- 再次输入密码-->
            <el-form-item prop="comfirpsd">
              <el-input :placeholder="$t('register.reEnter')" v-model="loginForm.comfirpsd" type="password" ref="comfPsd"></el-input>
              <span class="keyboardSpan" @click="openKeyboard(2)" :class="Keybord2 ? 'active' : ''">
                <!-- <i class="far fa-keyboard"></i> -->
                <img src="../../assets/keyboad.png" alt="">
              </span>
            </el-form-item>
            <el-form-item class="btns">
              <el-button type="primary" @click="sumitPhone">{{ $t('register.Submit') }}</el-button>
            </el-form-item>
            <el-form-item class="btns">
              <a href="#" @click="toLogin">{{ $t('login.Return') }}</a>
            </el-form-item>
          </el-form>
          <div class="keyWrap" v-if="showKeybord" :class="curEnter == 1 ? 'psdKey' : ''">
            <loginKey :getCurKey.sync="curKey" @delKey="toDel"></loginKey>
          </div>

        </el-tab-pane>
        <el-tab-pane :label='$t("login.Retrieve")' name="second">
          <el-form ref="LoginFormRef" :model="FormEmail" label-width="0" :rules="FormEmailRules" class="login_form">
            <!-- 邮箱-->
            <el-form-item prop="email">
              <el-input :placeholder='$t("login.email2")' v-model="FormEmail.email"></el-input>
              <span v-if="unregister">
                <span class="redFont">{{$t("login.email")}}</span>
              </span>
            </el-form-item>
            <el-form-item prop="verification">
              <el-input :placeholder='$t("register.code")' v-model="FormEmail.verification"></el-input>
              <el-button class="verifBnt" @click="getEmailVefiBnt" v-if="isVerif">{{$t('register.Obtain')}}</el-button>
              <el-button class="verifBnt verifBnt2" disabled v-else>{{ seconds }}{{$t('register.seconds')}}</el-button>
            </el-form-item>
            <!-- 密码-->
            <el-form-item prop="password">
              <el-input :placeholder='$t("register.phone")' v-model="FormEmail.password" type="password" ref="psd"></el-input>
              <span class="keyboardSpan" @click="openKeyboard(1)" :class="Keybord1 ? 'active' : ''">
                <!-- <i class="far fa-keyboard"></i> -->
                <img src="../../assets/keyboad.png" alt="">
              </span>
            </el-form-item>
            <!-- 再次输入密码-->
            <el-form-item prop="comfirpsd">
              <el-input :placeholder='$t("register.reEnter")' v-model="FormEmail.comfirpsd" type="password" ref="comfPsd"></el-input>
              <span class="keyboardSpan" @click="openKeyboard(2)" :class="Keybord2 ? 'active' : ''">
                <!-- <i class="far fa-keyboard"></i> -->
                <img src="../../assets/keyboad.png" alt="">
              </span>
            </el-form-item>
            <el-form-item class="btns">
              <el-button type="primary" @click="sumitEmail">{{ $t('register.Submit') }}</el-button>
            </el-form-item>
            <el-form-item class="btns">
              <a href="#" @click="toLogin">{{ $t('login.Return') }}</a>
            </el-form-item>
          </el-form>
          <div class="keyWrap" v-if="showKeybord" :class="curEnter == 1 ? 'psdKey' : ''">
            <loginKey :getCurKey.sync="curKey" @delKey="toDel"></loginKey>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import loginKey from '../../components/loginKey/loginKey.vue'
import axios from "../../api/axios";
export default {
  components: {
    loginKey
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error(this.$t('login.empty2')))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error(this.$t('login.emailFormat')))
        }
      }, 100)
    }
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error(this.$t('register.empty')))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error(this.$t('register.numerical')))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error(this.$t('register.Incorrect')))
          }
        }
      }, 100)
    }
    var passWord = (rule, value, callback) => {
      if (value == '' && this.$refs.psd != document.activeElement) {
        return callback(new Error(this.$t('register.passwords')))
      } else {
        callback()
      }
    }
    const comfpsd = (rule, value, callback) => {
      if (value === '' && this.$refs.psd != document.activeElement) {
        callback(new Error(this.$t('register.passwords')))
      } else if (value !== this.loginForm.password && this.$refs.psd != document.activeElement) {
        callback(new Error(this.$t('register.match')))
      } else {
        callback()
      }
    }
    return {
      unregister: false,
      activeName: 'first',
      curEnter: 0,
      // virtual:'',//虚拟键盘的连续值
      curKey: '', // 虚拟键盘的值
      showKeybord: false,
      Keybord1: false, // 第一块虚拟键盘激活
      Keybord2: false, // 第二块虚拟键盘激活
      isVerif: true,
      seconds: 10,
      areaNum: '+86',
      areaNumList: ['+86', '+65'],
      istoEnter: false,
      // 登录的初始化数据：备注默认应该是空
      loginForm: {
        verification: '',
        password: '',
        comfirpsd: '',
        phone: ''
      },
      FormEmail: {
        verification: '',
        password: '',
        comfirpsd: '',
        email: ''
      },
      // 正则表达式的相关校验
      LoginFormRules: {
        password: [{ required: true, trigger: 'change', validator: passWord }],
        // comfirpsd: [{ required: true, trigger: 'change', validator: comfpsd }],
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
        verification: [{ required: true, trigger: 'blur', message:this.$t('register.code') }]
      },
      FormEmailRules: {
        password: [{ required: true, trigger: 'change', validator: passWord }],
        comfirpsd: [{ required: true, trigger: 'change', validator: comfpsd }],
        email: [{ required: true, trigger: 'blur', validator: checkEmail }],
        verification: [{ required: true, trigger: 'blur', message: this.$t('register.code') }]
      }
    }
  },
  computed: {
    commanyname() {
      return this.loginForm.commanyname
    }
  },
  watch: {
    curKey: {
      handler(newName, oldName) {
        if (this.curEnter == 1) {
          this.loginForm.password = this.loginForm.password + newName
        } else {
          this.loginForm.comfirpsd = this.loginForm.comfirpsd + newName
        }
      }
    },
    commanyname: {
      handler(newName, oldName) {
        /* const reg = new RegExp(/\s/);
      console.log('222:'+reg.test(newName));
      if (reg.test(newName) == true) {
        console.log('有空格:'+newName.length);
      }   */
        const reg = new RegExp(/\s/)
        if (
          reg.test(newName) == false &&
          newName.length == 12 &&
          this.istoEnter == true
        ) {
          this.$refs.secInput.focus()
        }
      }
    }
  },
  methods: {
    async isPhoneRegister() {
      var phoneNumber = this.loginForm.phone
      var url = '/reach/portal/api/user/isPhoneRegister'
      var result = await axios.get(url, {
        params: {
          phone: phoneNumber
        }
      })
      return result
    },
    async isEmailRegister() {
      var email = this.FormEmail.email
      var url = '/reach/portal/api/user/isEmailRegister'
      var result = await axios.get(url, {
        params: {
          email: email
        }
      })
      return result
    },
    getVerifyByPhone() {
      var url = '/reach/portal/api/user/getVerifyCode'
      var phoneNumber = this.loginForm.phone
      axios.post(url, {}, {
        params: {
          phone: phoneNumber
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.$my_confirm( this.$t('login.sent'), "提示", {
            type: "warn",
            cancelBtnText:  this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => { })
            .catch(() => { });
        }
      })
    },
    getVerifyByEmail() {
      var url = '/reach/portal/api/user/sendVerifyCodeByEmail'
      var email = this.FormEmail.email
      axios.post(url, {}, {
        params: {
          email: email
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.$my_confirm( this.$t('login.sent'), "提示", {
            type: "warn",
            cancelBtnText:  this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => { })
            .catch(() => { });
        }
      })
    },
    checkVerifyCode() {
      var url = '/reach/portal/api/user/checkVerifyCode'
      var key = ''
      var veri = ''
      if (this.loginForm !== '') {
        key = this.loginForm.phone
        veri = this.loginForm.verification
      }
      if (this.FormEmail.email !== '') {
        key = this.FormEmail.email
        veri = this.FormEmail.verification
      }
      var checkResult = axios.post(url, {}, {
        params: {
          phone: key,
          verifyCode: veri
        }
      })
      return checkResult
    },
    toDel() {
      if (this.curEnter == 1) {
        var psd = this.loginForm.password
        this.loginForm.password = psd.length ? psd.substring(0, psd.length - 1) : psd
      } else {
        var psd = this.loginForm.comfirpsd
        this.loginForm.comfirpsd = psd.length ? psd.substring(0, psd.length - 1) : psd
      }
    },
    async getverifBnt() {
      var result = await this.isPhoneRegister()
      if (!result.data) {
        this.$my_confirm(this.$t('login.exist'), "提示", {
          type: "warn",
          cancelBtnText:  this.$t('login.sure'),
          otherBtnText: "",
          yesBtnText: ""
        })
          .then(action => { })
          .catch(() => { });
        return
      }
      this.getVerifyByPhone()
      this.isVerif = false
      var that = this
      var myVar = setInterval(function () {
        --that.seconds
        if (that.seconds == 0) {
          that.isVerif = true
          that.seconds = 60
          clearInterval(myVar)
        }
      }, 1000)
    },
    async getEmailVefiBnt() {
      var result = await this.isEmailRegister()
      if (result.data !== true) {
        this.unregister = true
        return
      } else {
        this.unregister = false
      }
      this.getVerifyByEmail()
      this.isVerif = false
      var that = this
      var myVar = setInterval(function () {
        --that.seconds
        if (that.seconds === 0) {
          that.isVerif = true
          that.seconds = 60
          clearInterval(myVar)
        }
      }, 1000)
    },
    openKeyboard(e) {
      var that = this
      if (this.curEnter != 0 && this.showKeybord == true) {
        this.showKeybord = false
        this.Keybord1 = false
        this.Keybord2 = false
        if (e != this.curEnter) {
          setTimeout(function () {
            that.showKeybord = true
            that.curEnter = 0
            that.curEnter = e
          }, 500)
        }
      } else {
        this.showKeybord = true
        this.$nextTick(function () {
          that.curEnter = e
          if (e == 1) {
            that.$refs.psd.focus()
            that.Keybord1 = true
          } else {
            that.$refs.comfPsd.focus()
            that.Keybord2 = true
          }
        })
      }
    },
    isEnter() {
      this.istoEnter = true
    },
    // 清空表单的校验
    resetLoginForm() {
      this.$refs.LoginFormRef.resetFields()
    },
    toLogin() {
      this.$router.push({ name: 'loginn', params: { lgin: 'regi' } })
    },
    // 手机号找回密码
    async sumitPhone() {
      if (this.loginForm.comfirpsd !== this.loginForm.password && this.$refs.psd != document.activeElement) {
        this.$my_confirm(this.$t('login.confirm'), "提示", {
          type: "warn",
          cancelBtnText:  this.$t('login.sure'),
          otherBtnText: "",
          yesBtnText: ""
        })
          .then(action => { })
          .catch(() => { });
        return
      }
      var result = await this.checkVerifyCode()
      if (result.data.code === 0) {
        this.$my_confirm(this.$t('register.error'), "提示", {
          type: "warn",
          cancelBtnText:  this.$t('login.sure'),
          otherBtnText: "",
          yesBtnText: ""
        })
          .then(action => { })
          .catch(() => { });
        return
      }
      var psword = this.$md5(this.loginForm.password)
      var obj = {
        phone: this.loginForm.phone,
        password: psword
      }
      this.$getSame(obj, this.loginForm)
      obj.password = psword
      var url = '/reach/portal/api/user/modifyPwdByPhone'
      axios.post(url, obj).then(response => {
        if (response.data.code === 1) {
          this.$my_confirm(this.$t('login.changed'), "提示", {
            type: "warn",
            cancelBtnText:  this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => { })
            .catch(() => { });
          this.$router.push({ path: '/loginn' })
        } else {
          this.$message.warning(response.data.message)
        }
      })
    },
    // 邮箱提交
    async sumitEmail() {
      var result = await this.checkVerifyCode()
      if (result.data.code === 0) {
        this.$my_confirm(this.$t('register.error'), "提示", {
          type: "warn",
          cancelBtnText:  this.$t('login.sure'),
          otherBtnText: "",
          yesBtnText: ""
        })
          .then(action => { })
          .catch(() => { });
        return
      }
      var psword = this.$md5(this.FormEmail.password)
      var obj = {
        email: this.FormEmail.email,
        password: psword
      }
      this.$getSame(obj, this.FormEmail)
      obj.password = psword
      var url = '/reach/portal/api/user/modifyPwdByEmail'
      axios.post(url, obj).then(response => {
        if (response.data.code === 1) {
          this.$my_confirm(this.$t('login.changed'), "提示", {
            type: "warn",
            cancelBtnText:  this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => { })
            .catch(() => { });
          this.$router.push({ path: '/loginn' })
        } else {
          this.$message.warning(response.data.message)
        }
      })

    }
  }
}
</script>
<style lang="less" scoped>
.redFont {
  color: #FF4E00;
  position: absolute;
  right: 11%;
  top: 112%;
}

.hadTopTitle {
  color: #333333;
  font-size: 14Px;
  position: absolute;
  right: 35%;
  top: -93%;
}

.login_container {
  height: 12rem;
  position: relative;

  a {
    text-decoration: underline;
    color: #0E00FF;
  }
}

.bg {
  background: url(../../assets/u3056.png) no-repeat center;
  background-size: cover;
  height: 100%;
}

.login_box {
  width: 15rem;
  height: 7.9rem;
  background-color: #fff;
  border-radius: 10Px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .topBotmLine {
    position: absolute;
    left: 23%;
    top: 12%;
    width: 470Px;
    height: 0.1rem;
    background-color: #d4d2d2;
  }

  /deep/ .el-tabs {
    .el-tabs__header {
      .el-tabs__nav-scroll {
        height: 49Px;
        padding-top: 20Px;

        .el-tabs__nav {
          float: none;
        }

        .el-tabs__active-bar {
          height: 0.1rem;
          bottom: -9Px;
          left: 17%;
          width: 3.9rem !important;

        }

        .el-tabs__item {
          height: 0.5rem;
          line-height: 0.5rem;
          font-size: 20Px;
          font-weight: 600;
          padding: 0 50Px;
          color: #303133 !important;
        }
      }
    }

    .el-tabs__content {
      height: 8rem;
    }
  }
}

.title {
  position: absolute;
  left: 12%;
  top: 2.5%;
  font-size: 23Px;
  font-weight: 600;
}

.login_form {
  position: absolute;
  // bottom: 13%;
  width: 100%;
  box-sizing: border-box;

  .marlf {
    /deep/ .el-input__inner {
      padding-left: 1.27rem;
    }
  }

  .keyboardSpan {
    width: 25Px;
    height: 50Px;
    position: absolute;
    right: 16.4%;
    // cursor: pointer;

    .svg-inline--fa.fa-w-18 {
      //color: #4d87b9;//蓝色
      color: #4a4a4ac7;
      cursor: pointer;
      width: 100%;
      height: 21Px;
      margin-top: 3px;
    }

  }

  .active {
    .svg-inline--fa.fa-w-18 {
      color: #4d87b9;
    }
  }

  /deep/ .el-select {
    position: absolute;
    top: 2.7%;
    left: 15%;
    z-index: 100;

    .el-input__inner {
      height: 0.65rem;
      width: 1.2rem;
      padding: 0 8Px;
      background: linear-gradient(180deg, #ffffff 0%, #cccccc 99%);
      box-sizing: border-box;
      border-width: 1Px;
      border-style: solid;
      border-color: #d4d4d4;
      border-radius: 5Px;
      border-top-right-radius: 0Px;
      border-bottom-right-radius: 0Px;
      box-shadow: none;
      color: #3C3C3C;
      font-size: 15Px;
    }

    .el-input__suffix {
      right: -0.05rem;

      .el-select__caret {
        font-weight: 700;
        color: #010101;
      }
    }

    .el-select-dropdown {
      min-width: auto !important;
      width: 1.3rem !important;

      .el-select-dropdown__item {
        height: 0.4rem;
        line-height: 0.4rem;
        width: 70px;

        &:hover {
          background-color: #53a8ff;
          color: #fff;
        }
      }
    }

    .el-icon-arrow-up:before {
      color: #828a8d;
      content: "\e790";
      // position: absolute;
      // width: 100%;
      // height: 100%;
      // top: 30%;
      // left: 50%;;
      // transform: translate(-50%, -50%);
    }
  }

  .verifBnt {
    width: 1.9rem !important;
    height: 0.68rem !important;
    font-size: 15Px !important;
    position: absolute;
    top: 4px;
    right: 15%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(219, 219, 219, 1) 100%);
    box-sizing: border-box;
    border-width: 1Px;
    border-style: solid;
    border-color: rgba(212, 212, 212, 1);
    border-radius: 5Px;
    border-top-left-radius: 0Px;
    border-bottom-left-radius: 0Px;
    box-shadow: none;
    color: #848484;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-radius: 5Px;
      background: #53a8ff; //不能用background-color无效
      color: #fff;
    }
  }

  .verifBnt2 {
    width: 2.5rem !important;
  }
}

.keyWrap {
  position: absolute;
  top: 52%;
  right: 16%;
  background-color: #3270a5;
  padding: 10Px 0 0 12Px;
}

.psdKey {
  top: 37%;
}

.agreement {
  /deep/ .el-form-item__content {
    margin-left: 0px;
    text-align: left;
    padding-left: 10%;

    .el-checkbox__inner {
      border: 0.05rem solid #DCDFE6;
      border-radius: 0.03rem;
      width: 0.25rem;
      height: 0.25rem;

      &::after {
        border: 0.04rem solid #FFF;
        border-left: 0;
        border-top: 0;
        height: 0.18rem;
        left: 0.03rem;
        top: -0.09rem;
        width: 0.1rem;
      }
    }
  }
}

.btns {
  display: flex;
  justify-content: center;
  width: 100%;

  /deep/ .el-form-item__content {
    width: 70%;

    .el-button {
      width: 100%;
      // height: 0.6rem;
      border-radius: 6Px;
      // font-size: 17Px;
      letter-spacing: 14px;
      background-color: #1fbaf3;
    }
  }
}

.login_box /deep/ .el-input {
  font-size: 20Px;
}

.login_box /deep/ .el-input__inner {
  height: 0.65rem;
  width: 70%;
  padding-left: 0.1rem;
  border-radius: 4Px;
  font-size: 16Px;
}

.login_box /deep/ .el-form-item {
  margin-bottom: 15px;
}

.login_box /deep/ .el-form-item__error {
  left: 15%;
  line-height: 1.5;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  width: 1rem;
  // height: 0.5rem;
  // font-size: 19Px;
}</style>
