<template>
	<div  class="introWrap"> 
		<p class="altitle title">{{getData.title}}</p>
		<div class="imgWrap"> 
			<img :src="getData.maxImg" alt=""/>
            <el-button>{{$t('heard.Start')}} >></el-button>
		</div>
	</div>
</template>
<script>
	export default {
        name:'introduce',
		props: ["getData"],
		data() {
			return {
			};
		},
		created() {},
		mounted() {},
		methods: {}
	};
</script>
<style scoped lang="less">
.introWrap{
	margin: 2rem 0;
	p{
		margin-bottom: 0.3rem;
	}
.imgWrap{
	display: flex;
	justify-content: center;
    align-items: center;
	img{
		width: 71%;
	}
    .el-button{
        background-color: #3ac0f1;
        color: #fff;
        border-radius: 0.17rem;
        width: 2.3rem;
        height: 0.6rem;
	}
}
}
</style>
