<template>
  <div class="pay_wrap">
    <div class="pay_inner">
      <div class="icon_wrap">
        <i class="el-icon-circle-check"></i>
        <span>	{{$t('Cart.completed')}}</span>
      </div>
      <div class="icon_bottom">
      </div>
      <div class="btn_wrap">
        <el-button type="primary" round @click="topath('buyTab')">	{{$t('Cart.list')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import Storebaner from "../../components/storeBaner/storeBaner.vue";
import axios from '../../api/axios'
export default {
  components: {
    // Storebaner,
  },
  data() {
    return {
      sec: 10,
      cotTag: "",
      localPay: {
        cartlist:[]
      },
      currentAdname:'',
      addressid:''
    };
  },
  created() {
   this.getAdress();
  },
  mounted() {
     var that = this;
    that.cotTag = setInterval(function () {
      if (that.sec <= 0) {
        clearInterval(that.cotTag);
        that.$router.push({
          name: "buyTab",
        });
      } else {
        --that.sec;
      }
    }, 1000);
  },
  methods: {
    topath(e) {
      var that = this;
      clearInterval(this.cotTag);
      if (e == "goodsDeil") {
        this.$router.push({
          name: e,
          query: {
            id: that.localPay.id,
          },
        });
      } else {
        this.$router.push({
          name: e,
        });
      }
    },
	async sendPaydata(){
	  var that = this;
		that.localPay = JSON.parse(window.localStorage.getItem("allbuy")) || {};
		var usertoken = window.localStorage.getItem("usertoken") || "";
		var curretUser = window.localStorage.getItem("curretUser") || "";
		if (JSON.stringify(that.localPay) !== "{}") {
    that.currentAdname=that.localPay.currentAdname;
/*
		var parm = this.$qs.stringify(obj);
		let res = await this.$Net.post("/f/eshopping/eshoppingModeAndProduct/saveorder", parm); */
var aobj = {
			orderId: that.localPay.numId,
			productId: that.localPay.cartlist[0].id,
			productName: that.localPay.cartlist[0].productName,
			modeId: that.localPay.cartlist[0].modeId,
			modeName: that.localPay.cartlist[0].modeName,
			orderPay: that.localPay.total,
			orderWay: "支付宝",
			creatBy: curretUser,
			eshoppingtoken: usertoken,
			addressId:that.addressid,
			Sells:that.localPay.cartlist[0].buyNum,
      addressId:that.localPay.adddressId
		};
    console.log(aobj);

    // var url = '/f/eshopping/eshoppingModeAndProduct/saveorder'
    //   axios.post(url, {}, {
    //     params: {
    //      	orderId: that.localPay.numId,
    //       productId: that.localPay.cartlist[0].id,
    //       productName: that.localPay.cartlist[0].productName,
    //       modeId: that.localPay.cartlist[0].modeId,
    //       modeName: that.localPay.cartlist[0].modeName,
    //       orderPay: that.localPay.total,
    //       orderWay: "支付宝",
    //       creatBy: curretUser,
    //       eshoppingtoken: usertoken,
    //       addressId:that.addressid,
    //       Sells:that.localPay.cartlist[0].buyNum,
    //       addressId:that.localPay.adddressId
    //     }
    //   }).then(response => {
    //      console.log("saveorder");
    //      console.log(that.localPay);
    //      console.log(response.data);
    //    /*  if (response.data.code === 1) {
    //       console.log('response.data.code');
    //       console.log(response.data);
    //
    //     } */
    //   })
		}
	},
   async getAdress(){
     var that=this;
     var usertoken = window.localStorage.getItem("usertoken") || "";
     let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/addressList",{"eshoppingtoken": usertoken});
     that.adress=res[0].province+res[0].city+res[0].district+res[0].address;
     that.addressid=res[0].addressid;

   /*  console.log("得到：");
    console.log(that.adress); */
    this.sendPaydata();
  }
  },
};
</script>
<style>
	.pay_inner .el-icon-circle-check {
		font-weight: 600;
		color: #00a0ee;
		font-size: 64Px;
	}
</style>
<style scoped lang="less">
	.pay_wrap {
		width: 100%;
		margin: 3rem 0 3rem 0;

		.pay_inner {
			width: 11.25rem;
			margin: 0 auto;
			text-align: center;

			.icon_wrap {
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 38Px;
				}
			}

			.icon_bottom {
				display: flex;
				flex-direction: column;
				margin: 25px 0 25px 0;

				span {
					font-size: 21Px;
					color: #255169;
					margin-bottom: 0.28rem;
				}
			}
            .btn_wrap{
			  margin: 1rem 0 1.5rem 0;
			//  /deep/ .el-button{
			// 	width: 2rem;
			// 	height: 0.6rem;
			//  }
		 }
		}
	}
</style>
