<template>
  <div class="introWrap">
    <div class="aspect-ratio" v-for="(item,index) in videoData.videoList" :key="index">
          <iframe width="100%" :src="item.bvid"  frameborder="0" align="center"> </iframe>
          <p class="title">{{item.videoName}}<a :href="item.bvid" target="_blank">{{$t('about.full')}}</a></p>
          <p class="videoTime">{{item.createTime}}<span>{{item.duration}}</span></p>
    </div> 
  </div>
</template>
<script>
export default {
  name: 'videoo',
  props: ['videoData'],
  data () {
    return {}
  },
  created () {    
  },
  mounted () {},
  methods: {}
}
</script>
<style scoped lang="less">
.introWrap{
   height: 9rem;
   padding-top: 0.5rem;
   display: flex;
   flex-wrap: wrap;
   .aspect-ratio{
      width: 32%;
      margin-right: 0.2rem;
     .title{
       text-align: left;
       padding: 0.3rem 0.3rem 0.16rem 0.3rem;
       a{
         float: right;
         color: #333;
       }
     }
     .videoTime{
       text-align: left;
       padding: 0 0.3rem;
       span{
         margin-left: 0.5rem;
       }
     }
   }
}

</style>
