<template>
	<div class="content_all">
		<div class="content_wrap">
			<el-main>
				<div class="main_title">{{ curPath == 'nowCart' ? $t('Cart.Buy') : $t('Cart.Cart') }}</div>
				<el-dialog :title="$t('Cart.Add')" :visible.sync="dialogVisible" width="30%"
					:before-close="handleClose">
					<div class="demo-input-suffix">
						{{ $t('Cart.name') }}：
						<el-input placeholder="" v-model="goods_name"> </el-input>
					</div>
					<div class="demo-input-suffix">
						{{ $t('Cart.quantity') }}：
						<el-input placeholder="" v-model="goods_num"> </el-input>
					</div>
					<div class="demo-input-suffix">
						{{ $t('Cart.pricing') }}：
						<el-input placeholder="" v-model="goods_price">
						</el-input>
					</div>
					<div class="demo-input-suffix">
						{{ $t('Cart.Description') }}：
						<el-input placeholder="" v-model="goods_decri">
						</el-input>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button @click="cancel()">{{ $t('Cart.Cancel') }}</el-button>
						<el-button type="primary" @click="save()">{{ $t('login.sure') }}</el-button>
					</span>
				</el-dialog>
				<div class="idNum">>{{ $t('Cart.number') }}：{{ numId }}</div>
				<el-table :data="cartList" border style="width: 99%">
					<el-table-column type="index"> </el-table-column>
					<el-table-column prop="productName" :label="$t('Cart.Product')">
					</el-table-column>
					<el-table-column prop="img" :label="$t('Purchased.image')">
						<template slot-scope="scope">
							<viewer>
								<img :src="scope.row.productPicture" class="img_row" />
							</viewer>
						</template>
					</el-table-column>
					<el-table-column prop="productPrice" :label="$t('Cart.pricing')">
					</el-table-column>
					<el-table-column prop="productNumber" :label="$t('Cart.quantity')">
						<template slot-scope="scope">
							<span style="color:red" v-if="scope.row.productSku == 0">{{ $t('Cart.inventory')
								}}</span>
							<el-input-number v-else v-model="scope.row.productNumber" @change="issub(scope)" size="mini"
								:min="1" :max="scope.row.productSku">
							</el-input-number>
						</template>
					</el-table-column>
					<el-table-column prop="productStatus" :label="$t('Cart.ItemStatus')">
						<template slot-scope="scope">
							<span style="color:red" v-if="scope.row.auditStatus == 0">{{ $t('Cart.review')
								}}</span>
							<span style="color:red" v-if="scope.row.uploadStatus == 0">{{ $t('Cart.Unlisted')
								}}</span>
							<span style="color:green"
								v-if="scope.row.uploadStatus == 1 && scope.row.auditStatus == 1">{{
					$t('Cart.Listing') }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="modeName" :label="$t('Cart.Module')" class-name="decrit" :resizable="true">
					</el-table-column>
					<el-table-column :label="$t('Cart.operation')">
						<template slot-scope="scope">
							<el-button @click="update(scope)" type="text" size="small" v-if="false">{{
					$t('Cart.edit') }}</el-button>
							<el-button type="text" size="small" @click="del(scope.$index, scope)">{{
					$t('Cart.Remove') }}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="bottom">
					<!-- <el-button type="primary" @click="dialogVisible = true" size="mini" class="cartBnt">增添商品</el-button> -->
					<el-button v-if="curPath != 'nowCart'" type="primary" @click="toHome()" size="mini"
						class="cartBnt">{{ $t('Cart.Add')
						}}</el-button>
					<div class="bottom_right">
						<!-- <el-input v-model="total" style="width: 300px">
              <template slot="prepend">合计:</template>
              <template slot="append">元</template>
            </el-input> -->
						<span class="allCout">{{ $t('Cart.total') }}:<span>￥{{ total }}</span></span>
						<el-button type="danger" @click="openAddress()" v-preventReClick="2000" size="mini"
							class="cartBnt">{{
					$t('Cart.place') }}</el-button>
					</div>
				</div>
				<div v-html="bkContent"></div>
			</el-main>
			<!-- 取消购买弹出<popBox></popBox> -->
			<adressview ref="addressBox" :cart="true"></adressview>
			<choosePay :numId="numId" :total="total" ref="choosePay"></choosePay>
		</div>
	</div>
</template>

<script>
import axios from '../../api/axios'
import adressview from "../userDetail/adressview.vue";
import choosePay from "../userDetail/choosePay.vue";
import Cookies from 'js-cookie'
export default {
	components: {
		adressview,
		choosePay
	},
	props: {
		// msg: popBox,
	},
	data() {
		return {
			curPath: "",
			activeIndex: "1",
			activeIndex2: "4",
			bkContent: "",
			dialogVisible: false,
			active: "zero_num",
			goods_name: "",
			goods_num: "",
			goods_price: "",
			goods_decri: "",
			goods_index: -1, // 当前本次操作的商品信息[-1表示新增,大于等于0表示编辑]
			numId: 0,
			cartList: [],
			buyedList: [],
			goods_list: [{
				name: "电脑入门",
				img: require("../../assets/store/u1053.png"),
				num: 27,
				price: 20,
				decri: "知识性商品介绍是为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考为公众能够科学、正确地认、选购和使用商品提供参考",
			},
			{
				name: "电脑进阶",
				img: require("../../assets/store/u1053.png"),
				num: 21,
				price: 20,
				decri: "知识性商品介绍是为公众能够科学、正确地认、选购和使用商品提供参考",
			},
			{
				name: "电脑高级",
				img: require("../../assets/store/u1053.png"),
				num: 17,
				price: 20,
				decri: "知识性商品介绍是为公众能够科学、正确地认、选购和使用商品提供参考",
			},
			{
				name: "电脑研究",
				img: require("../../assets/store/u1053.png"),
				num: 37,
				price: 20,
				decri: "知识性商品介绍是为公众能够科学、正确地认、选购和使用商品提供参考",
			},
			{
				name: "电脑购买",
				img: require("../../assets/store/u1053.png"),
				num: 57,
				price: 20,
				decri: "知识性商品介绍是为公众能够科学、正确地认、选购和使用商品提供参考",
			},
			],
		};
	},
	directives: {
		preventReClick: {
			bind(el, binding) {
				el.addEventListener("click", () => {
					if (!el.disabled) {
						el.disabled = true;
						setTimeout(() => {
							el.disabled = false;
						}, binding.value || 3000);
					}
				});
			},
		},
	},
	created() {
		var pathType = this.$route.query.type;
		if (pathType == 'nowCart') {
			this.curPath = 'nowCart'
			var nowCart = window.localStorage.getItem('nowCart') || '';
			if (nowCart != '') {
				this.cartList = JSON.parse(nowCart);
			}
		} else {
			/* var addCart=window.localStorage.getItem('addCart')||'';
			if(addCart!=''){
				this.cartList = JSON.parse(addCart);
			} */
			var usertoken = window.localStorage.getItem('usertoken') || '';
			var url = '/f/eshopping/eshoppingModeAndProduct/shoppingCartList';
			axios.post(url, {}, {
				params: {
					eshoppingtoken: usertoken
				}
			}).then(response => {
				//console.log(response);
				this.cartList = response.data
				for (let i = 0; i < this.cartList.length; i++) {
					if (this.cartList[i].uploadStatus == 0) {
						this.cartList[i].productStatus = 0;
					}
					if (this.cartList[i].auditStatus == 0) {
						this.cartList[i].productStatus = 0;
					}
				}
				/* if (response.data === 1) {
				this.$router.push({name:'cartView'})
				} else {
				this.$message.warning(response.data.message)
				} */
			})
		}
		this.numId = this.RondomPass(18);
		/* console.log("本地存储：")
		console.log(this.cartList); */
	},
	methods: {
		checkToken() {
			var url = '/reach/portal/api/user/getUserInfo'
			//区别刷新时候结束，不用往下执行
			axios.get(url).then(response => {
				if (response.data.code == 2) {
					this.$my_confirm(this.$t('Cart.expired'), this.$t('login.prompt'), {
						type: "warn",
						cancelBtnText: this.$t('login.sure'),
						otherBtnText: "",
						yesBtnText: ""
					})
						.then(action => {
							if (action == "yes") {
								this.$router.push({ path: "/loginn" });
							} else if (action == "other") { }
						})
						.catch(() => { });
					Cookies.set('token', null)
					window.localStorage.setItem('curretUser', '')
					window.localStorage.setItem('usertoken', '')
					this.reload();
				}
				else {
					this.$refs.addressBox.dialogVisible = true;
				}
			})
		},
		toHome() {
			this.$router.push({
				path: '/storePage'
			});
		},
		openAddress() {
			var that = this;
			if (this.cartList.length > 0) {
				var list = this.cartList.filter(item => item.productSku == 0)
				var notuplist = this.cartList.filter(item => item.productStatus == 0)
				if (notuplist.length > 0) {
					this.$my_confirm(this.$t('Cart.remove'), {
						type: "warn",
						cancelBtnText: this.$t('login.sure'),
						otherBtnText: "",
						yesBtnText: ""
					})
						.then(action => {
							if (action == "yes") {
							}
							else if (action == "other") {
								// that.cartList=that.cartList.filter(item=>item.productSku!=0);
							}
						})
						.catch(() => { });

				}
				else if (list.length > 0) {
					this.$my_confirm(this.$t('Cart.before'), {
						type: "warn",
						cancelBtnText: this.$t('login.sure'),
						otherBtnText: "",
						yesBtnText: ""
					})
						.then(action => {
							if (action == "yes") {
							}
							else if (action == "other") {
								// that.cartList=that.cartList.filter(item=>item.productSku!=0);
							}
						})
						.catch(() => { });

				}
				else {
					this.checkToken();
				}
			}
			else {
				this.$my_confirm(this.$t('Cart.empty'), {
					type: "warn",
					cancelBtnText: this.$t('login.sure'),
					otherBtnText: "",
					yesBtnText: ""
				})
					.then(action => {
						if (action == "yes") {
						}
						else if (action == "other") { }
					})
					.catch(() => { });
			}
		},
		openChoose() {
			this.$refs.choosePay.dialogVisible = true;
		},
		saveOrder(payWay) {
			var curretUser = window.localStorage.getItem("curretUser") || "";
			var allbuy = window.localStorage.getItem("adddressId") || "";
			var usertoken = window.localStorage.getItem('usertoken') || '';
			var obj = {
				orderid: this.numId,
				cartList: this.buyedList,
				orderPay: this.total,
				orderStatus: "9",
				orderWay: payWay,
				creatBy: curretUser,
				addressId: allbuy
			};
			axios.post("/f/eshopping/eshoppingModeAndProduct/saveorder", obj, {
				params: {
					eshoppingtoken: usertoken
				}
			}).then(res => {

			}).catch((err) => {
				console.log(err);
			});
		},
		async sendwx() {
			for (let i = 0; i < this.cartList.length; i++) {
				let a = {
					productName: this.cartList[i].productName,
					productId: this.cartList[i].id,
					modeId: this.cartList[i].modeId,
					modeName: this.cartList[i].modeName,
					buyNum: this.cartList[i].productNumber
				}
				this.buyedList.push(a);
			}
		},
		async send() {
			/* 			var subject = "";
				  this.goods_list.forEach((v) => {
					subject = subject !== "" ? subject + ";" + v.name : subject + v.name;
				  }); */
			var usertoken = window.localStorage.getItem('usertoken') || '';
			var curretUser = window.localStorage.getItem("curretUser") || "";
			var params = new URLSearchParams();
			var allbuy = window.localStorage.getItem("adddressId") || "";
			for (let i = 0; i < this.cartList.length; i++) {
				let a = {
					productName: this.cartList[i].productName,
					productId: this.cartList[i].id,
					modeId: this.cartList[i].modeId,
					modeName: this.cartList[i].modeName,
					buyNum: this.cartList[i].productNumber,
					productPrice: this.cartList[i].productPrice
				}
				this.buyedList.push(a);
			}
			/*    let res = await this.$axios.post("/f/eshopping/eshoppingModeAndProduct/saveorder",obj,{
				params:{
				  eshoppingtoken: usertoken
				}
			  })  */
			// let res = await this.$Net.post("/f/eshopping/eshoppingModeAndProduct/saveorder", parm);
			params.append("WIDout_trade_no", this.numId);
			params.append("WIDtotal_amount", this.total);
			params.append("WIDsubject", this.cartList[0].id);
			params.append("WIDbody", this.cartList[0].modeName);
			var that = this;
			axios.post("/f/eshopping/eshoppingKey/pay", {}, {
				params: params
			}).then(res => {
				var allpay = {};
				allpay.id = that.cartList[0].id;
				allpay.total = that.total;
				allpay.numId = that.numId;
				allpay.productName = that.cartList[0].productName;
				// window.localStorage.removeItem('allPay');//不然会数据重叠，对于立即购买--不会的，存对象直接覆盖原来的
				window.localStorage.setItem('allPay', JSON.stringify(allpay));
				//多购物车对buyList.vue 开始
				var allbuy = JSON.parse(window.localStorage.getItem("allbuy")) || {};
				allbuy.cartlist = that.cartList;
				allbuy.total = that.total;
				allbuy.numId = that.numId;
				window.localStorage.setItem('allbuy', JSON.stringify(allbuy));
				// allpay.productName = that.cartList[0].productName;
				//多购物车对buyList.vue 结束
				that.bkContent = res.data;
				document.querySelector("body").innerHTML = res.data;
				document.forms[0].acceptCharset = 'UTF-8'
				document.forms[0].submit();
			}).catch((err) => {
				console.log(err);
			});
			/* this.$axios
			  .post(
				"/f/eshopping/eshoppingKey/pay",
				params
			  )
			  .then((res) => {
				var allpay = {};
				allpay.id = that.cartList[0].id;
				allpay.total = that.total;
				allpay.numId = that.numId;
				allpay.productName = that.cartList[0].productName;
				// window.localStorage.removeItem('allPay');//不然会数据重叠，对于立即购买--不会的，存对象直接覆盖原来的
				window.localStorage.setItem('allPay', JSON.stringify(allpay));
				//多购物车对buyList.vue 开始
				var allbuy = JSON.parse(window.localStorage.getItem("allbuy")) || {};
				allbuy.cartlist= that.cartList;
				allbuy.total = that.total;
				allbuy.numId = that.numId;
				window.localStorage.setItem('allbuy', JSON.stringify(allbuy));
				// allpay.productName = that.cartList[0].productName;
				//多购物车对buyList.vue 结束
				that.bkContent = res.data;
				document.querySelector("body").innerHTML = res.data;
				const div = document.createElement("div"); // 创建div
				div.innerHTML = res;
				document.body.appendChild(div);
				document.forms[0].submit();
	
			  })
			  .catch((err) => {
				console.log(err);
			  }); */
		},
		handleClose(done) {
			this.$confirm(this.$t('Purchased.closure'))
				.then(() => {
					done();
				})
				.catch(() => { });
		},
		issub(e) {
			var usertoken = window.localStorage.getItem('usertoken') || '';
			var obj = {
				id: e.row.shoppingCartId,
				productId: e.row.id, // 产品id
				productName: e.row.productName, // 产品名称
				productSells: e.row.productNumber	// 购买个数
			}
			var url = '/f/eshopping/eshoppingModeAndProduct/saveShoppingCart';
			// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
			axios.post(url, obj, {
				params: {
					eshoppingtoken: usertoken
				}
			}).then(response => {
				console.log(response);

				/* if (response.data === 1) {
					this.$router.push({name:'cartView'})
				}  */
			})
		},
		save() {
			// 保存数据[添加数据]
			var numId = this.RondomPass(18);
			if (this.goods_index == -1) {
				this.goods_list.push({
					name: this.goods_name,
					num: parseInt(this.goods_num),
					price: parseFloat(this.goods_price),
					decri: this.goods_decri,
					img: require("../../assets/store/u1053.png"),
					numId: numId,
				});
			} else {
				this.goods_list[this.goods_index].name = this.goods_name;
				this.goods_list[this.goods_index].num = parseInt(this.goods_num);
				this.goods_list[this.goods_index].price = parseFloat(this.goods_price);
				this.goods_list[this.goods_index].decri = this.goods_decri;
			}
			this.cancel();
		},
		cancel() {
			this.dialogVisible = false;
			this.goods_index = -1;
			this.goods_name = "";
			this.goods_num = "";
			this.goods_price = "";
			this.goods_decri = "";
		},
		del(index, scope) {
			// 删除数据
			var pathType = this.$route.query.type;
			this.$my_confirm(this.$t('Cart.removes'), {
				type: "warn",
				cancelBtnText: this.$t('login.sure'),
				otherBtnText: "",
				yesBtnText: this.$t('Cart.Cancel')
			})
				.then(action => {
					if (action == "yes") {
						this.cartList.splice(index, 1);
						if (pathType == 'nowCart') {
							window.localStorage.setItem('nowCart', this.cartList)
						}
						else {
							this.delPost(scope.row.shoppingCartId)
						}
					} else if (action == "other") { }
				})
				.catch(() => { });
		},
		delPost(idd) {
			var usertoken = window.localStorage.getItem('usertoken') || '';
			var url = '/f/eshopping/eshoppingModeAndProduct/shoppingCartDelete';
			axios.post(url, {}, {
				params: {
					eshoppingtoken: usertoken,
					id: idd
				}
			}).then(response => {
				if (response.data == 1) {
					this.$my_confirm(this.$t('Cart.removes'), {
						type: "warn",
						cancelBtnText: this.$t('login.sure'),
						otherBtnText: "",
						yesBtnText: ""
					})
						.then(action => {
							if (action == "yes") { } else if (action == "other") { }
						})
						.catch(() => { });
				}
			})
		},
		update(index) {
			// 显示当前编辑的商品信息
			this.dialogVisible = true;
			console.log(index);
			this.goods_index = index.$index;
			this.goods_name = index.row.name;
			this.goods_num = index.row.num;
			this.goods_price = index.row.price;
			this.goods_decri = index.row.decri;
			// 当用户点击保存时，修改对应数据
		},
		RondomPass(number) {
			var arr = new Array();
			var arr1 = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");

			for (var i = 0; i < number; i++) {
				var n = Math.floor(Math.random() * 10);
				arr[i] = arr1[n];
				//document.write(arr1[n]);
			}

			var val = arr.join("");
			//document.write(val);
			return val;
		},
	},
	computed: {
		total() {
			let ret = 0;
			if (this.cartList.length > 0) {
				this.cartList.forEach(function (value) {
					// console.log(value,index);
					let sum_price = parseFloat(value.productPrice) * parseFloat(value.productNumber);
					ret = ret + sum_price;
				});
				ret = ret.toFixed(2);
				return ret;
			}
		}
	},
};
</script>

<style scoped>
.content_all {
	width: 100%;
	height: 100%;
	margin: 2rem 0 1rem 0;
}

.el-header {
	background-color: rgb(84, 92, 100);
}

.content_wrap {
	width: 71%;
	margin: 0 auto;
}

.el-header {
	padding: 0;
}

.el-main {
	padding: 20px 0 20px 0;
}

.el-main .main_title {
	font-size: 23Px;
	font-weight: 600;
	text-align: center;
	margin: 20px;
	margin-bottom: 0.68rem;
}

.idNum {
	text-align: left;
	margin-bottom: 10px;
	font-size: initial;
	font-weight: normal;
}

.img_row {
	height: 150px;
	margin: 0 auto;
	display: inherit;
}

.el-table /deep/ .decrit .cell {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.el-table /deep/ th.is-leaf {
	border-bottom: 1px solid #ebeef5;
	text-align: center;
}

/* .el-table--enable-row-hover /deep/ .el-table__body tr:hover>td { */
.el-table--enable-row-hover /deep/ .el-table__body tr>td {
	background-color: #f5f7fa;
	text-align: center;
}

.bottom {
	margin: 1rem 0 1.5rem 0;
	display: flex;
	justify-content: space-between;
	padding-right: 0.25rem;
}

.bottom_right {
	display: flex;
	justify-content: center;
	align-items: center;
}

.allCout {
	display: inline-block;
	margin-right: 11px;
	font-family: "Gill Sans", sans-serif;
	height: 32px;
	line-height: 32px;
	font-size: 15Px;
}

.allCout>span {
	color: #b13841;
	font-size: 19Px;
	font-weight: 600;
	margin: 0 0.3rem 0 0.1rem;
}
</style>
<style lang="less" scoped>
.content_wrap /deep/ .el-table {
	font-size: 14Px;
}

.content_wrap /deep/ .el-table th {
	padding: 0.5rem 0;
}

.content_wrap /deep/ .el-input--mini {
	.el-input__inner {
		height: 0.5rem;
		line-height: 0.5rem;
	}
}

.content_wrap /deep/ .el-table .cell {
	line-height: 0.4rem;
}

.content_wrap /deep/ .img_row {
	height: 1.6rem;
}

.content_wrap /deep/ .el-input-number--mini {
	width: 1.6rem;
	line-height: 0.5rem;
}

.content_wrap /deep/ .el-input-number__decrease {
	border: 0.02rem solid #DCDFE6;
	height: 0.45rem;
	width: 0.3rem;
}

.content_wrap /deep/ .el-input-number__increase {
	border: 0.02rem solid #DCDFE6;
	height: 0.45rem;
	width: 0.3rem;
	font-size: 0.3rem;
}

.content_wrap .cartBnt {
	// width: 2rem;
	// height: 0.6rem;
}
</style>
