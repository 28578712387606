<template>
  <div class="content_all">
    <div class="topWrap">
        <img :src="bannerImg">
        <p class="altitle">{{$t('heard.Mall')}}</p>
    </div>
    <div class="content_inner">
            <el-card class="box-card" v-for="(item, index) in contactUsList" :key="index">
                <p>{{$t('Purchased.REACH')}}</p>
                <p>{{item.title}}</p>
                <img :src="item.image">
                <p class="pThird">{{item.content}}</p>
            </el-card>
            <el-card class="box-card">
                <el-row type="flex">
                    <i class="fab fa-qq"></i>
                    <span>
                        <i>{{contactService.qqName}}</i><i>{{contactService.qqService}}</i>
                    </span>
                </el-row>
                <el-row type="flex">
                    <i class="icon"><img src="../../assets/u246.svg" alt=""></i>
                    <span>
                        <i>{{contactService.buyName}}</i><i>{{contactService.buyService}}</i>
                    </span>
                </el-row>
                <el-row type="flex">
                    <i class="icon"><img src="../../assets/u246.svg" alt=""></i>
                    <span>
                        <i>{{contactService.beforeName}}</i><i>{{contactService.beforeService}}</i>
                    </span>
                </el-row>
                <el-row type="flex">
                    <i class="icon"><img src="../../assets/u246.svg" alt=""></i>
                    <span>
                        <i>{{contactService.afterName}}</i><i>{{contactService.afterService}}</i>
                    </span>
                </el-row>
            </el-card>
    </div>
    <!--
    <p class="altitle">分部及办事处</p>
    <div class="bottomWrap">
      <el-card class="box-card" v-for="(item, index) in businessConsultList" :key="index" >
        <p>锐骐物联</p>
        <p>{{item.title}}</p>
        <p class="adress"><span>地址：</span><span>{{item.addr}}</span></p>
        <p class="phone">电话：{{item.phone}}</p>
        <p class="phone">传真：{{item.fax}}</p>
        <img :src="item.image">
        <p class="text">商务合作</p>
        <p class="text">微信扫码联系</p>
        <p class="text">{{item.contacts}}</p>
      </el-card>
     </div>
     -->
  </div>
</template>
<script>
import axios from '../../api/axios'
export default {
  name: 'produceSeries',
  data () {
    return {
      contactService: {
        qqName: '',
        qqService: '',
        buyName: '',
        buyService: '',
        beforeName: '',
        beforeService: '',
        afterName: '',
        afterService: ''
      },
      businessConsultList: [],
      contactUsList: [],
      bannerImg: require('../../assets/vedioBack.png')
    }
  },
  created () {},
  mounted () {
    this.getBusinessConsultList()
    this.getContactUs()
    this.getBannerImg()
    this.getContactService()
  },
  methods: {
    getBusinessConsultList () {
      var url = '/reach/portal/api/support/getBusinessConsult'
      axios.get(url).then(response => {
        if (response.data.code === 1) {
          this.businessConsultList = response.data.data
        }
      })
    },
    getContactUs () {
      var url = '/reach/portal/api/about/getContactUsList'
      axios.post(url, {}, {
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.contactUsList = response.data.data
        }
      })
    },
    getBannerImg () {
      var bannerType = 'howBuy'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          this.bannerImg = response.data.data[0].adImage
        }
      })
    },
    getContactService () {
      var url = '/reach/portal/api/about/getCustomService'
      axios.get(url, {
        params: {
          serviceType: 'contact',
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.contactService = response.data.data
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}
.content_all {
  padding-bottom: 2rem;
  .topWrap{
    margin-top: 1.5rem;
    // padding-top: 1.5rem;
    height: 240Px;
    // background: url(../../assets/vedioBack.png) no-repeat;
    background-size: cover;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content_inner{
      width: 77%;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      margin-top: 1rem;
      flex-wrap: wrap;
      .box-card{
            width: 230Px;
            height: 7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:1rem 0 0 1rem;
            // background-color: #e3f2f6;
            &:hover{
                background-color: #1db8f3;
            }
          p{
              font-weight: 400;
              font-size: 0.5rem;
              color: #333333;
              &:nth-child(1){
                  font-weight: 600;
              }
          }
          .pThird{
              font-size: 0.3rem;
          }
          img{
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.4rem 0;
          }
          .svg-inline--fa.fa-w-14{
              font-size: 0.5rem;
          }
          .icon{
              img{
                  width: 0.4rem;
                  height: auto;
              }
          }
          span{
              display: flex;
              flex-direction: column;
              text-align: left;
              margin-left: 0.2rem;
              justify-content: center;
              i{
                  font-style: normal;
                  font-size: 0.25rem;
              }
          }
      }
  }
  .bottomWrap{
      width: 77%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .box-card{
            width: 23%;
            height: 8.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #e3f2f6;
            &:hover{
                background-color: #1db8f3;
            }
          p{
              font-weight: 400;
              font-size: 0.5rem;
              color: #333333;
              &:nth-child(1){
                  font-weight: 600;
              }
          }
          .text{
              font-size: 0.25rem;
          }
          .phone{
              font-size: 0.25rem;
              text-align: left;
              padding-left: 0.2rem;
              margin-top: 0.2rem;
          }
          .adress{
              font-size: 0.25rem;
              display: flex;
              align-items: flex-start;
              width: 90%;
              margin: auto;
              margin-top: 0.5rem;
              span{
                text-align: left;
                margin-left: 0;
                &:nth-child(1){
                    width: 38%;
                }
              }
          }
          img{
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.4rem 0;
          }
          .svg-inline--fa.fa-w-14{
              font-size: 0.5rem;
          }
          .icon{
              img{
                  width: 0.4rem;
                  height: auto;
              }
          }
          span{
              display: flex;
              flex-direction: column;
              text-align: left;
              margin-left: 0.2rem;
              justify-content: center;
              i{
                  font-style: normal;
                  font-size: 0.25rem;
              }
          }
       }
  }
}
</style>
