import { render, staticRenderFns } from "./wXuserDetail.vue?vue&type=template&id=3dc2961c&scoped=true&"
import script from "./wXuserDetail.vue?vue&type=script&lang=js&"
export * from "./wXuserDetail.vue?vue&type=script&lang=js&"
import style0 from "./wXuserDetail.vue?vue&type=style&index=0&id=3dc2961c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc2961c",
  null
  
)

export default component.exports