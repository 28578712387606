<template>
  <div class="login_container">
    <div class="bg"></div>
    <!-- <div class="bg_left"></div> -->
    <div class="login_box">
      <!-- 头像 -->
      <p class="title">在线申请</p>
      <el-form
        :model="signInfo"
        label-width="0"
        class="login_form"
        :rules="rules"
        ref="LoginFormRef"
      >
        <el-form-item prop="deviceTypeId">
          <span>申请业务：</span>
          <el-select v-model="signInfo.deviceTypeId" placeholder="请选择">
            <el-option
              v-for="item in deviceTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
              :disabled="item.disabled">
            </el-option>
          </el-select>
          <div v-show="showSelect" class="showSelect">请选择申请业务</div>
        </el-form-item>
        <el-form-item prop="applyName">
          <span>申请人 / 企业：</span>
          <el-input
            placeholder="必填"
            v-model="signInfo.applyName"
          ></el-input>
        </el-form-item>
        <el-form-item prop="contactPerson">
          <span>联系人：</span>
          <el-input
            placeholder="必填"
            v-model="signInfo.contactPerson"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phoneNum">
          <span>电话 / 手机：</span>
          <el-input
            placeholder="必填"
            v-model="signInfo.phoneNum"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <span>E-mail：</span>
          <el-input
            placeholder="必填"
            v-model="signInfo.email"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span>经营方式：</span>
          <el-radio-group v-model="signInfo.managementMode">
            <el-radio :label="1">个人</el-radio>
            <el-radio :label="2">公司</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <p class="seTitle">组织形式：</p>
          <el-select v-model="signInfo.companyType" placeholder="请选择">
            <el-option
              v-for="(item) in companyTypeList"
              :key="item.key"
              :label="item.name"
              :value="item.key"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span>{{signInfo.managementMode==1?'身份证照片：':'营业执照：'}}</span>
          <el-upload
            class="avatar-uploader"
            :http-request="Upload"
            action=""
            list-type="picture-card"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item prop="addr">
          <span>营业地址：</span>
          <el-input
            placeholder="详细地址"
            v-model="signInfo.addr"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item prop="businessPerId">
          <span style="line-height:0.35rem">指定业务人员/代理：</span>
          <el-input
            placeholder="请输入内容说明"
            v-model="signInfo.businessPerId"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span>附言：</span>
          <el-input
            placeholder="非必填"
            v-model="signInfo.postscript"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="submitRegister">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from '../../api/axios'
import qs from 'qs'
export default {
  data () {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      showSelect:false,
      deviceTypeList: [],
      companyTypeList: [
        {
          key: 1,
          name: '有限责任公司'
        },
        {
          key: 2,
          name: '股份有限公司'
        },
        {
          key: 3,
          name: '个人独资'
        }
      ],
      signInfo: {
        signType: 1,
        contractTypeId: '',
        deviceTypeId: '',
        deviceTypeName: '',
        applyName: '',
        contactPerson: '',
        phoneNum: '',
        email: '',
        managementMode:1,
        idCardNum: '',
        companyType: '有限责任公司',
        expectArea: '',
        province: '',
        city: '',
        district: '',
        addr: '',
        website: '',
        businessPerId: '',
        businessPerName: '',
        postscript: '',
        imgList: []
      },
      rules: {
          applyName: [
            { required: true, message: '请输入申请人 / 企业', trigger: 'blur'}
          ],
          contactPerson: [
             { required: true, message: '请输入联系人', trigger: 'blur'}
          ],
          phoneNum: [{ required: true, trigger: 'blur', validator: checkPhone }],
          email: [
             { required: true, message: '请输入E-mail', trigger: 'blur'}
          ],
          addr: [
             { required: true, message: '请输入营业地址', trigger: 'blur'}
          ],
          businessPerId: [
             { required: true, message: '请输入指定业务人员/代理', trigger: 'blur'}
          ]
        }
    }
  },
  watch: {
    'signInfo.deviceTypeId' (val) {
      console.log(val);
      if(val!=''){
        this.showSelect=false
      }
    }
  },
  mounted () {
    this.getDeviceTypeList();
    this.checkToken(1);
  },
  methods: {
    Upload (e) {
      const params = new FormData()
      params.append('fileList', e.file)
      axios.post('/reach/portal/api/file/uploadImage',
        params)
        .then(res => {
          this.teamForm.imgList.push(res.data.data[0])
        })
    },
    getDeviceTypeList () {
      var url = '/reach/portal/api/support/getDeviceTypeList'
      axios.post(url,{},{
        params:{ language: localStorage.getItem("locale")}
      }).then(response => {
        if (response.data.code === 1) {
          this.deviceTypeList = response.data.data
        }
      })
    },
    checkToken (parm) {
          var url = '/reach/portal/api/user/getUserInfo'
          axios.get(url).then(response => {
              if(response.data.code==2){
                this.$my_confirm(this.$t('Cart.expired'), this.$t('login.prompt'), {
                        type: "warn",
                        cancelBtnText: this.$t('login.sure'),
                        otherBtnText: "",
                        yesBtnText: ""
                    })
                    .then(action => {
                        if (action == "yes") {
                            this.$router.push({ path: "/loginn" });
                        } else if (action == "other") {}
                    })
                    .catch(() => {});  
                    Cookies.set('token', null)
                    window.localStorage.setItem('curretUser', '')
                    window.localStorage.setItem('usertoken', '')
                    this.reload();
              }
              else{
                if(parm==2){
                  this.submitRegister () 
                }
              }
          })
    },
    beforeSubmit () {
      this.checkToken(2);
    },
    submitRegister () {
      var that = this;
      if(this.signInfo.deviceTypeId==''){
        this.showSelect=true
      }
      else{
        this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
           axios.post(
              '/reach/portal/api/cooperate/applyOnlineSign',
              this.signInfo
            ).then(res => {
              if (res.data.code === 1) {
                that.$my_confirm('申请提交成功', '提示', {
                  type: 'warn',
                  cancelBtnText: '确定',
                  otherBtnText: '',
                  yesBtnText: ''
                }).then(action => {
                if (action == "yes") {
                   this.$router.push({ name: 'homePage'})
                }
                else if (action == "other") {}
                })
              .catch(() => {});

              } else {
                that.$my_confirm(res.data.message, '提示', {
                  type: 'warn',
                  cancelBtnText: '确定',
                  otherBtnText: '',
                  yesBtnText: ''
                })
              }
            })
        }})
      }  
    }
  }
}
</script>
<style lang="less" scoped>
  .redFont{
    color: #FF4E00;
    position: absolute;
    right: 11%;
    top: 112%;
  }
  .hadTopTitle{
    color: #333333;
    font-size: 14Px;
    position: absolute;
    right: 35%;
    top: -93%;
  }
  .login_container{
    height: 25rem;
    position: relative;
    a{
      text-decoration: underline;
      color: #0E00FF;
    }
  }
  .bg{
    background:url(../../assets/city.jpg) no-repeat center;
    background-size: cover;
    height: 100%;
   }
   /* .bg_left{
     background:url(../../assets/u3058.png) no-repeat;
     background-size: cover;
     height: 20%;
     position: absolute;
     top: 31%;
     left: 13%;
     width: 30%;
   } */
  .login_box{
    width: 10rem;
    height: auto;
    background-color: #fff;
    border-radius: 10Px;
    position: absolute;
    right: 9%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .title{
   /*  position: absolute;
    left: 12%;
    top: 2.5%; */
    font-size: 23Px;
    font-weight: 600;
    margin: 0.3rem 0;
  }

  .login_form{
    /* position: absolute;
    bottom: -3%;
    width: 100%; */
    padding: 7Px;
    box-sizing: border-box;
    .seTitle{
        text-align: left;
        font-size: 0.3rem;
        margin-bottom: 0.1rem;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 0.5rem;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    /deep/ .el-form-item__content{
        display: flex;
        align-items: center;
        span{
            font-size: 0.3rem;
            width: 22%;
        }
        .el-input{
            font-size: 20Px;
            width: 70%;
            .el-input__inner{
                width: 100%;
                height: 0.57rem;
                padding-left: 0.1rem;
                border-radius: 4Px;
                font-size: 16Px;
            }
        }
        .el-textarea{
            font-size: 16Px;
        }
    }
    .marlf{
      /deep/ .el-input__inner{
        padding-left: 1.27rem;
      }
    }
    /deep/ .el-radio{
        .el-radio__label{
            padding-left: 0.3rem;
        }
        .el-radio__inner{
            width: 0.4rem;
            height: 0.4rem;
            border: 0.07rem solid #DCDFE6;
            margin-right: 0.1rem;
              &:hover{
                  border-color: #409EFF;
              }
        }
        .is-checked{
            .el-radio__inner{
                border: 0.2rem solid #409EFF;
                 &::after{
                  width: 0.3rem;
                  height: 0.3rem;
              }
            }
        }
    }
  }
.keyWrap{
    position: absolute;
    top: 55%;
    right: 0%;
    background-color: #3270a5;
    padding:10Px 0 0 12Px;
  }
  .psdKey{
    top: 44%;
  }
  .agreement{
    /deep/ .el-form-item__content{
      margin-left: 0px;
      text-align: left;
      padding-left: 10%;
      .el-checkbox__inner{
          border: 0.05rem solid #DCDFE6;
          border-radius: 0.03rem;
          width: 0.25rem;
          height: 0.25rem;
        &::after{
          border: 0.04rem solid #FFF;
          border-left: 0;
          border-top: 0;
          height: 0.18rem;
          left: 0.03rem;
          top: -0.09rem;
          width: 0.1rem;
        }
      }
    }
  }
  .btns{
    display: flex;
    justify-content:center;
    width: 100%;
    /deep/ .el-form-item__content{
      width: 80%;
      .el-button{
        width: 100%;
        height: 0.6rem;
        border-radius: 6Px;
        font-size: 17Px;
        letter-spacing: 14px;
        background-color: #1fbaf3;
      }
    }
  }
.login_box /deep/ .el-form-item {
    margin-bottom: 0.35rem;
}
 .login_box /deep/ .el-form-item__error {
    left: 22%;
    line-height: 1.5;
}
 .login_box /deep/ .el-input__prefix {
    left: 0.2rem;
}
 .login_box /deep/ .el-input__icon {
    font-size: 27Px;
    height: 100%;
    width: 0.6rem;
    line-height: 0.58rem;
}
 .login_box /deep/ .el-button {
    width: 1rem;
    height: 0.5rem;
    font-size: 19Px;
}
.el-select-dropdown{
    .el-select-dropdown__item {
      font-size: 0.27rem;
      height: 0.4rem;
      line-height: 0.3rem;
    }
}
.showSelect{
  color: red;
  position: absolute;
  top: 115%;
  left: 22%;
}
</style>
