/**
 * Created by WebStorm.
 * User: nirongxu
 * Date: 2019-01-02
 * Description: 文件描述
 */
import axios from 'axios'
import Cookies from 'js-cookie'
import { Message, MessageBox } from 'element-ui'
// axios默认配置
axios.defaults.timeout = 10000 // 超时时间
axios.defaults.baseURL = process.env.API_HOST

// http request 拦截器
axios.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    if (Cookies.get('token')) {
        config.headers['eshoppingtoken'] = Cookies.get('token');
    }
    return config
},
    error => {
        console.log('request error:', JSON.stringify(error))
        return Promise.reject(error.response)
    })

// http response 拦截器
axios.interceptors.response.use(
    response => {
        // console.log('response:', response)
        if (response.data.code === 11000) {
            Cookies.set('access_token', response.data.message, { expires: 1 / 12 })
            return Promise.resolve()
        } else if (response.data.code === 10000) { // 约定报错信息
            Message({
                message: response.data.message,
                type: 'warning'
            })
            return Promise.reject(response)
        } else if (response.data.code === 2 || response.data.code === '2') {
            // 登录信息过期
            // this.$my_confirm(this.$t('Cart.expired'), this.$t('login.prompt'), {
            //     type: "warn",
            //     cancelBtnText: this.$t('login.sure'),
            //     otherBtnText: "",
            //     yesBtnText: ""
            // })
            //     .then(action => {
            //         if (action == "yes") {
            //             this.$router.push({ path: "/loginn" });
            //         } else if (action == "other") { }
            //     })
            //     .catch(() => { });
            // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            //     confirmButtonText: '重新登录',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }
            // ).then(() => {
            //     // this.$router.push({ path: "/loginn" });
            //     location.href = '/loginn';
            // }).catch(() => {
            // });
            return Promise.resolve(response);
        } else {
            return Promise.resolve(response);
        }
    },
    error => {
        // if (error.response.status === 404) {
        //     Message({
        //         message: '请求地址出错',
        //         type: 'warning'
        //     })
        // } else if (error.response.status === 401) {
        //     Message({
        //         message: error.response.data.message,
        //         type: 'warning'
        //     })
        //     Cookies.remove('access_token')
        //     setTimeout(() => {
        //         location.reload()
        //     }, 3000)
        // }
        return Promise.reject(error.response) // 返回接口返回的错误信息
    })
export default axios
