<template>
  <div>
    <el-dialog :title="$t('modify.List')" :visible.sync="dialogVisible" width="600px" @close='closeDialog'
      :append-to-body="true" >
      <!-- 表单 -->
      <el-form ref="adressList" :model="elFormmm" label-width="0" class="login_form"
        v-if="elFormmm.adressList.length > 0">
        <el-radio-group v-model="whichCheck" @change="handleCheckbox">
          <template v-for="(item, index) in elFormmm.adressList">
            <el-radio :label="index" :key="index" class="adress_row">
              <el-input type="textarea" v-model="item.message" prefix-icon="el-icon-lock" :disabled="edit"></el-input>
              <span class="adress_edit">
                <i class="el-icon-delete" @click="delAdress(item.adrId)"></i>
              </span>
            </el-radio>
          </template>
        </el-radio-group>
      </el-form>
      <span v-else-if="elFormmm.adressList.length == 0 && isEmpty == false" class="toload"><i class="el-icon-loading"></i>
        加载中，请稍等...</span>
      <span v-else class="toload"><i class="el-icon-warning"></i>{{ $t('modify.currently') }}</span>
      <span class="dialog-footer">
        <el-button size="mini" type="primary" @click="addAd()">{{ $t('modify.Add') }}</el-button>
        <el-button size="mini" type="danger" @click="toPay()" v-if="cart">{{ $t('modify.payment') }}</el-button>
        <el-button size="mini" type="danger" @click="cancel()" v-if="!cart">{{ $t('modify.close') }}</el-button>
      </span>
    </el-dialog>
    <areaChioce ref="addressBox" :user="true"></areaChioce>
  </div>
</template>

<script>
import areaChioce from "../../components/areaChioce/areaChioce.vue";
import axios from '../../api/axios'
export default {
  components: {
    areaChioce
  },
  name: "adressview",
  data() {
    const comfadress = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('modify.shipping')));
      } else {
        callback();
      }
    };
    return {
      isEmpty: false,
      whichCheck: 1,
      currentAd: -1,
      currentAdname: '',
      edit: true,
      dialogVisible: false,
      adressList: [],
      adressObj: {},
      elFormmm: { adressList: [] }

    };
  },
  props: ["cart"],
  methods: {
    handleCheckbox(e) {
      this.currentAd = this.elFormmm.adressList[e].adrId;
      this.currentAdname = this.elFormmm.adressList[e].adr;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$emit('update:sechShow', false);
    },
    cancel() {
      this.closeDialog();
    },
    async getAdress() {
      this.elFormmm.adressList = [];
      var that = this;
      var usertoken = window.localStorage.getItem("usertoken") || "";
      let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/addressList", { "eshoppingtoken": usertoken });
      if (res.statuscode != 0) {
        res.forEach((v, i) => {
          var obj = {
            address: v.address,
            city: v.city,
            district: v.district,
            province: v.province,
            adr: v.province + v.city + v.district + v.address,
            adrId: v.addressid,
            phone: v.phone,
            addressee: v.addressee
          };
          obj.message = this.$t('modify.addressee') + ':' + v.addressee + '\n' + this.$t('modify.addressee') + ':' + v.phone + '\n' + this.$t('modify.address') + ':' + obj.adr
          that.elFormmm.adressList.push(obj);
        });
      }
      setTimeout(function () {
        if (res.length == 0) {
          that.isEmpty = true;
        }
        else {
          that.currentAd = that.elFormmm.adressList[0].adrId;
        }
      }, 3000);
    },
    delAdress(e) {
      var that = this;
      that.$confirm(this.$t('modify.sure'))
        .then(() => {
          var usertoken = window.localStorage.getItem("usertoken") || "";
          var obj = {
            addressId: e,
            eshoppingtoken: usertoken,
          };
          that.delPost(obj);
        })
        .catch(() => { });
    },
    async delPost(obj) {
      var that = this;
      axios.post("/f/eshopping/eshoppingModeAndProduct/deleteAddress", {}, {
        params: {
          addressId: obj.addressId,
          eshoppingtoken: obj.eshoppingtoken,
        }
      }).then(res => {
        if (res.data.statuscode == 1) {
          that.$confirm(this.$t('modify.deleted'))
            .then(() => { })
            .catch(() => { });
          that.getAdress();
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    async editAdress(e) {
      this.$refs.addressBox.dialogVisible = true;
      this.$refs.addressBox.getadId = e;
      this.$refs.addressBox.getadbnt = 'setad';
    },
    addAd() {
      this.$refs.addressBox.dialogVisible = true;
      this.$refs.addressBox.getadbnt = 'addAd';
    },
    toPay() {
      if (this.elFormmm.adressList == 0) {
        return this.$notify({
          title: this.$t('modify.fill'),
          type: "warning",
        });
      }
      var that = this;
      window.localStorage.removeItem('allbuy');
      var localPay = {};
      localPay.adddressId = this.currentAd;
      localPay.currentAdname = this.currentAdname[0];
      window.localStorage.setItem('adddressId', localPay.adddressId);
      window.localStorage.setItem('adddressName', localPay.currentAdname);
      this.$parent.openChoose();
    }
  },
  created() {
    this.getAdress();
  }
}
</script>
<style lang="less" scoped>
.login_container {
  height: 10rem;
  padding-top: 9.5rem;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #2b4b6b, skyblue);
  // filter: blur(20Px);
}

.login_box {
  max-height: 11.2rem;
  width: 10rem;
  height: fit-content;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%)
}

.avatar_box {
  width: 80Px;
  height: 80Px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 64%;
    height: 69%;
    //   border-radius: 50%;
    background-color: #eee;
  }
}

.login_form {
  /* position: absolute;
    bottom: 5%; */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  // padding-top: 1rem;
}

.btns {
  display: flex;
  justify-content: center;
}

.login_box /deep/ .el-form-item__content {
  display: flex;
}

.login_box /deep/ .el-checkbox__inner {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.12rem;
}

.login_box /deep/ .el-checkbox__inner::after {
  border: 0.09rem solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 0.5rem;
  left: 0.33rem;
  top: 0.005208rem;
  width: 0.2rem;
}

.login_box /deep/ .el-input__inner {
  height: 0.9rem;
  line-height: 0.4rem;
  width: 98%;
  padding-left: 0.8rem;
}

.login_box /deep/ .el-form-item {
  margin-bottom: 0.35rem;
}

.login_box /deep/ .el-form-item__error {
  left: 4.5%;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  // width: 1rem;
  // height: 0.5rem;
  display: block;
  // margin: 0 auto
}

.login_box /deep/ .el-dialog__title {
  font-size: 20Px;
  font-weight: 600;
}

.login_box /deep/ .el-dialog {
  margin-top: 0.5rem !important;
}

.adress_row {
  position: relative;

  .adress_edit {
    position: absolute;
    right: 3%;
    top: 33%;
    font-size: 17Px;
    cursor: pointer;
    display: none;

    i {
      margin-right: 0.15rem;

      &:hover {
        color: #1fbaf3;
      }
    }
  }

  &:hover {
    .adress_edit {
      display: block;
    }
  }
}

.toload {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 17Px;
  line-height: 2rem;
}

.dialog-footer {
  /*  position: fixed;
    bottom: 0;
    left: 0; */
  display: flex;
  justify-content: space-between;
}

.el-radio-group {
  margin-bottom: 0.3rem;

  /deep/ .el-radio {
    width: 8.3rem;
    margin-bottom: 0.25rem;

    .el-radio__input {
      margin-bottom: 0.5rem;

      .el-radio__inner {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.1rem;

        &::after {
          width: 0rem;
          height: 0rem;
        }
      }
    }

    .el-radio__label {
      font-size: 0.6rem;

      .el-textarea {
        .el-textarea__inner {
          min-height: 1.5rem !important;
          color: #5c5a5a;
          font-size: 0.25rem;
        }
      }
    }
  }
}
</style>
