<template>
  <div class="login_container">
    <div class="bg"></div>
    <div class="login_box">
      <!-- 头像 -->
      <div class="avatar_box">
        <img src="../../assets/loginn.png" alt />
      </div>
      <!-- 表单 -->
      <el-form :model="loginForm" label-width="0" class="login_form" :rules="LoginFormRules" ref="LoginFormRef">
        <el-form-item>
          <p class="title">{{ $t('modify.registration') }}</p>
        </el-form-item>
        <el-form-item prop="username" class="pwd_wrap">
          <el-input :placeholder="$t('login.usernames')" v-model="loginForm.username" @keyup.enter.native="isEnter()"
            prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 手机号-->
        <el-form-item prop="phone" class="pwd_wrap">
          <el-input :placeholder="$t('register.number')" v-model="loginForm.phone"
            prefix-icon="el-icon-phone"></el-input>
        </el-form-item>
        <el-form-item prop="verification">
          <el-input :placeholder="$t('register.code')" v-model="loginForm.verification"></el-input>
          <el-button class="verifBnt" @click="getverifBnt" v-if="isVerif">{{ $t('register.Obtain') }}</el-button>
          <el-button class="verifBnt verifBnt2" disabled v-else>{{ seconds }}{{ $t('register.seconds') }}</el-button>
        </el-form-item>
        <!-- 密码-->
        <el-form-item prop="password" class="pwd_wrap">
          <el-input :placeholder="$t('register.phone')" v-model="loginForm.password" prefix-icon="el-icon-lock"
            type="password"></el-input>
        </el-form-item>
        <!-- 再次输入密码-->
        <el-form-item prop="comfirpsd">
          <el-input :placeholder="$t('register.reEnter')" v-model="loginForm.comfirpsd" prefix-icon="el-icon-unlock"
            type="password"></el-input>
        </el-form-item>
        <!-- 收货地址-->
        <!--    <el-form-item prop="adress" class="pwd_wrap">
          <el-input :disabled="edit" placeholder="请输入收货地址" v-model="loginForm.adress" prefix-icon="el-icon-location-outline"></el-input>
          <i class="el-icon-menu pws_edit" @click="openEditadss"></i>
        </el-form-item> -->
        <el-form-item class="btns">
          <el-button type="danger" @click="saveMessge">{{ $t('modify.informations') }}</el-button>
          <!-- <el-button type="success" @click="pwdChange">修改密码</el-button> -->
          <!-- <el-button type="primary" @click="tocart">已购商品</el-button> -->
          <!-- <el-button type="info" @click="$router.back(-1)">返回</el-button> -->
        </el-form-item>
      </el-form>
      <changePsd ref="changePwd" :curretUser="curretUser"></changePsd>
      <adressview ref="addressBox"></adressview>
    </div>
  </div>
</template>

<script>
import { toBase64 } from "../../api/base64.js";
import changePsd from "./changePsd.vue";
import adressview from "./adressview.vue";
import axios from '../../api/axios'
import Cookies from 'js-cookie'
export default {
  components: {
    changePsd,
    adressview
  },
  data() {
    const comfpsd = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t('register.passwordss')));
      } else if (value !== this.loginForm.password) {
        callback(new Error(this.$t('register.match')));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

      if (!value) {
        return callback(new Error(this.$t('register.empty')))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error(this.$t('register.numerical')))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error(this.$t('register.Incorrect')))
          }
        }
      }, 100)
    }
    return {
      isVerif: true,
      hadSave: false,
      seconds: 10,
      curretUser: '',
      istoEnter: false,
      loginForm: {
        username: "",
        password: "",
        phone: "",
        verification: '',
        comfirpsd: ""
      },
      LoginFormRules: {
        username: [{ required: true, message: this.$t('register.enter'), trigger: "blur" }],
        password: [{ required: true, message: this.$t('register.passwords'), trigger: "blur" }],
        verification: [{ required: true, trigger: 'blur', message: this.$t('register.code') }],
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
        comfirpsd: [{ required: true, trigger: "blur", validator: comfpsd }]
      },
    };
  },
  created() {
    var wxCode = window.localStorage.getItem("wxCode")
    if (!wxCode) {
      // 已经绑定微信则跳回首页
      this.$router.push({ path: "/homePage" });
    }
  },
  methods: {
    checkVerifyCode() {
      var url = '/reach/portal/api/user/checkVerifyCode'
      var checkResult = axios.post(url, {}, {
        params: {
          phone: this.loginForm.phone,
          verifyCode: this.loginForm.verification
        }
      })
      return checkResult
    },
    async saveMessge(e) {
      var that = this;
      var checkResult = await this.checkVerifyCode()
      console.log(checkResult.data)
      if (checkResult.data.code === 0) {
        this.$my_confirm(this.$t('register.error'), this.$t('login.prompt'), {
          type: "warn",
          cancelBtnText: this.$t('login.sure'),
          otherBtnText: "",
          yesBtnText: ""
        })
        return
      }
      this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
          that.$my_confirm(this.$t('modify.submission'), this.$t('login.prompt'), {
            type: "warn",
            cancelBtnText: this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => {
              if (action == "yes") {
                var url = '/reach/portal/api/user/wxBingAccount'
                axios.post(url, {}, {
                  params: {
                    username: that.loginForm.username,
                    password: that.$md5(that.loginForm.password),
                    phone: that.loginForm.phone,
                    verification: that.loginForm.verification,
                  }
                }).then(response => {
                  if (response.data.code == 1) {
                    var urll = '/reach/portal/api/user/login'
                    axios.post(urll, {}, {
                      params: {
                        username: that.loginForm.username,
                        password: that.$md5(that.loginForm.password)
                      }
                    }).then(response => {
                      if (response.data.code === 1) {
                        Cookies.set('token', response.data.data.token)
                        window.localStorage.setItem('usertoken', response.data.data.token)
                        window.localStorage.setItem('wxCode', this.wxCode)
                        window.localStorage.setItem('curretUser', response.data.data.username)
                        that.$my_confirm(this.$t('modify.homepage'), this.$t('login.prompt'), {
                          type: "warn",
                          cancelBtnText: this.$t('login.sure'),
                          otherBtnText: "",
                          yesBtnText: ""
                        })
                          .then(action => {
                            if (action == "yes") {
                              that.$router.push({ path: "/homePage" });
                            }
                            else if (action == "other") { }
                          })
                      } else {
                        this.$message.error(response.data.message);
                      }
                    })
                  } else {
                    if (response.data.message === '用户名已注册') {
                      // 如果该用户名已存在，手动触发验证规则，并设置错误信息
                      this.$refs.LoginFormRef.fields.forEach(field => {
                        if (field.prop === 'username') {
                          field.validateState = 'error'; // 设置验证状态为错误
                          field.validateMessage = this.$t('register.userName'); // 设置错误提示信息
                        }
                      });
                    } else {
                      // 其他错误
                      this.$message.error(response.data.message);
                    }
                  }
                })
              }
              else if (action == "other") { }
            })
            .catch(() => { });
        }
      })
    },

    getverifData() {
      var url = '/reach/portal/api/user/getVerifyCode'
      var phoneNumber = this.loginForm.phone
      axios.post(url, {}, {
        params: {
          phone: phoneNumber
        }
      }).then(response => {
        if (response.data.code === 1) {
        }
      })
    },
    async isPhoneRegister() {
      var phoneNumber = this.loginForm.phone
      var url = '/reach/portal/api/user/isPhoneRegister'
      var result = await axios.get(url, {
        params: {
          phone: phoneNumber
        }
      })
      return result
    },
    async getverifBnt() {
      // 先检查手机号码是否为空
      if (this.loginForm.phone === null || this.loginForm.phone === undefined || this.loginForm.phone === '') {
        this.$message.error(this.$t("register.number"));
        return;
      }
      // 请求先看是否存在这个手机号 this.loginForm.phone，再获取
      // this.hadSave?true
      // 点击后xx秒再次获取
      var result = await this.isPhoneRegister()
      if (result.data === true) {
        this.hadSave = true
        // this.$message.error(this.$t("register.registered"));
        // 如果该号码已经注册，手动触发验证规则，并设置错误信息
        this.$refs.LoginFormRef.fields.forEach(field => {
          if (field.prop === 'phone') {
            field.validateState = 'error'; // 设置验证状态为错误
            field.validateMessage = this.$t("register.registered"); // 设置错误提示信息
          }
        });
        return
      } else {
        this.$message.success(this.$t('login.sent'));
        this.hadSave = false
      }
      this.getverifData()
      this.isVerif = false
      var that = this
      var myVar = setInterval(function () {
        --that.seconds
        if (that.seconds == 0) {
          that.isVerif = true
          that.seconds = 60
          clearInterval(myVar)
        }
      }, 1000)
    },
    pwdChange() {
      this.$refs.changePwd.dialogVisible = true;
    },
    tocart() {
      this.$router.push({ path: "/buyTab" });
    },
    openEditadss() {
      this.$refs.addressBox.dialogVisible = true;
    },
    isEnter() {
      this.istoEnter = true;
    },
    async getUserinfo() {
      var that = this;
      this.curretUser = window.localStorage.getItem('curretUser') || '';
      let res = await this.$Net.get(
        "/f/eshopping/eshoppinglogin/userlist",
        { username: that.curretUser }
      );
      that.$getSame(that.loginForm, res[0]);
      that.loginForm.createDate = that.$timestampToTime(res[0].createDate);
      that.loginForm.updateDate = that.$timestampToTime(res[0].updateDate);
    },
    async getAdress() {
      var that = this;
      var usertoken = window.localStorage.getItem("usertoken") || "";
      let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/addressList", { "eshoppingtoken": usertoken });
      that.loginForm.adress = res[0].province + res[0].city + res[0].district + res[0].address;
    },
  },
};
</script>
<style lang="less" scoped>
.login_container {
  // background-color: #2b4b6b;
  // background-image: linear-gradient(to right , #2b4b6b, skyblue);
  // filter: blur(20px);
  height: 10rem;
  padding-top: 9.5rem;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #2b4b6b, skyblue);
  // filter: blur(20Px);
}

.login_box {
  width: 10rem;
  // height: 4rem;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%)
}

.avatar_box {
  width: 80Px;
  height: 80Px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 64%;
    height: 69%;
    //   border-radius: 50%;
    background-color: #eee;
  }
}

.login_form {
  /* position: absolute;
    bottom: 5%; */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 1rem;
}

.btns {
  display: flex;
  justify-content: center;
}

.login_box /deep/ .el-input {
  font-size: 20Px;
}

.login_box /deep/ .el-input__inner {
  height: 0.9rem;
  line-height: 0.4rem;
  width: 98%;
  padding-left: 0.8rem;
}

.login_box /deep/ .el-form-item {
  margin-bottom: 0.35rem;
}

.login_box /deep/ .el-form-item__error {
  left: 1%;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  width: 2rem;
  // height: 0.6rem;
  font-size: 19Px;
}

.pwd_wrap {
  position: relative;

  .pws_edit {
    position: absolute;
    right: 3%;
    top: 33%;
    font-size: 17Px;
    cursor: pointer;
    display: none;
  }

  &:hover {
    .pws_edit {
      display: block;
    }
  }
}

.verifBnt {
  width: 1.9rem !important;
  height: 0.9rem !important;
  font-size: 15Px !important;
  position: absolute;
  right: 0.65%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(219, 219, 219, 1) 100%);
  box-sizing: border-box;
  border-width: 1Px;
  border-style: solid;
  border-color: rgba(212, 212, 212, 1);
  border-radius: 5Px;
  border-top-left-radius: 0Px;
  border-bottom-left-radius: 0Px;
  box-shadow: none;
  color: #848484;
  text-align: center;

  &:hover {
    border-radius: 5Px;
    background: #53a8ff; //不能用background-color无效
    color: #fff;
  }
}

.verifBnt2 {
  width: 2.5rem !important;
}

.title {
  font-size: 0.4rem;
  font-weight: 600;
  margin: 0.4rem;
}
</style>
