<template>
  <div class="content_all">
    <banner :type="BannerType" style="padding-bottom:0px"></banner>
    <div class="content_inner">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('cooperate.Partners')" name="first">
          <div v-for="(item, index) in compoentList" :key="index" class="firentWrap">
            <serve style="padding-bottom: 0px;" :cooper=true :showBackgd=false :sendData='item'
              :styleName='item.styleName'></serve>
            <a @click="toPath(1)" class="contract"><el-button v-if="index == 0">{{
              $t('cooperate.Registration') }}>></el-button></a>
            <a @click="toPath(2)" target="_blank" class="RIV"><el-button v-if="index == 1">{{
              $t('heard.Mall') }}>></el-button></a>
          </div>
          <div class="cooImg">
            <p class="altitle">{{ $t('cooperate.Partners') }}</p>
            <div class="imgWrap">
              <img :src="item.image" v-for="(item, index) in cooperationList" :key="index" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('cooperate.success')" name="second">
          <p class="altitle">{{ $t('cooperate.success') }}</p>
          <div class="coopCotenWrap">
            <div class="docWrap" v-for="(item, index) in successData.successList" :key="index">
              <div class="top">
                <a :href="item.link"><img :src="item.image" /></a>
              </div>
              <div class="bottom">
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
          <div>
            <el-pagination @current-change="handleCurrentChange" :prev-text="$t('help.Previous')"
              :next-text="$t('help.Next')" layout="prev, pager, next" :page-size="6" :current-page.sync="currentPage"
              :total="successData.total">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import banner from '../../components/homePages/banner.vue'
import serve from '../../components/customPart/serve.vue'
import axios from '../../api/axios'
export default {
  name: 'cooperation',
  components: {
    banner,
    serve
  },
  data() {
    return {
      BannerType: 'cooperation',
      currentPage: 1,
      activeName: 'first',
      successData: {
        total: 7,
        successList: [
          {
            dataImage: require('../../assets/u252.svg'),
            dataTitle: '智能水表在地下管井的应用',
            downLink: 'https://www.baidu.com/'
          },
          {
            dataImage: require('../../assets/u252.svg'),
            dataTitle: '智能水表在地下管井的应用',
            downLink: 'https://www.baidu.com/'
          },
          {
            dataImage: require('../../assets/u252.svg'),
            dataTitle: '智能水表在地下管井的应用',
            downLink: 'https://www.baidu.com/'
          },
          {
            dataImage: require('../../assets/u252.svg'),
            dataTitle: '智能水表在地下管井的应用',
            downLink: 'https://www.baidu.com/'
          },
          {
            dataImage: require('../../assets/u252.svg'),
            dataTitle: '智能水表在地下管井的应用',
            downLink: 'https://www.baidu.com/'
          },
          {
            dataImage: require('../../assets/u252.svg'),
            dataTitle: '智能水表在地下管井的应用',
            downLink: 'https://www.baidu.com/'
          }
        ]
      },
      //合作伙伴
      compoentList: [
        {
          styleName: 'introduce',
          title: this.$t('cooperate.REACH'),
          content: this.$t('help.Ruiqi'),
          link: 'https://www.baidu.com/'
        }
      ],
      //合作伙伴的log
      cooImgList: [
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png'),
        require('../../assets/cooLog.png')
      ],
      cooperationList: []
    }
  },
  watch: {
    currentPage: function (val) { // 当前页码
    },
    $route: {
      handler(val, oldVal) {
        if (val.query.succs) {
          this.activeName = 'second'
        }
        else {
          this.activeName = 'first'
        }
      },
      deep: true,
    }
  },
  created() { },
  mounted() {
    if (this.$route.query.succs) {
      this.activeName = 'second'
    }
    this.getCooperationList()
    this.getSuccCaseList()
  },
  methods: {
    toPath(e) {
      if (e == 1) {
        this.$router.push({ path: '/contract' })
      }
      else {
        this.$router.push({ path: '/storePage' })
      }
    },
    getCooperationList() {
      var url = '/reach/portal/api/cooperate/getCooperationList'
      axios.get(url, {
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.cooperationList = response.data.data
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getSuccCaseList()
    },
    getSuccCaseList() {
      var url = '/reach/portal/api/cooperate/getSuccCaseList'
      axios.get(url, {
        params: {
          pageIndex: this.currentPage,
          pageSize: 6,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.successData.total = response.data.data.total
          this.successData.successList = response.data.data.succCaseList
          console.log(response.data.data.succCaseList)
        }
      })
    }
  }
}
</script>
<style  scoped>
.heart .el-col-8 {
  width: 18.33333%;
}

.content_all .el-button--primary {
  /* width: 2.8rem;
  height: 0.63rem;
  line-height: 0.4rem; */
  border-radius: 0.15rem;
  background-color: rgba(31, 186, 243, 1);
}

.product_row {
  padding: 0 28px 0 27px;
}

.product_row .el-col-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boderCol:after {
  width: 3.22rem;
  height: 0.1rem;
  background-color: #ffde5d;
  content: "";
  position: absolute;
  left: -0.067708rem;
  top: 3.24rem;
  z-index: 10;
}

.product_row .el-card {
  width: 3rem;
  height: 3rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

.product_row .el-card__body {
  padding: 0px;
  color: #a4a4a4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.news_heart p {
  padding: 15px 0 19px 0;
}

.news_heart .el-card {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.news_heart .el-card__body {
  padding: 0;
}

.new_indro {
  padding: 0 8px 0 10px;
  text-align: left;
}

.news_heart .new_indro span {
  font-size: 0.25rem;
  color: #827c7c;
}

.news_heart .new_indro .news_title {
  display: block;
  font-weight: 600;
  font-size: 0.28rem;
  color: #3c3b3c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.1rem;
}

.news_heart img {
  width: 100%;
}
</style>
<style scoped lang="less">
.content_all {
  padding-bottom: 2rem;

  .topWrap {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    height: 3rem;
    background: url(../../assets/vedioBack.png) no-repeat;
    background-size: cover;
  }

  .content_inner {
    width: 100%;
    margin: 0 auto;

    .firentWrap {
      position: relative;

      .el-button {
        background-color: #3ac0f1;
        color: #fff;
        border-radius: 0.17rem;
        width: 2.3rem;
        // height: 0.6rem;
      }

      .contract {
        position: absolute;
        top: 69%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .RIV {
        position: absolute;
        bottom: 2%;
        left: 45%;
        transform: translate(0, -50%);
      }
    }

    .cooImg {
      .imgWrap {
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        margin: 0 auto;
        justify-content: space-around;
        margin-top: 0.5rem;

        img {
          width: 15%;
        }
      }
    }

    .el-tabs {
      /deep/ .el-tabs__content {
        .el-tab-pane {
          .coopCotenWrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 0.7rem;

            .docWrap {
              width: 30%;
              margin: 0 0.5rem 0.5rem 0;

              .top {
                width: 100%;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .bottom {
                margin-top: 0.3rem;

                p {
                  display: inline-block;
                  font-size: 0.3rem;
                  font-weight: 600;
                }

                a {
                  color: #22b0ee;
                  font-size: 0.3rem;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    /deep/ .el-tabs {
      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__nav-wrap {
        height: 0.8rem;
        line-height: 0.8rem;
        padding-left: 0.5rem;

        .el-tabs__nav-scroll {
          display: flex;
          justify-content: center;
        }
      }

      .el-tabs__active-bar {
        height: 0.07rem;
      }

      .el-tabs__item {
        padding: 0 0.4rem;
        font-size: 0.3rem;
      }
    }

    /deep/ .el-pagination {
      button {
        border: 0.03rem #b9bfc1 solid;
        padding: 0.2rem;
        color: #39a9d9;
        display: inline-block;
        font-size: 0.3rem;
        min-width: 0.6rem;
        height: 0.7rem;
      }

      .btn-prev {
        border-radius: 0.1rem 0 0 0.1rem;
      }

      .btn-next {
        border-left: none;
        border-radius: 0 0.1rem 0.1rem 0;
      }

      .el-pager li {
        padding: 0 0.25rem;
        border: 0.03rem #b9bfc1 solid;
        border-left: none;
        font-size: 0.3rem;
        height: 0.7rem;
        line-height: 0.6rem;
      }

      .el-pager li.active {
        color: #fff;
        background-color: #32a6d8;
      }
    }
  }
}</style>
