import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import axios from 'axios'
import './assets/css/base.scss'
import fastclick from 'fastclick'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Net from "./api/net.js";
import {toBase64,base64ToString} from "./api/base64.js";
import { msgboxx,getSame,timestampToTime,isphone } from "./commethods/commethods.js";
import "amfe-flexible";
import 'lib-flexible/flexible'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import qs from 'qs'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import md5 from 'js-md5'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'
import Confirm from '@/components/Confirm/Confirm'//Confirm组件
import i18n from '@/lang/index'
Vue.config.productionTip = false//阻止启动生产消息，常用作指令  消息提示的环境配置，设置为开发环境或者生产环境
Vue.prototype.$my_confirm = Confirm.install;//Confirm组件



//字体图标开始
import fontawesome from '@fortawesome/fontawesome'
import * as FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'
import regular from '@fortawesome/fontawesome-free-regular'
import brands from '@fortawesome/fontawesome-free-brands'

fontawesome.library.add(solid)
fontawesome.library.add(regular)
fontawesome.library.add(brands)

Vue.component('font-awesome-icon', FontAwesomeIcon)
//字体图标结束
Vue.use(VueAwesomeSwiper)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': false, 'title': true, 'toolbar': false, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
fastclick.attach(document.body)
Vue.use(ElementUI)
Vue.use(less)
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
VueTouch.config.swipe = {
  threshold: 100, //手指左右滑动距离
  direction: "horizontal"
}
import $ from 'jquery'
Vue.prototype.$ = $;
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$md5 = md5
Vue.prototype.$toBase64 = toBase64;
Vue.prototype.$base64ToString = base64ToString;
Vue.prototype.$Net=Net;  //其中$xx为新命的名。
Vue.prototype.$getSame=getSame;
Vue.prototype.$msgboxx=msgboxx;
Vue.prototype.$timestampToTime=timestampToTime;
Vue.prototype.$isphone=isphone;
Vue.config.productionTip = false;
ElementUI.Dialog.props.closeOnClickModal.default = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
